import { NgModule } from '@angular/core';
import { RouterStates } from '../../../../core/router-states.constant';
import { CoreComponentsModule } from '../../../../core-components.module';
import { UIRouterModule } from '@uirouter/angular';
import { ViewUserComponent } from './view-user.component';
import { UserBasicModule } from './user-basic/user-basic.module';
import { UserTeamsModule } from './user-teams/user-teams.module';
import { UserPermissionsModule } from './user-permissions/user-permissions.module';

let viewUserState = { name: RouterStates.dashboard_user_view, url: '/users/:userId', params: { userName: '' }, component: ViewUserComponent };

@NgModule({
  declarations: [
    ViewUserComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [viewUserState]
    }),
    UserBasicModule,
    UserTeamsModule,
    UserPermissionsModule
  ],
  exports: [
    
  ],
  providers: [
  ],
  bootstrap: []
})
export class ViewUserModule { }
