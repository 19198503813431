
import {of as observableOf,  Observable } from 'rxjs';

import {map,  take } from 'rxjs/operators';
import { Component, ViewChild, OnChanges, SimpleChanges, Input, OnInit, AfterViewInit } from '@angular/core';
import { MatTableDataSource, MatDialog, MatSelectChange, MatDialogRef } from '@angular/material';
import { IOperationTemplateBase, IOperationTemplate, IOperationTemplateSet } from '../../../../api/operations/operation-template.model';
import { ProcedureRequestBase } from '../../../../api/operations/procedure-request.model';
import { PlanPeriod } from '../../../../api/plans/plan-period.model';
import { IProcedureComponent } from '../../../procedures/procedure-component-interface';
import { DashboardApiService } from '../../../../api/dashboard-api.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DialogCaption, DialogNoButton, DialogYesButton } from '../../../../core/dialog.service';
import { ProcedureTypes, getCreateableProcedureTypes } from '../../../../api/operations/procedure-types.enum';
import { OperationTemplateTypes } from '../../../../api/operations/operation-template-types.enum';
import * as _ from 'underscore';
import { ViewTemplateComponent } from '../../../../sections/dashboard/operation-template/edit-template/view-template/view-template.component';
import { OperationKey } from '../../../../api/operations/operation-key.model';

@Component({
    templateUrl: './operation-template-selector.component.html',
    styleUrls: ['./operation-template-selector.component.scss'],
    selector: 'operation-template-selector'
})
export class OperationTemplateSelectorComponent implements OnInit, OnChanges, AfterViewInit {

    @Input() public allowedProcedureTypes: ProcedureTypes[];
    @Input() public isNavigating: boolean;
    public operationTemplatesDatasource: MatTableDataSource<IOperationTemplateBase> = new MatTableDataSource<IOperationTemplateBase>();
    public templateColumns: string[] = ['selector', 'name', 'type', 'repetition', 'summary', 'buttons'];
    public procedureTypes: ProcedureTypeModel[] = [];
    public selectedProcedureType: ProcedureTypeModel = undefined;
    public repetitionCount = 1;
    public repetitionPeriod = PlanPeriod.Default();
    public editProcedure: ProcedureRequestBase;
    public selectedTemplate: IOperationTemplateBase = null;
    public tabIndex: SelectorTabs = SelectorTabs.FromTemplate;

    private operationTemplates: IOperationTemplateBase[] = [];
    @ViewChild('procedureEditor', { static: false }) private procedureEditor: IProcedureComponent;

    constructor(
        private api: DashboardApiService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private mdDialog: MatDialog) {
        
            
    }

    public ngOnInit() {
        this.allowedProcedureTypes = this.getDefaultProcedureTypes();
        this.updateAvailableProcedureTypes();
    }

    public ngOnChanges(changes: SimpleChanges) {
        
        if (changes.allowedProcedureTypes != null && changes.allowedProcedureTypes.currentValue != null) {

            let allowedProcedureTypes = changes.allowedProcedureTypes.currentValue;
            if (allowedProcedureTypes == null || allowedProcedureTypes.length === 0) {
                allowedProcedureTypes = this.getDefaultProcedureTypes();
            }
            
            this.updateAvailableProcedureTypes();
        }
    }

    public ngAfterViewInit() {
        this.getOperationTemplates()
            .subscribe(templates => {
                this.operationTemplatesDatasource.data = templates;
            },
            err => console.error(err)
        );
        setTimeout(() => {
            this.loadProcedureTypeNames();
        }, 50);
    }
    
    public procedureTypeChange(change: MatSelectChange) {
        if (change.value != null) {
            let procedureType = <ProcedureTypes>change.value;
            if (this.editProcedure != null) {
                this.dialogService.showDialog(
                    new DialogCaption('EDIT_OPERATION_TEMPLATE.TEMPLATE.CHANGE_PROCEDURE'),
                    new DialogYesButton(() => this.editProcedure = ProcedureRequestBase.CreateProcedure(procedureType)),
                    new DialogNoButton()
                );
            }
            else {
                this.editProcedure = ProcedureRequestBase.CreateProcedure(procedureType);
            }
        }
    } 

    public canMoveNext(): boolean {
        return (this.tabIndex === SelectorTabs.FromTemplate && this.selectedTemplate != null)
                || (this.tabIndex === SelectorTabs.CreateNew && this.editProcedure != null);
    }

    public getSelectedTemplates(): Observable<IOperationTemplate[]> {
        if (this.tabIndex === SelectorTabs.FromTemplate) {
            if (this.selectedTemplate == null) {
                return observableOf<IOperationTemplate[]>([]).pipe(take(1));
            }

            if (this.selectedTemplate.type === OperationTemplateTypes.Template) {
                let op = <IOperationTemplate>this.selectedTemplate;
                op.offset = null;
                return observableOf<IOperationTemplate[]>([op]).pipe(take(1));
            }
            else if (this.selectedTemplate.type === OperationTemplateTypes.TemplateSet) {
                let set = <IOperationTemplateSet>this.selectedTemplate;
                return this.loadTemplates(set.templates).pipe(
                            map(templates => _.chain(templates)
                                                .filter(t => t.type === OperationTemplateTypes.Template)
                                                .map(t => <IOperationTemplate>t)
                                                .value()),
                            map(templates => {
                                for (let template of templates) {
                                    let settings = set.childSettings[template.identifier.key];
                                    if (settings != null && settings.offset != null) {
                                        template.offset = settings.offset;
                                    }
                                    else {
                                        template.offset = null;
                                    }
                                }
                                return templates;
                            }),);    
            }
            else {
                console.error('Template type not recognised in getSelectedTemplates()');
                return observableOf<IOperationTemplate[]>([]).pipe(take(1));
            }
        }
        else if (this.tabIndex === SelectorTabs.CreateNew) {
            return new Observable(subscriber => {
                this.validateNewTemplate()
                    .subscribe(isValid => {
                        if (!isValid) {
                            subscriber.error('Template is invalid.');
                        }
                        else {
                            subscriber.next([this.convertInputToTemplate()]);
                        }
                    },
                    err => subscriber.error(err),
                    () => subscriber.complete());
            });
        }
    }

    public viewOperationTemplate(operation: IOperationTemplateBase) {
        this.showTemplateModal(operation);
    }

    private convertInputToTemplate(): IOperationTemplate {
        let template: IOperationTemplate = {
            type: OperationTemplateTypes.Template,
            repetitionCount: this.repetitionCount,
            repetitionPeriod: this.repetitionPeriod,
            procedure: this.editProcedure,
            name: null,
            identifier: null,
            isArchived: false
        };
        return template;
    }

    private validateNewTemplate(): Observable<boolean> {
        return observableOf<boolean>(true).pipe(take(1));
    }

    private updateAvailableProcedureTypes() {
        this.procedureTypes = [];
        for (let type of this.allowedProcedureTypes) {
            this.procedureTypes.push({ key: type, displayName: type });
        }
    }

    private loadTemplates(templateKeys: OperationKey[]): Observable<IOperationTemplateBase[]> {
        let observables = [];
        if (templateKeys.length === 0) {
            return observableOf<IOperationTemplateBase[]>([]).pipe(take(1));
        }
        let keys = _.map(templateKeys, k => k.version != null ? (k.key + '|' + k.version) : k.key);
        return this.api.operationTemplates
                .getOperationTemplates(OperationTemplateTypes.Template, keys, true).pipe(
                map((v, i) =>
                     _.filter(v.items, t => this.allowedProcedureTypes.indexOf((<IOperationTemplate>t).procedure.type) !== -1)
                ));
    }
    
    private getDefaultProcedureTypes(): ProcedureTypes[] {
        return getCreateableProcedureTypes();
    }

    private getOperationTemplates(): Observable<IOperationTemplateBase[]> {
        let self = this;
        return this.api.operationTemplates.getOperationTemplates().pipe(
            map((c, i) => {
                let templateSets = _.chain(c.items).filter(t => t.type === OperationTemplateTypes.TemplateSet).value();
                let templates = _.chain(c.items).filter(t => t.type === OperationTemplateTypes.Template).value();
                
                let combinedWithMatch = templateSets
                    .concat(templates)
                    .map(v => <MatchWrapped<IOperationTemplateBase>>{ match: /^(?:\W+)?(?:【|\[)\W?([[0-9a-z０－９ａ－ｚ])\W?(?:】|\])/gmiu.exec(v.name), value: v })
                    .sort((a, b) => self.sortFunc(a, b));

                return combinedWithMatch.map<IOperationTemplateBase>(v => v.value);
            }));
    }

    private sortFunc(a: MatchWrapped<IOperationTemplateBase>, b: MatchWrapped<IOperationTemplateBase>): number {

        let order = [OperationTemplateTypes.TemplateSet, OperationTemplateTypes.Template];
        let orderA = order.indexOf(a.value.type);
        let orderB = order.indexOf(b.value.type);

        if (orderA === orderB) {
            if (a.match == null && b.match != null) {
                return 1;
            }
            else if (a.match != null && b.match == null) {
                return -1;
            }
            else if (a.match == null && b.match == null) {
                return a.value.name.localeCompare(b.value.name);
            }

            let aValue = this.fixNumber(this.toHalfwidth(a.match[1]));
            let bValue = this.fixNumber(this.toHalfwidth(b.match[1]));
            return aValue.localeCompare(bValue);            
        }
        else if (orderA > orderB) {
            return 1;
        }
        else {
            return -1;
        }
    }

    private toHalfwidth(value: string): string {
        return value.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) { return String.fromCharCode(s.charCodeAt(0) - 0xFEE0); });
    }

    private fixNumber(value: string): string {
        let numericVal = Number(value);
        if (Number.isNaN(numericVal)) {
            return value;
        }
        value = numericVal.toString();
        while (value.length < 5) {
            value = '0' + value;
        }
        return value;
    }

    private loadProcedureTypeNames() {
        // update the existing procedure types with the updated translations
        let translateKeys = [];
        let mapping: { [id: string]: ProcedureTypeModel } = {};
        for (let proc of this.procedureTypes) {
            let transKey = 'SHARED_OPERATIONS.PROCEDURES.' + proc.key.toUpperCase();
            translateKeys.push(transKey);
            mapping[transKey] = proc;
        }
        if (translateKeys.length === 0) { return; }
        this.translateService.get(translateKeys)
            .subscribe(
                translations => {
                    for (let k in translations) {
                        if (translations.hasOwnProperty(k)) {
                            mapping[k].displayName = translations[k];
                        }
                    }
                });
    }

    private showTemplateModal(template: IOperationTemplateBase): MatDialogRef<ViewTemplateComponent>  {
        return this.mdDialog.open(ViewTemplateComponent, { 
            width: template.type === OperationTemplateTypes.Template ? '550px' : '900px',
            data: {
                operation: template
            }
        });
    }
}

enum SelectorTabs {
    FromTemplate = 0,
    CreateNew = 1
}

interface ProcedureTypeModel {
    key: string;
    displayName: string;
}

interface MatchWrapped<T> {
    value: T;
    match: RegExpMatchArray;
}

