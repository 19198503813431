import { NgModule } from '@angular/core';
import { CoreModulesModule } from '../../core-modules.module';
import { LoginProviderComponent } from './login-provider.component';

@NgModule({
  declarations: [
    LoginProviderComponent
  ],
  imports: [
      CoreModulesModule
  ],
  exports: [
    LoginProviderComponent
  ],
  providers: [
  ],
  bootstrap: [
    LoginProviderComponent
  ]
})
export class LoginProviderModule { }
