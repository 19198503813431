import { Injectable } from '@angular/core';
import {
  HttpRequest,
  HttpHandler,
  HttpEvent,
  HttpInterceptor,
  HttpErrorResponse
} from '@angular/common/http';
import { Observable, of, throwError } from 'rxjs';
import { catchError, delay, switchMap } from 'rxjs/operators';
import { AuthenticationService } from './authentication.service';

@Injectable()
export class RefreshTokenInterceptor implements HttpInterceptor {

  constructor(
    private authService: AuthenticationService) {}

  intercept(request: HttpRequest<unknown>, next: HttpHandler): Observable<HttpEvent<unknown>> {
    return this.interceptImpl(request, next, 1);
  }

  private interceptImpl(request: HttpRequest<unknown>, next: HttpHandler, count: number): Observable<HttpEvent<unknown>> {
    return next.handle(request)
      .pipe(catchError((err: any) => {
          if ( err instanceof HttpErrorResponse ) {
              switch (err.status) {
                  case 401:
                    return this.authService.isAuthenticated()
                      .pipe(switchMap(isAuthenticated => {

                          return isAuthenticated
                            ? this.refreshAndRetry(request, next, err, count)
                            : throwError(() => err);

                      }));
              }
          }
          
          return throwError(() => err);
      }));
  }

  private refreshAndRetry(request: HttpRequest<unknown>, next: HttpHandler, err: HttpErrorResponse, count: number) : Observable<HttpEvent<unknown>> {

    const minDuration = 100; //ms
    const maxDuration = 1000; // ms
    const randomDuration = Math.floor(Math.random() * (maxDuration - minDuration)) + minDuration;

    return of(delay(randomDuration))
      .pipe(
        // refresh the token and retry the request
        switchMap(_ => this.authService.refreshToken()),
        switchMap((accessToken, _) => {
          if (!accessToken) {
            this.authService.logout();
            return throwError(() => err);
          }

          const authReq = request.clone({
            headers: request.headers.set('Authorization', 'Bearer ' + accessToken)
          });
          // console.log(`Refreshed and injected ${response.accessToken}`);

          // If we still fail due to a 401, we should logout and redirect the user to the main page
          return next.handle(authReq)
            .pipe(catchError((err: any, caught) => {
                if ( err instanceof HttpErrorResponse ) {
                    switch (err.status) {
                        case 401:
                          if (count < 3) {
                            return this.interceptImpl(request, next, count + 1);
                          }
                          this.authService.logout();
                    }
                }            
                return throwError(() => err);
            }));
        }))
  }
}
