import { NgModule } from '@angular/core';
import { WelcomeModule } from './welcome/welcome.module';
import { AuthOkModule } from './authok/authok.module';

@NgModule({
  declarations: [],
  imports: [
      WelcomeModule,
      AuthOkModule
  ],
  providers: [
  ],
  bootstrap: []
})
export class LoginModule { }
