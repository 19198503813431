import { ApiHttp } from '../../core/api-http';
import { Observable } from 'rxjs';
import { IConnectivityTest } from './connectivity-test.model';

export class DiagnosticsResource {

    constructor(private http: ApiHttp) {

    }

    public getConnectivityTestResult(testId: string): Observable<IConnectivityTest> {
        let url = '/v1/diagnostics/connectivityresults/:testId';
        url = url.replace(':testId', testId);
        return this.http.getTyped<IConnectivityTest>(url);
    }

}