import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { ViewTaskComponent } from './view-task.component';
import { PrettyJsonModule } from 'angular2-prettyjson';
import { TaskBasicComponent } from './task-basic/task-basic.component';
import { TaskParametersComponent } from './task-parameters/task-parameters.component';
import { TaskRunsComponent } from './task-runs/task-runs.component';
import { TaskRelatedComponent } from './task-related/task-related.component';
import { CdkTableModule } from '@angular/cdk/table';
import { TasksModule } from 'app/components/tasks/tasks.module';

let viewTaskState = { name: RouterStates.dashboard_tasks_view, url: '/tasks/:taskId', component: ViewTaskComponent };

@NgModule({
    declarations: [
        ViewTaskComponent,
        TaskBasicComponent,
        TaskParametersComponent,
        TaskRelatedComponent,
        TaskRunsComponent
    ],
    imports: [
        CoreComponentsModule,
        PrettyJsonModule,
        CdkTableModule,
        TasksModule,
        UIRouterModule.forChild({
          states: [viewTaskState]
        })
    ],
    providers: [
        ViewTaskComponent
    ]
})
export class ViewTaskModule { }
