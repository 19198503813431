import { Component, Inject, SecurityContext } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelectChange } from '@angular/material';
import * as moment from 'moment';
import * as _ from 'underscore';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';
import { dictionaryToArray } from '../../../../core/dictionary-to-array';

@Component({
    templateUrl: './download-emails-modal.component.html',
    styleUrls: ['./download-emails-modal.component.scss']
})
export class DownloadEmailsModalComponent {
    
    public roleOrder = ['administrator', 'manager', 'editor', 'member', 'readonly'];
    public checkedRoles: {[id: string]: boolean} = {};
    public get anySelected(): boolean { return _.any(dictionaryToArray(this.checkedRoles), r => r.value === true); }
    
    constructor(
        public dialogRef: MatDialogRef<DownloadEmailsModalComponent>,
        private sanitizer: DomSanitizer,
        private translate: TranslateService) {

        for (let role of this.roleOrder) {
            this.checkedRoles[role] = false;
        }
        this.checkedRoles['administrator'] = true;
        this.checkedRoles['manager'] = true;
        this.checkedRoles['editor'] = true;
        this.checkedRoles['member'] = true;
    }

    public close() {
        this.dialogRef.close({generate: false});
    }

    public confirm() {
        let checkedRoles = _.chain(dictionaryToArray(this.checkedRoles)).filter(r => r.value === true).map(r => r.key).value();
        this.dialogRef.close({generate: true, roles: checkedRoles});
    }
}
