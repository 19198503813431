import { NgModule } from '@angular/core';
import { StorageAssignmentProcedureModule } from './storage-assignment-procedure/storage-assignment-procedure.module';
import { MemberLimitProcedureModule } from './member-limit-procedure/member-limit-procedure.module';
import { SupporterLimitProcedureModule } from './supporter-limit-procedure/supporter-limit-procedure.module';
import { ProceduresContainerComponent } from './procedures-container.component';
import { CoreComponentsModule } from '../../core-components.module';
import { ExpirePlanWarningProcedureModule } from './expire-plan-warning-procedure/expire-plan-warning-procedure.module';
import { ExpirePlanProcedureModule } from './expire-plan-procedure/expire-plan-procedure.module';
import { UpdateStorageStatsProcedureModule } from './update-storage-stats-procedure/update-storage-stats-procedure.module';
import { SendEmailProcedureModule } from './send-email-procedure/send-email-procedure.module';
import { MediaOptionsProcedureModule } from './media-options-procedure/media-options-procedure.module';
import { EnsurePlanProcedureModule } from './ensure-plan-procedure/ensure-plan-procedure.module';

@NgModule({
  declarations: [
    ProceduresContainerComponent
  ],
  imports: [
    CoreComponentsModule,
    StorageAssignmentProcedureModule,
    UpdateStorageStatsProcedureModule,
    MemberLimitProcedureModule,
    SupporterLimitProcedureModule,
    ExpirePlanWarningProcedureModule,
    ExpirePlanProcedureModule,
    SendEmailProcedureModule,
    MediaOptionsProcedureModule,
    EnsurePlanProcedureModule
  ],
  exports: [
    ProceduresContainerComponent
  ],
  providers: [
  ],
  bootstrap: [
    ProceduresContainerComponent
  ]
})
export class ProceduresModule { }
