import { Component, Input } from '@angular/core';

@Component({
    templateUrl: './scrollable.component.html',
    selector: 'scrollable'
})
export class ScrollableComponent {

    @Input() public overflow: string;
    public get overflowStyle(): any { return this.overflowStyles.hasOwnProperty(this.overflow) ? this.overflowStyles[this.overflow] : this.overflowStyles['y']; }    
    private readonly overflowStyles: { [id: string]: any } = {
        'x': { 'overflow-x': 'auto' },
        'y': { 'overflow-y': 'auto' },
        'xy': { 'overflow': 'auto' }
    }

}
