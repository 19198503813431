
import {of as observableOf,  Observable } from 'rxjs';

import {shareReplay,  take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { OperationTemplateTypes } from '../api/operations/operation-template-types.enum';
import { ICollection } from '../api/common/collection.model';
import { IOperationTemplateBase, IOperationTemplate, IOperationTemplateSet } from '../api/operations/operation-template.model';
import * as _ from 'underscore';
import { MatTableDataSource } from '@angular/material';
import { DashboardApiService } from '../api/dashboard-api.service';

@Injectable()
export class OperationTemplateService {

    public get loadingData(): boolean { return this._loadingData; }

    public get templates(): IOperationTemplate[] {
        return _.chain(this.loadedTemplates)
                .filter(t => t.type === OperationTemplateTypes.Template)
                .map(t => <IOperationTemplate>t)
                .value();
    }

    public get templateSets(): IOperationTemplateSet[] {
        return _.chain(this.loadedTemplates)
                .filter(t => t.type === OperationTemplateTypes.TemplateSet)
                .map(t => <IOperationTemplateSet>t)
                .value();
    }

    public templatesDataSource: MatTableDataSource<IOperationTemplate> = new MatTableDataSource<IOperationTemplate>();
    public templateSetsDataSource: MatTableDataSource<IOperationTemplateSet> = new MatTableDataSource<IOperationTemplateSet>();

    private loadedTemplates: IOperationTemplateBase[] = [];
    private _loadingData = true;

    constructor (private api: DashboardApiService) {

    }

    public loadTemplatesIfEmpty(): Observable<ICollection<IOperationTemplateBase>> {
        if (this.loadedTemplates.length === 0) {
            return this.loadTemplates();
        }
        else {
            return observableOf<ICollection<IOperationTemplateBase>>({ items: this.loadedTemplates, total: this.loadedTemplates.length }).pipe(take(1));
        }
    }

    public refresh(): Observable<ICollection<IOperationTemplateBase>> {
        this._loadingData = true;
        return this.loadTemplates();
    } 

    public deleteTemplate(templateIdentifier: string): Observable<IOperationTemplateBase> {
        let result = this.api.operationTemplates
                .deleteOperationTemplate(templateIdentifier)
                .pipe(
                    shareReplay()
                );

        result.subscribe(dt => {
            
            let idx = _.findIndex(this.loadedTemplates, t => t.identifier.key === templateIdentifier);
            if (idx !== -1) {
                let template = this.loadedTemplates[idx];
                this.loadedTemplates.splice(idx, 1);
                this.updateTemplateDataSources();
            }

        }, 
        err => console.error('Unable to delete template:', err));
        return result;
    }

    public mergeTemplate(template: IOperationTemplateBase) {
        this.mergeTemplates([template]);
    }

    private loadTemplates(): Observable<ICollection<IOperationTemplateBase>> {

        let op = this.api.operationTemplates.getOperationTemplates();
        op.subscribe(t => {
                this.mergeTemplates(t.items, true);
            },
            err => null,
            () => this._loadingData = false
        );
        return op;

    }

    private mergeTemplates(templates: IOperationTemplateBase[], deleteMissing?: boolean) {

        let self = this;
        templates = _.map(templates, v => <MatchWrapped<IOperationTemplateBase>>{ match: /^(?:\W+)?(?:【|\[)\W?([[0-9a-z０－９ａ－ｚ])\W?(?:】|\])/gmiu.exec(v.name), value: v })
                    .sort((a, b) => self.sortFunc(a, b))
                    .map(v => v.value);

        if (deleteMissing === true) {
            this.loadedTemplates = _.filter(this.loadedTemplates, t => _.findIndex(templates, lt => lt.identifier.key === t.identifier.key) !== -1);
        }

        for (let template of templates) {
            let idx = _.findIndex(this.loadedTemplates, t => t.identifier.key === template.identifier.key);
            if (idx !== -1) {
                this.loadedTemplates.splice(idx, 1, template);
            }
            else {
                this.loadedTemplates.push(template);
            }
        }

        this.updateTemplateDataSources();
    }

    private updateTemplateDataSources() {
        this.templatesDataSource.data = this.templates;
        this.templateSetsDataSource.data = this.templateSets;
    }

    
    private sortFunc(a: MatchWrapped<IOperationTemplateBase>, b: MatchWrapped<IOperationTemplateBase>): number {

        let order = [OperationTemplateTypes.TemplateSet, OperationTemplateTypes.Template];
        let orderA = order.indexOf(a.value.type);
        let orderB = order.indexOf(b.value.type);

        if (orderA === orderB) {
            if (a.match == null && b.match != null) {
                return 1;
            }
            else if (a.match != null && b.match == null) {
                return -1;
            }
            else if (a.match == null && b.match == null) {
                return a.value.name.localeCompare(b.value.name);
            }

            let aValue = this.fixNumber(this.toHalfwidth(a.match[1]));
            let bValue = this.fixNumber(this.toHalfwidth(b.match[1]));
            return aValue.localeCompare(bValue);            
        }
        else if (orderA > orderB) {
            return 1;
        }
        else {
            return -1;
        }
    }

    private toHalfwidth(value: string): string {
        return value.replace(/[Ａ-Ｚａ-ｚ０-９]/g, function(s) { return String.fromCharCode(s.charCodeAt(0) - 0xFEE0); });
    }

    private fixNumber(value: string): string {
        let numericVal = Number(value);
        if (Number.isNaN(numericVal)) {
            return value;
        }
        value = numericVal.toString();
        while (value.length < 5) {
            value = '0' + value;
        }
        return value;
    }
}

interface MatchWrapped<T> {
    value: T;
    match: RegExpMatchArray;
}

