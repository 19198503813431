
export function getEnumValues(src: any): string[] {
    let output: string[] = [];
    for (let key in src) {
        if (src.hasOwnProperty(key)) {
            output.push(src[key]);
        }
    }
    return output;
}


export function getEnumValuesTyped<TEnumType>(src: any): TEnumType[] {
    let output: TEnumType[] = [];
    for (let key in src) {
        if (src.hasOwnProperty(key)) {
            output.push(src[key]);
        }
    }
    return output;
}
