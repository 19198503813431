import { Component, OnDestroy } from '@angular/core';
import { ITeam } from '../../../../../api/teams/team.model';
import { IUser } from '../../../../../api/users/user.model';
import { TeamService } from '../../team.service';
import { RouterStates } from '../../../../../core/router-states.constant';
import { StateService } from '@uirouter/angular';
import { UserService } from '../../../../../core/user.service';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton, DialogOKButton } from '../../../../../core/dialog.service';
import { MatChipInputEvent, MatDialog } from '@angular/material';
import * as _ from 'underscore';
import { TeamRequest } from '../../../../../api/teams/team-request.model';
import {ENTER, COMMA} from '@angular/cdk/keycodes';
import { deepCopy } from '../../../../../core/deep-copy.function';
import { DeleteTeamModalComponent, IDeleteTeamModalOptions } from './delete-team-modal/delete-team-modal.component';
import { Subscription, Observable } from 'rxjs';
import * as moment from 'moment';
import { IApiError } from 'app/api/error/api-error.model';
import { HttpErrorResponse } from '@angular/common/http';
import { getApiErrors } from 'app/api/error/get-api-errors.function';
import { EnvironmentService } from 'app/core/environment.service';

@Component({
    templateUrl: './team-basic.component.html',
    styleUrls: ['./team-basic.component.scss'],
    selector: 'team-basic'
})
export class TeamBasicComponent implements OnDestroy {

    public isSaving = false;
    public isEditing = false;
    public isDeleting = false;
    public get team(): ITeam { return this.teamService ? this.teamService.team : null; }
    public get isUserAdmin(): boolean { return this.userService.hasRole('Administrator'); }
    public editTeam: ITeam;
    public tagModel: string;
    public separatorKeysCodes = [ENTER, COMMA];
    public isMigratingToCrm = false;
    public migrateSubscription: Subscription;

    constructor(
        private teamService: TeamService,
        private stateService: StateService,
        private userService: UserService,
        private dialogService: DialogService,
        private mdDialog: MatDialog,
        private environmentService: EnvironmentService) {

    }

    public ngOnDestroy() {
        if (this.migrateSubscription != null) {
            this.migrateSubscription.unsubscribe();
        }
    }

    public toggleEditing() {
        if (!this.isEditing) {
            this.editTeam = deepCopy(this.team);
            this.tagModel = '';
            this.isEditing = true;
            return;
        }

        this.save();
    }

    public cancelEditing() {
        this.isEditing = false;
    }

    public viewUser(admin: IUser) {
        if (admin) {
            this.stateService.go(RouterStates.dashboard_user_view, { userId: admin.id, userName: admin.name });
        }
    }

    public addTag(event: MatChipInputEvent) {
        let value = event.value;
        value = (value || '').trim();
        let valueLower = value.toLowerCase();

        if (valueLower.length === 0 || valueLower.length > 30) {
            return;
        }
        
        if (_.any(this.editTeam.tags, t => t.toLowerCase().trim() === valueLower)) {
            this.tagModel = '';
            return;
        }

        this.editTeam.tags.push(value);
        this.tagModel = '';
    }

    public removeTag(tag: string) {
        if (this.isSaving) { return; }
        let value = (tag || '').trim();
        console.log(value);
        console.log(this.editTeam.tags);
        let position = _.indexOf(this.editTeam.tags, value);
        console.log(position);
        if (position !== -1) {
            this.editTeam.tags.splice(position, 1);
        }
    }

    private showMinDeleteDialog(team: ITeam) {
        let expiryDate = moment(team.plan.storagePlan.endDate).format('L');
        let minDeleteDate = moment(team.minDeleteDate).format('L');

        this.dialogService.showDialog(
            new DialogCaption('TEAM_VIEW.EXPIRED_TOO_RECENTLY', true, { expiry: expiryDate, minDelete: minDeleteDate })
        );
    }
    
    public deleteTeam() {
        let team: ITeam = this.team;

        if (team.minDeleteDate != null) {

            let minDelete = moment(team.minDeleteDate);
            let isPrematureDeletion = minDelete.valueOf() > moment.now().valueOf();
            if (this.environmentService.isProduction && isPrematureDeletion) {
                this.showMinDeleteDialog(team);
                return;
            }
        }

        if (!team.plan.readOnly) {
            this.dialogService.showDialog(new DialogCaption('TEAM_VIEW.NOT_EXPIRED'));
            return;
        }

        this.mdDialog.open(DeleteTeamModalComponent, { 
            width: '500px',
            disableClose: false,
            data: <IDeleteTeamModalOptions>{
                teamId: team.id,
                teamName: team.name
            }
        })
        .afterClosed()
        .subscribe(v => {
            if (v == null || v.delete !== true) {
                return;
            }
            if (v.teamId !== team.id) {
                this.dialogService.showDialog(new DialogCaption('SHARED.GENERIC_ERROR', true));
                return;
            }
            this.performDeleteTeam(team, v.reason);
        });
    }

    public migrateTeamToCrm() {
        this.isMigratingToCrm = true;
        this.dialogService.showDialog(
            new DialogCaption('TEAM_VIEW.MIGRATE_CRM_CONFIRMATION'), 
            new DialogYesButton(() => this.migrateToCrmImpl()), 
            new DialogNoButton(() => this.isMigratingToCrm = false)
        );
    }

    private migrateToCrmImpl() {

        let teamId = this.team.id;
        this.teamService.migrateToCrm(teamId)
            .subscribe(result => {

                let observable = Observable.interval(1000).switchMap(() => this.teamService.getMigrationStatus(teamId, result));
                this.migrateSubscription = observable
                    .subscribe(statusResult => {

                        if (statusResult.status === 'error' || statusResult.status === 'complete' || this.team.id !== teamId) {
                            this.isMigratingToCrm = false;
                            this.migrateSubscription.unsubscribe();
                        }

                        if (statusResult.status === 'complete') {
                            this.teamService.refresh();
                        } else if (statusResult.status === 'error') {
                            this.dialogService.showDialog(new DialogCaption(statusResult.log), new DialogOKButton());
                        }

                    });
            },
            err => {
                this.isMigratingToCrm = false;
            });

    }

    private performDeleteTeam(team: ITeam, reason: string) {
        this.teamService.deleteTeam(team.id, reason)
            .subscribe(
                r => {
                    this.dialogService.showDialog(new DialogCaption('TEAM_VIEW.TEAM_DELETED', true, null), new DialogOKButton(() => {
                        this.stateService.go(RouterStates.dashboard_teams);
                    }));
                },
                err => {
                    console.error(err);
                    let errorCollection: IApiError[] = null;
                    if (err instanceof HttpErrorResponse && (errorCollection = getApiErrors(err)) != null && errorCollection.length > 0) {
                        let errorObject = errorCollection[0];
                        switch (errorObject.code) {
                            case 'EXPIRED_TOO_RECENTLY':
                                this.showMinDeleteDialog(team);
                                break;
                            case 'NOT_EXPIRED':
                                this.dialogService.showDialog(new DialogCaption('TEAM_VIEW.NOT_EXPIRED'));
                                break;
                            default:
                                this.dialogService.showDialog(new DialogCaption('SHARED.GENERIC_ERROR'));
                                break;
                        }
                    }
                    else {
                        this.dialogService.showDialog(new DialogCaption('SHARED.GENERIC_ERROR'));
                    }
                });
    }

    private save() {
        let self = this;
        this.isSaving = true;
        let hasChanges = false;
        let saveRequest: TeamRequest = {};

        let zippedTags = _.zip(this.team.tags, this.editTeam.tags);
        // if not all are equal then some have changed - this intentionally counts A B vs B A as different to allow for
        // sorting based on preference.
        if (!_.all(zippedTags, z => z.length === 2 && z[0] === z[1])) {
            saveRequest.tags = this.editTeam.tags;
            hasChanges = true;
        }

        if (this.team.reseller !== this.editTeam.reseller) {
            saveRequest.reseller = this.editTeam.reseller;
            hasChanges = true;
        }

        if (this.team.transcoder !== this.editTeam.transcoder) {
            saveRequest.transcoder = this.editTeam.transcoder;
            hasChanges = true;
        }

        if (this.team.noDelete !== this.editTeam.noDelete) {
            saveRequest.noDelete = this.editTeam.noDelete;
            hasChanges = true;
        }

        if (!hasChanges) {
            this.isSaving = false;
            this.isEditing = false;
            return;
        }

        this.teamService.patchTeam(this.team.id, saveRequest)
            .subscribe(() => {
                this.isEditing = false;
                this.isSaving = false;
            },
            error => {
                self.isSaving = false;
                console.log('unable to save team');
            },
            () => {
                self.isSaving = false;
            });
    }
}
