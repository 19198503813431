import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { EditTemplateComponent } from './edit-template.component';
import { ViewTemplateModule } from './view-template/view-template.module';

let viewTemplateState = { 
    name: RouterStates.dashboard_operation_template_view, 
    url: '/operation-templates/:operationIdentifier?v', 
    params: { templateName: '' },
    component: EditTemplateComponent 
};

let newTemplateState = { 
    name: RouterStates.dashboard_operation_template_new, 
    url: '/operation-templates/new?type', 
    component: EditTemplateComponent 
};

@NgModule({
  declarations: [
    EditTemplateComponent
  ],
  imports: [
    CoreComponentsModule,
    ViewTemplateModule,
    UIRouterModule.forChild({
      states: [viewTemplateState, newTemplateState]
    })
  ],
  exports: [
    
  ],
  providers: [
  ],
  bootstrap: []
})
export class EditTemplateModule { }

