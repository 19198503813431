import { PlanPeriod } from './plan-period.model';

export class SubscriptionPlanRequest {
    public displayName?: string;
    public memberLimit?: number;
    public readOnlyMemberLimit?: number;
    public units?: string;
    public maxSize?: number;
    public renewals?: number;
    public storageRenewalFrequency?: PlanPeriod;
    public fixedEndDate?: string;
    public updatePlanExpiry?: boolean;
    public planExpiry?: PlanPeriod;
    public autoAnalysis?: boolean;
}
