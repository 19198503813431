import { Component, Inject, OnInit, OnDestroy, TemplateRef, ViewChild, SecurityContext, AfterViewInit } from '@angular/core';
import { AngularEditorConfig, AngularEditorComponent, AngularEditorService } from '@kolkov/angular-editor'; 
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton } from 'app/core/dialog.service';
import { Subscription, Observable } from 'rxjs';
import { EmailContentRequest } from 'app/api/email/email-content-request.model';
import { IEmailContent } from 'app/api/email/email-content.model';
import { Template } from '@angular/compiler/src/render3/r3_ast';
import { AngularEditorCustomButtonSet } from '@kolkov/angular-editor/lib/config';
import { SelectPlaceholderInputData, SelectPlaceholderComponent } from './select-placeholder/select-placeholder.component';
import { take, share, finalize } from 'rxjs/operators';
import { IEmailTemplatePreview } from 'app/api/email/email-template-preview.model';
import { shareReplay } from 'rxjs-compat/operator/shareReplay';
import { HttpErrorResponse } from '@angular/common/http';
import { getApiErrors } from 'app/api/error/get-api-errors.function';
import { IApiError } from 'app/api/error/api-error.model';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

export class EmailContentEditorInputData {
    public content: (IEmailContent | EmailContentRequest);
    public isNew?: boolean;
    public save: (content: EmailContentRequest) => Observable<IEmailContent>;
    public preview: (content: EmailContentRequest) => Observable<IEmailTemplatePreview>;
}

@Component({
    templateUrl: './email-content-editor.component.html',
    styleUrls: ['./email-content-editor.component.scss']
})
export class EmailContentEditorComponent implements OnInit, OnDestroy, AfterViewInit {

    public editorConfig: AngularEditorConfig = {
        editable: true,
        spellcheck: true,
        height: '400px',
        minHeight: '5rem',
        placeholder: 'Enter text here...',
        translate: 'no',
        disableImageUpload: true,
        // uploadUrl: 'v1/images', // if needed
        // customClasses: [ // optional
        //   {
        //     name: 'quote',
        //     class: 'quote'
        //   },
        //   {
        //     name: 'redText',
        //     class: 'redText'
        //   },
        //   {
        //     name: 'titleText',
        //     class: 'titleText',
        //     tag: 'h1'
        //   }
        // ],
        fonts: [
            {class: 'arial', name: 'Arial'},
            {class: 'times-new-roman', name: 'Times New Roman'},
            {class: 'calibri', name: 'Calibri'},
            {class: 'Helvetica Textbook', name: 'Helvetica Textbook'},
            {class: 'Helvetica Neue', name: 'Helvetica Neue'},
            {class: 'Helvetica', name: 'Helvetica'}
        ],
        customButtons: [{
            buttons: [
                { iconClass: 'fa fa-image', title: 'Add image', disabled: () => false, commandName: 'image' },
                { iconClass: 'fa fa-puzzle-piece', title: 'Insert placeholder', disabled: () => false,  commandName: 'placeholder' }
            ]            
        }]
      };

    public subject = 'test';
    public body = '';
    public get language(): string { return this.inputData.content.language; }
    @ViewChild('editor', { static: false }) public editor: AngularEditorComponent;
    @ViewChild('testSubcomponent', { read: TemplateRef, static: true }) public customButtons: TemplateRef<any>;
    public isSaving = false;
    public isLoadingPreview = false;
    public previewSubject = '';
    public previewBody = '';
    public previewContent = '';
    @ViewChild('previewWindow', { read: TemplateRef, static: true }) private previewTemplate: TemplateRef<any>;
    // @ViewChild('previewIframeContent', { read: TemplateRef, static: true }) private previewIframeContent: TemplateRef<any>;
    private backdropSubscription: Subscription = null;
    private previewDialog: MatDialogRef<any, any> = null;

    constructor(
        private mdDialogRef: MatDialogRef<EmailContentEditorComponent>,
        @Inject(MAT_DIALOG_DATA) private inputData: EmailContentEditorInputData,
        private dialogService: DialogService,
        private mdDialog: MatDialog,
        private sanitizer: DomSanitizer,
        private editorService: AngularEditorService) {

        // handle backdrop click through the closeDialog() method
        this.backdropSubscription = mdDialogRef.backdropClick()
            .subscribe((e) => {
                this.closeDialog();
            });

        console.log(this.editorService);
    }
    
    public ngOnInit(): void {
        this.subject = this.inputData.content.subject;
        this.body = this.inputData.content.body;
    }

    public ngOnDestroy(): void {
        if (this.backdropSubscription != null) {
            this.backdropSubscription.unsubscribe();
        }
    }

    public ngAfterViewInit(): void {
        this.editor.textArea.nativeElement.addEventListener('paste', (e) => {

            // // Stop data actually being pasted into div

            // Get pasted data via clipboard API
            let clipboardData = e.clipboardData || (<any>window).clipboardData;
            let pastedData = clipboardData.getData('text/html');

            let exp = /(<span.*?>{{<\/span><span.*?>([A-Za-z0-9\.]+)<\/span><span.*?>}}<\/span>)/gm;
            let modifiedPastedData = pastedData.replace(exp, '{{$2}}');
            
            if (modifiedPastedData !== pastedData) {
                e.stopPropagation();
                e.preventDefault();
                this.editorService.insertHtml(modifiedPastedData);
            }
        });
    }

    public interceptCommand(command: string, editorService: AngularEditorService, next: (command: string) => void) {
        if (command === 'custom_image') {
            const url = prompt('Image URL', 'http:\/\/');
            if (url && url !== '' && url !== 'http://') {
              editorService.insertImage(url);
            }
        } else if (command === 'custom_placeholder') {
            this.insertPlaceholder(editorService);
        } else if(command.startsWith('custom_')) {
            console.log('Unknown toolbar command:', command);
        } else {
            next(command);
        }
    }

    public closeDialog() {
        if (this.hasChanges()) {
            this.dialogService.showDialog(
                new DialogCaption('SHARED.WITHOUT_SAVING_PROMPT'), 
                new DialogYesButton(() => this.mdDialogRef.close(null)), 
                new DialogNoButton()
            );
        } else {
            this.mdDialogRef.close(null);
        }
    }

    public preview() {
        this.loadPreview()
            .subscribe(preview => {
                this.showPreview(preview);
            },
            err => {
                let errorCollection: IApiError[] = null;
                if (err instanceof HttpErrorResponse && (errorCollection = getApiErrors(err)) != null && errorCollection.length > 0) {
                    let errorObject = errorCollection[0];
                    switch (errorObject.code) {
                        case 'INVALID_PLACEHOLDER':
                        case 'INVALID_MODIFIER':
                            this.dialogService.showDialog(new DialogCaption(errorObject.message, false));
                            break;
                        default:
                            this.dialogService.showDialog(new DialogCaption('SHARED.GENERIC_ERROR'));
                            break;
                    }
                }
                else {
                    this.dialogService.showDialog(new DialogCaption('SHARED.GENERIC_ERROR'));
                }
            });
    }
    
    public save() {

        let hasChanges = false;
        let saveObject: EmailContentRequest = {};

        if (this.inputData.isNew) {
            hasChanges = true;
            saveObject.language = this.inputData.content.language;
        }

        if (this.subject !== this.inputData.content.subject) {
            hasChanges = true;
            saveObject.subject = this.subject;
        }

        if (this.body !== this.inputData.content.body) {
            hasChanges = true;
            saveObject.body = this.body;
        }
        
        if (hasChanges) {
            this.isSaving = true;
            this.loadPreview()
                .switchMap(preview => this.inputData.save(saveObject))
                .pipe(finalize(() => this.isSaving = false))
                .subscribe(res => {
                        if (res != null) {
                            this.mdDialogRef.close(res);
                        }
                    }, err => {
                        let errorCollection: IApiError[] = null;
                        if (err instanceof HttpErrorResponse && (errorCollection = getApiErrors(err)) != null && errorCollection.length > 0) {
                            let errorObject = errorCollection[0];
                            switch (errorObject.code) {
                                case 'INVALID_PLACEHOLDER':
                                case 'INVALID_MODIFIER':
                                    this.dialogService.showDialog(new DialogCaption(errorObject.message, false));
                                    break;
                                default:
                                    this.dialogService.showDialog(new DialogCaption('SHARED.GENERIC_ERROR'));
                                    break;
                            }
                        }
                        else {
                            this.dialogService.showDialog(new DialogCaption('SHARED.GENERIC_ERROR'));
                        }
                    });
        } else {
            this.mdDialogRef.close(this.inputData.content);
        }    
    }

    public closePreview() {
        if (this.previewDialog != null) {
            this.previewDialog.close();
        }
    }

    private hasChanges(): boolean {
        return this.inputData.content.body !== this.body || this.inputData.content.subject !== this.subject;
    }

    private insertPlaceholder(editorService: AngularEditorService) {
        this.mdDialog.open(SelectPlaceholderComponent, {
            width: '70vw',
            maxHeight: '90vh',
            data: <SelectPlaceholderInputData>{

            }
        })
        .afterClosed().subscribe(
            selection => {
                if (selection != null && selection.length > 0) {

                    this.editor.textArea.nativeElement.focus();
                    if (editorService.savedSelection != null && editorService.savedSelection.endContainer.parentElement === this.editor.textArea.nativeElement) {
                        editorService.restoreSelection();
                    }
                    
                    editorService.insertHtml(selection); 
                }
            });
    }

    private loadPreview(): Observable<IEmailTemplatePreview> {
        let previewRequest: EmailContentRequest = {
            language: this.inputData.content.language,
            subject: this.subject,
            body: this.body
        };

        this.isLoadingPreview = true;
        let observable = this.inputData.preview(previewRequest)
            .pipe(finalize(() => this.isLoadingPreview = false))
            .pipe(share());
            
        observable.subscribe(v => {
            /**/
        },
        err => {
            /**/
        });
        return observable;
    }

    private showPreview(preview: IEmailTemplatePreview) {

        this.previewSubject = this.sanitizer.sanitize(SecurityContext.HTML, preview.renderedSubject);
        this.previewBody = this.sanitizer.sanitize(SecurityContext.HTML, preview.renderedBody);

        this.previewContent = '<div><h1>' + this.previewSubject + '</h1></div><div>' + this.previewBody + '</div>';

        this.previewDialog = this.mdDialog.open(this.previewTemplate, {
            width: '90vw',
            height: '90vh'
        });

        this.previewDialog.afterClosed().subscribe(v => this.previewDialog = null);

    }
}
