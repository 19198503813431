import { Component, Input } from '@angular/core';

import { IOperationTemplateBase } from '../../../../../api/operations/operation-template.model';
import { OperationTemplateRequestBase } from '../../../../../api/operations/operation-template-request.model';

import { EditTemplateShared } from '../edit-template-shared.service';

@Component({
    templateUrl: './basic-info.component.html',
    styleUrls: ['./basic-info.component.scss'],
    selector: 'basic-info'
})
export class BasicInfoComponent {

    @Input() public shared: EditTemplateShared;
    @Input() public operation: IOperationTemplateBase;
    @Input() public editOperation: OperationTemplateRequestBase;
    @Input() public get isEditing() { return this.shared != null ? this.shared.isEditing : false; }
    @Input() public get isSaving() { return this.shared != null ? this.shared.isSaving : false; }
    @Input() public canEditName: boolean;

}
