import { ApiHttp } from '../../core/api-http';
import { Observable } from 'rxjs';
import { ICollection } from '../common/collection.model';
import { IOrganization } from './organization.model';
import { CreateOrganizationRequest } from './create-organization.request.model';
import { OrganizationPlanRequest } from './organization-plan-request.model';
import { IOrganizationPlan } from './organization-plan.model';
import { IStorageStats } from '../plans/storage-stats.model';
import { StorageStatsRequestModel } from '../plans/storage-stats-request.model';
import { IUser } from '../users/user.model';
import { OrganizationImportConfigType } from './organization-import-config-type.enum';
import { IOrganizationImportConfig } from './organization-import-config.model';
import { OrganizationImportConfigRequest } from './organization-import-config-request.model';

export class OrganizationsResource {

    constructor(private http: ApiHttp) {

    }

    public getOrganizations(query?: string): Observable<ICollection<IOrganization>> {

        let url = '/v1/organizations';
        let params: any =  {};

        if (query != null && query !== undefined && query.length > 0) {
            params.query = query;
        }

        return this.http.getTyped<ICollection<IOrganization>>(url, {
            params: params
        });

    }

    public getOrganization(id: string): Observable<IOrganization> {

        let url = '/v1/organizations/:organizationId';
        url = url.replace(':organizationId', id);
        
        return this.http.getTyped<IOrganization>(url);
        
    }

    public createOrganization(request: CreateOrganizationRequest): Observable<IOrganization> {
        let url = '/v1/organizations';
        return this.http.postTyped<IOrganization>(url, JSON.stringify(request));
    }

    public patchOrganizationPlan(organizationId: string, request: OrganizationPlanRequest): Observable<IOrganizationPlan> {
        let url = '/v1/organizations/:organizationId/plan';
        url = url.replace(':organizationId', organizationId);
        return this.http.patchTyped<IOrganizationPlan>(url, JSON.stringify(request));
    }

    public updateOrganizationStorageStats(organizationId: string, stats: StorageStatsRequestModel): Observable<IStorageStats> {
        let url = '/v1/organizations/:organizationId/plan/storageStats';
        url = url.replace(':organizationId', organizationId);
        return this.http.patchTyped<IStorageStats>(url, JSON.stringify(stats));
    }

    public addOrganizationAdministrator(organizationId: string, administratorId: string): Observable<IUser> {
        let url = '/v1/organizations/:organizationId/administrators';
        url = url.replace(':organizationId', organizationId);
        return this.http.postTyped<IUser>(url, JSON.stringify({ id: administratorId }));
    }

    public removeOrganizationAdministrator(organizationId: string, administratorId: string): Observable<any> {
        let url = '/v1/organizations/:organizationId/administrators/:adminId';
        url = url.replace(':organizationId', organizationId);
        url = url.replace(':adminId', administratorId);
        return this.http.delete(url);
    }

    public getOrganizationImportConfiguration(organizationId: string, configurationType: OrganizationImportConfigType): Observable<IOrganizationImportConfig> {
        let url = '/v1/organizations/:organizationId/importconfigs/:type';
        url = url.replace(':organizationId', organizationId);
        url = url.replace(':type', configurationType);
        return this.http.getTyped<IOrganizationImportConfig>(url);
    }

    public saveOrganizationImportConfiguration(organizationId: string, configurationType: OrganizationImportConfigType, config: OrganizationImportConfigRequest): Observable<IOrganizationImportConfig> {
        let url = '/v1/organizations/:organizationId/importconfigs/:type';
        url = url.replace(':organizationId', organizationId);
        url = url.replace(':type', configurationType);
        return this.http.putTyped<IOrganizationImportConfig>(url, JSON.stringify(config));
    }
}
