import { IStoragePlan } from './storage-plan.model';
import { PlanPeriodTypes } from './plan-period.model';

export function defaultStoragePlan(): IStoragePlan {
    return {
        units: 'seconds',
        canUpload: true,
        maxSize: 0,
        startDate: (new Date().toISOString()),
        endDate: null,
        nextUpdate: null,
        renewals: 0,
        remainingRenewals: 0,
        planPeriod: { 
            periodLength: 1,
            periodType: PlanPeriodTypes.MONTH
        },
        readOnly: false,
        autoAnalysis: false
    };
}
