import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { SupporterLimitProcedureComponent } from './supporter-limit-procedure.component';

@NgModule({
  declarations: [
    SupporterLimitProcedureComponent
  ],
  imports: [
    CoreComponentsModule
  ],
  exports: [
    SupporterLimitProcedureComponent
  ],
  providers: [
  ],
  bootstrap: [
    SupporterLimitProcedureComponent
  ]
})
export class SupporterLimitProcedureModule { }

