
import {map} from 'rxjs/operators';
import { ApiHttp } from '../../core/api-http';
import { Observable } from 'rxjs';
import { ICollection } from '../common/collection.model';
import { ITeam } from './team.model';
import { ITeamPlan }  from './team-plan.model';
import { ITeamStatistics } from './team-statistics.model';
import { TeamPlanRequest } from './team-plan-request.model';
import { IGroup } from '../groups/group.model';
import { GroupMemberRequest } from '../groups/group-member-request.model';
import { IGroupMember } from '../groups/group-member.model';
import { TeamSetupRequest } from './team-setup-request.model';
import { ITeamSetup } from './team-setup.model';
import { StorageStatsRequestModel } from '../plans/storage-stats-request.model';
import { IStorageStats } from '../plans/storage-stats.model';
import { TeamRequest } from './team-request.model';
import { ITeamRequestForm } from './team-request-form.model';
import { ITeamRenewalForm } from './team-renewal-form.model';
import { IMigrationStatus } from './migration-status.model';
import { IGraduation } from './graduation.model';
import { HttpHeaders } from '@angular/common/http';
import { GraduationStatus } from './graduation-status.enum';
import { ITeamMembershipTermiantion } from './team-membership-termination.model';
import { ITeamGraduationCollection } from './team-graduation-collection.model';
import { ITeamRequestRenewal } from './team-request-renewal.model';
import { TeamRequestRenewalType } from './team-request-renewal-type.enum';

export class TeamsResource {

    constructor(
        private http: ApiHttp) {

    }

    public getTeams(query?: string, skip?: number, pageSize?: number, sortBy?: string, sortAsc?: boolean): Observable<ICollection<ITeam>> {

        let url = '/v1/teams';
        let params: any =  {};

        if (query != null && query !== undefined && query.length > 0) {
            params.query = query;
        }

        if (skip != null && skip !== undefined) {
            params.skip = skip;
        }

        if (pageSize != null && pageSize !== undefined) {
            params.pageSize = pageSize;
        }

        if (sortBy != null && sortBy !== undefined) {
            params.sortBy = sortBy;
        }

        if (sortAsc != null && sortAsc !== undefined) {
            params.sortAsc = sortAsc;
        }       

        return this.http.getTyped<ICollection<ITeam>>(url, {
            params: params
        });
    }

    public getTeam(teamId: string): Observable<ITeam> {
        let url = '/v1/teams/:teamId';
        url = url.replace(':teamId', teamId);
        return this.http.getTyped<ITeam>(url);
    }

    public deleteTeam(teamId: string, checksum: string, reason: string): Observable<any> {
        let url = '/v1/teams/:teamId';
        url = url.replace(':teamId', teamId);
        return this.http.deleteTyped<any>(url, {
            params: { checksum: checksum, reason: reason }
        });
    }

    public getTeamRoles(teamId: string): Observable<ICollection<IGroup>> {
        let url = '/v1/teams/:teamId/roles';
        url = url.replace(':teamId', teamId);
        return this.http.getTyped<ICollection<IGroup>>(url);
    }

    public getTeamStatistics(teamId: string): Observable<ITeamStatistics> {
        let url = '/v1/teams/:teamId/statistics';
        url = url.replace(':teamId', teamId);
        return this.http.getTyped<ITeamStatistics>(url);
    }

    public patchTeam(teamId: string, team: TeamRequest): Observable<ITeam> {
        let url = '/v1/teams/:teamId';
        url = url.replace(':teamId', teamId);
        return this.http.patchTyped<ITeam>(url, JSON.stringify(team));
    }

    public patchPlan(teamId: string, plan: TeamPlanRequest): Observable<ITeamPlan> {
        let url = '/v1/teams/:teamId/plan';
        url = url.replace(':teamId', teamId);
        return this.http.patchTyped<ITeamPlan>(url, JSON.stringify(plan));
    }

    public setNewAdministrator(teamId: string, roleId: string, groupMember: GroupMemberRequest): Observable<IGroupMember> {
        let url = '/v1/teams/:teamId/roles/:roleId/members';
        url = url.replace(':teamId', teamId);
        url = url.replace(':roleId', roleId);
        return this.http.postTyped<IGroupMember>(url, JSON.stringify(groupMember));
    }

    public getTeamSetups(skip?: number, pageSize?: number, sortBy?: string, sortAsc?: boolean): Observable<ICollection<ITeamSetup>> {
        let url = '/v1/teams/setups';
        let params: any =  {};
        
        if (skip != null && skip !== undefined) {
            params.skip = skip;
        }

        if (pageSize != null && pageSize !== undefined) {
            params.pageSize = pageSize;
        }
        
        if (sortBy != null && sortBy !== undefined) {
            params.sortBy = sortBy;
        }

        if (sortAsc != null && sortAsc !== undefined) {
            params.sortAsc = sortAsc;
        }       

        return this.http.getTyped<ICollection<ITeamSetup>>(url, {
            params: params
        });
    }
    
    public getTeamSetup(setupId: string): Observable<ITeamSetup> {
        let url = '/v1/teams/setups/:setupId';
        url = url.replace(':setupId', setupId);
        
        return this.http.getTyped<ITeamSetup>(url);
    }
    
    public createTeamSetup(setup: TeamSetupRequest): Observable<ITeamSetup> {
        let url = '/v1/teams/setups';
        return this.http.postTyped<ITeamSetup>(url, JSON.stringify(setup));
    }
    
    public updateTeamSetup(setupId: string, setup: TeamSetupRequest): Observable<ITeamSetup> {
        let url = '/v1/teams/setups/:setupId';
        url = url.replace(':setupId', setupId);
        return this.http.putTyped<ITeamSetup>(url, JSON.stringify(setup));
    }

    public deleteTeamSetup(setupId: string): Observable<any> {
        let url = '/v1/teams/setups/:setupId';
        url = url.replace(':setupId', setupId);
        return this.http.deleteTyped(url);
    }

    public updateTeamStorageStats(teamId: string, stats: StorageStatsRequestModel): Observable<IStorageStats> {
        let url = '/v1/teams/:teamId/plan/storageStats';
        url = url.replace(':teamId', teamId);
        return this.http.patchTyped<IStorageStats>(url, JSON.stringify(stats));
    }

    public searchTeamRequestRenewalForms(query?: string, skip?: number, pageSize?: number, types?: TeamRequestRenewalType[]): Observable<ICollection<ITeamRequestRenewal>> {
        let url = '/v1/teamrequests/search';
        let params: any =  {};

        if (query != null && query !== undefined){ 
            params.query = query;
        }
        
        if (skip != null && skip !== undefined) {
            params.skip = skip;
        }

        if (pageSize != null && pageSize !== undefined) {
            params.pageSize = pageSize;
        }

        if (types != null && types !== undefined && types.length > 0){
            params.type = types;
        }
        
        return this.http.getTyped<ICollection<ITeamRequestRenewal>>(url, {
            params: params
        });
    }

    public getTeamRequestForm(formId: string): Observable<ITeamRequestForm> {
        let url = '/v1/teamrequests/:formId';
        url = url.replace(':formId', formId);
        return this.http.getTyped<ITeamRequestForm>(url);
    }

    public getTeamRenewalForm(formId: string): Observable<ITeamRequestForm> {
        let url = '/v1/teamrenewals/:formId';
        url = url.replace(':formId', formId);
        return this.http.getTyped<ITeamRequestForm>(url);
    }

    public getMemberEmailAddressesRequest(roles?: string[]): Observable<string> {
        let url = '/v1/teams/members/emails';
        let params: any = {};

        if (roles != null && roles.length > 0) {
            params.roles = roles;
        }

        return this.http.getText(url, { params, responseType: 'text' });
    }

    public beginTeamCrmMigration(teamId: string): Observable<IMigrationStatus> {
        let url = '/v1/teams/:teamId/migrations';
        url = url.replace(':teamId', teamId);

        return this.http.postTyped<IMigrationStatus>(url, null);
    }

    public getTeamCrmMigrationStatus(teamId: string, migrationId: string): Observable<IMigrationStatus> {
        let url = '/v1/teams/:teamId/migrations/:migrationId';
        url = url.replace(':teamId', teamId);
        url = url.replace(':migrationId', migrationId);

        return this.http.getTyped<IMigrationStatus>(url);
    }

    public getDeletionCandidates(): Observable<string> {
        let url = '/v1/teams/deletioncandidates';
        let params: any = {};

        return this.http.getText(url, { params, responseType: 'text' });
    }

    public importGraduationsCsv(file: File, teamId?: string): Observable<ICollection<IGraduation>> {
        let url = '/v1/teams/graduations';
        let params: any = {};
        
        let headers = new HttpHeaders();
        
        let formData = new FormData();
        formData.append('CsvFile', file, file.name);

        if (teamId != null && teamId.length > 0) {
            formData.append('TeamId', teamId);
        }

        return this.http.postTyped<ICollection<IGraduation>>(url, formData, { params, headers, responseType: 'json' });
    }

    public getTeamGraduations(teamId: string, skip?: number, pageSize?: number): Observable<ITeamGraduationCollection> {
        let url = '/v1/teams/:teamId/graduations';
        url = url.replace(':teamId', teamId);

        let params: any = {};
        if (skip != null) {
            params.skip = skip;
        }

        if (pageSize != null) {
            params.pageSize = pageSize;
        }

        return this.http.getTyped<ITeamGraduationCollection>(url, { params, responseType: 'json' });
    }

    public updateGraduation(graduationId: string, newStatus: GraduationStatus): Observable<IGraduation> {
        let url = '/v1/teams/graduations/:graduationId';
        url = url.replace(':graduationId', graduationId);

        let requestModel = {
            status: newStatus
        };

        return this.http.patchTyped<IGraduation>(url, JSON.stringify(requestModel));
    }

    public getGraduationById(graduationId: string): Observable<IGraduation> {
        let url = '/v1/teams/graduations/:graduationId';
        url = url.replace(':graduationId', graduationId);

        return this.http.getTyped<IGraduation>(url);
    }

    public getTeamMembershipTerminations(teamId: string, skip?: number, pageSize?: number): Observable<ICollection<ITeamMembershipTermiantion>> {
        let url = '/v1/teams/:teamId/terminations';
        url = url.replace(':teamId', teamId);

        let params: any = {};
        if (skip != null) {
            params.skip = skip;
        }

        if (pageSize != null) {
            params.pageSize = pageSize;
        }

        return this.http.getTyped<ICollection<ITeamMembershipTermiantion>>(url, { params, responseType: 'json' });
    }

    public generateInvitation(teamId: string, role: string): Observable<string> {
        const url = `/v1/teams/${teamId}/invites`;
        const body = { role: role };
        return this.http.postTyped<any>(url, JSON.stringify(body))
            .pipe(map(r => {
                return r.inviteUrl;
            }));
    }

}
