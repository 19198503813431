import { Component, Input } from '@angular/core';
import * as _ from 'underscore';

@Component({
    templateUrl: './login-provider.component.html',
    styleUrls: ['./login-provider.component.scss'],
    host: {
        // 'class': 'wide-row mat-paginator'
    },
    selector: 'login-providers'
})
export class LoginProviderComponent {

    // all entries must be lowercase
    public providerSettings: { [id: string]: IProviderSettings } = {
        'spoch': { position: 0, hasIcon: true },
        'google': { position: 1, hasIcon: true },
        'twitter': { position: 2, hasIcon: true },
        'line': { position: 3, hasIcon: true },
        'facebook': { position: 4, hasIcon: true },
        'apple': { position: 5, hasIcon: true },
        'microsoft': { position: 6, hasIcon: true }
    };

    @Input('providers') public loginProviders: string[];
    public get orderedProviders(): string[] {
        return _.sortBy(this.loginProviders || [], p => this.getProviderPosition(p));
    }

    public fixProvider(name: string): string {
        return (name || '').toLowerCase().trim();
    }

    private getProviderPosition(provider: string): number {
        const settings = this.getSettingsByProvider(provider);
        if (!settings) {
            return -1;
        }
    }

    private getSettingsByProvider(provider: string): IProviderSettings {
        const fixedName = this.fixProvider(provider);
        if (this.providerSettings.hasOwnProperty(fixedName)) {
            return this.providerSettings[fixedName];
        }
        return null;
    }
}

interface IProviderSettings {
    position: number;
    hasIcon: boolean;
}
