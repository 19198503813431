import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { MediaOptionsProcedureComponent } from './media-options-procedure.component';

@NgModule({
  declarations: [
    MediaOptionsProcedureComponent
  ],
  imports: [
    CoreComponentsModule
  ],
  exports: [
    MediaOptionsProcedureComponent
  ],
  providers: [
  ],
  bootstrap: [
    MediaOptionsProcedureComponent
  ]
})
export class MediaOptionsProcedureModule { }

