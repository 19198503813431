import { Injectable } from '@angular/core';
import { StateService, StateOrName, RawParams } from '@uirouter/angular';

@Injectable()
export class NewTabService {
    
    constructor(private stateService: StateService) {

    }

    public openHref(href: string) {
        let tmp = document.createElement('a');
        tmp.href = href;
        tmp.target = '_blank';
        tmp.rel = 'noreferrer noopener';
        tmp.click();
        tmp.remove();        
    }

    public openState(to: StateOrName, params?: RawParams) {
        let href = this.stateService.href(to, params);
        this.openHref(href);
    }

}
