
import {of as observableOf,  Observable ,  BehaviorSubject } from 'rxjs';

import {share,  take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { DashboardSidebarService } from '../../../core/dashboard-sidebar.service';
import { DashboardApiService } from '../../../api/dashboard-api.service';
import { IUser } from '../../../api/users/user.model';
import { IUserTeamPlan } from '../../../api/users/user-team-plan.model';
import { UserTeamPlanRequest } from '../../../api/users/user-team-plan-request.model';
import { ICollection } from '../../../api/common/collection.model';
import { IUserTeam } from '../../../api/users/user-team.model';

@Injectable() 
export class UserService {

    public user: IUser = null;
    public loadingUser = true;
    public userTeams: ICollection<IUserTeam> = null;

    public get userId(): string { return this.user != null ? this.user.id : null; }
    public userSubject: BehaviorSubject<IUser>;

    private teamsUserId = '';

    constructor(private api: DashboardApiService,
                private dashboardSidebarService: DashboardSidebarService) {
        this.userSubject = new BehaviorSubject<IUser>(null);
    }

    public loadUser(userId: string): Observable<IUser> {
        this.loadingUser = true;
        let userRequest = this.api.users.getUser(userId).pipe(share());
        userRequest    
            .subscribe(
                user => { 
                    this.user = user;
                    this.userSubject.next(user);
                    this.dashboardSidebarService.setUserName(user.name);
                },
                error => console.error('load team:', error),
                () => {
                    this.loadingUser = false;
                }
            );
        return userRequest;
    }

    public patchUserTeamPlan(userId: string, planRequest: UserTeamPlanRequest): Observable<IUserTeamPlan> {
        let patchRequest = this.api.users.patchUserTeamPlan(userId, planRequest).pipe(share());
        patchRequest.subscribe
            (plan => {
                if (this.user && this.user.id === userId) {
                    this.user.teamPlan = plan;
                }
            },
            error => console.error('patch team plan', error));
        return patchRequest;
    }

    public refresh(): Observable<IUser> {
        if (!this.user || !this.user.id) {
            return observableOf<IUser>(null).pipe(take(1));
        }
        this.loadingUser = true;
        this.teamsUserId = null;
        return this.loadUser(this.user.id);
    }

    public loadTeams(userId: string): Observable<ICollection<IUserTeam>> {
        let teamsRequest = this.api.users.getUserTeams(userId).pipe(share());
        teamsRequest.subscribe(
            teams => {
                if (this.user.id !== userId) {
                    return;
                }
                this.userTeams = teams;
                this.teamsUserId = this.user.id;
            },
            err => null
        );
        return teamsRequest;
    }
    

}
