import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { ConnectivityTestComponent } from './connectivity-test.component';
import { ChartsModule } from 'ng2-charts';

let validatorState = { 
    name: RouterStates.dashboard_connectivityTest, 
    url: '/connectivitytests?:resultId',
    component: ConnectivityTestComponent
};

@NgModule({
  declarations: [
    ConnectivityTestComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [validatorState]
    }),
    ChartsModule
  ],
  exports: [
    
  ],
  bootstrap: []
})
export class ConnectivityTestModule { }
