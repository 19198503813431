import { Directive, ElementRef, Input, OnChanges, SimpleChanges, HostBinding, OnDestroy, OnInit } from '@angular/core';
import { UserService } from '../../core/user.service';
import * as _ from 'underscore';
import { Subscription } from 'rxjs';

@Directive({
    selector: '[spRole]',
    exportAs: 'spRole'
})
export class SpRoleDirective implements OnInit, OnChanges, OnDestroy {

    @Input() public spRole: any;
    @Input() public spCondition: () => boolean;
    private userChangedSubscription: Subscription;
    private originalDisplayMode = null;    

    constructor(
        private element: ElementRef,
        private userService: UserService) {
            
    }

    public ngOnInit() {
        this.userChangedSubscription = this.userService.userChanged.subscribe(
            () => this.doUpdate(this.spRole, this.spCondition)
        );
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.spRole != null) {
            this.doUpdate(changes.spRole.currentValue, this.spCondition);
        }

        if (changes.spCondition != null) {
            this.doUpdate(this.spRole, changes.spCondition.currentValue);
        }
    }

    public ngOnDestroy() {
        if (this.userChangedSubscription != null) {
            this.userChangedSubscription.unsubscribe();
        }
    }

    private doUpdate(roleInfo: any, condition: () => boolean) {

        if (this.originalDisplayMode == null && (<any>this.element).nativeElement.style.display !== 'none') {
            this.originalDisplayMode = (<any>this.element).nativeElement.style.display;
        } else if (this.originalDisplayMode == null) {
            this.originalDisplayMode = 'block';
        }

        if (roleInfo === '') {
            roleInfo = null;
        }

        if (<any>condition === '') {
            condition = null;
        }

        if (roleInfo == null && roleInfo !== undefined && condition == null && condition !== undefined) {
            return;
        }

        let allowed = false;
        if (condition != null && condition !== undefined) {

            allowed = condition();

        } else if (roleInfo != null) {
            let roles: string[] = [];
            if (typeof roleInfo.currentValue === 'string') {
                roles = [<string>roleInfo];
            }
            else if (Array.isArray(roleInfo)) {
                roles = _.map(roleInfo, v => <string>v);
            }
            allowed = _.any(roles, r => this.userService.hasRole(r));
        } 

        if (!allowed) {
            (<any>this.element).nativeElement.style.display = 'none';
        } else if ((<any>this.element).nativeElement.style.display === 'none') {
            (<any>this.element).nativeElement.style.display = this.originalDisplayMode;
        }
        
    }
}
