import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../core-components.module';
import { HeaderModule } from '../../components/header/header.module';
import { HomeModule } from './home/home.module';
import { TeamsModule } from './teams/teams.module';
import { RouterStates } from '../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { DashboardComponentsModule } from './dashboard-components/dashboard-components.module';
import { TeamModule } from './team/team.module';
import { TeamSetupModule } from './team-setup/team-setup.module';
import { PlansModule }  from './plans/plans.module';
import { UsersModule } from './users/users.module';
import { UserModule } from './user/user.module';
import { TasksModule } from './tasks/tasks.module';
import { OrganizationsModule } from './organizations/organizations.module';
import { OperationTemplateModule } from './operation-template/operation-template.module';

import { DashboardMainComponent } from './dashboard-main.component';
import { UrlValidatorModule } from './url-validator/url-validator.module';
import { TeamRequestsModule } from './team-requests/team-requests.module';
import { EmailTemplatesModule } from './email-templates/email-templates.module';
import { ClusterScriptsModule } from './cluster-scripts/cluster-scripts.module';
import { GraduationsModule } from './graduations/graduations.module';
import { ConnectivityTestModule } from './connectivity-test/connectivity-test.module';
import { ThreadsModule } from './threads/threads.module';

let dashboardState = { name: RouterStates.dashboard, component: DashboardMainComponent };

@NgModule({
  declarations: [
    DashboardMainComponent
  ],
  imports: [
    CoreComponentsModule,
    DashboardComponentsModule,
    HomeModule,
    HeaderModule,
    TeamsModule,
    TeamSetupModule,
    TeamModule,
    ThreadsModule,
    PlansModule,
    UsersModule,
    UserModule,
    TasksModule,
    OperationTemplateModule,
    OrganizationsModule,
    UrlValidatorModule,
    TeamRequestsModule,
    EmailTemplatesModule,
    ClusterScriptsModule,
    GraduationsModule,
    ConnectivityTestModule,
    UIRouterModule.forChild({
      states: [dashboardState]
    })
  ],
  exports: [
  ],
  providers: [
  ],
  bootstrap: []
})
export class DashboardModule { }

