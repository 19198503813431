import { Pipe, PipeTransform, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Pipe({ name: 'sptranslateprefix', pure: false })
export class SplyzaTranslatePrefixPipe implements PipeTransform, OnDestroy {

    private subscription: Subscription;

    constructor(private _ref: ChangeDetectorRef, private translateService: TranslateService) {

        this.subscription = this.translateService.onTranslationChange
            .subscribe((t) => {
                this._ref.markForCheck();
            });

    }

    public transform(value: string, prefix: string, defaultValue: string): any {
        let key = (prefix || '') + (value || '');
        let fallbackKey = defaultValue != null ? (prefix || '') + defaultValue : key;
        return this.getTranslation(key.toUpperCase(), fallbackKey.toUpperCase());
    }

    public ngOnDestroy() {

        if (this.subscription) {
            this.subscription.unsubscribe();
        }

    }

    private getTranslation(key: string, fallbackKey?: string): string {
        key = key || fallbackKey;
        let result = this.translateService.instant(key);
        if (result !== key) {
            return result;
        }
        if (key !== fallbackKey && fallbackKey != null) {
            return this.translateService.instant(fallbackKey);
        }
        return key;
    }
}
