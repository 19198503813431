import { NgModule } from '@angular/core';
import { StorageStatsComponent } from './storage-stats.component';
import { CoreModulesModule } from '../../core-modules.module';
import { SpRoleModule } from '../sp-role/sp-role.module';

@NgModule({
  declarations: [
    StorageStatsComponent
  ],
  imports: [
    CoreModulesModule,
    SpRoleModule
  ],
  exports: [
    StorageStatsComponent
  ],
  providers: [
  ],
  bootstrap: [
    StorageStatsComponent
  ]
})
export class StorageStatsModule { }
