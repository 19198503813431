import { NgModule } from '@angular/core';
import { JsonViewerComponent } from './json-viewer.component';
import { CoreModulesModule } from '../../core-modules.module';

@NgModule({
  imports: [
      CoreModulesModule
  ],
  declarations: [
    JsonViewerComponent
  ],
  exports: [
    JsonViewerComponent
  ],
  bootstrap: [
    JsonViewerComponent
  ]
})
export class JsonViewerModule { }

