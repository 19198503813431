import { Component, OnChanges, OnInit, SimpleChanges, Input, Output }from '@angular/core';
import { ISubscriptionPlan }from '../../api/plans/subscription-plan.model';
import * as _ from 'underscore';
import { TranslateService }from '@ngx-translate/core';
import { EventEmitter }from 'events';
import { PlanPeriodTypes } from '../../api/plans/plan-period.model';

@Component({
    templateUrl: './subscription-plan.component.html',
    // styleUrls: ['./subscription-plan.component.scss'],
    selector: 'subscription-plan'
})
export class SubscriptionPlanComponent implements OnInit, OnChanges {
    
    @Input() public plan: ISubscriptionPlan;
    @Input() public hideDisplayName: boolean;
    @Input('newPlan') public editModel: ISubscriptionPlan;
    @Output('newPlan') public editModelChanges: EventEmitter;
    @Input() public isEditing: boolean;

    public endType: string;
    public editEndType: string;
    public planPeriods: PlanPeriodType[] = [];

    private lastExpiryPeriodKey: string;
    private lastExpiryPeriodText: string;
    private lastStoragePeriodKey: string;
    private lastStoragePeriodText: string;

    constructor(private translateService: TranslateService) {

    }

    public ngOnChanges(changes: SimpleChanges): void {
        if (changes.plan && changes.plan.previousValue !== changes.plan.currentValue && this.plan) {
            if (this.plan.planExpiry) {
                this.endType = 'period';
            }
            else if (this.plan.fixedEndDate) {
                this.endType = 'fixed';
            }
            else {
                this.endType = 'none';
            }
        }

        if (changes.editModel && changes.editModel.previousValue !== changes.editModel.currentValue && this.editModel) {
            if (this.editModel.planExpiry) {
                this.editEndType = 'period';
            }
            else if (this.editModel.fixedEndDate) {
                this.editEndType = 'fixed';
            }
            else {
                this.editEndType = 'none';
            }
            
            if (!this.editModel.fixedEndDate) {
                this.editModel.fixedEndDate = new Date().toISOString();
            }
            if (!this.editModel.planExpiry) {
                this.editModel.planExpiry = {
                    periodLength: 1,
                    periodType: PlanPeriodTypes.MONTH
                };
            }
        }

        if (changes.isEditing && changes.isEditing.previousValue !== changes.isEditing.currentValue && changes.isEditing.currentValue === true) {
            this.editEndType = this.endType;
        }
    }
    
    public ngOnInit() {
        this.loadPlanPeriodTranslations();
    }
    
    public getExpiryPeriodText() {
        if (!this.plan || !this.plan.planExpiry) {
            return null;
        }

        if (this.lastExpiryPeriodKey === this.plan.planExpiry.periodType) {
            return this.lastExpiryPeriodText;
        }
        
        let period = _.find(this.planPeriods, p => p.key === this.plan.planExpiry.periodType);
        if (!period) {
            return this.plan.planExpiry.periodType;
        }

        this.lastExpiryPeriodKey = period.key;
        this.lastExpiryPeriodText = period.displayName;
        return period.displayName;
    }

    public getStoragePeriodText() {
        if (!this.plan || !this.plan.storageRenewalFrequency) {
            return null;
        }

        if (this.lastStoragePeriodKey === this.plan.storageRenewalFrequency.periodType) {
            return this.lastStoragePeriodText;
        }
        
        let period = _.find(this.planPeriods, p => p.key === this.plan.storageRenewalFrequency.periodType);
        if (!period) {
            return this.plan.storageRenewalFrequency.periodType;
        }

        this.lastStoragePeriodKey = period.key;
        this.lastStoragePeriodText = period.displayName;
        return period.displayName;
    }
    
    public hasPlanEndChanged(): boolean {
        if (this.endType !== this.editEndType) {
            return true;
        }        

        if (this.editEndType === 'period') {
            if (!this.plan.planExpiry && this.editModel.planExpiry) {
                return true;
            }
            if (this.plan.planExpiry.periodLength !== this.editModel.planExpiry.periodLength
                || this.plan.planExpiry.periodType !== this.editModel.planExpiry.periodType) {
                return true;
            }
        }

        if (this.editEndType === 'fixed') {
            return this.plan.fixedEndDate !== this.editModel.fixedEndDate;
        }

        return false;
    }
    
    private loadPlanPeriodTranslations() {
        let types = ['day', 'week', 'month', 'year'];
        let keys = _.map(types, t => 'SHARED.STORAGE.DISPLAY_PERIOD.' + t.toUpperCase());

        this.translateService.get(keys)
            .subscribe(translations => {
                for (let i = 0; i < types.length; ++i) {
                    let type = types[i];
                    let translation = translations[keys[i]];
                    this.planPeriods.push({
                        key: type,
                        displayName: translation
                    });
                }
            });
    }
}

interface PlanPeriodType {
    key: string;
    displayName: string;
}
