import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { MatDialogModule, MatButtonModule } from '@angular/material';
import { TranslateModule } from '@ngx-translate/core';

import { GenericDialogComponent } from './generic-dialog.component';

@NgModule({
  declarations: [
      GenericDialogComponent
  ],
  imports: [
      FormsModule,
      CommonModule,
      MatDialogModule,
      MatButtonModule,
      TranslateModule
  ],
  exports: [
      GenericDialogComponent
  ],
  providers: [
  ],
  bootstrap: [
      GenericDialogComponent
  ]
})
export class GenericDialogModule { }
