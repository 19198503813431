

import { Injectable, EventEmitter } from '@angular/core';

import * as _ from 'underscore';

@Injectable()
export class EditTemplateShared {
    public get isLoading(): boolean { return this._isLoading; }
    public isNew = false;
    public get isEditing(): boolean { return this._isEditing; }
    public set isEditing(v: boolean) { this._isEditing = v; this.isEditingChange.emit(v); }
    public isEditingChange = new EventEmitter();
    public isSaving = false;
    public get showSpinner(): boolean { return this._showSpinner; }
    public operationChanged = new EventEmitter();

    private _loadingKeys: {[id: string]: boolean} = {};
    private _isLoading = false;
    private _isEditing = false;
    private _spinnerTimeout: NodeJS.Timer = null;
    private _showSpinner = false;

    public addLoadingKey(s: string) {
        this._isLoading = true;
        this._loadingKeys[s] = true;
        this._spinnerTimeout = setTimeout(() => this._showSpinner = true, 300);
    }

    public removeLoadingKey(s: string) {
        delete this._loadingKeys[s];
        setTimeout(() => {
            this._isLoading = _.where(Object.keys(this._loadingKeys), k => this._loadingKeys.hasOwnProperty(k)).length > 0;
            if (!this._isLoading && this._spinnerTimeout != null) {
                clearTimeout(this._spinnerTimeout);
                this._spinnerTimeout = null;
                this._showSpinner = false;
            }
        }, 500);
    }
}
