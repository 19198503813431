
import {map} from 'rxjs/operators';
import { ApiHttp } from '../../core/api-http';
import { Observable } from 'rxjs';
import { IStatus } from './status.model';

export class StatusResource {

    constructor(private http: ApiHttp) {

    }

    public getStatus(staging?: boolean): Observable<IStatus> {
        let observable: Observable<IStatus> = null;
        if (staging) {
            observable = this.http.getTyped<IStatus>('{staging}/v1/status'); // force staging
        }
        else {
            observable = this.http.getTyped<IStatus>('{production}/v1/status'); // force production
        }

        // inject if the environment is staging
        return observable.pipe(map(o => { o.staging = (staging || false); return o; }));
    }

}
