import { Component, OnInit } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication.service';
import { UserService } from '../../../core/user.service';
import { RouterStates } from '../../../core/router-states.constant';
import { StateService } from '@uirouter/angular';

@Component({
    templateUrl: './authok.component.html'
})
export class AuthOkComponent implements OnInit {

    constructor(
        private authenticationService: AuthenticationService,
        private stateService: StateService) {
    }

  public ngOnInit() {
    if (this.stateService.params.code && this.stateService.params.state) {
        this.authenticationService.validateAuth(this.stateService.params.code, this.stateService.params.state)
            .subscribe(r => {
                if(r) {
                    console.log('go home');
                    this.stateService.go(RouterStates.dashboard_home);
                } else {
                    this.stateService.go(RouterStates.welcome);
                }
            });
    } else {
        this.stateService.go(RouterStates.welcome);
    }
  }
}
