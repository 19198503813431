import { Injectable } from '@angular/core';
import { DashboardDataSource } from '../../../../core/dashboard-data-source.model';
import { DashboardApiService } from '../../../../api/dashboard-api.service';
import { Subscription ,  Observable } from 'rxjs';
import { ICollection } from '../../../../api/common/collection.model';
import { ITask } from '../../../../api/tasks/task.model';

@Injectable()
export class TasksService {
    
    public dataSource = new DashboardDataSource<ITask>();
    public loadingData = true;

    private timeout: any = null;
    private loadingSubscription: Subscription = null;

    constructor(private api: DashboardApiService) {
        this.loadingData = true;
        this.dataSource.set([]);
    }

    public reset(fromDate?: string, page?: number, pageSize?: number, sortBy?: string, sortAsc?: boolean, selectedStates?: string[]): Observable<ICollection<ITask>> {

        let skip = 0;
        if (page != null && page !== undefined) {
            if (pageSize == null) {
                pageSize = 10;
            }
            skip = page * pageSize;
        }

        let self = this;
        if (this.loadingSubscription) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            this.loadingSubscription.unsubscribe();
        }

        this.timeout = setTimeout(() => {
            if (self.timeout == null) { return; }
            self.loadingData = true;
            self.timeout = null;
        }, 500);

        return new Observable((subscriber) => {

            let result = self.api.tasks.getTasks(fromDate, skip, pageSize, sortBy, sortAsc, selectedStates);
            self.loadingSubscription = result
                .subscribe(
                    data => {
                        self.timeout = null;
                        self.dataSource.set(data.items);
                        subscriber.next(data);
                    },
                    error => {
                        console.error('Unable to get team setups', error);
                        subscriber.error(error);
                    },
                    () => {
                        clearTimeout(self.timeout);
                        self.loadingData = false;
                        self.loadingSubscription = null;
                        subscriber.complete();
                    }
                );

        });
    }

}
