import { NgModule } from '@angular/core';
import { CoreModulesModule } from '../../core-modules.module';
import { IframeContainerComponent } from './iframe-container.component';

@NgModule({
  declarations: [
      IframeContainerComponent
  ],
  imports: [
      CoreModulesModule
  ],
  exports: [
    IframeContainerComponent
  ],
  providers: [
  ],
  bootstrap: [
    IframeContainerComponent
  ]
})
export class IFrameContainerModule { }
