import { ApiHttp } from '../../core/api-http';
import { OperationStatus } from './operation-status.enum';
import { Observable } from 'rxjs';
import { IOperation } from './operation.model';
import { ICollection } from '../common/collection.model';
import { OperationsRevisionRequest } from './operations-revision-request.model';
import { IOperationsRevision } from './operations-revision.model';
import { OperationRequest } from './operation-request.model';
import { PatchOperationRequest } from './patch-operation-request.model';

export class TeamOperationsResource {

    constructor(private http: ApiHttp) {

    }

    public getOperations(teamId: string, statuses?: OperationStatus[]): Observable<ICollection<IOperation>> {
        let url = '/v1/teams/:teamId/operations';
        url = url.replace(':teamId', teamId);

        let params: any = {};
        if (statuses != null && statuses.length > 0) {
            params.statuses = statuses;
        }

        return this.http.getTyped<ICollection<IOperation>>(url, { params: params });
    }

    public getOperation(teamId: string, operationId: string): Observable<IOperation> {
        let url = '/v1/teams/:teamId/operations/:operationId';
        url = url.replace(':teamId', teamId).replace(':operationId', operationId);

        return this.http.getTyped<IOperation>(url);
    }

    public reviseOperations(teamId: string, revisions: OperationsRevisionRequest) {
        let url = '/v1/teams/:teamId/operations/revisions';
        url = url.replace(':teamId', teamId);

        return this.http.postTyped<IOperationsRevision>(url, JSON.stringify(revisions));
    }

    public createOperation(teamId: string, operation: OperationRequest): Observable<IOperation> {
        let url = '/v1/teams/:teamId/operations';
        url = url.replace(':teamId', teamId);

        return this.http.postTyped<IOperation>(url, JSON.stringify(operation));
    }

    public patchOperation(teamId: string, operationId: string, operation: PatchOperationRequest): Observable<IOperation> {
        let url = '/v1/teams/:teamId/operations/:operationId';
        url = url.replace(':teamId', teamId).replace(':operationId', operationId);

        return this.http.patchTyped<IOperation>(url, JSON.stringify(operation));
    }

    public deleteOperation(teamId: string, operationId: string): Observable<Response> {
        let url = '/v1/teams/:teamId/operations/:operationId';
        url = url.replace(':teamId', teamId).replace(':operationId', operationId);
        return this.http.delete(url);
    }    
}
