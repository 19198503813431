import { ApiHttp } from 'app/core/api-http';
import { ICollection } from '../common/collection.model';
import { Observable } from 'rxjs';
import { ICluster } from './cluster.model';
import { IScriptGroup } from './script-group.model';
import { IScript } from './script.model';
import { IScriptRun } from './script-run.model';
import { ScriptRunRequest } from './script-run.request.model';

export class ClusterScriptsResource {

    constructor(private http: ApiHttp) {

    }

    public getClusters(): Observable<ICollection<ICluster>> {
        let url = 'v1/clusterscripts/clusters';
        return this.http.getTyped<ICollection<ICluster>>(url);
    }

    public getClusterByClusterHash(clusterHash: string): Observable<ICluster> {
        let url = 'v1/clusterscripts/clusters/:clusterHash';
        url = url.replace(':clusterHash', clusterHash);
        return this.http.getTyped<ICluster>(url);
    }

    public getAllScripts(): Observable<ICollection<IScriptGroup>> {
        let url = 'v1/clusterscripts/clusters/scripts';
        return this.http.getTyped<ICollection<IScriptGroup>>(url);
    }

    public getClusterScripts(clusterHash: string): Observable<ICollection<IScript>> {
        let url = 'v1/clusterscripts/clusters/:clusterHash/scripts';
        url = url.replace(':clusterHash', clusterHash);
        return this.http.getTyped<ICollection<IScript>>(url);
    }

    public getScriptByName(clusterHash: string, scriptName: string): Observable<IScript> {
        let url = 'v1/clusterscripts/clusters/:clusterHash/scripts/:scriptName';
        url = url.replace(':clusterHash', clusterHash);
        url = url.replace(':scriptName', scriptName);
        return this.http.getTyped<IScript>(url);
    }

    public getScriptRunsByScriptName(clusterHash: string, scriptName: string): Observable<ICollection<IScriptRun>> {
        let url = 'v1/clusterscripts/clusters/:clusterHash/scripts/:scriptName/runs';
        url = url.replace(':clusterHash', clusterHash);
        url = url.replace(':scriptName', scriptName);
        return this.http.getTyped<ICollection<IScriptRun>>(url);
    }

    public getScriptRun(clusterHash: string, scriptName: string, runId: string, logSkip?: number): Observable<IScriptRun> {
        let url = 'v1/clusterscripts/clusters/:clusterHash/scripts/:scriptName/runs/:runId';
        url = url.replace(':clusterHash', encodeURIComponent(clusterHash));
        url = url.replace(':scriptName', encodeURIComponent(scriptName));
        url = url.replace(':runId', encodeURIComponent(runId));

        let params: any = {};
        if (logSkip != null) {
            params.logSkip = logSkip;
        }
        
        return this.http.getTyped<IScriptRun>(url, { params });
    }

    public cancelScriptRun(clusterHash: string, scriptName: string, runId: string): Observable<any> {
        let url = 'v1/clusterscripts/clusters/:clusterHash/scripts/:scriptName/runs/:runId';
        url = url.replace(':clusterHash', clusterHash);
        url = url.replace(':scriptName', scriptName);
        url = url.replace(':runId', runId);
        return this.http.deleteTyped<any>(url);
    }

    public runScript(clusterHash: string, scriptName: string, params: ScriptRunRequest): Observable<IScriptRun> {
        let url = 'v1/clusterscripts/clusters/:clusterHash/scripts/:scriptName/runs';
        url = url.replace(':clusterHash', clusterHash);
        url = url.replace(':scriptName', scriptName);
        return this.http.postTyped<IScriptRun>(url, JSON.stringify(params));
    }
}
