import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { IOrganization } from '../../../../../api/organizations/organization.model';
import { OrganizationService } from '../organization.service';
import { Subscription } from 'rxjs';
import { DashboardApiService } from '../../../../../api/dashboard-api.service';
import { OrganizationImportConfigType } from '../../../../../api/organizations/organization-import-config-type.enum';
import { IOrganizationImportConfig } from '../../../../../api/organizations/organization-import-config.model';
import { deepCopy } from '../../../../../core/deep-copy.function';
import { OrganizationImportConfigRequest } from '../../../../../api/organizations/organization-import-config-request.model';
import { finalize } from 'rxjs/operators';

@Component({
    templateUrl: './organization-import-config.component.html',
    styleUrls: ['./organization-import-config.component.scss']
})
export class OrganizationImportConfigComponent implements OnInit, OnDestroy {

    public get loading(): boolean { return this.loadingConfig || this.organizationService.loadingOrganization; }
    public get organization(): IOrganization { return this.organizationService ? this.organizationService.organization : null; }
    public get jsonData(): string { return this.isEditing ? this.editJson : this.importConfig.configurationJson; }
    public set jsonData(value: string) { if (this.isEditing) { this.editJson = value; } }
    public importConfig: IOrganizationImportConfig;
    public isEditing = false;
    public isSaving = false;
    public editJson: string;
    private subscriptions: Subscription[] = [];
    private loadingConfig = true;

    constructor(
        private organizationService: OrganizationService,
        private api: DashboardApiService) {
    }

    public ngOnInit() {
        this.subscriptions.push(
            this.organizationService.organizationSubject
                .subscribe(
                    org => this.loadConfig(org))
        );
    }

    public ngOnDestroy() {
        if (this.subscriptions != null) {
            for (let subscription of this.subscriptions) {
                subscription.unsubscribe();
            }
        }
    }

    public toggleEditing() {
        if (!this.isEditing) {
            this.editJson = deepCopy(this.importConfig.configurationJson);
            this.isEditing = true;
        }
        else {
            let minimizedEditJson = this.minimizeJson(this.editJson);
            let minimizedOriginalJson = this.minimizeJson(this.importConfig.configurationJson);
            if (minimizedEditJson === minimizedOriginalJson) {
                this.isEditing = false;
            }
            else {
                this.saveConfig(minimizedEditJson);
            }
        }
    }

    public doPrettyPrint() {
        this.editJson = this.prettyPrintJson(this.editJson);
    }

    public cancelEditing() {
        this.isEditing = false;
    }

    public editorKeyDown(event: KeyboardEvent) {
        if (event.which === 9 && !event.shiftKey) {
            event.stopPropagation();
            event.preventDefault();
        }
    }

    public refresh() {
        this.organizationService.refresh();
    }

    private prettyPrintJson(json: string): string {
        if (json == null) {
            return null;
        }
        let obj = JSON.parse(json);
        return JSON.stringify(obj, null, 4);
    }

    private minimizeJson(json: string): string {
        if (json == null) {
            return null;
        }
        let obj = JSON.parse(json);
        return JSON.stringify(obj);
    }

    private saveConfig(minimizedEditJson: string) {
        this.isSaving = true;
        let request: OrganizationImportConfigRequest = {
            configurationJson: minimizedEditJson
        };
        this.api.organizations.saveOrganizationImportConfiguration(this.organization.id, this.importConfig.configurationType, request)
            .pipe(
                finalize(() => this.isSaving = false)
            )
            .subscribe(
                result => {
                    result.configurationJson = this.prettyPrintJson(result.configurationJson);
                    this.importConfig = result;
                    this.isEditing = false;
                },
                err => console.error('unable to save json config', err)
        );
    }

    private loadConfig(organization: IOrganization) {
        if (organization == null) {
            return;
        }
        this.loadingConfig = true;
        this.api.organizations.getOrganizationImportConfiguration(organization.id, OrganizationImportConfigType.videoTagImport)
            .pipe(
                finalize(() => this.loadingConfig = false)
            )
            .subscribe(
                conf => {
                    conf.configurationJson = this.prettyPrintJson(conf.configurationJson);
                    this.importConfig = conf;
                },
                err => console.error('unable to load config', err)
            );
    }

}
