import { NgModule } from '@angular/core';
import { TemplateSetComponent } from './template-set.component';
import { CoreComponentsModule } from '../../../../../core-components.module';
import { BasicInfoModule } from '../basic-info/basic-info.module';
import { SelectTemplateModule } from '../../select-template/select-template.module';

@NgModule({
  declarations: [
    TemplateSetComponent
  ],
  imports: [
    CoreComponentsModule,
    BasicInfoModule,
    SelectTemplateModule
  ],
  exports: [
    TemplateSetComponent
  ],
  providers: [
  ],
  bootstrap: [
    TemplateSetComponent
  ]
})
export class TemplateSetModule { }
