import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { UserComponent } from './user.component';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { UserService } from './user.service';
import { ViewUserModule } from './view-user/view-user.module';

let userState = { name: RouterStates.dashboard_user, params: { userName: '' }, component: UserComponent };

@NgModule({
  declarations: [
    UserComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [userState]
    }),
    ViewUserModule
  ],
  exports: [
    
  ],
  providers: [
    UserService
  ],
  bootstrap: []
})
export class UserModule { }
