import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
// import { TeamService } from './team.service';
import { DashboardSidebarService } from '../../../core/dashboard-sidebar.service';

@Component({
    templateUrl: './team-setup.component.html'
})
export class TeamSetupComponent implements OnInit {

    constructor(private stateService: StateService,
                private dashboardSidebarService: DashboardSidebarService) {

    }

    public ngOnInit() {
        // this.dashboardSidebarService.setTeamName(this.stateService.params.teamName);        
        // this.teamService.loadTeam(this.stateService.params.teamId);
    }

}
