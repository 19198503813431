import { Component, OnDestroy, OnInit,  } from "@angular/core";
import { StateService } from "@uirouter/angular";
import { DashboardApiService } from "app/api/dashboard-api.service";
import { ThreadContentType } from "app/api/threads/thread-content-type.enum";
import { IThread } from "app/api/threads/thread.model";
import { IObjectRef } from "app/api/common/object-ref.model";
import { ObjectTypes } from "app/api/common/object-types.enum";
import { DashboardSidebarService } from "app/core/dashboard-sidebar.service";
import { EnvironmentService } from "app/core/environment.service";
import { observeApiError } from "app/core/rxjs/observe-api-error.operator";
import { finalize } from "rxjs/operators";
import { IUser } from "app/api/users/user.model";
import { RouterStates } from "app/core/router-states.constant";
import { IUserAccess } from "app/api/users/user-access.model";
import * as _ from "underscore";
import { DialogCaption, DialogNoButton, DialogService, DialogYesButton } from "app/core/dialog.service";
import { MatDialog, MatDialogRef, MatSnackBar } from "@angular/material";
import { IUndeleteProgressParams, UndeleteProgressComponent } from "./undelete-progress/undelete-progress.component";

@Component({
    templateUrl: './view-thread.component.html',
    styleUrls: ['./view-thread.component.scss']
})
export class ViewThreadComponent implements OnInit, OnDestroy {

    public loading = false;
    public undeleting = false;
    public thread: IThread;
    public userAccess: IUserAccess[];
    public hasGraduates: boolean;
    public get threadTitle(): string {
        return (this.thread && this.thread.title) || (this.stateService && this.stateService.params.title);
    }

    public get threadUrl(): string {
        if (!this.thread) {
            return '';
        }

        let type: string;
        switch (this.thread.type) {
            case ThreadContentType.playlist:
                type = 'playlist';
                break;
            case ThreadContentType.video:
            case ThreadContentType.share:
                type = 'topics';
                break;
            default:
                return '';
        }

        const path = `/teams/${this.thread.team.id}/content/rooms/${this.thread.room.id}/${type}/${this.thread.id}`;
        return this.envrionment.makeFrontendUrl(path);
    }

    public get teamRef(): IObjectRef | undefined {
        return this.thread
            ? { type: ObjectTypes.Team, id: this.thread.team.id, name: this.thread.team.name }
            : undefined;
    }

    public get roomRef(): IObjectRef | undefined {
        return this.thread
            ? { type: ObjectTypes.Room, id: this.thread.room.id, name: this.thread.room.name }
            : undefined;
    }

    public get authorRef(): IObjectRef | undefined {
        const user = this.thread
            ? { type: ObjectTypes.User, id: this.thread.author.id, name: this.thread.author.name }
            : undefined;
        return user;
    }

    constructor(
        private envrionment: EnvironmentService,
        private api: DashboardApiService,
        private stateService: StateService,
        private sidebarService: DashboardSidebarService,
        private dialogService: DialogService,
        private snackbar: MatSnackBar,
        private mdDialog: MatDialog) {

        if (this.stateService.params.title) {
            this.sidebarService.setThreadTitle(this.stateService.params.title);
        }

        this.doRefresh();
    }

    public ngOnInit(): void {
        
    }

    public ngOnDestroy(): void {
        this.snackbar.dismiss();
    }

    public viewUser(user: IUser): void {
        this.stateService.go(RouterStates.dashboard_user_view, { userId: user.id, userName: user.name });
    }

    public refresh(): void {
        this.doRefresh();
    }

    public downloadCsv(): void {
        this.api.threads.getAnnotationsExportCsv(this.thread.id)
            .pipe(observeApiError(err => {
                this.dialogService.showDialog(new DialogCaption(err.messageWithCode, false));
            }))
            .subscribe(result => {
                console.log(result.type);
            });
    }

    public copyUrl(): void {
        navigator.clipboard.writeText(this.threadUrl);
        this.snackbar.open('Copied', null, { duration: 5000 });
    }

    public undeleteThread(): void {
        this.dialogService.showDialog(
            new DialogCaption('VIEW_THREAD.UNDELETE_PROMPT'),
            new DialogYesButton(() => this.doUndeleteThread()),
            new DialogNoButton()
        );
    }

    private doUndeleteThread(): void {
        if (this.undeleting) {
            return;
        }
        this.undeleting = true;
        this.api.threads.undeleteThreadById(this.thread.id)
            .pipe(finalize(() => this.undeleting = false))
            .pipe(observeApiError(err => {
                if (err.isHttpError && err.httpError.status === 409) {
                    this.dialogService.showDialog(new DialogCaption('VIEW_THREAD.UNDELETE_CONFLICT'));
                } else if (err.isApiError) {

                }
                else {
                    this.dialogService.showDialog(new DialogCaption(err.messageWithCode, false));
                }
            }))
            .subscribe(thread => {
                this.thread = thread;
                this.showUndeleteProgress(thread, thread.undeleteStatus.taskId);
            });
    }

    private showUndeleteProgress(thread: IThread, taskId: string): MatDialogRef<UndeleteProgressComponent> {        
        const dialogRef = this.mdDialog.open(UndeleteProgressComponent, { 
            width: '900px',
            data: <IUndeleteProgressParams>{
                thread: thread,
                taskId: taskId
            },
        });

        dialogRef.afterClosed().subscribe(() => this.doRefresh());
        return dialogRef;
    }

    private doRefresh(): void {
        this.loading = true;
        this.api.threads.getThreadById(this.stateService.params.threadId)
            .pipe(finalize(() => this.loading = false))
            .pipe(observeApiError(err => {
                if (err.isHttpError && err.httpError.status === 404) {
                    window.history.back();
                } else {
                    this.dialogService.showDialog(new DialogCaption(err.messageWithCode, false));
                }
            }))
            .subscribe(thread => {
                if (this.sidebarService.threadTitle !== thread.title) {
                    this.sidebarService.setThreadTitle(thread.title);
                }
                
                this.userAccess = _.sortBy(thread.userAccess, a => a.user.name);
                this.hasGraduates = _.any(this.userAccess, a => a.isGraduate);
                this.thread = thread;
            });
    }

}