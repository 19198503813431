import { ApiHttp } from '../../core/api-http';
import { Observable } from 'rxjs';
import { ICollection } from '../common/collection.model';
import { OperationTemplateTypes } from './operation-template-types.enum';
import { IOperationTemplateBase } from './operation-template.model';
import { OperationTemplateRequestBase } from './operation-template-request.model';

export class OperationTemplatesResource {

    constructor(private http: ApiHttp) {

    }

    public getOperationTemplates(type?: OperationTemplateTypes, ids?: string[], includeArchived?: boolean): Observable<ICollection<IOperationTemplateBase>> {
        let url = '/v1/operationtemplates';
        let params: any = {};

        if (type != null) {
            params.type = type;
        }

        if (ids != null) {
            params.ids = ids;
        }

        if (includeArchived != null) {
            if ((ids == null || ids.length === 0) && includeArchived === true) {
                throw 'includeArchived cannot be used when no ids are passed.';
            }
            else {
                params.includeArchived = includeArchived;
            }
        }

        return this.http.getTyped<ICollection<IOperationTemplateBase>>(url, {
            params: params
        });
    }
    
    public getOperationTemplate(identifierKey: string, includeArchived?: boolean): Observable<IOperationTemplateBase> {
        let url = '/v1/operationtemplates/:id';
        url = url.replace(':id', identifierKey);

        let params: any = {};
        if (includeArchived != null && includeArchived !== undefined) {
            params.includeArchived = includeArchived; 
        }

        return this.http.getTyped<IOperationTemplateBase>(url, { params: params });
    }
    
    public getOperationTemplateByVersion(identifierKey: string, version: number): Observable<IOperationTemplateBase> {
        let url = '/v1/operationtemplates/:id/versions/:version';
        url = url.replace(':id', identifierKey);
        url = url.replace(':version', version.toString());
        return this.http.getTyped<IOperationTemplateBase>(url);
    }
    
    public getOperationTemplateVersions(identifierKey: string): Observable<ICollection<IOperationTemplateBase>> {
        let url = '/v1/operationtemplates/:id/versions';
        url = url.replace(':id', identifierKey);
        return this.http.getTyped<ICollection<IOperationTemplateBase>>(url);
    }
    
    public createOperationTemplate(request: OperationTemplateRequestBase): Observable<IOperationTemplateBase> {
        let url = '/v1/operationtemplates';
        return this.http.postTyped<IOperationTemplateBase>(url, JSON.stringify(request));
    }

    public updateOperationTemplate(identifierKey: string, request: OperationTemplateRequestBase, restore?: boolean): Observable<IOperationTemplateBase> {
        let url = '/v1/operationtemplates/:id';
        url = url.replace(':id', identifierKey);
        
        let params: any = {};
        if (restore === true) {
            params.restore = restore;
        }

        return this.http.putTyped<IOperationTemplateBase>(url, JSON.stringify(request), { params: params });
    }

    public deleteOperationTemplate(identifierKey: string): Observable<IOperationTemplateBase> {
        let url = '/v1/operationtemplates/:id';
        url = url.replace(':id', identifierKey);
        return this.http.deleteTyped<IOperationTemplateBase>(url);
    }
}
