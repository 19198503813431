import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../../core-components.module';
import { RouterStates } from '../../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { ViewOrganizationComponent } from './view-organization.component';
import { OrganizationBasicModule } from './organization-basic/organization-basic.module';
import { OrganizationPlanModule } from './organization-plan/organization-plan.module';

let organizationState = {
    name: RouterStates.dashboard_organizations_organization_view,
    url: '/organizations/:organizationId', 
    params: {
        organizationName: ''
    },
    component: ViewOrganizationComponent
};

@NgModule({
  declarations: [
    ViewOrganizationComponent
  ],
  imports: [
    CoreComponentsModule,
    OrganizationBasicModule,
    OrganizationPlanModule,
    UIRouterModule.forChild({
      states: [organizationState]
    })
  ],
  exports: [
    
  ],
  providers: [
    ViewOrganizationComponent
  ],
  bootstrap: []
})
export class ViewOrganizationModule { }
