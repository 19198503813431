// Feature module with common modules

import { NgModule } from '@angular/core';
import { SubheaderModule } from './subheader/subheader.module';
import { ScrollableModule } from './scrollable/scrollable.module';
import { PaginatorModule } from './paginator/paginator.module';
import { DatepickerModule } from './datepicker/datepicker.module';
import { StoragePlanModule } from './storage-plan/storage-plan.module';
import { StorageStatsModule } from './storage-stats/storage-stats.module';
import { SubscriptionPlanModule } from './subscription-plan/subscription-plan.module';
import { PeriodSelectorModule } from './period-selector/period-selector.module';
import { SpRoleModule } from './sp-role/sp-role.module';
import { UserSelectorModule } from './user-selector/user-selector.module';
import { LoginProviderModule } from './login-provider/login-provider.module';
import { MediaOptionModule } from './media-option/media-option.module';
import { ProcedureSummaryModule } from './procedure-summary/procedure-summary.module';
import { LinkableRefModule } from './linkable-ref/linkable-ref.module';
import { JsonViewerModule } from './json-viewer/json-viewer.module';
import { NavLinkModule } from './nav-link/nav-link.module';
import { FilterListModule } from './filter-list/filter-list.module';
import { IFrameContainerModule } from './iframe-container/iframe-container.module';
import { FileUploaderModule } from './file-uploader/file-uploader.module';
import { GraduationsListModule } from './graduations-list/graduations-list.module';
import { TasksModule } from './tasks/tasks.module';

const modules = [
    SubheaderModule,
    ScrollableModule,
    PaginatorModule,
    DatepickerModule,
    StoragePlanModule,
    StorageStatsModule,
    SubscriptionPlanModule,
    PeriodSelectorModule,
    SpRoleModule,
    UserSelectorModule,
    LoginProviderModule,
    MediaOptionModule,
    ProcedureSummaryModule,
    LinkableRefModule,
    JsonViewerModule,
    NavLinkModule,
    FilterListModule,
    IFrameContainerModule,
    FileUploaderModule,
    GraduationsListModule,
    TasksModule
];

@NgModule({
  imports: modules,
  exports: modules
})
export class ComponentsModule { }
