import { NgModule } from '@angular/core';
import { TeamBasicComponent } from './team-basic.component';
import { CoreComponentsModule } from '../../../../../core-components.module';
import { DeleteTeamModalComponent } from './delete-team-modal/delete-team-modal.component';

@NgModule({
  declarations: [
      TeamBasicComponent,
      DeleteTeamModalComponent
  ],
  imports: [
    CoreComponentsModule
  ],
  providers: [
  ],
  exports: [
    TeamBasicComponent,
    DeleteTeamModalComponent
  ],
  bootstrap: [
      TeamBasicComponent,
      DeleteTeamModalComponent
  ]
})
export class TeamBasicModule {}

