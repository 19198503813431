import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { CdkTableModule } from '@angular/cdk/table';
import { ListRequestsComponent } from './list-requests.component';

let moduleState = { 
  name: RouterStates.dashboard_teamRequests_list,
  url: '/teamrequests?page&size&type',
  params: {
    page: {
      type: 'int',
      dynamic: true
    },
    size: {
      type: 'int',
      dynamic: true
    },
    type: {
        type: 'string',
        dynamic: true
    }
  },
  component: ListRequestsComponent
};

@NgModule({
  declarations: [
    ListRequestsComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [moduleState]
    }),
    CdkTableModule
  ],
  exports: [
    
  ],
  providers: [
    
  ],
  bootstrap: []
})
export class ListRequestsModule { }

