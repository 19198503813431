import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule } from '@angular/forms';
import { EmailContentEditorComponent } from './email-content-editor.component';
import { SelectPlaceholderModule } from './select-placeholder/select-placeholder.module';

@NgModule({
  declarations: [
    EmailContentEditorComponent
  ],
  imports: [
    CoreComponentsModule, 
    FormsModule,
    AngularEditorModule,
    SelectPlaceholderModule
  ],
  exports: [
  ],
  providers: [    
  ],
  entryComponents: [
      EmailContentEditorComponent
  ],
  bootstrap: [
  ]
})
export class EmailContentEditorModule { }
