import { Injectable } from '@angular/core';
import { DashboardApiService } from 'app/api/dashboard-api.service';
import { ICollection } from 'app/api/common/collection.model';
import {of as observableOf,  Observable, of } from 'rxjs';
import { catchError, finalize, map, shareReplay, take } from 'rxjs/operators';
import { IMediaTranscodeProfile } from 'app/api/media/media-transcode-profile.model';

@Injectable()
export class TranscodeProfileService {

    public profiles: IMediaTranscodeProfile[];
    public loadingProfiles = true;

    private loadingObservable: Observable<IMediaTranscodeProfile[]>;

    constructor(private api: DashboardApiService) {

    }

    public loadProfiles(): Observable<IMediaTranscodeProfile[]> {

        if (this.profiles == null || this.profiles.length === 0) {
            this.loadingProfiles = true;
        }

        let tmpObservable = this.loadingObservable;
        if (tmpObservable != null) {
            return tmpObservable;
        }

        return this.loadingObservable = this.api.media.getTranscodeProfiles()
            .pipe(
                shareReplay(),
                finalize(() => {
                    this.loadingObservable = null;
                    this.loadingProfiles = false;
                }),
                catchError((err, caught) => {
                    console.error('Loading quality sets', err);
                    return of<ICollection<IMediaTranscodeProfile>>({ total: 0, items: [] });  
                }),
                map(v => {
                    this.profiles = v.items;
                    return v.items;
                })
            );
    }

    public loadProfilesIfEmpty(): Observable<IMediaTranscodeProfile[]> {

        if (this.profiles == null || this.profiles.length === 0) {
            return this.loadProfiles();
        } else {
            return observableOf(this.profiles).pipe(take(1));
        }
        
    }

}
