import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { CdkTableModule } from '@angular/cdk/table';
import { TeamMembersComponent } from './team-members.component';

let teamMembersState = { name: RouterStates.dashboard_team_members, url: '/teams/:teamId/members', component: TeamMembersComponent };

@NgModule({
  declarations: [
    TeamMembersComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [teamMembersState]
    }),
    CdkTableModule
  ],
  providers: [
    TeamMembersComponent
  ],
  bootstrap: [
  ]
})
export class TeamMembersModule {}

