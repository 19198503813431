import { toModifiedBase64 } from "./base-64";

// Source: https://stackoverflow.com/a/59339394
export function hexToBase64(hexStr: string): string {
    return btoa([...hexStr].reduce((acc, _, i) =>
        acc += !(i - 1 & 1) ? String.fromCharCode(parseInt(hexStr.substring(i - 1, i + 1), 16)) : "" 
    ,""));
};

export function hexToBase64Url(hexStr: string): string {
    return toModifiedBase64([...hexStr].reduce((acc, _, i) =>
        acc += !(i - 1 & 1) ? String.fromCharCode(parseInt(hexStr.substring(i - 1, i + 1), 16)) : "" 
    ,""));
}