import { Component, Input, OnChanges, SimpleChanges, OnDestroy, OnInit, ChangeDetectorRef, EventEmitter } from '@angular/core';
import { ICluster } from 'app/api/cluster-scripts/cluster.model';
import { IScript } from 'app/api/cluster-scripts/script.model';
import { IScriptRun } from 'app/api/cluster-scripts/script-run.model';
import { DashboardApiService } from 'app/api/dashboard-api.service';
import { interval, Subscription, of, BehaviorSubject, Observable } from 'rxjs';
import * as moment from 'moment';
import * as _ from 'underscore';
import { switchMap, take } from 'rxjs/operators';

class DisplayOption {
    constructor(public displayName: string, public value: string) {

    }
}

@Component({
    templateUrl: './script-view.component.html',
    styleUrls: ['./script-view.component.scss'],
    selector: 'script-view'
})
export class ScriptViewComponent implements OnInit, OnChanges, OnDestroy {

    public get hasClusterAndScript(): boolean { return this.cluster != null && this.script != null; }
    @Input() public cluster: ICluster;
    @Input() public script: IScript;
    @Input() public newRuns: EventEmitter<IScriptRun>;
    public scriptRunDataSource: BehaviorSubject<IScriptRun[]>;
    public selectedRun: IScriptRun;
    public scriptRunColumns = ['created', 'status'];
    public formattedOptions: DisplayOption[] = [];
    private scriptRuns: IScriptRun[] = [];
    private lastScriptKey: string = null;
    private subscriptions: Subscription;

    constructor(
        private api: DashboardApiService,
        private changeDetector: ChangeDetectorRef) {

        this.scriptRunDataSource = new BehaviorSubject<IScriptRun[]>([]);
    }

    public ngOnInit() {
        this.subscriptions = interval(10000)
            .subscribe(v => {
                this.reloadRuns();
            });

        let runSubscription = this.newRuns.subscribe(run => this.addScriptRun(run));
        this.subscriptions = this.subscriptions.add(runSubscription);
    }

    public addScriptRun(scriptRun: IScriptRun) {
        
        let idx = _.findIndex(this.scriptRuns, r => r.id === scriptRun.id);
        if (idx === -1) {
            this.scriptRuns.splice(0, 0, scriptRun);
        }
        else {
            this.scriptRuns.splice(idx, 1, scriptRun);
        }
        this.formattedOptions = this.getFormattedOptions(this.script, scriptRun);
        this.scriptRunDataSource.next(this.scriptRuns);
        this.selectedRun = scriptRun;
    }
    
    public ngOnChanges(changes: SimpleChanges) {
        if (this.cluster != null && this.script != null) {
            let key = this.getScriptKey(this.cluster, this.script);
            if (key !== this.lastScriptKey) {
                this.lastScriptKey = key;
                this.selectedRun = null;
                this.scriptRuns = [];
                this.formattedOptions = [];
                this.scriptRunDataSource.next(this.scriptRuns);
                this.reloadRuns();
            }
        }
    }

    public ngOnDestroy() {
        if (this.subscriptions != null) {
            this.subscriptions.unsubscribe();
        }
    }

    public selectScriptRun(run: IScriptRun) {
        let idChanged = this.selectedRun == null || this.selectedRun.id !== run.id;
        this.selectedRun = run;
        if (idChanged) {
            this.loadRun(this.cluster, this.script, run.id)
                .subscribe(loadSuccess => { /* nothing */ });
        }
    }

    private getScriptKey(cluster: ICluster, script: IScript) {
        return cluster.hash + '@' + script.name;
    }

    private reloadRuns() {
        if (!this.hasClusterAndScript) {
            return;
        }

        let cluster = this.cluster;
        if (!cluster.online) {
            return;
        }

        let script = this.script;
        let key = this.getScriptKey(cluster, script);
        
        this.api.clusterScripts.getScriptRunsByScriptName(cluster.hash, script.name)
            .pipe(switchMap(runs => {
                if (this.getScriptKey(this.cluster, this.script) !== key) {
                    return of<any>(null).pipe(take(0));
                }

                this.scriptRuns = runs.items;
                this.scriptRunDataSource.next(this.scriptRuns);

                if (runs.items.length === 0) {
                    this.selectedRun = null;
                    return of<any>(null).pipe(take(0));
                }

                if (this.selectedRun != null && !_.any(runs.items, r => r.id === this.selectedRun.id)) {
                    this.selectedRun = null;
                }

                if (this.selectedRun == null) {
                    this.selectedRun = _.sortBy(runs.items, r => -moment(r.created).unix())[0];
                }

                if (this.selectedRun == null) {
                    return of<any>(null).pipe(take(0));
                }

                if (this.selectedRun.isShallow || (this.selectedRun.status !== 'Completed' && this.selectedRun.status !== 'Failed')) {
                    return this.loadRun(cluster, script, this.selectedRun.id);
                } else {
                    return of<IScriptRun>(this.selectedRun).pipe(take(1));
                }

            }))
            .subscribe(loadSuccess => { /* nothing */ });
    }

    private loadRun(cluster: ICluster, script: IScript, runId: string): Observable<boolean> {
        return this.api.clusterScripts.getScriptRun(cluster.hash, script.name, runId)
            .map(deepRun => {
                if (this.selectedRun == null || this.selectedRun.id !== deepRun.id || this.selectedRun.id !== runId) {
                    return false;
                }
        
                this.selectedRun = deepRun;
                this.formattedOptions = this.getFormattedOptions(script, deepRun);
                let idx = _.findIndex(this.scriptRuns, r => r.id === this.selectedRun.id);
                if (idx === -1) {
                    this.selectedRun = null;
                    return false;
                }

                this.scriptRuns.splice(idx, 1, deepRun);
                return true;
            });
    }
    
    private getFormattedOptions(script: IScript, run: IScriptRun): DisplayOption[] {
        let options: DisplayOption[] = [];
        for (let option of script.options) {
            let value = option.defaultValue || '';
            if (run.options.hasOwnProperty(option.name)) {
                value = run.options[option.name];
            }

            if (!option.isFreeText) {
                let enumOption = _.find(option.values, v => v.value === value);
                if (enumOption != null) {
                    value = enumOption.displayName;
                }
            }

            options.push(new DisplayOption(option.displayName, value));
        }
        return options;
    }
}
