
import {finalize} from 'rxjs/operators';
import { Component, OnInit, ViewChild, Inject } from '@angular/core';
import { ProcedureTypes } from '../../../api/operations/procedure-types.enum';
import { OperationTemplateSelectorComponent } from './operation-template-selector/operation-template-selector.component';
import { DashboardApiService } from '../../../api/dashboard-api.service';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton } from '../../../core/dialog.service';
import { MatDialog, MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import { IOperationTemplate } from '../../../api/operations/operation-template.model';
import { OperationRequest } from '../../../api/operations/operation-request.model';
import * as moment from 'moment';
import { addPlanPeriod } from '../../../api/plans/add-plan-period.function';
import { deepCopy } from '../../../core/deep-copy.function';
import { OperationsRevisionRequest } from '../../../api/operations/operations-revision-request.model';
import * as _ from 'underscore';
import { Observable } from 'rxjs';
import { IOperationsRevision } from '../../../api/operations/operations-revision.model';
import * as Flatpickr from 'flatpickr';

@Component({
    templateUrl: './operation-creation-modal.component.html',
    styleUrls: ['./operation-creation-modal.component.scss']
})
export class OperationCreationModalComponent implements OnInit {

    public isSaving = false;
    public isNavigating = false;
    public step = 0;
    public lastOpDateOneMonth: moment.Moment = null;
    public get allowedProcedureTypes(): ProcedureTypes[] { return this.data.allowedProcedureTypes; }
    @ViewChild(OperationTemplateSelectorComponent, { static: false }) public templateSelector: OperationTemplateSelectorComponent;
    public startDate: string;
    public createdOperations: MatTableDataSource<OperationRequest> = new MatTableDataSource<OperationRequest>();
    public createdOperationsColumns: string[] = ['runat', 'type', 'summary'];
    public datePickerOptions: Flatpickr.Options = null;    
    private selectedTemplates: IOperationTemplate[];
    private operationRequests: OperationRequest[];

    public get canMoveNext(): boolean {
        return (this.templateSelector != null && this.templateSelector.canMoveNext());
    }

    constructor(
        private api: DashboardApiService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private mdDialog: MatDialog,
        private mdDialogRef: MatDialogRef<OperationCreationModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: IOperationCreationModalInput) {
        if (data.lastOpDate != null) {
            this.lastOpDateOneMonth = moment(data.lastOpDate).add(1, 'months');
        }
    }

    public ngOnInit() {
        this.datePickerOptions = {
            minuteIncrement: 5,
            minDate: this.getRoundedDate(moment().add(-31, 'days')).toDate()
        };
    }
    
    public previous() {
        this.step = Math.max(0, this.step - 1);
    }

    public next() {
        if (!this.canMoveNext) {
            return;
        }
        this.isNavigating = true;

        if (this.step === 0) {
            this.templateSelector.getSelectedTemplates()
                .subscribe(st => {
                    ++this.step;
                    this.selectedTemplates = st;
                    this.startDate = this.getRoundedDate(new Date()).toISOString();
                    this.operationRequests = this.createOperationsFromTemplates(st);
                    this.createdOperations.data = this.operationRequests;
                },
                err => console.error('Error getting selected templates: ', err),
                () => this.isNavigating = false);
        }
        else if (this.step === 1) {

            if (this.isSaving) {
                return;
            }
            this.isSaving = true;
            this.data.reviseOperations({
                updates: this.operationRequests
            }).pipe(
            finalize(() => this.isSaving = false))
            .subscribe(operations => {
                console.log(operations);
                this.mdDialogRef.close();
            },
            err => console.error(err),
            () => this.isNavigating = false);

        }
    }

    public close() {
        this.dialogService.showDialog(
            new DialogCaption('SHARED.WITHOUT_SAVING_PROMPT'),
            new DialogYesButton(() => this.mdDialogRef.close()),
            new DialogNoButton()
        );
    }

    public startDateChanged(newDate: Date) {
        this.operationRequests = this.createOperationsFromTemplates(this.selectedTemplates, newDate);
        this.createdOperations.data = this.operationRequests;
    }

    public setStart(date?: string | moment.Moment | Date) {
        if (date == null) {
            date = new Date();
        }
        date = this.getRoundedDate(date);
        this.startDate = date.toISOString();
        this.startDateChanged(date.toDate());
    }

    private createOperationsFromTemplates(templates: IOperationTemplate[], startDate?: string | moment.Moment | Date): OperationRequest[] {
        let baseTime = this.getRoundedDate(startDate);
        let operations = [];
        for (let template of templates) {
            let templateStart = template.offset != null ? addPlanPeriod(baseTime, template.offset, 1) : baseTime;
            for (let i = 0; i < template.repetitionCount; ++i) {
                let operationTime = addPlanPeriod(templateStart, template.repetitionPeriod, i);
                let operation: OperationRequest = {
                    procedure: deepCopy(template.procedure),
                    runAt: operationTime.toISOString()
                };
                operations.push(operation);
            }
        }
        return _.sortBy(operations, op => moment(op.runAt).toDate());
    }

    private getRoundedDate(date: string | moment.Moment | Date) {
        date = moment(date);
        date = date.add(-date.milliseconds(), 'milliseconds');
        date = date.add(-date.seconds(), 'seconds');
        if (date.minutes() % 5 !== 0) {
            date = date.add(5 - date.minutes() % 5, 'minutes');
        }
        return date;
    }

}

class TemplateWrapper {
    public template: IOperationTemplate;
    public operationRequest: OperationRequest;
}

export interface IOperationCreationModalInput {
    lastOpDate: string;
    allowedProcedureTypes: ProcedureTypes[];
    reviseOperations: (reviseParams: OperationsRevisionRequest) => Observable<IOperationsRevision>;
}

