import { ApiHttp } from '../../core/api-http';
import { Observable } from 'rxjs';
import { ICollection } from '../common/collection.model';
import { IEmailTemplate } from './email-template.model';
import { IEmailContent } from './email-content.model';
import { EmailTemplateRequest } from './email-template-request.model';
import { EmailContentRequest } from './email-content-request.model';
import { IEmailPlaceholder } from './email-placeholder.model';
import { IEmailPlaceholderOptions } from './email-placeholder-options.model';
import { IEmailTemplatePreview } from './email-template-preview.model';

export class EmailTemplatesResource {

    constructor(private http: ApiHttp) {

    }
    
    public getTemplates(): Observable<ICollection<IEmailTemplate>> {
        let url = '/v1/emailTemplates';
        return this.http.getTyped<ICollection<IEmailTemplate>>(url);
    }
    
    public getTemplatesByIds(templateIds: string[]): Observable<ICollection<IEmailTemplate>> {
        let url = '/v1/emailTemplates';
        
        let params: any = {};
        if (templateIds != null && templateIds.length > 0) {
            params.ids = templateIds;
        }

        return this.http.getTyped<ICollection<IEmailTemplate>>(url, { params: params });
    }

    public getTemplateById(templateId: string): Observable<IEmailTemplate> {
        let url = '/v1/emailTemplates/:templateId';
        url = url.replace(':templateId', templateId);
        return this.http.getTyped<IEmailTemplate>(url);
    }

    public deleteTemplateById(templateId: string): Observable<IEmailTemplate> {
        let url = '/v1/emailTemplates/:templateId';
        url = url.replace(':templateId', templateId);
        return this.http.deleteTyped<IEmailTemplate>(url);
    }

    public updateTemplateById(templateId: string, request: EmailTemplateRequest): Observable<IEmailTemplate> {
        let url = '/v1/emailTemplates/:templateId';
        url = url.replace(':templateId', templateId);
        return this.http.patchTyped<IEmailTemplate>(url, JSON.stringify(request));
    }

    public createTemplate(request: EmailTemplateRequest): Observable<IEmailTemplate> {
        let url = '/v1/emailTemplates';
        return this.http.postTyped<IEmailTemplate>(url, JSON.stringify(request));
    }

    public addTemplateLanguageContent(templateId: string, request: EmailContentRequest): Observable<IEmailContent> {
        let url = '/v1/emailTemplates/:templateId/content';
        url = url.replace(':templateId', templateId);
        return this.http.postTyped<IEmailContent>(url, JSON.stringify(request));
    }

    public updateTemplateLanguageContent(templateId: string, language: string, request: EmailContentRequest): Observable<IEmailContent> {
        let url = '/v1/emailTemplates/:templateId/content/:language';
        url = url.replace(':templateId', templateId);
        url = url.replace(':language', language);
        return this.http.patchTyped<IEmailContent>(url, JSON.stringify(request));
    }

    public getTemplateLanguageContent(templateId: string, language: string): Observable<IEmailContent> {
        let url = '/v1/emailTemplates/:templateId/content/:language';
        url = url.replace(':templateId', templateId);
        url = url.replace(':language', language);
        return this.http.getTyped<IEmailContent>(url);
    }

    public deleteTemplateLanguageContent(templateId: string, language: string): Observable<Response> {
        let url = '/v1/emailTemplates/:templateId/content/:language';
        url = url.replace(':templateId', templateId);
        url = url.replace(':language', language);
        return this.http.delete(url);
    }

    public getPlaceholders(): Observable<IEmailPlaceholderOptions> {
        let url = '/v1/emailtemplates/placeholders';
        return this.http.getTyped<IEmailPlaceholderOptions>(url);
    }

    public getPreview(templateId: string, language: string, request: EmailContentRequest): Observable<IEmailTemplatePreview> {
        let url = '/v1/emailTemplates/:templateId/content/:language/previews';
        url = url.replace(':templateId', templateId);
        url = url.replace(':language', language);
        return this.http.postTyped<IEmailTemplatePreview>(url, JSON.stringify(request));
    }
}
