import {of as observableOf,  Observable, Subscription } from 'rxjs';
import { Component, Input, OnInit, OnDestroy, SimpleChanges, OnChanges } from '@angular/core';
import { IEnsurePlanProcedure } from '../../../api/operations/procedure-model';
import { IProcedureComponent } from '../procedure-component-interface';
import { take } from 'rxjs/operators';
import { QualitySetsService } from 'app/core/quality-sets.service';
import * as _ from 'underscore';
import { IMediaQualitySet } from 'app/api/media/media-quality-set.model';

@Component({
    templateUrl: './ensure-plan-procedure.component.html',
    styleUrls: ['./ensure-plan-procedure.component.scss'],
    selector: 'ensure-plan-procedure'
})
export class EnsurePlanProcedureComponent implements IProcedureComponent, OnInit, OnChanges, OnDestroy {

    @Input() public procedure: IEnsurePlanProcedure;
    @Input() public editProcedure: any;
    @Input() public isEditing: boolean;
    @Input() public isSaving: boolean;
    public get isReady(): boolean { return !this.qualitySetsService.loadingSets; }
    public qualitySet: IMediaQualitySet;
    private subscription: Subscription;

    constructor(private qualitySetsService: QualitySetsService) {

    }
    
    public hasChanges(): boolean {
        return false;
    }

    public validate(): Observable<boolean> {
        return observableOf<boolean>(false).pipe(take(1));
    }

    public ngOnInit() {
        this.subscription = this.qualitySetsService.loadSetsIfEmpty().subscribe(s => this.setQualitySetDetails());
    }

    public ngOnChanges(changes: SimpleChanges) {
        this.setQualitySetDetails();
    }

    public ngOnDestroy() {
        if (this.subscription != null) {
            this.subscription.unsubscribe();
        }
    }

    private setQualitySetDetails() {
        if (this.procedure == null || !this.isReady) {
            this.qualitySet = null;
            return;
        }
        this.qualitySet = _.find(this.qualitySetsService.qualitySets, q => q.id === this.procedure.qualitySetId);
    }
}
