import { Component, Input, TemplateRef, ViewChild, SimpleChanges, OnChanges, ViewChildren, QueryList, AfterViewInit, OnDestroy, ElementRef } from '@angular/core';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';

@Component({
    templateUrl: './iframe-container.component.html',
    selector: 'iframe-container'
})
export class IframeContainerComponent implements OnDestroy, OnChanges, AfterViewInit {

    // @ViewChild('iframe', { read: TemplateRef, static: false }) public iframe: TemplateRef<HTMLIFrameElement>;
    @ViewChildren('iframe', { read: ElementRef }) public iframes: QueryList<ElementRef<HTMLIFrameElement>>;
    // @ViewChild('container', { read: ElementRef }
    @Input() public template: string;
    private subscription: Subscription;    
    private blob: Blob;
    private url: string = null;

    public ngOnChanges(changes: SimpleChanges): void {

        if (changes.template.currentValue != null) {
            this.updateIframe();
        }

    }

    public ngAfterViewInit(): void {
        this.updateIframe();
        this.subscription = this.iframes.changes.subscribe(() => this.updateIframe());
    }

    public ngOnDestroy(): void {
        if (this.subscription != null) {
            this.subscription.unsubscribe();
        }
        this.blob = null;
        if (this.url != null) {
            URL.revokeObjectURL(this.url);
        }
    }

    private updateIframe() {
        if (this.iframes == null || this.iframes.length !== 1) {
            return;
        }

        if (this.template == null || this.template === '') {
            return;
        }

        let oldUrl = this.url;
        if (this.blob != null) {
            this.blob = null;
        }
        this.blob = new Blob([this.template], { type: 'text/html' });
        this.url = URL.createObjectURL(this.blob);
        
        let iframe = this.iframes.first;
        iframe.nativeElement.setAttribute('src', this.url);

        if (oldUrl != null) {
            URL.revokeObjectURL(oldUrl);
        }
        // let doc = iframe.nativeElement.contentWindow.document;
        // doc.childNodes.forEach(cn => doc.removeChild(cn));
        // doc.write(this.template);
    }
}
