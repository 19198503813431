import { Component, OnInit, OnDestroy } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { OrganizationService } from '../organization.service';
import { IOrganization } from '../../../../../api/organizations/organization.model';
import { Observable, Subscription } from 'rxjs';
import { take } from 'rxjs/operators';
import { IUser } from '../../../../../api/users/user.model';
import { MatTableDataSource, MatDialog, MatDialogRef } from '@angular/material';
import { RouterStates } from '../../../../../core/router-states.constant';
import { UserSelectorComponent } from '../../../../../components/user-selector/user-selector.component';
import { DialogService, DialogYesButton, DialogNoButton, DialogCaption } from '../../../../../core/dialog.service';
import * as _ from 'underscore';

@Component({
    templateUrl: './organization-members.component.html',
    styleUrls: ['./organization-members.component.scss']
})
export class OrganizationMembersComponent implements OnInit, OnDestroy {

    public get loading(): boolean { return this.organizationService.loadingOrganization; }
    public get organization(): IOrganization { return this.organizationService ? this.organizationService.organization : null; }
    public dataSource = new MatTableDataSource<IUser>();
    public get displayedColumns(): string[] { return this.dataSource.data != null && this.dataSource.data.length > 1 ? this._displayedColumns.concat('removeadmin') : this._displayedColumns; }
    private _displayedColumns = ['id', 'name', 'email', 'loginProviders'];
    private subscriptions: Subscription[] = [];

    constructor(
        private organizationService: OrganizationService,
        private stateService: StateService,
        private mdDialog: MatDialog,
        private dialogService: DialogService) {
        
    }

    public ngOnInit() {
        let updateSubscription = this.organizationService
            .organizationSubject
            .subscribe(org => {
                if (org == null) {
                    this.dataSource.data = [];
                }
                else {
                    this.dataSource.data = org.administrators;
                }
            });
        this.subscriptions.push(updateSubscription);
    }

    public ngOnDestroy() {
        for (let sub of this.subscriptions) {
            if (sub != null) {
                sub.unsubscribe();
            }
        }
    }

    public viewUser(user: IUser) {
        this.stateService.go(RouterStates.dashboard_user_view, { userId: user.id, userName: user.name });
    }

    public addAdministrator() {
        this.showUserSelectorDialog()
            .afterClosed()
            .subscribe(result => {
                console.log('result', result);
                if (result == null || result.user == null) {
                    return;
                }

                this.organizationService.addOrganizationAdministrator(this.organization.id, result.user.id)
                    .subscribe(
                        addResult => null,
                        err => {
                            console.error('adding admin failed', err);
                            this.dialogService.showDialog(new DialogCaption('ORGANIZATION_MEMBERS.ADD_ADMIN_FAIL'));
                        }
                    );
            });
    }

    public removeAdministrator(user: IUser) {
        this.dialogService.showDialog(
            new DialogCaption('ORGANIZATION_MEMBERS.CONFIRM_REMOVE_ADMIN', true, { name: user.name, organizationName: this.organization.name }),
            new DialogYesButton(() => {
                
                this.organizationService.removeOrganizationAdministrator(this.organization.id, user.id)
                    .subscribe(
                        removeResult => null,
                        err => {
                            console.error('removing admin failed', err);
                            this.dialogService.showDialog(new DialogCaption('ORGANIZATION_MEMBERS.REMOVE_ADMIN_FAIL'));
                        }
                    );

            }),
            new DialogNoButton()
        );
    }

    public refresh() {
        this.organizationService.refresh();
    }

    private showUserSelectorDialog(): MatDialogRef<UserSelectorComponent>  {
        return this.mdDialog.open(UserSelectorComponent, { 
            width: '1000px',
            height: '600px',
            data: {
                pageSize: 10,
                excludeUserIds: _.map(this.organization.administrators, a => a.id),
                selectConfirmationDialog: (user: IUser) => this.confirmNewAdministrator(user)
            }
        });
    }

    private confirmNewAdministrator(user: IUser): Observable<boolean> {
        return new Observable<boolean>((subscriber) => {
            // can't add the same guy again
            if (_.find(this.organization.administrators, a => a.id === user.id)) {
                
                this.dialogService.showDialog(
                    new DialogCaption('ORGANIZATION_MEMBERS.ALREADY_ADMIN', true, { name: user.name, organizationName: this.organization.name })
                );
                subscriber.next(false);
                subscriber.complete();
                return;
            }

            this.dialogService.showDialog(
                new DialogCaption('ORGANIZATION_MEMBERS.CONFIRM_ADD_ADMIN', true, { name: user.name, organizationName: this.organization.name }),
                new DialogYesButton(() => {
                    subscriber.next(true);
                    subscriber.complete();
                }),
                new DialogNoButton(() => {
                    subscriber.next(false);
                    subscriber.complete();
                })
            );
        });
    }
}
