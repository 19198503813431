import { ApiHttp } from "app/core/api-http";
import { ICollection } from "../common/collection.model";
import { Observable } from "rxjs";
import { IThread } from "./thread.model";
import { HttpEvent } from "@angular/common/http";

export class ThreadsResource {

    constructor(
        private http: ApiHttp) {

    }

    public getThreadById(threadId: string): Observable<IThread> {
        let url = '/v1/threads/:threadId';
        url = url.replace(':threadId', threadId);
        return this.http.getTyped<IThread>(url, {});
    }

    public searchThreads(query?: string, skip?: number, pageSize?: number, searchType?: string): Observable<ICollection<IThread>> {

        let url = '/v1/threads';
        let params: any =  {};

        if (query != null && query !== undefined && query.length > 0) {
            params.query = query;
        }

        if (skip != null && skip !== undefined) {
            params.skip = skip;
        }

        if (pageSize != null && pageSize !== undefined) {
            params.pageSize = pageSize;
        }

        if (searchType != null && searchType !== undefined) {
            params.searchType = searchType;
        }

        return this.http.getTyped<ICollection<IThread>>(url, {
            params: params
        });
    }

    
    public getAnnotationsExportCsv(threadId: string): Observable<any> {
        let url = '/v1/threads/:threadId/annotationscsv';
        url = url.replace(':threadId', threadId);
        return this.http.downloadCsv(url);
    }

    public undeleteThreadById(threadId: string): Observable<IThread> {
        let url = '/v1/threads/:threadId';
        url = url.replace(':threadId', threadId);
        let body = { isDeleted: false };
        return this.http.patchTyped<IThread>(url, JSON.stringify(body));
    }

}