import { NgModule } from '@angular/core';
import { TaskRunComponent } from './task-run/task-run.component';
import { CoreModulesModule } from '../../core-modules.module';
import { ScrollableModule } from '../scrollable/scrollable.module';

@NgModule({
  declarations: [
      TaskRunComponent
  ],
  imports: [
    CoreModulesModule,
    ScrollableModule
  ],
  exports: [
    TaskRunComponent
  ],
  providers: [
  ],
  bootstrap: [
    TaskRunComponent
  ]
})
export class TasksModule { }
