import { Component, Inject } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';

@Component({
    selector: 'sp-generic-dialog',
    styleUrls: [ './generic-dialog.component.scss' ],
    templateUrl: 'generic-dialog.component.html'
})
export class GenericDialogComponent {
    public caption: GenericDialogCaption;
    public buttons: GenericDialogButton[];

    constructor(
        public dialogRef: MatDialogRef<GenericDialogComponent>,
        @Inject(MAT_DIALOG_DATA) private data: any) {
            this.caption = this.data.caption;
            this.buttons = this.data.buttons;
    }
}

export class GenericDialogCaption {
    constructor(public text: string, public data?: any) {
    }
}

export class GenericDialogButton {
    constructor(public text: string, public id: string, public data?: any) {
    }
}

