
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {map} from 'rxjs/operators';
import { Component, Input, SimpleChanges, OnChanges, OnDestroy, OnInit } from '@angular/core';
import { IOperation } from '../../../api/operations/operation.model';
import { MatTableDataSource, MatDialog, MatDialogRef } from '@angular/material';
import * as _ from 'underscore';
import { OperationModalComponent, IOperationModalInput } from '../operation-modal/operation-modal.component';
import { OperationRequest } from '../../../api/operations/operation-request.model';
import { PatchOperationRequest } from '../../../api/operations/patch-operation-request.model';
import { OperationStatus } from '../../../api/operations/operation-status.enum';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton } from '../../../core/dialog.service';
import { OperationsRevisionRequest } from '../../../api/operations/operations-revision-request.model';
import { IOperationsRevision } from '../../../api/operations/operations-revision.model';
import { OperationCreationModalComponent, IOperationCreationModalInput } from '../operation-creation-modal/operation-creation-modal.component';
import * as moment from 'moment';
import { dictionaryToArray } from '../../../core/dictionary-to-array';
import { UserService } from '../../../core/user.service';
import { ProcedureTypes } from 'app/api/operations/procedure-types.enum';

@Component({
    templateUrl: './operations-list.component.html',
    styleUrls: ['./operations-list.component.scss'],
    selector: 'operations-list'
})
export class OperationsListComponent implements OnChanges, OnInit {

    @Input() public operations: IOperation[];
    @Input() public allowedProcedureTypes: string[];
    @Input() public editPermissionsCheck: () => boolean;
    @Input('getOperation') public getOperationInput: (operationId: string) => Observable<IOperation>;
    @Input('reviseOperations') public reviseOperationsInput: (revisionParams: OperationsRevisionRequest) => Observable<IOperationsRevision>;
    @Input('saveOperation') public saveOperationInput: (operationId: string, operationParams: PatchOperationRequest) => Observable<IOperation>;
    @Input('deleteOperation') public deleteOperationInput: (operationId: string) => Observable<Response>;

    public get permissionsCheck(): () => boolean { return this.editPermissionsCheck != null && this.editPermissionsCheck !== undefined ? this.editPermissionsCheck : null; }
    public get hasSelectedOperations(): boolean { return _.any(dictionaryToArray(this.checkedOperations), v => v.value === true); }
    public checkedOperations: {[id: string]: boolean} = {};
    public operationsChecked = false;
    public dataSource = new MatTableDataSource<IOperation>([]);
    public columns: string[] = ['check', 'runAt', 'status', 'type', 'buttons'];
    public highlightOperations: {[id: string]: boolean} = {};
    public isSaving = false;
    public completedOperationsCount = 0;
    public showCompletedOperationsCount = 0;

    constructor(
        private dialogService: DialogService,
        private mdDialog: MatDialog,
        private userService: UserService) {
    }

    public ngOnInit() {
        if (!this.userService.hasRole('Accounts')) {
            this.columns = _.filter(this.columns, c => c !== 'check');
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.operations && changes.operations.currentValue != null) {
            this.updateDataSource(changes.operations.currentValue);
        }
    }

    public viewOperation(rowOperation: IOperation) {
        if (this.getOperationInput == null) {
            console.warn('getOperation() not linked.');
            return;
        }
        this.getOperationInput(rowOperation.id)
            .subscribe(o => {
                let pos = _.findIndex(this.operations, op => op.id === o.id);
                this.operations.splice(pos, 1, o);
                this.updateDataSource(this.operations);
                this.showEditOperationModal(o);
            });
    }

    public deleteOperation(rowOperation: IOperation) {
        if (rowOperation.status !== OperationStatus.Pending) {
            return;
        }

        this.dialogService.showDialog(
            new DialogCaption('OPERATIONS_LIST.DELETE_OPERATION_CONFIRM_SINGLE'),
            new DialogYesButton(() => {
                this.isSaving = true;
                this.reviseOperations({ deletes: [ rowOperation.id ] })
                    .subscribe(
                        null,
                        err => console.error(err),
                        () => this.isSaving = false
                    );
            }),
            new DialogNoButton()
        );
    }

    public createNewOperation() {
        this.showCreateOperationsModal();
    }

    public deletedSelectedOperations() {
        let operationIds = _.chain(dictionaryToArray(this.checkedOperations))
                                .filter(o => o.value === true)
                                .map(o => o.key)
                                .value();

        let confirmQuestionKey = 
            operationIds.length === 1 
            ? 'OPERATIONS_LIST.DELETE_OPERATION_CONFIRM_SINGLE'
            : 'OPERATIONS_LIST.DELETE_OPERATION_CONFIRM_MULTI';

        this.dialogService.showDialog(
            new DialogCaption(confirmQuestionKey, true, { count: operationIds.length }),
            new DialogYesButton(() => {
                this.isSaving = true;
                this.reviseOperations({ deletes: operationIds })
                    .subscribe(
                        null,
                        err => console.error(err),
                        () => this.isSaving = false
                    );
            }),
            new DialogNoButton()
        );
    }

    public showOlderOperations() {
        this.showCompletedOperationsCount = Math.min(this.completedOperationsCount, this.showCompletedOperationsCount + 10);
    }

    public toggleSelectAll(event: any) {
        this.determineOperationsChecked();
        let newState = !this.operationsChecked;
        for (let key of Object.keys(this.checkedOperations)) {
            if (this.checkedOperations.hasOwnProperty(key) === true) {
                this.checkedOperations[key] = newState;
            }
        }
        this.operationsChecked = newState;
    }

    public operationChecked(event: any) {
        this.determineOperationsChecked();
    }

    private determineOperationsChecked() {
        this.operationsChecked = _.all(dictionaryToArray(this.checkedOperations), o => o.value === true);
    }

    private showEditOperationModal(operation?: IOperation, isNew?: boolean): MatDialogRef<OperationModalComponent>  {
        return this.mdDialog.open(OperationModalComponent, { 
            width: '550px',
            disableClose: true,
            data: <IOperationModalInput>{
                operation: operation,
                isNew: isNew,
                createOperation: (operationParams: OperationRequest) => this.createOperation(operationParams),
                saveOperation:  (operationId: string, operationParams: OperationRequest) => this.saveOperation(operationId, operationParams)
            }
        });
    }

    private showCreateOperationsModal(): MatDialogRef<OperationCreationModalComponent> {
        let lastOp = _.max(this.operations, op => moment(op.runAt).toDate());

        return this.mdDialog.open(OperationCreationModalComponent, { 
            width: '1000px',
            disableClose: true,
            data: <IOperationCreationModalInput>{
                lastOpDate: lastOp != null && lastOp.runAt != null ? lastOp.runAt.toString() : null,
                reviseOperations: (revisionParams: OperationsRevisionRequest) => this.reviseOperations(revisionParams),
                allowedProcedureTypes: this.allowedProcedureTypes
            }
        });
    }

    private createOperation(operationParams: OperationRequest): Observable<IOperation> {
        // ensure that there is no id accidentally passed through on the object
        if ((<any>operationParams).id != null) {
            delete (<any>operationParams).id;
        }

        return this.reviseOperations({ updates: [operationParams] }).pipe(
            map((result, i) => {
                return _.first(result.updates);
            }));
    }

    private reviseOperations(revisionParams: OperationsRevisionRequest): Observable<IOperationsRevision> {
        return this.reviseOperationsInput(revisionParams).pipe(
            map((result, i) => {
                for (let op of result.updates || []) {
                    let idx = _.findIndex(this.operations, o => o.id === op.id);
                    if (idx === -1) {
                        this.operations.push(op);
                    }
                    else {
                        this.operations.splice(idx, 1, op);
                    }
                }
                for (let opId of result.deletes || []) {
                    let idx = _.findIndex(this.operations, oi => oi.id === opId);
                    if (idx !== -1) {
                        this.operations.splice(idx, 1);
                    }
                }

                // if any operations have changed since we tried to do the revision
                // reload them
                let toReload = [];
                if (revisionParams.deletes != null) {
                    let deletedIds = (result.deletes || []);
                    let ids = _.filter(revisionParams.deletes, id => deletedIds.indexOf(id) === -1);
                    for (let id of ids) {
                        toReload.push(id);
                    }
                }
                if (revisionParams.updates != null) {
                    let reqUpdateIds = _.chain(revisionParams.updates).filter(u => u.id != null).map(u => u.id).value();
                    let updatedIds = _.map(result.updates || [], u => u.id);
                    let ids = _.filter(reqUpdateIds, id => updatedIds.indexOf(id) === -1);
                    for (let id of ids) {
                        toReload.push(id);
                    }
                }
                if (toReload.length > 0) {
                    this.reloadOperations(toReload);
                }

                this.updateDataSource(this.operations);
                return result;
            }));
    }

    private reloadOperations(operationIds: string[]) {
        // since this shouldn't realistically be a big number,
        // let's just do one call at a time
        let loadOps: Observable<IOperation>[] = [];
        for (let id of operationIds) {
            loadOps.push(this.getOperationInput(id));
        }
        observableForkJoin(
            loadOps
        ).subscribe(operations => {

            for (let op of operations || []) {
                let idx = _.findIndex(this.operations, o => o.id === op.id);
                if (idx === -1) {
                    this.operations.push(op);
                }
                else {
                    this.operations.splice(idx, 1, op);
                }
            }
            this.updateDataSource(this.operations);

        });
    }

    private saveOperation(operationId: string, operationParams: OperationRequest): Observable<IOperation> {
        return this.saveOperationInput(operationId, operationParams).pipe(
            map((o, i) => {
                let opIdx = _.findIndex(this.operations, oi => oi.id === o.id);
                if (opIdx !== -1) {
                    this.operations.splice(opIdx, 1, o);
                }
                else {
                    this.operations.splice(0, 0, o);
                }
                this.updateDataSource(this.operations);
                return o;
            }));
    }

    private updateDataSource(operations: IOperation[]) {
        // update the checked for removal object
        let chkObj: {[id: string]: boolean} = {};
        this.highlightOperations = {};
        let completedOperationsCount = 0;

        for (let op of operations) {
            chkObj[op.id] = op.status === OperationStatus.Pending && (this.checkedOperations[op.id] || false);

            if (op.status === OperationStatus.Completed) {
                ++completedOperationsCount;
            }
            else if (op.status === OperationStatus.Pending && op.procedure != null && op.procedure.type === ProcedureTypes.ExpirePlan) {
                this.highlightOperations[op.id] = true;
            }
        }

        this.completedOperationsCount = completedOperationsCount;
        this.showCompletedOperationsCount = 5;
        this.checkedOperations = chkObj;
        this.determineOperationsChecked();
        this.dataSource.data = _.sortBy(operations, c => moment(c.runAt).toDate());
    }
}
