import { NgModule } from '@angular/core';
import { TemplateNormalComponent } from './template-normal.component';
import { BasicInfoModule } from '../basic-info/basic-info.module';
import { CoreComponentsModule } from '../../../../../core-components.module';
import { ProceduresModule } from '../../../../../components/procedures/procedures.module';

@NgModule({
  declarations: [
    TemplateNormalComponent
  ],
  imports: [
    CoreComponentsModule,
    BasicInfoModule,
    ProceduresModule
  ],
  exports: [
    TemplateNormalComponent
  ],
  providers: [
  ],
  bootstrap: [
    TemplateNormalComponent
  ]
})
export class TemplateNormalModule { }
