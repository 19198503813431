import { NgModule } from '@angular/core';
import { UserSelectorComponent } from './user-selector.component';
// import { CoreModule } from '../../core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { CoreModulesModule } from '../../core-modules.module';
import { CdkTableModule } from '@angular/cdk/table';
import { LoginProviderModule } from '../login-provider/login-provider.module';
import { PaginatorModule } from '../paginator/paginator.module';

@NgModule({
  declarations: [
    UserSelectorComponent
  ],
  imports: [
    // CoreModule,
    // TranslateModule
    CoreModulesModule,
    CdkTableModule,
    LoginProviderModule,
    PaginatorModule
  ],
  exports: [
    UserSelectorComponent
  ],
  providers: [
  ],
  bootstrap: [
    UserSelectorComponent
  ]
})
export class UserSelectorModule { }
