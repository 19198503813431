import { Directive, OnDestroy, Input, Output, HostListener, EventEmitter, ElementRef } from '@angular/core';

import * as Flatpickr from 'flatpickr';
import * as moment from 'moment';
import * as _ from 'underscore';
import { TranslateService } from '@ngx-translate/core';

@Directive({
    selector: '[SpDatePicker]',
    exportAs: 'spDatePicker'
})
export class DatepickerDirective implements OnDestroy {

    // public get hasChild(): boolean { return this.buttonContainer ? this.safeTrim(this.buttonContainer.nativeElement.innerHTML).length !== 0 : false; }
    // public readonly buttonId: string = 'dtp_' + uuid();
    // @ViewChild('buttonContainer') public buttonContainer: ElementRef;
    @Input() public handleClick = true;
    @Input() public dateModel: string;
    @Input() public options: Flatpickr.Options = null;
    @Output() public dateModelChange = new EventEmitter();

    private picker: Flatpickr;

    constructor(private element: ElementRef, private translateService: TranslateService) {

    }

    public ngOnDestroy() { 
        if (this.picker) {
            this.picker.destroy();
        }
    }

    @HostListener('click', ['$event'])
    public openPicker(event: MouseEvent) {
        console.log(event);
        if (this.handleClick) {
            this.showPicker();
        }
    }

    public showPicker() {
        if (this.picker) {
            this.picker.destroy();
            this.picker = null;
        }

        let self = this;
        let options: Flatpickr.Options = {
            enableTime: true,
            time_24hr: true,
            minuteIncrement: 30,
            onChange: function(selectedDates: Date[], dateStr: string, instance: Flatpickr, elem: HTMLElement) {
                if (!selectedDates || selectedDates.length === 0) {
                    self.setDate(null);
                }
                else {
                    let date = moment(selectedDates[0]);
                    self.setDate(date.toISOString());
                }
            },
            onClose: (selectedDates: Date[], dateStr: string, instance: Flatpickr, elem: HTMLElement) => {
                // kill the picker after we're done with it
                setTimeout(() => {
                    instance.destroy();
                    instance = null;
                    self.picker.destroy();
                    self.picker = null;
                }, 50);
            },
            locale: this.getLocale()
        };
        _.extend(options, this.options);
        this.picker = new Flatpickr(this.element.nativeElement, options);

        if (this.dateModel) {
            let momentDate = moment(this.dateModel);
            this.picker.setDate(momentDate.local().toISOString());
        }
        else {
            this.picker.setDate(new Date());
        }
        this.picker.open();
    }

    public hidePicker() {
        console.log('hide');
        if (this.picker) {
            this.picker.close();
            this.picker.destroy();
            this.picker = null;
        }
    }

    private setDate(date: string) {
        this.dateModel = date;
        this.dateModelChange.emit(this.dateModel);
    }

    // TODO: Fix to import these from flatpickr correctly
    private getLocale() {
        let lang = this.translateService.currentLang;
        if (lang === 'ja') {
            return this.getLocaleJa();
        }
        else {
            return this.getLocaleEn();
        }
    }

    private getLocaleEn() {
        return {
            weekdays: {
                shorthand: ['Sun', 'Mon', 'Tue', 'Wed', 'Thu', 'Fri', 'Sat'],
                longhand: [
                    'Sunday', 'Monday', 'Tuesday', 'Wednesday',
                    'Thursday', 'Friday', 'Saturday'
                ]
            },
            months: {
                shorthand: [
                    'Jan', 'Feb', 'Mar', 'Apr',
                    'May', 'Jun', 'Jul', 'Aug',
                    'Sep', 'Oct', 'Nov', 'Dec'
                ],
                longhand: [
                    'January', 'February', 'March',	'April',
                    'May', 'June', 'July', 'August',
                    'September', 'October', 'November', 'December'
                ]
            },
            daysInMonth: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
            firstDayOfWeek: 0,
            ordinal: (nth) => {
                const s = nth % 100;
                if (s > 3 && s < 21) {
                    return 'th';
                }
                switch (s % 10) {
                    case 1: return 'st';
                    case 2: return 'nd';
                    case 3: return 'rd';
                    default: return 'th';
                }
            },
            rangeSeparator: ' to ',
            weekAbbreviation: 'Wk',
            scrollTitle: 'Scroll to increment',
            toggleTitle: 'Click to toggle'
        };
    }

    private getLocaleJa() {
        return {
            weekdays: {
                shorthand: ['日', '月', '火', '水', '木', '金', '土'],
                longhand: ['日曜日', '月曜日', '火曜日', '水曜日', '木曜日', '金曜日', '土曜日']
            },
            months: {
                shorthand: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月'],
                longhand: ['1月', '2月', '3月', '4月', '5月', '6月', '7月', '8月', '9月', '10月', '11月', '12月']
            },
            daysInMonth: [31, 28, 31, 30, 31, 30, 31, 31, 30, 31, 30, 31],
            firstDayOfWeek: 0,
            ordinal: (nth) => {
                return '';
            },
            rangeSeparator: ' - ',
            weekAbbreviation: '週目',
            scrollTitle: 'Scroll to increment',
            toggleTitle: 'Click to toggle'
        };
    }
}
