import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { TeamService } from './team.service';
import { DashboardSidebarService } from '../../../core/dashboard-sidebar.service';

@Component({
    templateUrl: './team.component.html'
})
export class TeamComponent implements OnInit {

    constructor(private stateService: StateService, private teamService: TeamService,
                private dashboardSidebarService: DashboardSidebarService) {
        this.dashboardSidebarService.setTeamName(this.stateService.params.teamName);        
    }

    public ngOnInit() {
        this.teamService.loadTeam(this.stateService.params.teamId);
    }

}
