import { Component, Inject, OnDestroy, OnInit } from "@angular/core";
import { MAT_DIALOG_DATA, MatDialog, MatDialogRef } from "@angular/material";
import { DashboardApiService } from "app/api/dashboard-api.service";
import { ITaskRun } from "app/api/tasks/task-run.model";
import { ITask } from "app/api/tasks/task.model";
import { IThread } from "app/api/threads/thread.model";
import * as moment from "moment";
import { Observable, Subject, Subscription, forkJoin, interval, of } from "rxjs";
import { map, tap } from "rxjs/operators";
import * as _ from 'underscore';

@Component({
    templateUrl: './undelete-progress.component.html',
    styleUrls: ['./undelete-progress.component.scss']
})
export class UndeleteProgressComponent implements OnInit, OnDestroy {

    private progressSubscription: Subscription;
    public task: ITask;
    public taskRun: ITaskRun;
    public isRefreshing = true;

    constructor(
        private mdDialog: MatDialog,
        private mdDialogRef: MatDialogRef<UndeleteProgressComponent>,
        @Inject(MAT_DIALOG_DATA) private data: IUndeleteProgressParams,
        private api: DashboardApiService) {

    }

    public ngOnInit(): void {
        let observable: Observable<{task: ITask, run: ITaskRun}> = interval(1500)
            .pipe(tap(() => this.isRefreshing = true))
            .switchMap(() => this.api.tasks.getTaskById(this.data.taskId))
            .switchMap(task => {
                this.task = task;
                let latestRun = _.chain(task.runs.items).sortBy(r => moment(r.start).toDate().getTime()).last().value();
                if (!latestRun) {
                    return forkJoin([of(task), of(null)]);
                }
                return forkJoin([of(task), this.api.tasks.getTaskRunById(task.id, latestRun.id)]);
            })
            .pipe(map(taskAndRun => { return { task: taskAndRun[0], run: taskAndRun[1] }; }));

        this.progressSubscription = observable
            .subscribe(taskAndRun => {
                const task = taskAndRun.task;
                const run = taskAndRun.run;

                const failureStates = ['complete', 'error', 'skipped', 'cancelled'];
                if (failureStates.indexOf(task.status) >= 0) {
                    // we've reached the last event we need
                    this.progressSubscription.unsubscribe();
                }
                this.taskRun = run;
                this.isRefreshing = false;
            });
    }

    public ngOnDestroy(): void {

        if (this.progressSubscription) {
            this.progressSubscription.unsubscribe();
        }

    }

    public close(): void {
        
        if (this.progressSubscription) {
            this.progressSubscription.unsubscribe();
        }
        this.mdDialogRef.close();

    }
}

export interface IUndeleteProgressParams {
    thread: IThread;
    taskId: string;
}