import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { CdkTableModule } from '@angular/cdk/table';
import { TeamGraduationsComponent } from './team-graduations.component';

let teamGraduationsState = { name: RouterStates.dashboard_team_graduations, url: '/teams/:teamId/graduations', component: TeamGraduationsComponent };

@NgModule({
  declarations: [
    TeamGraduationsComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [teamGraduationsState]
    }),
    CdkTableModule
  ],
  providers: [
    TeamGraduationsComponent
  ],
  bootstrap: [
  ]
})
export class TeamGraduationsModule {}

