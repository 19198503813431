import { ApiHttp } from '../../core/api-http';
import { Observable } from 'rxjs';
import { ICollection } from '../common/collection.model';
import { IUser } from './user.model';
import { IUserTeam } from './user-team.model';
import { UserTeamPlanRequest } from './user-team-plan-request.model';
import { IUserTeamPlan } from './user-team-plan.model';
import { ObjectRef } from '../common/object-ref.model';
import { IUserPermissions } from './user-permissions.model';

export class UsersResource {

    constructor(private http: ApiHttp) {

    }

    public getUsers(query: string, skip?: number, pageSize?: number, sortBy?: string, sortAsc?: boolean, excludeUserIds?: string[]): Observable<ICollection<IUser>> {

        let params: any =  {};
        
        if (query != null && query !== undefined && query.length > 0) {
            params.query = query;
        }

        if (skip != null && skip !== undefined) {
            params.skip = skip;
        }

        if (pageSize != null && pageSize !== undefined) {
            params.pageSize = pageSize;
        }

        if (sortBy != null && sortBy !== undefined) {
            params.sortBy = sortBy;
        }

        if (sortAsc != null && sortAsc !== undefined) {
            params.sortAsc = sortAsc;
        }

        if (excludeUserIds != null && excludeUserIds.length > 0) {
            params.excludeUserIds = excludeUserIds;
        }
    
        return this.http.getTyped<ICollection<IUser>>('/v1/users', {
            params: params
        });
    }

    public getUser(userId: string): Observable<IUser> {
        let url = '/v1/users/:userId';
        url = url.replace(':userId', userId);
        return this.http.getTyped<IUser>(url);
    }

    public patchUserTeamPlan(userId: string, data: UserTeamPlanRequest): Observable<IUserTeamPlan> {
        let url = '/v1/users/:userId/teamPlan';
        url = url.replace(':userId', userId);
        return this.http.patchTyped<IUserTeamPlan>(url, JSON.stringify(data));
    }

    public getUserTeams(userId: string): Observable<ICollection<IUserTeam>> {
        let url = '/v1/users/:userId/teams';
        url = url.replace(':userId', userId);
        return this.http.getTyped<ICollection<IUserTeam>>(url);
    }

    public getUserEmailAddressesRequest(): Observable<string> {
        let url = '/v1/users/emails';
        let params: any = {};

        return this.http.getText(url, { params, responseType: 'text' });
    }

    public getUserPermissions(userId: string, ref: ObjectRef): Observable<IUserPermissions> {
        let url = '/v1/users/:userId/permissions';
        url = url.replace(':userId', userId);
        return this.http.getTyped<IUserPermissions>(url, {
            params: {
                target: ref.type + ':' + ref.id
            }
        });
    }
}
