import { Component, OnInit } from '@angular/core';
import { DashboardApiService } from '../../../../api/dashboard-api.service';
import { StateService } from '@uirouter/angular';
import { ITeamRenewalForm } from '../../../../api/teams/team-renewal-form.model';
import { ITeam } from '../../../../api/teams/team.model';
import { RouterStates } from '../../../../core/router-states.constant';
import { IUser } from '../../../../api/users/user.model';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';


@Component({
    templateUrl: './view-renewal.component.html',
    styleUrls: ['./view-renewal.component.scss']
})
export class ViewRenewalComponent implements OnInit {

    public renewal: ITeamRenewalForm;
    public get renewalId(): string { return this.renewal != null ? this.renewal.id : (this.stateService.params.renewalId != null ? this.stateService.params.renewalId : ''); }
    public loading = true;

    public get fee(): string {
        if (this.renewal == null) {
            return '';
        }

        let total = this.renewal.price || 0;
        let parts: string[] = [];
        if (this.renewal.initialFee != null && this.renewal.initialFee > 0) {
            total += this.renewal.initialFee;
            parts.push(this.translateService.instant('VIEW_TEAM_RENEWAL.PAYMENT_AMOUNT_INITIAL_FEE', { fee: this.formatNumber(this.renewal.initialFee) }));
        }

        if (this.renewal.supportFee != null && this.renewal.supportFee > 0) {
            total += this.renewal.supportFee;
            parts.push(this.translateService.instant('VIEW_TEAM_RENEWAL.PAYMENT_AMOUNT_SUPPORT_FEE', { fee: this.formatNumber(this.renewal.supportFee) }));
        }

        let compiledParts = '';
        for (let part of parts) {
            compiledParts += this.translateService.instant('VIEW_TEAM_RENEWAL.PAYMENT_AMOUNT_ADDITION_FORMAT', { value: part });
        }

        const formatStringKey = parts.length > 0 ? 'VIEW_TEAM_RENEWAL.PAYMENT_AMOUNT_FORMAT_EXTRAS' : 'VIEW_TEAM_RENEWAL.PAYMENT_AMOUNT_FORMAT';
        return this.translateService.instant(formatStringKey, { 
            price: this.formatNumber(this.renewal.price),
            extras: compiledParts,
            total: this.formatNumber(total)
        });
    }


    private formatNumber(num: number): string {
        let numberFormat = new Intl.NumberFormat('en-US');
        return numberFormat.format(num);
    }

    constructor (
        private api: DashboardApiService,
        private stateService: StateService,
        private translateService: TranslateService) {

    }

    public ngOnInit() {

        this.api.teams.getTeamRenewalForm(this.stateService.params.renewalId)
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe(renewalForm => {
                console.log(renewalForm);
                this.renewal = renewalForm;
            });

    }

    public viewTeam(team: ITeam) {
        this.stateService.go(RouterStates.dashboard_team_view, { teamId: team.id, teamName: team.name });
    }

    public viewUser(user: IUser) {
        this.stateService.go(RouterStates.dashboard_user_view, { userId: user.id, userName: user.name });
    }

}
