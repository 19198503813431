import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { SearchThreadsComponent } from './search-threads.component';
import { CdkTableModule } from '@angular/cdk/table';
import { MatSlideToggleModule } from '@angular/material';

let threadsState = {
    name: RouterStates.dashboard_threads_search,
    url: '/threads?q&page&size&type',
    component: SearchThreadsComponent,
    params: {
      q: {
        type: 'string',
        dynamic: true
      },
      page: {
        type: 'int',
        dynamic: true
      },
      size: {
        type: 'int',
        dynamic: true
      },
      type: {
        type: 'string',
        dynamic: true
      }
    }
  };

@NgModule({
  declarations: [
    SearchThreadsComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [threadsState]
    }),
    CdkTableModule
  ],
  providers: [
    
  ],
  bootstrap: [
  ]
})
export class SearchThreadsModule {}

