
export class RouterStates {
    public static readonly welcome = 'welcome';
    public static readonly authok = 'authok';

    public static readonly dashboard = 'dashboard';
    public static readonly dashboard_home = 'dashboard.home';
    public static readonly dashboard_teams = 'dashboard.teams';
    public static readonly dashboard_threads = 'dashboard.threads';
    public static readonly dashboard_threads_search = 'dashboard.threads.search';
    public static readonly dashboard_thread_view = 'dashboard.threads.view';
    public static readonly dashboard_plans = 'dashboard.plans';
    public static readonly dashboard_graduations = 'dashboard.graduations';
    public static readonly dashboard_connectivityTest = 'dashboard.connectivityTest';
    public static readonly dashboard_team = 'dashboard.team';
    public static readonly dashboard_team_view = 'dashboard.team.view';
    public static readonly dashboard_team_members = 'dashboard.team.members';
    public static readonly dashboard_team_graduations = 'dashboard.team.graduations';
    public static readonly dashboard_teamSetup = 'dashboard.teamSetup';
    public static readonly dashboard_teamSetup_setups = 'dashboard.teamSetup.setups';
    public static readonly dashboard_teamSetup_setup_view = 'dashboard.teamSetup.view';
    public static readonly dashboard_teamSetup_setup_new = 'dashboard.teamSetup.new';
    public static readonly dashboard_teamRequests = 'dashboard.teamRequests';
    public static readonly dashboard_teamRequests_list = 'dashboard.teamRequests.list';
    public static readonly dashboard_teamRequests_request_view = 'dashboard.teamRequests.requestView';
    public static readonly dashboard_teamRequests_renewal_view = 'dashboard.teamRequests.renewalView';
    public static readonly dashboard_tasks_list = 'dashboard.tasks.listTasks';
    public static readonly dashboard_tasks = 'dashboard.tasks';
    public static readonly dashboard_tasks_view = 'dashboard.tasks.viewTask';
    public static readonly dashboard_tasks_new = 'dashboard.tasks.newTask';
    public static readonly dashboard_users = 'dashboard.users';
    public static readonly dashboard_user = 'dashboard.user';
    public static readonly dashboard_user_view = 'dashboard.user.view';
    public static readonly dashboard_urlValidator = 'dashboard.urlValidator';
    public static readonly dashboard_urlValidator_withUrl = 'dashboard.urlValidator_withUrl';
    public static readonly dashboard_operation_template = 'dashboard.operationTemplate';
    public static readonly dashboard_operation_template_view = 'dashboard.operationTemplate.view';
    public static readonly dashboard_operation_template_new = 'dashboard.operationTemplate.new';
    public static readonly dashboard_operation_templates = 'dashboard.operationTemplate.templates';
    public static readonly dashboard_organizations = 'dashboard.organizations';
    public static readonly dashboard_organizations_listOrganizations = 'dashboard.organizations.listOrganizations';
    public static readonly dashboard_organizations_organization = 'dashboard.organizations.organization';
    public static readonly dashboard_organizations_organization_view = 'dashboard.organizations.organization.view';
    public static readonly dashboard_organizations_organization_members = 'dashboard.organizations.organization.members';
    public static readonly dashboard_organizations_organization_importConfig = 'dashboard.organizations.organization.importConfig';
    public static readonly dashboard_emailTemplates = 'dashboard.emailTemplates';
    public static readonly dashboard_emailTemplates_list = 'dashboard.emailTemplates.list';
    public static readonly dashboard_emailTemplates_view = 'dashboard.emailTemplates.view';
    public static readonly dashboard_emailTemplates_new = 'dashboard.emailTemplates.new';
    public static readonly dashboard_clusterScripts = 'dashboard.clusterScripts';
}
