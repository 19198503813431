import { Component, Input, OnChanges, SimpleChanges, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton } from '../../core/dialog.service';

@Component({
    templateUrl: './json-viewer.component.html',
    styleUrls: ['./json-viewer.component.scss'],
    selector: 'json-viewer',
    host: {
        'style': 'width: 100%; height: calc(100%);'
    }
})
export class JsonViewerComponent implements OnChanges {

    public jsonData: string;
    public get isEditing(): boolean { return this.isEditingInt != null ? this.isEditingInt : false; }
    public get isDialog(): boolean { return this.dialogData != null; }
    @Input() public json: string;
    @Input() public captionKey: string;
    @Input() public captionParams: any;
    @Input('isEditing') public isEditingInt: boolean;
    public get showButtons() { return this.dialogData != null; }

    constructor(
        @Optional() private mdDialogRef: MatDialogRef<JsonViewerComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) private dialogData: any,
        private dialogService: DialogService) {
            if (dialogData != null) {
                this.jsonData = this.prettyPrintJson(dialogData.json);
                if (dialogData.captionKey) {
                    this.captionKey = dialogData.captionKey;
                    this.captionParams = dialogData.captionParams || {};
                }
                this.isEditingInt = dialogData.isEditing;
            }
        }
    
    public ngOnChanges(changes: SimpleChanges) {
        if (changes.json && changes.json.currentValue !== changes.json.previousValue) {
            this.jsonData = this.prettyPrintJson(changes.json.currentValue || '');
        }
    }

    public closeDialog() {
        if (this.mdDialogRef != null) {
            this.mdDialogRef.close(null);
        }
    }

    public cancel() {
        this.dialogService.showDialog(
            new DialogCaption('SHARED.WITHOUT_SAVING_PROMPT'),
            new DialogYesButton(() => this.mdDialogRef.close()),
            new DialogNoButton()
        );
    }

    public save() {
        this.mdDialogRef.close({ json: this.minimizeJson(this.jsonData) });
    }

    public doPrettyPrint() {
        this.jsonData = this.prettyPrintJson(this.jsonData);
    }

    private prettyPrintJson(json: string): string {
        if (json == null) {
            return null;
        }
        let obj = JSON.parse(json);
        return JSON.stringify(obj, null, 4);
    }

    private minimizeJson(json: string): string {
        if (json == null) {
            return null;
        }
        let obj = JSON.parse(json);
        return JSON.stringify(obj);
    }
}
