
import {of as observableOf,  Observable } from 'rxjs';
import { Component, OnInit, Input, EventEmitter, Output, ViewChildren, QueryList } from '@angular/core';

import { IOperationTemplate, IOperationTemplateBase } from '../../../../../api/operations/operation-template.model';
import { OperationTemplateRequest } from '../../../../../api/operations/operation-template-request.model';
import { IProcedureComponent } from '../../../../../components/procedures/procedure-component-interface';
import { ITemplateComponent } from '../template-component-interface';
import { IProcedure } from '../../../../../api/operations/procedure-model';
import { ProcedureRequestBase } from '../../../../../api/operations/procedure-request.model';
import { PlanPeriod, PlanPeriodTypes } from '../../../../../api/plans/plan-period.model';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton } from '../../../../../core/dialog.service';
import { DashboardApiService } from '../../../../../api/dashboard-api.service';
import { ProcedureTypes, getCreateableProcedureTypes } from '../../../../../api/operations/procedure-types.enum';
import { TranslateService } from '@ngx-translate/core';
import { MatSelectChange } from '@angular/material';
import { take } from 'rxjs/operators';
import { EditTemplateShared } from '../edit-template-shared.service';
import { OperationTemplateService } from '../../../../../core/operation-template.service';

@Component({
    templateUrl: './template-normal.component.html',
    styleUrls: ['./template-normal.component.scss'],
    selector: 'template-normal'
})
export class TemplateNormalComponent implements OnInit, ITemplateComponent {

    @Input() public shared: EditTemplateShared;
    @Input() public operation: IOperationTemplate;
    @Output() public operationChange = new EventEmitter();
    @Input() public editOperation: OperationTemplateRequest;
    public get procedure(): IProcedure { return this.operation != null ? this.operation.procedure : null; }
    public get hasProcedure(): boolean { return this.procedure != null || this.editProcedure != null; }
    public get procedureTypeName(): string { return this.operation != null ? this.operation.procedure.type : (this.editOperation != null ? this.editOperation.procedure.type : ''); }
    public get editProcedure(): ProcedureRequestBase { return this.editOperation != null ? this.editOperation.procedure : null; }
    public get isEditing(): boolean { return this.shared != null ? this.shared.isEditing : false; }
    public get isSaving(): boolean { return this.shared != null ? this.shared.isSaving : false; }
    public get offset(): PlanPeriod { 
        return this.isEditing ? this.editOperation.offset : this.operation.offset; 
    }
    public get repetitionPeriod(): PlanPeriod {
        return this.isEditing ? this.editOperation.repetitionPeriod : this.operation.repetitionPeriod;
    }
    public set repetitionPeriod(p: PlanPeriod) {
        if (this.isEditing) {
            this.editOperation.repetitionPeriod = p;
        }
    }
    public get offsetEnabled(): boolean { return this.isEditing ? this.editOperation.offset != null : this.offset != null; }
    public set offsetEnabled(value: boolean) { if (this.isEditing) { this.editOperation.offset = value ? new PlanPeriod(PlanPeriodTypes.MONTH, 1) : null; } }
    public procedureTypes: ProcedureTypeModel[] = [];
    public selectedProcedureType: ProcedureTypeModel = undefined;

    @ViewChildren('procedureEditor') private children: QueryList<IProcedureComponent>;
    private get procedureComponent(): IProcedureComponent { return this.children.length > 0 ? this.children.first : null; }
    
    constructor (
        private api: DashboardApiService,
        private dialogService: DialogService,
        private translateService: TranslateService,
        private templateService: OperationTemplateService) {

        let procTypes = getCreateableProcedureTypes();
        for (let type of procTypes) {
            this.procedureTypes.push({ key: type, displayName: type });
        }
    }

    public ngOnInit() {
        // update the existing procedure types with the updated translations
        let translateKeys = [];
        let mapping: { [id: string]: ProcedureTypeModel } = {};
        for (let proc of this.procedureTypes) {
            let transKey = 'SHARED_OPERATIONS.PROCEDURES.' + proc.key.toUpperCase();
            translateKeys.push(transKey);
            mapping[transKey] = proc;
        }
        this.translateService.get(translateKeys)
            .subscribe(
                translations => {
                    for (let k in translations) {
                        if (translations.hasOwnProperty(k)) {
                            mapping[k].displayName = translations[k];
                        }
                    }
                });
    }

    public save(): Observable<boolean> {
        if (this.procedureComponent == null) {
            return observableOf<boolean>(false).pipe(take(1));
        }

        let hasChanges = false;
        if ((this.operation || null) == null) {
            hasChanges = true;
        }

        if (!hasChanges) {
            hasChanges = this.operation.name !== this.editOperation.name;
            hasChanges = hasChanges || this.periodHasChanges(this.operation.offset, this.editOperation.offset);
            hasChanges = hasChanges || this.periodHasChanges(this.operation.repetitionPeriod, this.editOperation.repetitionPeriod);
            hasChanges = hasChanges || this.operation.repetitionCount !== this.editOperation.repetitionCount;
        }

        if (!hasChanges) {
            hasChanges = this.procedureComponent.hasChanges();
        }

        if (!hasChanges) {
            console.log('no changes');
            return observableOf<boolean>(true).pipe(take(1));
        }

        return new Observable<boolean>((subscriber) => {
            this.validate()
                .subscribe(
                    canSave => {
                        if (canSave) {
                            let resultObservable: Observable<IOperationTemplateBase> = null;
                            
                            if (this.shared.isNew) {
                                resultObservable = this.api.operationTemplates
                                    .createOperationTemplate(this.editOperation);
                            }
                            else {
                                resultObservable = this.api.operationTemplates
                                    .updateOperationTemplate(
                                        this.operation.identifier.key,
                                        this.editOperation
                                    );
                            }

                            resultObservable
                                .subscribe(
                                    o => {
                                        this.operation = <IOperationTemplate>o;
                                        this.operationChange.emit(this.operation);
                                        this.templateService.mergeTemplate(o);
                                        subscriber.next(true);
                                    },
                                    err => subscriber.error(err),
                                    () => subscriber.complete()
                                );
                        }
                        else {
                            this.dialogService.showDialog(
                                new DialogCaption('EDIT_OPERATION_TEMPLATE.VALIDATION_FAIL')
                            );
                            subscriber.next(false);
                            subscriber.complete();
                        } 
                    }
                );
        });
    }

    public procedureTypeChange(change: MatSelectChange) {
        if (change.value != null) {
            let procedureType = <ProcedureTypes>change.value;
            if (this.editOperation.procedure != null) {
                this.dialogService.showDialog(
                    new DialogCaption('EDIT_OPERATION_TEMPLATE.TEMPLATE.CHANGE_PROCEDURE'),
                    new DialogYesButton(() => this.editOperation.procedure = ProcedureRequestBase.CreateProcedure(procedureType)),
                    new DialogNoButton()
                );
            }
            else {
                this.editOperation.procedure = ProcedureRequestBase.CreateProcedure(procedureType);
            }
        }
    }

    private validate(): Observable<boolean> {
        if (this.editOperation.repetitionCount < 1
            || (this.editOperation.offset && this.editOperation.offset.periodLength < 1)
            || this.editOperation.repetitionPeriod.periodLength < 1
            || this.editOperation.name.length < 3
            || this.editOperation.procedure == null) {
                console.log(this.editOperation);
            return observableOf<boolean>(false).pipe(take(1));
        }
        console.log('validate procedure call');
        return this.procedureComponent.validate();
    }

    private periodHasChanges(a: PlanPeriod, b: PlanPeriod) {
        a = a || null;
        b = b || null;

        if ((a != null) !== (b != null)) {
            return true;
        }

        return a && b && (a.periodType !== b.periodType || a.periodLength !== b.periodLength);
    }
}


interface ProcedureTypeModel {
    key: string;
    displayName: string;
}
