import { getEnumValues } from 'app/core/enum-values.function';
import * as _ from 'underscore';

export enum ProcedureTypes {
    /* If any procedures are added/changed/removed here, also update the procedure-summary component */
    StorageAssignment = 'StorageAssignment',
    UpdateStorageStats = 'UpdateStorageStats',
    MemberLimit = 'MemberLimit',
    SupporterLimit = 'SupporterLimit',
    ExpirePlanWarning = 'ExpirePlanWarning',
    ExpirePlan = 'ExpirePlan',
    SendEmail = 'SendEmail',
    MediaOptions = 'MediaOptions',
    EnsurePlan = 'EnsurePlan',
    EnsurePlanV2 = 'EnsurePlanV2' // ,
    // SendEmail = 'SendEmail'
}

// Some procedure types aren't user-creatable, so these should be excluded
export function getCreateableProcedureTypes(): ProcedureTypes[] {
    return <ProcedureTypes[]>_.filter(getEnumValues(ProcedureTypes), t => t !== ProcedureTypes.EnsurePlan && t !== ProcedureTypes.EnsurePlanV2);
}
