import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { OperationsListComponent } from './operations-list.component';
import { OperationsSharedModule } from '../operations-shared.module';


@NgModule({
  declarations: [
    OperationsListComponent
  ],
  imports: [
    CoreComponentsModule,
    OperationsSharedModule
  ],
  exports: [
    OperationsListComponent
  ],
  providers: [
  ],
  bootstrap: [
    OperationsListComponent
  ]
})
export class OperationsListModule { }
