import { IProcedureComponent } from '../procedure-component-interface';
import { Input, Component, ViewChild, OnChanges, SimpleChanges } from '@angular/core';
import { IMediaOptionsProcedure } from 'app/api/operations/procedure-model';
import { MediaOptionsProcedureRequest } from 'app/api/operations/procedure-request.model';
import { Observable, of } from 'rxjs';
import { MediaOptionComponent } from 'app/components/media-option/media-option.component';
import { take } from 'rxjs/operators';
import { IMediaOption } from 'app/api/common/media-option.model';
import { deepCopy } from 'app/core/deep-copy.function';

@Component({
    templateUrl: './media-options-procedure.component.html',
    styleUrls: ['./media-options-procedure.component.scss'],
    selector: 'media-options-procedure'
})
export class MediaOptionsProcedureComponent implements IProcedureComponent, OnChanges {
        
    @ViewChild(MediaOptionComponent, {static: false}) public mediaOptionComponent !: MediaOptionComponent;
    @Input() public procedure: IMediaOptionsProcedure;
    @Input() public editProcedure: MediaOptionsProcedureRequest;
    @Input() public isEditing: boolean;
    @Input() public isSaving: boolean;

    public hasChanges(): boolean {
        return this.mediaOptionComponent.hasChanges();
    }

    public validate(): Observable<boolean> {
        this.editProcedure.mediaOptions = this.mediaOptionComponent.optionsToSave;
        console.log('validate', this.editProcedure);
        return of(true).pipe(take(1));
    }

    public ngOnChanges(changes: SimpleChanges) {
        // if (changes.procedure.currentValue !== changes.procedure.previousValue && changes.procedure.currentValue != null) {
        //     console.log(changes);
        //     this.mediaOptions = deepCopy(changes.procedure.currentValue.options);
        // }
    }
}
