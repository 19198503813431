import { NgModule } from '@angular/core';
import { OperationCreationModalComponent } from './operation-creation-modal.component';
import { ProceduresModule } from '../../procedures/procedures.module';
import { CoreComponentsModule } from '../../../core-components.module';
import { ViewTemplateModule } from '../../../sections/dashboard/operation-template/edit-template/view-template/view-template.module';
import { OperationTemplateSelectorComponent } from './operation-template-selector/operation-template-selector.component';
import { MatStepperModule } from '@angular/material';
import { ProcedureSummaryModule } from '../../procedure-summary/procedure-summary.module';

@NgModule({
  imports: [
    CoreComponentsModule,
    ProceduresModule,
    ViewTemplateModule,
    MatStepperModule,
    ProcedureSummaryModule
  ],
  declarations: [
    OperationCreationModalComponent,
    OperationTemplateSelectorComponent
  ],
  exports: [
    OperationCreationModalComponent
  ],
  bootstrap: [
    OperationCreationModalComponent,
    OperationTemplateSelectorComponent
  ]
})
export class OperationCreationModalModule { }

