import { RouterStates } from 'app/core/router-states.constant';
import { TemplateListComponent } from './template-list.component';
import { CoreComponentsModule } from 'app/core-components.module';
import { UIRouterModule } from '@uirouter/angular';
import { NgModule } from '@angular/core';
import { TemplateListService } from './template-list.service';

let routerState = { 
    name: RouterStates.dashboard_emailTemplates_list, 
    url: '/email-templates', 
    component: TemplateListComponent
};

@NgModule({
    declarations: [
      TemplateListComponent
    ],
    imports: [
      CoreComponentsModule,
      UIRouterModule.forChild({
        states: [routerState]
      })
    ],
    providers: [
        TemplateListService
    ],
    exports: [
    ],
    bootstrap: [
    ]
  })
  export class TemplateListModule { }
  