import { Component, Input, ViewChild } from '@angular/core';
import { IUpdateStorageStatsProcedure } from '../../../api/operations/procedure-model';
import { IProcedureComponent } from '../procedure-component-interface';
import { Observable, of } from 'rxjs';
import { UpdateStorageStatsProcedureRequest } from '../../../api/operations/procedure-request.model';
import { take } from 'rxjs/operators';
import { MatCheckboxChange } from '@angular/material';

@Component({
    templateUrl: './update-storage-stats-procedure.component.html',
    styleUrls: ['./update-storage-stats-procedure.component.scss'],
    selector: 'update-storage-stats-procedure'
})
export class UpdateStorageStatsProcedureComponent implements IProcedureComponent {

    @Input() public procedure: IUpdateStorageStatsProcedure;
    @Input() public editProcedure: UpdateStorageStatsProcedureRequest;
    @Input() public isEditing: boolean;
    @Input() public isSaving: boolean;
    
    public hasChanges(): boolean {
        return (this.procedure || null) == null 
            || (this.procedure.units !== this.editProcedure.units)
            || (this.procedure.available !== this.editProcedure.available)
            || (this.procedure.isDeltaAmount !== this.editProcedure.isDeltaAmount)
            || (!this.editProcedure.isDeltaAmount && this.procedure.allowDecreaseStorage !== this.editProcedure.allowDecreaseStorage);
    }

    public validate(): Observable<boolean> {
        return of<boolean>(this.editProcedure.units === 'seconds' && (this.editProcedure.isDeltaAmount || this.editProcedure.available >= 0)).pipe(take(1));
    }

    public onDeltaChange(event: any) {
        if (!this.isEditing) {
            return;
        }

        if (!this.editProcedure.isDeltaAmount && this.editProcedure.available < 0) {
            this.editProcedure.available = 0;
        }
        if (this.editProcedure.isDeltaAmount) {
            this.editProcedure.allowDecreaseStorage = null;
        }
        else {
            this.editProcedure.allowDecreaseStorage = false;
        }

        console.log(this.editProcedure);
    }

}
