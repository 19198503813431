import { Injectable } from '@angular/core';
import { DashboardApiService } from 'app/api/dashboard-api.service';
import { BehaviorSubject, Observable } from 'rxjs';
import { IEmailTemplate } from 'app/api/email/email-template.model';
import { ICollection } from 'app/api/common/collection.model';
import { map, share } from 'rxjs/operators';

@Injectable()
export class TemplateListService {

    public get templates(): BehaviorSubject<IEmailTemplate[]> { return this._templates; }
    public loadingData = true;
    private _timeout: any = null;
    private _templates: BehaviorSubject<IEmailTemplate[]>;
    private _refreshObservable: Observable<ICollection<IEmailTemplate>>;

    constructor(private api: DashboardApiService) {
        this._templates = new BehaviorSubject<IEmailTemplate[]>([]);
    }

    public refresh(): Observable<ICollection<IEmailTemplate>> {
        if (this._refreshObservable != null) {
            return this._refreshObservable;
        }

        let self = this;
        this._timeout = setTimeout(() => {
            if (self._timeout == null) { return; }
            self.loadingData = true;
            self._timeout = null;
        }, 500);

        let observable = this._refreshObservable = this.api.emailTemplates.getTemplates()
            .pipe(map(v => {
                this._templates.next(v.items);
                return v;
            }))
            .pipe(share());

        observable
            .subscribe(v => {
                //
            }, err => {
                //
            }, () => {
            clearTimeout(self._timeout);
            self.loadingData = false;
        });

        return observable;
    }

}
