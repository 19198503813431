import { NgModule } from '@angular/core';
import { CoreModulesModule } from '../../../core-modules.module';
import { HeaderModule } from '../../../components/header/header.module';
import { WelcomeComponent } from './welcome.component';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';

let welcomeState = { name: RouterStates.welcome, url: '/welcome', component: WelcomeComponent };

@NgModule({
  declarations: [
      WelcomeComponent
  ],
  imports: [
    CoreModulesModule,
    HeaderModule,
    UIRouterModule.forChild({
      states: [welcomeState]
    })
  ],
  providers: [
  ],
  bootstrap: []
})
export class WelcomeModule { }
