import { NgModule } from '@angular/core';
import { OperationModalComponent } from './operation-modal.component';
import { ProceduresModule } from '../../procedures/procedures.module';
import { CoreComponentsModule } from '../../../core-components.module';

@NgModule({
  imports: [
    CoreComponentsModule,
    ProceduresModule
  ],
  declarations: [
    OperationModalComponent
  ],
  exports: [
    OperationModalComponent
  ],
  bootstrap: [
    OperationModalComponent
  ]
})
export class OperationModalModule { }

