import { Pipe, PipeTransform } from '@angular/core';
import { val } from '@uirouter/angular';

@Pipe({ name: 'sppostcode', pure: true })
export class SplyzaPostCode implements PipeTransform {

    public transform(value: string): any {
        if (value == null || value === undefined) { return value; }
        let tmp = value.trim().replace('-', '');
        if (tmp.length !== 7 || !Number.isInteger(Number(tmp))) {
            return value;
        }
        return '〒' + tmp.substr(0, 3) + '-' + tmp.substr(3, 4);
    }

}
