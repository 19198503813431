
import {finalize} from 'rxjs/operators';
import { Component, Inject, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { PlanService } from '../../../../core/plan.service';
import { ISubscriptionPlan } from '../../../../api/plans/subscription-plan.model';
import { SubscriptionPlanRequest } from '../../../../api/plans/subscription-plan-request.model';
import { TranslateService } from '@ngx-translate/core';

import { deepCopy } from '../../../../core/deep-copy.function';
import { DialogService, DialogYesButton, DialogNoButton, DialogOKButton, DialogCaption } from '../../../../core/dialog.service';
import { Observable } from 'rxjs';
import { SubscriptionPlanComponent } from '../../../../components/subscription-plan/subscription-plan.component';
import { defaultSubscriptionPlan } from '../../../../api/plans/default-subscription-plan.function';
import { PlanPeriodTypes } from '../../../../api/plans/plan-period.model';

@Component({
    templateUrl: './view-plan.component.html',
    styleUrls: [ './view-plan.component.scss' ]
})
export class ViewPlanComponent {

    public newPlan = false;
    public showButtons = false;
    public plan: ISubscriptionPlan;

    public isEditing = false;
    public isSaving = false;
    public editModel: ISubscriptionPlan;

    @ViewChild(SubscriptionPlanComponent, { static: true }) private subscriptionPlan: SubscriptionPlanComponent;

    constructor(
        private mdDialogRef: MatDialogRef<ViewPlanComponent>,
        @Inject(MAT_DIALOG_DATA) data: any,
        private planService: PlanService,
        private translateService: TranslateService,
        private dialogService: DialogService) {
        
        if (data) {
            if (data.newPlan) {
                this.newPlan = true;
                this.updatePlan(defaultSubscriptionPlan());
                this.toggleEditing();
            }
            else {
                this.newPlan = false;
                this.updatePlan(data.plan);
            }
        }
    }
    
    public setPlan(plan: ISubscriptionPlan) {
        this.updatePlan(plan);
    }

    public toggleEditing() {
        if (!this.isEditing) {
            this.mdDialogRef.disableClose = true;
            this.editModel = deepCopy(this.plan);
            if (!this.editModel.fixedEndDate) {
                this.editModel.fixedEndDate = new Date().toISOString();
            }
            if (!this.editModel.planExpiry) {
                this.editModel.planExpiry = {
                    periodLength: 1,
                    periodType: PlanPeriodTypes.MONTH
                };
            }
            // this.editEndType = this.endType || 'period';
            this.isEditing = true;
        }
        else {
            this.savePlan();
        }
    }

    public cancelEditing() {
        this.mdDialogRef.disableClose = false;
        this.isEditing = false;
    }

    public close() {
        if (this.isEditing) {
            // TODO: Check changes
            let self = this;
            this.dialogService.showDialog(
                new DialogCaption('SHARED.WITHOUT_SAVING_PROMPT'),
                new DialogYesButton(() => self.mdDialogRef.close()),
                new DialogNoButton()
            );
        }
        else {
            this.mdDialogRef.close();
        }
    }

    private updatePlan(plan: ISubscriptionPlan) {
        this.plan = plan;
        this.showButtons = plan.key !== 'default';
        this.isEditing = false;
    }

    private savePlan() {
        let hasChanges = false || this.newPlan;

        let request = new SubscriptionPlanRequest();

        let oldPlan = this.plan;
        let newPlan = this.editModel;

        if (this.newPlan || newPlan.displayName !== oldPlan.displayName) {
            if (!newPlan || !newPlan.displayName || newPlan.displayName.trim().length === 0) {
                this.dialogService.showDialog(
                    new DialogCaption('PLANS.PLAN_NAME_REQUEST'),
                    new DialogOKButton()
                );
                return;
            }
            request.displayName = newPlan.displayName;
            hasChanges = true;
        }

        if (this.newPlan || this.subscriptionPlan.hasPlanEndChanged()) {
            if (this.subscriptionPlan.editEndType === 'period') {
                request.planExpiry = newPlan.planExpiry;
            }
            else if (this.subscriptionPlan.editEndType === 'fixed') {
                request.fixedEndDate = newPlan.fixedEndDate;
            }
            request.updatePlanExpiry = true;
            hasChanges = true;
        }

        if (this.newPlan 
            || (oldPlan.storageRenewalFrequency.periodLength !== newPlan.storageRenewalFrequency.periodLength 
                || oldPlan.storageRenewalFrequency.periodType !== newPlan.storageRenewalFrequency.periodType)) {
            request.storageRenewalFrequency = newPlan.storageRenewalFrequency;
            hasChanges = true;
        }

        if (this.newPlan || newPlan.memberLimit !== oldPlan.memberLimit) {
            request.memberLimit = newPlan.memberLimit;
            hasChanges = true;
        }

        if (this.newPlan || newPlan.readOnlyMemberLimit !== oldPlan.readOnlyMemberLimit) {
            request.readOnlyMemberLimit = newPlan.readOnlyMemberLimit;
            hasChanges = true;
        }

        if (this.newPlan || newPlan.renewals !== oldPlan.renewals) {
            request.renewals = newPlan.renewals;
            hasChanges = true;
        }

        if (this.newPlan || newPlan.units !== oldPlan.units) {
            request.units = newPlan.units;
            hasChanges = true;
        }

        if (this.newPlan || newPlan.maxSize !== oldPlan.maxSize) {
            request.maxSize = newPlan.maxSize;
            hasChanges = true;
        }

        if (this.newPlan || newPlan.autoAnalysis !== oldPlan.autoAnalysis) {
            request.autoAnalysis = newPlan.autoAnalysis || false;
            hasChanges = true;
        }

        if (!hasChanges) {
            this.mdDialogRef.close();
            return;
        }

        if (this.subscriptionPlan.editEndType === 'fixed' && newPlan.fixedEndDate && this.planService.isExpired(newPlan.fixedEndDate)) {
            this.dialogService.showDialog(new DialogCaption('PLANS.UPDATE_FIXED_PLAN_EXPIRED'));
            return;
        }

        this.isSaving = true;
        let planRequest: Observable<ISubscriptionPlan> = null;
        if (this.newPlan) {
            planRequest = this.planService.createPlan(request);
        }
        else {
            planRequest = this.planService.updatePlan(oldPlan.key, request);
        }
        planRequest.pipe(
            finalize(() => this.isSaving = false))
            .subscribe(
                plan => {
                   this.mdDialogRef.close(); 
                },
                error => console.error(error)
            );
    }

}

// interface PlanPeriodType {
//     key: string;
//     displayName: string;
// }
