import { NgModule } from '@angular/core';
import { LoginModule } from './login/login.module';
import { DashboardModule } from './dashboard/dashboard.module';

@NgModule({
  declarations: [],
  imports: [
    LoginModule,
    DashboardModule
  ],
  providers: [
  ],
  bootstrap: []
})
export class SectionsModule { }
