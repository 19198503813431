
import {of as observableOf,  Observable } from 'rxjs';
import { Component, Input } from '@angular/core';
import { IStorageAssignmentProcedure } from '../../../api/operations/procedure-model';
import { IProcedureComponent } from '../procedure-component-interface';
import { StorageAssignmentProcedureRequest } from '../../../api/operations/procedure-request.model';
import { take } from 'rxjs/operators';

@Component({
    templateUrl: './storage-assignment-procedure.component.html',
    styleUrls: ['./storage-assignment-procedure.component.scss'],
    selector: 'storage-assignment-procedure'
})
export class StorageAssignmentProcedureComponent implements IProcedureComponent {

    @Input() public procedure: IStorageAssignmentProcedure;
    @Input() public editProcedure: StorageAssignmentProcedureRequest;
    @Input() public isEditing: boolean;
    @Input() public isSaving: boolean;
    
    public hasChanges(): boolean {
        return (this.procedure || null) == null 
            || (this.procedure.units !== this.editProcedure.units)
            || (this.procedure.available !== this.editProcedure.available);
    }

    public validate(): Observable<boolean> {
        return observableOf<boolean>(this.editProcedure.units === 'seconds' && this.editProcedure.available >= 0).pipe(take(1));
    }
    
}
