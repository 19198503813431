
export function dictionaryToArray<TVal>(dictionary: { [id: string]: TVal }): KeyValuePair<TVal>[] {
    let result: KeyValuePair<TVal>[] = [];
    for (let key in dictionary) {
        if (dictionary.hasOwnProperty(key)) {
            result.push(new KeyValuePair<TVal>(key, dictionary[key]));
        }
    }
    return result;
}

export class KeyValuePair<TVal> {
    public key: string;
    public value: TVal;

    constructor (key: string, value: TVal) {
        this.key = key;
        this.value = value;
    }
}
