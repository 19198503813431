import { Injectable } from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';
import { DashboardDataSource } from '../../../../core/dashboard-data-source.model';
import { IOrganization } from '../../../../api/organizations/organization.model';
import { ICollection } from '../../../../api/common/collection.model';
import { DashboardApiService } from '../../../../api/dashboard-api.service';

@Injectable()
export class OrganizationsService {

    public dataSource = new DashboardDataSource<IOrganization>();
    public loadingData = true;

    private timeout: any = null;
    private loadingSubscription: Subscription = null;

    constructor(private api: DashboardApiService) {
        this.loadingData = true;
        this.dataSource.set([]);
    }

    public reset(query: string): Observable<ICollection<IOrganization>> {
        let self = this;
        // let sortAsc = direction === 'asc';
        // let skip = page * pageSize;

        if (this.loadingSubscription) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            this.loadingSubscription.unsubscribe();
        }

        this.timeout = setTimeout(() => {
            if (self.timeout == null) { return; }
            self.loadingData = true;
            self.timeout = null;
        }, 500);

        return new Observable((subscriber) => {

            let result = self.api.organizations.getOrganizations(query);
            self.loadingSubscription = result
                .subscribe(
                    data => {
                        self.timeout = null;
                        self.dataSource.set(data.items);
                        subscriber.next(data);
                    },
                    error => {
                        console.error('Unable to get organizations', error);
                        subscriber.error(error);
                    },
                    () => {
                        clearTimeout(self.timeout);
                        self.loadingData = false;
                        self.loadingSubscription = null;
                        subscriber.complete();
                    }
                );
        });
    }

}
