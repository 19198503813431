import { Injectable } from '@angular/core';
import { ApiHttp } from '../core/api-http';
import { StatusResource } from './status/status.resource';
import { AuthResource } from './auth/auth.resource';
import { TeamsResource } from './teams/teams.resource';
import { PlansResource } from './plans/plans.resource';
import { UsersResource } from './users/users.resource';
import { TasksResource } from './tasks/tasks.resource';
import { OperationTemplatesResource } from './operations/operation-templates.resource';
import { TeamOperationsResource } from './operations/team-operations.resource';
import { UrlValidationResource } from './url-validation/url-validation.resource';
import { OrganizationsResource } from './organizations/organizations.resource';
import { ClusterScriptsResource } from './cluster-scripts/cluster-scripts.resource';
import { EmailTemplatesResource } from './email/email-templates.resource';
import { MediaResource } from './media/media.resource';
import { DiagnosticsResource } from './diagnostics/diagnostics.resource';
import { ThreadsResource } from './threads/threads.resource';

@Injectable()
export class DashboardApiService {

    public readonly status: StatusResource;
    public readonly auth: AuthResource;
    public readonly teams: TeamsResource;
    public readonly threads: ThreadsResource;
    public readonly plans: PlansResource;
    public readonly users: UsersResource;
    public readonly tasks: TasksResource;
    public readonly operationTemplates: OperationTemplatesResource;
    public readonly urlValidation: UrlValidationResource;
    public readonly organizations: OrganizationsResource;
    public readonly teamOperations: TeamOperationsResource;
    public readonly clusterScripts: ClusterScriptsResource;
    public readonly emailTemplates: EmailTemplatesResource;
    public readonly media: MediaResource;
    public readonly diagnostics: DiagnosticsResource;

    constructor (private http: ApiHttp) {
        this.status = new StatusResource(this.http);
        this.auth = new AuthResource(this.http);
        this.teams = new TeamsResource(this.http);
        this.threads = new ThreadsResource(this.http);
        this.plans = new PlansResource(this.http);
        this.users = new UsersResource(this.http);
        this.tasks = new TasksResource(this.http);
        this.operationTemplates = new OperationTemplatesResource(this.http);
        this.urlValidation = new UrlValidationResource(this.http);
        this.teamOperations = new TeamOperationsResource(this.http);
        this.urlValidation = new UrlValidationResource(this.http);
        this.organizations = new OrganizationsResource(this.http);
        this.clusterScripts = new ClusterScriptsResource(this.http);
        this.emailTemplates = new EmailTemplatesResource(this.http);
        this.media = new MediaResource(this.http);
        this.diagnostics = new DiagnosticsResource(this.http);
    }
}
