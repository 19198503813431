import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { TeamRequestsComponent } from './team-requests.component';
import { ListRequestsModule } from './list-requests/list-requests.module';
import { ViewRequestModule } from './view-request/view-request.module';
import { ViewRenewalModule } from './view-renewal/view-renewal.module';

let tasksState = { name: RouterStates.dashboard_teamRequests, component: TeamRequestsComponent };

@NgModule({
  declarations: [
    TeamRequestsComponent
  ],
  imports: [
    CoreComponentsModule,
    ListRequestsModule,
    ViewRequestModule,
    ViewRenewalModule,
    UIRouterModule.forChild({
      states: [tasksState]
    })
  ],
  exports: [
    
  ],
  providers: [
    
  ],
  bootstrap: []
})
export class TeamRequestsModule { }

