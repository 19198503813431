
import {of as observableOf,  Observable } from 'rxjs';

import {share,  take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ISubscriptionPlan } from '../api/plans/subscription-plan.model';
import { SubscriptionPlanRequest } from '../api/plans/subscription-plan-request.model';
import { ICollection } from '../api/common/collection.model';
import { DashboardDataSource } from './dashboard-data-source.model';
import { DashboardApiService } from '../api/dashboard-api.service';
import * as _ from 'underscore';
import * as moment from 'moment';

@Injectable()
export class PlanService {

    public dataSource = new DashboardDataSource<ISubscriptionPlan>();
    public plans: ICollection<ISubscriptionPlan>;
    public loadingPlans = true;

    private readonly defaultKey: string = 'default';

    constructor(private api: DashboardApiService) {
        this.dataSource.set([]);
    }

    public loadPlans(): Observable<ICollection<ISubscriptionPlan>> {
        if (!this.plans || this.plans.total === 0) {
            this.loadingPlans = true;
        }

        let planRequest = this.api.plans.getPlans();
        planRequest
            .subscribe(
                plans => {
                    console.log('src', plans);
                    this.loadingPlans = false;
                    this.plans = plans;
                    this.sortPlans();
                    console.log('sorted', plans);
                    this.dataSource.set(plans.items); 
                },
                error => console.error('load plans', error)
            );
        return planRequest;
    }

    public loadPlansIfEmpty(): Observable<ICollection<ISubscriptionPlan>> {
        if (!this.plans || this.plans.total === 0) {
            return this.loadPlans();
        }
        else {
            return observableOf(this.plans).pipe(take(1));
        }
    }

    public createPlan(request: SubscriptionPlanRequest): Observable<ISubscriptionPlan> {
        let planRequest = this.api.plans.createPlan(request).pipe(share());
        planRequest
            .subscribe(
                plan => {
                    this.updateOrInsertPlan(plan);
                },
                err => null
            );
        return planRequest;
    }

    public updatePlan(planKey: string, request: SubscriptionPlanRequest): Observable<ISubscriptionPlan> {
        let planRequest = this.api.plans.updatePlan(planKey, request).pipe(share());
        planRequest
            .subscribe(
                plan => {
                    this.updateOrInsertPlan(plan);
                },
                err => null
            );
        return planRequest;
    }

    public deletePlan(planKey: string): Observable<any> {
        let planRequest = this.api.plans.deletePlan(planKey).pipe(share());
        planRequest
            .subscribe(
                result => {
                    let planIndex = _.findIndex(this.plans.items, p => p.key === planKey);
                    if (planIndex !== -1) {
                        this.plans.items.splice(planIndex, 1);
                        this.dataSource.set(this.plans.items);
                    }
                },
                err => null
            );
        return planRequest;
    }

    public isExpired(endDate?: string): boolean {
        if (endDate) {
            let date = moment(endDate);
            let nowDate = moment();
            let diff = nowDate.diff(date, 'seconds');
            return diff > 0;
        }
        else {
            return false;
        }
    }

    public setPlanDefault(request: SubscriptionPlanRequest): Observable<ISubscriptionPlan> {
        let planRequest = this.api.plans.setPlan(this.defaultKey, request).pipe(share());
        planRequest
            .subscribe(
                plan => {
                    this.updateOrInsertPlan(plan);
                },
                err => null
            );
        return planRequest;
    }

    private updateOrInsertPlan(plan: ISubscriptionPlan) {
        let planIndex = _.findIndex(this.plans.items, p => p.key === plan.key);
        if (planIndex === -1) {
            ++this.plans.total;
            this.plans.items.push(plan);
        }
        else {
            this.plans.items.splice(planIndex, 1, plan);
        }
        this.sortPlans();
        this.dataSource.set(this.plans.items);
    }

    private sortPlans() {
        if (!this.plans || !this.plans.items || this.plans.items.length === 0) {
            return;
        }
        
        let defaultPlanIndex = _.findIndex(this.plans.items, p => p.key === this.defaultKey);
        let plan: ISubscriptionPlan = null;
        if (defaultPlanIndex !== -1) {
            plan = this.plans.items.splice(defaultPlanIndex, 1)[0];
        }

        this.plans.items = _.sortBy(this.plans.items, p => p.displayName);
        if (plan != null) {
            this.plans.items.splice(0, 0, plan);
        }
    }

}
