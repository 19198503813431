import { NgModule } from '@angular/core';
import { GraduationsListComponent } from './graduations-list.component';
import { CoreModulesModule } from '../../core-modules.module';

@NgModule({
  imports: [
      CoreModulesModule
  ],
  declarations: [
    GraduationsListComponent
  ],
  exports: [
    GraduationsListComponent
  ],
  bootstrap: [
    GraduationsListComponent
  ]
})
export class GraduationsListModule { }

