import { Component, Input, Output, EventEmitter, OnInit } from '@angular/core';
import { ITeamManagementDataModel } from 'app/api/teams/team-management-data.model';
import { TeamService } from '../../../team.service';
import { TeamManagementDataRequestModel } from 'app/api/teams/team-management-data-request.model';
import { deepCopy } from 'app/core/deep-copy.function';
import { TeamRequest } from 'app/api/teams/team-request.model';
import { ITeam } from 'app/api/teams/team.model';
import { DashboardApiService } from 'app/api/dashboard-api.service';
import { finalize } from 'rxjs/operators';
import { CountriesService, CountryEntity } from 'app/core/countries.service';
import * as _ from 'underscore';
import { isObject } from 'util';
import { UserService } from 'app/core/user.service';
import { Observable } from 'rxjs';

@Component({
    templateUrl: './management-data.component.html',
    styleUrls: ['./management-data.component.scss'],
    selector: 'team-management-data'
})
export class ManagementDataComponent implements OnInit {

    @Input() public team: ITeam;
    @Input() public managementData: ITeamManagementDataModel;
    @Output() public managementDataChange = new EventEmitter<ITeamManagementDataModel>();
    public isEditing = false;
    public isSaving = false;
    public countries: CountryEntity[];
    public introductionRoutes = ['WORD_OF_MOUTH', 'AGENT', 'ORGANIC', 'SALES', 'REFERRAL', 'COLD CALL', 'DIRECT', 'TRADE SHOW', 'BROCHURE DOWNLOAD', 'WEBSITE', 'PARTNER', 'SOCIAL MEDIA', 'NEWS', 'INTERNET SEARCH', 'ADVERT', 'SPORTS ANALYTICS LAB', 'OTHER'];
    public subscriptionStatuses = ['TRIAL', 'ANNUAL_PAYMENT', 'MONTHLY_PAYMENT', 'JFL', 'EXPIRED', 'TEST_TEAM', 'NOT_TRACKED', 'OFFSEASON'];

    private editModel: ITeamManagementDataModel;
    private preferredCountryCodes = ['JP'];


    constructor(
        private api: DashboardApiService, 
        private countriesService: CountriesService,
        private userService: UserService) {

    }

    public getEditPermissionCheck(): (() => boolean) {
        return () => {
          
            if (this.team == null) {
                return false;
            }

            if (!this.team.isCrmManaged) {
                return this.userService.hasRole('Accounts');
            } else {
                return this.userService.hasRole('Administrator');
            }

        };
    }

    public toggleEditing() {
        if (!this.isEditing) {
            this.editModel = deepCopy(this.managementData);
            this.isEditing = true;
        }
        else {
            this.save();
        }
    }

    public cancelEditing() {
        this.isEditing = false;
    }

    public ngOnInit() {
        this.countriesService.getCountryTranslations().subscribe(result => {
            let countries = [new CountryEntity('', '')].concat(result.slice());
            let preferredCountries = [''].concat(this.preferredCountryCodes);
            countries = _.sortBy(countries, country => {
                let idx = preferredCountries.indexOf(country.code);
                if (idx === -1) {
                    idx = countries.findIndex(c => c.code === country.code) + preferredCountries.length;
                }
                return idx;
            });
            this.countries = countries;
        });
    }

    private save() {
        this.isSaving = true;

        let hasChanges = false;
        let requestObject: TeamManagementDataRequestModel = {};

        if (this.editModel.contactName !== this.managementData.contactName) {
            hasChanges = true;
            requestObject.contactName = this.editModel.contactName;
        }

        if (this.editModel.contactEmail !== this.managementData.contactEmail) {
            hasChanges = true;
            requestObject.contactEmail = this.editModel.contactEmail;
        }

        if (this.editModel.phoneNumber !== this.managementData.phoneNumber) {
            hasChanges = true;
            requestObject.phoneNumber = this.editModel.phoneNumber;
        }

        if (this.editModel.addressLine1 !== this.managementData.addressLine1) {
            hasChanges = true;
            requestObject.addressLine1 = this.editModel.addressLine1;
        }

        if (this.editModel.addressLine2 !== this.managementData.addressLine2) {
            hasChanges = true;
            requestObject.addressLine2 = this.editModel.addressLine2;
        }

        if (this.editModel.postCode !== this.managementData.postCode) {
            hasChanges = true;
            requestObject.postCode = this.editModel.postCode;
        }

        if (this.editModel.country !== this.managementData.country) {
            hasChanges = true;
            requestObject.country = this.editModel.country;
        }
        
        if (this.editModel.introductionRoute !== this.managementData.introductionRoute) {
            hasChanges = true;
            requestObject.introductionRoute = this.editModel.introductionRoute;
        }

        if (this.editModel.subscriptionStatus !== this.managementData.subscriptionStatus) {
            hasChanges = true;
            requestObject.subscriptionStatus = this.editModel.subscriptionStatus;
        }

        if (this.editModel.seminarDate !== this.managementData.seminarDate) {
            hasChanges = true;
            requestObject.updateSeminarDate = true;
            requestObject.seminarDate = this.editModel.seminarDate;
        }

        if (this.editModel.clubMemberCount !== this.managementData.clubMemberCount) {
            hasChanges = true;
            requestObject.clubMemberCount = this.editModel.clubMemberCount;
        }

        if (!hasChanges) {
            this.isSaving = false;
            this.isEditing = false;
            this.editModel = null;
            return;
        }

        let patchTeamRequest: TeamRequest = {
          managementData: requestObject  
        };

        this.api.teams.patchTeam(this.team.id, patchTeamRequest)
            .pipe(finalize(() => this.isSaving = false))
            .subscribe(team => {
                this.managementDataChange.emit(team.managementData);
                this.isEditing = false;
            },
            error => {
                console.error('error', error);
            });
    }
}
