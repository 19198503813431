import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { CdkTableModule } from '@angular/cdk/table';
import { ViewRenewalComponent } from './view-renewal.component';

let moduleState = { 
  name: RouterStates.dashboard_teamRequests_renewal_view,
  url: '/teamrenewals/renewal/{renewalId}',
  component: ViewRenewalComponent
};

@NgModule({
  declarations: [
    ViewRenewalComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [moduleState]
    }),
    CdkTableModule
  ],
  exports: [
    
  ],
  providers: [
      
  ],
  bootstrap: []
})
export class ViewRenewalModule { }

