import { RouterStates } from 'app/core/router-states.constant';
import { CoreComponentsModule } from 'app/core-components.module';
import { UIRouterModule } from '@uirouter/angular';
import { NgModule } from '@angular/core';
import { EditEmailTemplateComponent } from './edit-email-template.component';
import { SelectLanguageModule } from '../select-language/select-language.module';
import { EmailContentEditorModule } from '../email-content-editor/email-content-editor.module';

let viewTemplateState = { 
    name: RouterStates.dashboard_emailTemplates_view, 
    url: '/email-templates/:templateId?v', 
    params: { templateName: '' },
    component: EditEmailTemplateComponent 
};

let newTemplateState = { 
    name: RouterStates.dashboard_emailTemplates_new, 
    url: '/email-templates/new', 
    component: EditEmailTemplateComponent 
};

@NgModule({
    declarations: [
        EditEmailTemplateComponent,
    ],
    imports: [
      CoreComponentsModule,
      EmailContentEditorModule,
      SelectLanguageModule,
      UIRouterModule.forChild({
        states: [viewTemplateState, newTemplateState]
      })
    ],
    providers: [
    ],
    exports: [
    ],
    bootstrap: [
    ]
  })
  export class EditEmailTemplateModule { }
  