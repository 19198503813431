import { Injectable } from '@angular/core';
import { DashboardApiService } from '../api/dashboard-api.service';
import { EnvironmentService } from './environment.service';
import { IStatus } from '../api/status/status.model';

@Injectable()
export class ApiStatusService {

    private _productionStatus: IStatus;
    private _stagingStatus: IStatus;

    public get Production() { return this._productionStatus || null; }
    public get Staging() { return this._stagingStatus || null; }

    constructor(private api: DashboardApiService, private environmentService: EnvironmentService) {
        this.getStatuses();
    }

    private getStatuses() {
        this.api.status.getStatus(false)
            .subscribe(
                (status) => {
                    this._productionStatus = status;
                }
            );

        if (this.environmentService.apiBaseStaging && this.environmentService.apiBaseStaging.length > 0) {
            this.api.status.getStatus(true)
                .subscribe(
                    (status) => {
                        this._stagingStatus = status;
                    }
                );
        }
    }

}
