import { Component, OnInit } from '@angular/core';
import { MatDialog, MatDialogRef } from '@angular/material';
// import { RouterStates } from '../../../core/router-states.constant';
// import { StateService } from '@uirouter/angular';
import { PlanService } from '../../../core/plan.service';
import { ISubscriptionPlan } from '../../../api/plans/subscription-plan.model';
import { SubscriptionPlanRequest } from '../../../api/plans/subscription-plan-request.model';
import { ViewPlanComponent } from './view-plan/view-plan.component';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton } from '../../../core/dialog.service';


@Component({
    templateUrl: './plans.component.html',
    styleUrls: ['./plans.component.scss']
})
export class PlansComponent implements OnInit {

    public get loadingPlans(): boolean { return this.planService.loadingPlans; }
    public get dataSource() { return this.planService.dataSource; }
    public get plans(): ISubscriptionPlan[] { return (this.planService.plans ? this.planService.plans.items : null); }
    public displayedColumns = ['displayName', 'memberLimit', 'readOnlyMemberLimit', 'storage', 'buttons'];
    public viewingPlan: boolean;

    constructor(public planService: PlanService, private mdDialog: MatDialog, private dialogService: DialogService) {

    }

    public ngOnInit() {
        // this.planService.loadPlans();
        this.planService.loadPlans()
            .subscribe(plans => {
                // this.newPlan = false;
                // this.selectedPlan = (plans && plans.items && plans.items.length > 0) 
                //                     ?  _.find(plans.items, p => p.key === 'default') || plans.items[0]
                //                     : null;
            });
    }

    public viewPlan(plan: ISubscriptionPlan) {
        this.showPlanDialog(plan, false);
    }

    public addPlan() {
        this.showPlanDialog(null, true);        
    }

    public setPlanDefault(plan: ISubscriptionPlan) {
        if (this.planService.isExpired(plan.fixedEndDate)) {
            this.dialogService.showDialog(new DialogCaption('PLANS.DEFAULT_FIXED_PLAN_EXPIRED'));
            return;
        }

        let requestObj = this.buildRequestFromExisting(plan);
        this.dialogService.showDialog(
            new DialogCaption('PLANS.PLAN_CONFIRM_DEFAULT', null, { name: plan.displayName }),
            new DialogYesButton(() => this.doSetPlanDefault(requestObj)),
            new DialogNoButton()
        );
    }

    public deletePlan(plan: ISubscriptionPlan) {
        this.dialogService.showDialog(
            new DialogCaption('PLANS.PLAN_CONFIRM_DELETE', null, { name: plan.displayName }),
            new DialogYesButton(() => {
                this.planService.deletePlan(plan.key);
            }),
            new DialogNoButton()
        );
    }

    private doSetPlanDefault(requestObj: SubscriptionPlanRequest) {
        this.planService.setPlanDefault(requestObj)
            .subscribe(
                plan => null,
                error => {
                    this.dialogService.showDialog(new DialogCaption('SHARED.GENERIC_ERROR'));
                }
            );
    }

    private buildRequestFromExisting(plan: ISubscriptionPlan): SubscriptionPlanRequest {
        return {
            displayName: plan.displayName,
            memberLimit: plan.memberLimit,
            readOnlyMemberLimit: plan.readOnlyMemberLimit,
            units: plan.units,
            maxSize: plan.maxSize,
            renewals: plan.renewals,
            storageRenewalFrequency: plan.storageRenewalFrequency,
            updatePlanExpiry: true,
            fixedEndDate: plan.fixedEndDate,
            planExpiry: plan.planExpiry,
            autoAnalysis: plan.autoAnalysis
        };
    }

    private showPlanDialog(plan: ISubscriptionPlan, newPlan: boolean): MatDialogRef<ViewPlanComponent>  {
        return this.mdDialog.open(ViewPlanComponent, { 
            width: '450px',
            data: {
                plan: plan,
                newPlan: newPlan
            }
        });
    }
}
