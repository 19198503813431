import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { TeamSetupsService } from './team-setups.service';
import { DashboardSidebarService } from '../../../../core/dashboard-sidebar.service';
import { MatSort } from '@angular/material';
import { PaginatorComponent } from '../../../../components/paginator/paginator.component';
import * as _ from 'underscore';
import { ITeamSetup } from '../../../../api/teams/team-setup.model';
import { RouterStates } from '../../../../core/router-states.constant';

@Component({
    templateUrl: './team-setups.component.html',
    styleUrls: ['./team-setups.component.scss']
})
export class TeamSetupsComponent implements OnInit {

    public get dataSource() { return this.teamSetupsService.dataSource; }
    public get loadingData(): boolean { return this.teamSetupsService.loadingData; }
    public displayedColumns = ['teamName', 'memberLimit', 'readOnlyMemberLimit', 'isUsed', 'used', 'created'];
        
    @ViewChild(MatSort, { static: true }) private sort: MatSort;
    @ViewChild(PaginatorComponent, { static: true }) private paginator: PaginatorComponent;

    private debouncedRefresh: Function;

    constructor(private stateService: StateService,
                private dashboardSidebarService: DashboardSidebarService,
                private teamSetupsService: TeamSetupsService) {
        let self = this;
        this.debouncedRefresh = _.debounce(() => { self.refreshView(self.sort); }, 500);
    }

    public ngOnInit() {
        this.initialize();
    }

    public refresh() {
        this.debouncedRefresh();
    }

    public viewSetup(row: ITeamSetup) {
        this.dashboardSidebarService.setTeamTokenName(row.teamName);
        this.stateService.go(RouterStates.dashboard_teamSetup_setup_view, { setupId: row.id, teamName: row.teamName });
    }

    public newSetup() {
        this.stateService.go(RouterStates.dashboard_teamSetup_setup_new);
    }

    private initialize() {
        this.sort.active = 'created';
        this.sort.direction = 'desc';
        this.sort.sortChange
            .subscribe(
                sort => {
                if (sort.direction === '') {
                    sort.direction = 'asc';
                    this.sort.direction = 'asc';
                }
                this.paginator.pageIndex = 0;
                this.refreshView(sort);
                }
            );

        this.paginator.pageIndex = 0;
        this.paginator.pageSize = 10;

        this.paginator.onPageChange
            .subscribe(() => {
            this.refreshView(this.sort);
            });

        this.refreshView(this.sort);
    }

    private refreshView(sort: MatSort) {
      this.teamSetupsService
        .reset(this.convertToSort(sort.active), sort.direction, this.paginator.pageIndex, this.paginator.pageSize)
        .subscribe((i) => this.paginator.itemCount = i.total);
    }
    
    private convertToSort(column: string) {
        if (column === 'used') {
            return 'used';
        }
        else {
            return 'created';
        }
    }
}
