import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { ListTasksComponent } from './list-tasks.component';
import { TasksService } from './tasks.service';
import { CdkTableModule } from '@angular/cdk/table';

let tasksState = { 
  name: RouterStates.dashboard_tasks_list,
  url: '/tasks?fromDate&page&size&sortBy&direction&type',
  params: {
    fromDate: {
      type: 'string',
      dynamic: true
    },
    page: {
      type: 'int',
      dynamic: true
    },
    size: {
      type: 'int',
      dynamic: true
    },
    sortBy: {
      type: 'string',
      dynamic: true
    },
    direction: {
      type: 'string',
      dynamic: true
    },
    type: {
      type: 'any',
      dynamic: true
    }
  },
  component: ListTasksComponent
};

@NgModule({
  declarations: [
    ListTasksComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [tasksState]
    }),
    CdkTableModule
  ],
  exports: [
    
  ],
  providers: [
    TasksService
  ],
  bootstrap: []
})
export class ListTasksModule { }

