import { Component, Input }from '@angular/core';
import { ITaskRun } from '../../../api/tasks/task-run.model';
import { ITaskExecutionRef, ITaskAzureExecutionRef, ITaskHangfireExecutionRef } from '../../../api/tasks/task-execution-ref.model';
import { exec } from 'child_process';
import { toDictionary, fromDictionary, IKeyValue } from '../../../core/to-dictionary.function';

@Component({
    templateUrl: './task-run.component.html',
    styleUrls: ['./task-run.component.scss'],
    selector: 'task-run'
})
export class TaskRunComponent {

    @Input() public showHeader: boolean = true;
    @Input() public taskRun: ITaskRun;
    public get fileItems(): IKeyValue<string, string>[] { return (this.taskRun == null || this.taskRun.files == null) ? [] : fromDictionary(this.taskRun.files, v => v); }
    public get metadataItems(): IKeyValue<string, string>[] { return (this.taskRun == null || this.taskRun.metadata == null) ? [] : fromDictionary(this.taskRun.metadata, v => v); }
    public get logItems(): string[] { return (this.taskRun == null || this.taskRun.log == null) ? [] : this.taskRun.log; }

    public getExecutionInfo(executionRef: ITaskExecutionRef): string {
        if (executionRef == null) {
            return;
        }
        if (executionRef.platform === 'Azure') {
            return (<ITaskAzureExecutionRef><any>executionRef).invocationId;
        }
        else if (executionRef.platform === 'Hangfire') {
            return (<ITaskHangfireExecutionRef><any>executionRef).jobId;
        }
        return null;
    }

    public getFileNameFromUrl(url: string): string {
        let parsedUrl = new URL(url);
        let pathName = parsedUrl.pathname.substring(1);
        pathName = decodeURIComponent(pathName);
        
        let lastSlashIdx = pathName.lastIndexOf('/');
        if (lastSlashIdx >= 0) {
            pathName = pathName.substring(lastSlashIdx + 1);
        }
        return pathName;
    }
}
