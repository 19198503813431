import { ISubscriptionPlan } from './subscription-plan.model';
import { PlanPeriodTypes } from './plan-period.model';

export function defaultSubscriptionPlan(): ISubscriptionPlan {
    let plan: ISubscriptionPlan = {
        key: '',
        displayName: '',
        memberLimit: 0,
        readOnlyMemberLimit: 0,
        units: 'seconds',
        maxSize: 0,
        renewals: 0,
        storageRenewalFrequency: {
            periodLength: 1,
            periodType: PlanPeriodTypes.MONTH
        },
        fixedEndDate: new Date().toISOString(),
        planExpiry: {
            periodLength: 1,
            periodType: PlanPeriodTypes.MONTH
        },
        autoAnalysis: false
    };
    return plan;
}
    
