import { PlanPeriod } from './plan-period.model';

export class StoragePlanRequest {
    public maxSize?: number;
    public startDate?: string;
    public endDate?: string;
    public noEndDate?: boolean;
    public remainingRenewals?: number;
    public planPeriod?: PlanPeriod;
}
