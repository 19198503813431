import { Component, NgZone, OnInit } from '@angular/core';
import { ITeam } from '../../../../../api/teams/team.model';
import { TeamService } from '../../team.service';

import { StorageStatsRequestModel } from '../../../../../api/plans/storage-stats-request.model';
import { IStorageStats } from '../../../../../api/plans/storage-stats.model';
import { Observable } from 'rxjs';
import { UserService } from 'app/core/user.service';

@Component({
    templateUrl: './team-management.component.html',
    selector: 'team-management'
})
export class TeamManagementComponent implements OnInit {

    // @ViewChild('tabBasic') public tabBasic: MatTab;
    // @ViewChild('tabStoragePlan') public tabStoragePlan: MatTab;
    // @ViewChild('tabStorageStats') public tabStorageStats: MatTab;

    public get team(): ITeam { return this.teamService ? this.teamService.team : null; }
    // public editing : Editing;
    private _isEditing = false;

    // public get isEditing(): boolean { return this._isEditing; }
    // public set isEditing(value: boolean) {
    //     this._isEditing = value;
       
    //     this.zone.run(() => {
    //         this.tabBasic.disabled = value;
    //     });
    // }

    public getEditPermissionCheck(): (() => boolean) {
        return () => {
          
            if (this.team == null) {
                return false;
            }

            if (!this.team.isCrmManaged) {
                return this.userService.hasRole('Accounts');
            } else {
                return this.userService.hasRole('Administrator');
            }

        };
    }

    public updateStorageStats(): (request: StorageStatsRequestModel) => Observable<IStorageStats> {
        let self = this;
        return (request) => self.teamService.updateTeamStorageStats(self.team.id, request);
    }

    constructor(private teamService: TeamService, private userService: UserService, private zone: NgZone) {
        // this.editing = new Editing(zone);
        // console.log(tab);
    }

    public ngOnInit() {
        // console.log(this.tabBasic);
        // console.log(this.tabStoragePlan);
        // console.log(this.tabStorageStats);
    }

}
