
import {zip as observableZip,  Subject } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { TeamService } from '../team.service';
import { ITeam } from '../../../../api/teams/team.model';
import { PlanService } from '../../../../core/plan.service';
import { QualitySetsService } from 'app/core/quality-sets.service';
import { TranscodeProfileService } from 'app/core/transcode-profile.service';
import { DialogButton, DialogCaption, DialogService } from 'app/core/dialog.service';
import { DashboardApiService } from 'app/api/dashboard-api.service';
import { switchMap } from 'rxjs/operators';

@Component({
    templateUrl: './view-team.component.html',
    styleUrls: ['./view-team.component.scss']
})
export class ViewTeamComponent implements OnInit {

    public loading = false;
    public get team(): ITeam { return this.teamService.team; }

    constructor(
        private api: DashboardApiService,
        private teamService: TeamService, 
        private planService: PlanService, 
        private qualitySetsService: QualitySetsService, 
        private profileService: TranscodeProfileService,
        private dialogService: DialogService) {

    }

    public ngOnInit() {
        this.loading = true;
        observableZip(
            this.planService.loadPlansIfEmpty(),
            this.teamService.teamSubject,
            this.qualitySetsService.loadSets(),
            this.profileService.loadProfiles()
        )
        .subscribe(
            d => null, 
            e => console.error(e),
            () => {
                this.loading = false;
            }
        );
    }

    public refresh() {
        this.loading = true;
        observableZip(
            this.teamService.refresh(),
            this.profileService.loadProfiles()
        )
        .subscribe(
            d => null,
            e => console.error(e),
            () => this.loading = false
        );
    }

    public generateInvite() {
        
        var roleSubject = new Subject<string>();
        roleSubject
            .asObservable()
            .pipe(switchMap(role => this.api.teams.generateInvitation(this.team.id, role)))
            .subscribe(inviteUrl => {
                this.dialogService.showDialog(new DialogCaption('The invite URL is:  ' + inviteUrl, false));
            }, err => this.dialogService.showDialog(new DialogCaption('There was an error', false)));

        this.dialogService.showDialog(
            new DialogCaption('Which role?', true),
            new DialogButton('SHARED.ROLES.MANAGER', true, undefined, () => roleSubject.next('manager')),
            new DialogButton('SHARED.ROLES.EDITOR', true, undefined, () => roleSubject.next('editor')),
            new DialogButton('SHARED.ROLES.MEMBER', true, undefined, () => roleSubject.next('member')),
            new DialogButton('SHARED.ROLES.READ_ONLY', true, undefined, () => roleSubject.next('readonly')),
            new DialogButton('SHARED.CANCEL', true, undefined, () => roleSubject.complete())
        );

    }

}
