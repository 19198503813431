import { Component, ViewChild, OnInit, OnDestroy } from '@angular/core';
import { UsersService } from './users.service';
import { RouterStates } from '../../../core/router-states.constant';
import { StateService, Transition, TransitionService } from '@uirouter/angular';
import { IUser } from '../../../api/users/user.model';
import * as _ from 'underscore';
import { PaginatorComponent } from '../../../components/paginator/paginator.component';
import { MatSort, MatDialog } from '@angular/material';
import { downloadFileFromString } from '../../../core/blob-downloader';
import { DownloadEmailsModalComponent } from './download-emails-modal/download-emails-modal.component';
import { DashboardApiService } from '../../../api/dashboard-api.service';

@Component({
    templateUrl: './users.component.html' // ,
    // styleUrls: ['./users.component.scss']
})
export class UsersComponent implements OnInit, OnDestroy {

    public searchText: string;
    public get loadingUsers(): boolean { return this.usersService.loadingData; }
    public get dataSource() { return this.usersService.dataSource; }
    public userCount = 0;
    public displayedColumns = ['name', 'email', 'joined', 'lastSeen', 'teamCount', 'loginProviders'];

    @ViewChild(MatSort, { static: true }) private sort: MatSort;
    @ViewChild(PaginatorComponent, { static: true }) private paginator: PaginatorComponent;
    
    private debouncedRefresh: Function;
    private stopWatchingTransition: Function;
    private lastSearchText: string;
    private refreshTriggeredSort = false;

    constructor(
        private usersService: UsersService,
        private stateService: StateService,
        private transitionService: TransitionService,
        private mdDialog: MatDialog,
        private api: DashboardApiService) {
        let self = this;
        this.debouncedRefresh = _.debounce(() => { self.refreshView(self.sort); }, 500);
    }

    public ngOnInit() {
        this.sort.active = 'joined';
        this.sort.direction = 'desc';
        this.sort.sortChange
          .subscribe(
              sort => {
                if (sort.direction === '') {
                  sort.direction = 'asc';
                  this.sort.direction = 'asc';
                }

                if (!this.refreshTriggeredSort) { 
                    this.paginator.pageIndex = 0;
                    this.refreshView(sort);
                }
              }
          );

        this.paginator.pageIndex = 0;
        this.paginator.pageSize = 10;
  
        this.updateFromState();
        this.stopWatchingTransition = this.transitionService.onSuccess({ to: this.stateService.current.name }, (trans: Transition) => {
            this.updateFromState();
        });

        this.paginator.onPageChange
            .subscribe(() => {
            this.refreshView(this.sort);
        });
            
        this.refreshView(this.sort);
    }

    public ngOnDestroy() {
        if (this.stopWatchingTransition) {
            this.stopWatchingTransition();
        }
    }

    public performSearch() {
        if (this.searchText !== this.lastSearchText) {
            this.paginator.pageIndex = 0;
            this.debouncedRefresh();
        }
    }

    public viewUser(user: IUser) {
      this.stateService.go(RouterStates.dashboard_user_view, { userId: user.id, userName: user.name });
    }

    public hasProvider(user: IUser, provider: string): boolean {
        let lowerProvider = provider.toLowerCase().toString();
        return _.any(user.loginProviders, p => p.toLowerCase().trim() === lowerProvider);
    }

    public downloadAllEmails() {
        this.usersService.getUserEmails()
            .subscribe(csvData => {
                downloadFileFromString(csvData, 'all-users.csv', 'text/csv', true);
            });
    }

    public downloadEmails() {
      
      this.mdDialog.open(DownloadEmailsModalComponent, { 
          width: '250px',
          disableClose: false
      })
      .afterClosed()
      .subscribe(v => {
        if (v == null || v.generate === false) {
          return;
        }
        this.api.teams.getMemberEmailAddressesRequest(v.roles)
          .subscribe(csv => {
            downloadFileFromString(csv, 'users.csv', 'text/csv', true);
          });
      });
    }

    private updateFromState() {
        let searchText = this.stateService.params.q;
        let page = this.stateService.params.page;
        let size = this.stateService.params.size;
        let sort = this.stateService.params.sort;
        let direction = this.stateService.params.direction;
        let changes = false;

        if (searchText && searchText !== this.searchText) {
            this.searchText = decodeURIComponent(searchText);
            changes = true;
        }
        
        if (size && size !== this.paginator.pageSize) {
            this.paginator.pageSize = size;
            changes = true;
        }

        if (page && page !== this.paginator.pageIndex) {
            this.paginator.pageIndex = page;
            changes = true;
        }
        
        if (sort && sort !== this.sort.active) {
            this.sort.active = sort;
        }

        if (direction && direction !== this.sort.direction) {
            this.sort.direction = direction;
        }

        if (changes) {
            this.refreshView(this.sort);
        }
    }

    private isSearchProviderOnly(query: string) {
        let replacedQuery = (query || '').replace(/(provider:[a-zA-Z0-9]+)/g, '').trim();
        return replacedQuery.length === 0;
    }

    private refreshView(sort: MatSort) {

        this.lastSearchText = this.searchText;
        let query = (this.searchText || '').trim();
        let providerOnly = query.length === 0 || this.isSearchProviderOnly(query);

        this.sort.disabled = !providerOnly;
        if (!providerOnly) {
            this.refreshTriggeredSort = true;
            // this.sort.sort({id:''} as MatSortable);
        }
        else if (providerOnly && (sort.active || '').length === 0) {
            this.refreshTriggeredSort = true;
            sort.direction = 'desc';
            sort.active = 'joined';
            // this.sort.sort(this.sort.sortables['joined']);
        }

        let currentSize = this.paginator.pageSize;
        this.usersService.reset(query, this.paginator.pageIndex, this.paginator.pageSize, this.sort.active, this.sort.direction)
            .subscribe(
                users => {
                    // hack to fix pagination bug
                    if (this.paginator.pageSize !== currentSize) {
                        this.paginator.pageSize = currentSize;
                    }
                    this.stateService.transitionTo(this.stateService.current.name, 
                        { // if new parameters are added here, add them as dynamic in the routing
                            q: query, 
                            page: this.paginator.pageIndex, 
                            size: this.paginator.pageSize, 
                            sort: sort.active, 
                            direction: sort.direction
                        });
                    this.userCount = users.items.length; 
                    this.paginator.itemCount = users.total;
                }
        );

    }
}
