import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { OperationTemplateComponent } from './operation-template.component';
import { OperationTemplatesModule } from './operation-templates/operation-templates.module';
import { EditTemplateModule } from './edit-template/edit-template.module';

let operationTemplateState = { name: RouterStates.dashboard_operation_template, component: OperationTemplateComponent };

@NgModule({
  declarations: [
    OperationTemplateComponent
  ],
  imports: [
    CoreComponentsModule,
    OperationTemplatesModule,
    EditTemplateModule,
    UIRouterModule.forChild({
      states: [operationTemplateState]
    })
  ],
  exports: [
    
  ],
  providers: [
    // TeamService
  ],
  bootstrap: []
})
export class OperationTemplateModule { }

