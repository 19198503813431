
import {zip as observableZip,  Observable } from 'rxjs';
import { Component, OnInit } from '@angular/core';
import { UserService } from '../user.service';
import { PlanService } from '../../../../core/plan.service';
import { IUser } from '../../../../api/users/user.model';
import { OperationTemplateService } from '../../../../core/operation-template.service';

@Component({
    templateUrl: './view-user.component.html',
    styleUrls: ['./view-user.component.scss']
})
export class ViewUserComponent implements OnInit {

    public loading = false;
    public get user(): IUser { return this.userService.user; }

    constructor(
        private userService: UserService,
        private templateService: OperationTemplateService) {
        
    }

    public ngOnInit() {
        this.loading = true;
        observableZip(
            this.templateService.loadTemplatesIfEmpty(),
            this.userService.userSubject
        )
        .subscribe(
            d => null, 
            e => console.error(e),
            () => {
                this.loading = false;
            }
        );
    }

    public refresh() {
        this.loading = true;
        this.userService.refresh()
            .subscribe(
                d => null,
                e => console.error(e),
                () => this.loading = false
            );
    }

}
