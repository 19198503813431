import { HttpInterceptor, HttpRequest, HttpHandler, HttpEvent } from '@angular/common/http';
import { Observable } from 'rxjs';
import { AuthenticationService } from './authentication.service';
import { Injectable } from '@angular/core';
import { switchMap } from 'rxjs/operators';
import { environment } from 'environments/environment';
import { EnvironmentService } from './environment.service';

@Injectable()
export class ApiTokenInterceptor implements HttpInterceptor {

    constructor(
      private authService: AuthenticationService,
      private environmentService: EnvironmentService) {
  
    }

    private replacePlaceholder(url: string, placeholder: string, baseUrl: string): string {
      if (!url.startsWith(placeholder)) {
        return url;
      }

      if (baseUrl.endsWith('/')) {
        baseUrl = baseUrl.substring(0, baseUrl.length - 1);
      }

      const placeholderLength = placeholder.length + (url.startsWith(`${placeholder}/`) ? 1 : 0);
      const urlWithoutPlaceholder = url.substring(placeholderLength);
      return `${baseUrl}/api/${urlWithoutPlaceholder}`;
    }
  
    intercept(req: HttpRequest<any>, next: HttpHandler) {
  
      let url = req.url;
      url = this.replacePlaceholder(url, '{staging}', environment.apiBaseStaging);
      url = this.replacePlaceholder(url, '{production}', environment.apiBase);
      
      if (this.environmentService.useStaging) {
        url = this.replacePlaceholder(url, '{api}', environment.apiBaseStaging);
      } else {
        url = this.replacePlaceholder(url, '{api}', environment.apiBase);
      }

      if (url !== req.url) {
        req = req.clone({ url: url });
      }
  
      const expression = /^https:\/\/(staging\.)?adminapi\.teams\.(sandbox\.)?splyza\.com/gm;
      if (!req.url.match(expression)) {
        return next.handle(req);
      }
  
      return this.processRequest(req, next);
  
    }
  
    private processRequest(req: HttpRequest<any>, next: HttpHandler): Observable<HttpEvent<any>> {
      return this.authService.getAccessToken()
        .pipe(switchMap(accessToken => {
          // Clone the request and replace the original headers with
          // cloned headers, updated with the authorization.
          const authReq = req.clone({
            headers: req.headers.set('Authorization', 'Bearer ' + accessToken)
          });
  
          // send cloned request with header to the next handler.
          return next.handle(authReq);  
        }));
    }
  }