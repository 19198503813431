import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { UserService } from './user.service';
import { DashboardSidebarService } from '../../../core/dashboard-sidebar.service';

@Component({
    templateUrl: './user.component.html'
})
export class UserComponent implements OnInit {

    constructor(private stateService: StateService, private userService: UserService,
                private dashboardSidebarService: DashboardSidebarService) {
        this.dashboardSidebarService.setUserName(this.stateService.params.userName);
    }

    public ngOnInit() {
        this.userService.loadUser(this.stateService.params.userId)
            .subscribe(u => null, err => null);
    }

}
