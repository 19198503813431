import { ITaskParameter } from './task-parameter.model';
import { TaskParameterType } from './task-parameter-type.enum';

export class TaskRequestParameter {
    public name: string;
    public type: TaskParameterType;
    public data: any;

    constructor (name: string | ITaskParameter, type?: TaskParameterType, data?: any) {

        if (typeof name === 'string') {
            this.name = name;
            this.type = type || TaskParameterType.Simple;
            this.data = data || null;
        }
        else {
            let inObj = <ITaskParameter>name;
            this.name = inObj.name;
            this.type = inObj.type;
            this.data = inObj.value;
        }

    }
}
