import { Component, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';

export class SelectLanguageInputData {
    public displayLanguages: string[];
}

@Component({
    templateUrl: './select-language.component.html',
    styleUrls: ['./select-language.component.scss']
})
export class SelectLanguageComponent {

    public get displayLanguages(): string[] { return (this.inputData != null && this.inputData.displayLanguages != null) ? this.inputData.displayLanguages : []; }

    constructor(
        private mdDialogRef: MatDialogRef<SelectLanguageComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) private inputData: SelectLanguageInputData) {

    }

    public selectLanguage(language: string) {
        this.mdDialogRef.close(language);
    }
}
