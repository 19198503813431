import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule, Ng2StateDeclaration } from '@uirouter/angular';
import { UsersComponent } from './users.component';
import { UsersService } from './users.service';
import { CdkTableModule } from '@angular/cdk/table';
import { DownloadEmailsModalComponent } from './download-emails-modal/download-emails-modal.component';

let usersState: Ng2StateDeclaration = { 
    name: RouterStates.dashboard_users,
    url: '/users?q&page&size&sort&direction',
    component: UsersComponent,
    params: {
      q: {
        type: 'string',
        dynamic: true
      },
      page: {
        type: 'int',
        dynamic: true
      },
      size: {
        type: 'int',
        dynamic: true
      },
      sort: {
        type: 'string',
        dynamic: true
      },
      direction: {
        type: 'string',
        dynamic: true
      }
    }
  };

@NgModule({
  declarations: [
    UsersComponent,
    DownloadEmailsModalComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [usersState]
    }),
    CdkTableModule
  ],
  providers: [
    UsersService
  ],
  bootstrap: [
    DownloadEmailsModalComponent
  ]
})
export class UsersModule {}

