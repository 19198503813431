import { Component, ViewEncapsulation, OnDestroy }from '@angular/core';
import { StateService }from '@uirouter/angular';
import * as _ from 'underscore';
import { DashboardApiService }from '../../../../api/dashboard-api.service';
import { ITask }from '../../../../api/tasks/task.model';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton } from '../../../../core/dialog.service';
import { getApiErrors } from '../../../../api/error/get-api-errors.function';
import { ITaskRun } from '../../../../api/tasks/task-run.model';
import * as moment from 'moment';
import { RouterStates } from '../../../../core/router-states.constant';
import { NewTabService } from '../../../../core/new-tab.service';

@Component({
    templateUrl: './view-task.component.html',
    styleUrls: ['./view-task.component.scss'],
    host: {
        'class': 'view-task-component'
    },
    encapsulation: ViewEncapsulation.None 
})
export class ViewTaskComponent implements OnDestroy {

    public loading = true;
    public loadingRightPanel = false;
    public task: ITask = null;
    public taskRun: ITaskRun = null;
    public displayMode: DisplayMode = DisplayMode.Empty;

    public get taskJson(): string { return this.task != null ? JSON.stringify(this.task.rawTask) : null; }
    public get taskName(): string { return this.task != null ? this.task.name : ''; }

    private debouncedViewRun: (taskRun: ITaskRun) => any & _.Cancelable = null;

    constructor(
        private stateService: StateService,
        private api: DashboardApiService,
        private dialogService: DialogService,
        private newTabService: NewTabService) {
        
        let taskId = this.stateService.params.taskId;
        this.doRefresh(taskId);

        let self = this;
        this.debouncedViewRun = _.debounce((taskRun: ITaskRun) => self.doViewRun(taskRun), 500, false);
    }

    public ngOnDestroy() {
        if (this.debouncedViewRun != null) {
            let cancelable = <_.Cancelable><any>this.debouncedViewRun;
            if (cancelable.cancel != null) {
                cancelable.cancel();
            }
        }
    }

    public refresh() {
        this.loading = true;
        this.doRefresh();
    }

    public createNew() {
        if (this.task == null) {
            return;
        }

        this.stateService.go(RouterStates.dashboard_tasks_new, { srcId: this.task.id, srcTask: this.task });
    }

    public requeue() {
        if (this.task == null) {
            return;
        }

        if (this.task.cancellationRequested) {
            this.dialogService.showDialog(new DialogCaption('VIEW_TASK.REQUEUE_CANCELLED'));
            return;
        }

        this.dialogService.showDialog(new DialogCaption('VIEW_TASK.REQUEUE_CONFIRM'),
            new DialogYesButton(() => {
                this.api.tasks.requeueTaskById(this.task.id)
                    .subscribe(task => {
                        this.task = task;
                    },
                    error => {
                        let errors = getApiErrors(error);
                        let errorCaptionName = 'SHARED.GENERIC_ERROR';
                        if (errors != null && _.any(errors, err => err.code === 'TASK_CANCELLED')) {
                            errorCaptionName = 'VIEW_TASK.REQUEUE_CANCELLED';
                        }
                        this.dialogService.showDialog(new DialogCaption(errorCaptionName));
                        return;
                    });

            }),
            new DialogNoButton()
        );
    }

    public viewRelatedTask(event: any) {
        event = event || {};
        let task = event.task;
        let newWindow = event.newWindow || false;
        if (task == null) {
            return;
        }

        if (newWindow) {
            this.newTabService.openState(RouterStates.dashboard_tasks_view, { taskId: task.id });
        }
        else {
            this.stateService.go(RouterStates.dashboard_tasks_view, { taskId: task.id });
        }
    }

    public viewRun(taskRun: ITaskRun) {
        this.loadingRightPanel = true;
        this.debouncedViewRun(taskRun);
    }

    public viewJson() {
        this.loadingRightPanel = false;
        this.displayMode = DisplayMode.TaskJson; 
    }

    private doViewRun(taskRun: ITaskRun) {
        this.api.tasks.getTaskRunById(this.task.id, taskRun.id)
            .subscribe(item => {
                this.taskRun = item;
                this.displayMode = DisplayMode.TaskRun;
            },
            null,
            () => { this.loadingRightPanel = false; });
    }

    private doRefresh(taskId?: string) {
        taskId = taskId || ((this.task || { id: null }).id);
        if (taskId == null) {
            return;
        }

        this.api.tasks.getTaskById(taskId)
        .subscribe(task => {
            this.loading = false;
            this.task = task;
            if (this.displayMode === DisplayMode.Empty && this.task.runs != null && this.task.runs.items.length > 0) {
                this.loadingRightPanel = true;
                this.debouncedViewRun(_.chain(this.task.runs.items).sortBy(i => -moment(i.start).unix()).first().value());
            }
            else if (this.displayMode === DisplayMode.TaskRun && this.taskRun != null) {
                this.debouncedViewRun(this.taskRun);
            }
        },
        error => console.error(error));
    }
}

enum DisplayMode {
    Empty = 'empty',
    TaskJson = 'taskJson',
    TaskRun = 'taskRun'
}
