
export enum OperationStatus {
    Pending = 'pending',
    Executing = 'executing',
    Completed = 'completed',
    Skipped = 'skipped',
    Failed = 'failed',
    Fatal = 'fatal',
    Deleted = 'deleted'
}
