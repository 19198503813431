import { NgModule } from '@angular/core';
import { CreateOrganizationDialogComponent } from './create-organization-dialog.component';
import { CoreModulesModule } from '../../../../../core-modules.module';
import { CoreComponentsModule } from '../../../../../core-components.module';

@NgModule({
  declarations: [
    CreateOrganizationDialogComponent
  ],
  imports: [
    CoreModulesModule,
    CoreComponentsModule
  ],
  exports: [
    CreateOrganizationDialogComponent
  ],
  providers: [
  ],
  bootstrap: [
    CreateOrganizationDialogComponent
  ]
})
export class CreateOrganizationDialogModule { }
