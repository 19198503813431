import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { EmailTemplatesComponent } from './email-templates.component';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule } from '@angular/forms';
import { EmailContentEditorModule } from './email-content-editor/email-content-editor.module';
import { TemplateListModule } from './template-list/template-list.module';
import { EditEmailTemplateModule } from './edit-email-template/edit-email-template.module';

let routerState = { 
    name: RouterStates.dashboard_emailTemplates, 
    component: EmailTemplatesComponent
};

@NgModule({
  declarations: [
    EmailTemplatesComponent
  ],
  imports: [
    CoreComponentsModule,
    TemplateListModule,
    EmailContentEditorModule,
    EditEmailTemplateModule,
    UIRouterModule.forChild({
      states: [routerState]
    })
  ],
  exports: [
  ],
  bootstrap: []
})
export class EmailTemplatesModule { }
