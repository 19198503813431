import { Injectable } from '@angular/core';
import { IOrganization } from '../../../../api/organizations/organization.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { DashboardApiService } from '../../../../api/dashboard-api.service';
import { DashboardSidebarService } from '../../../../core/dashboard-sidebar.service';
import { OrganizationPlanRequest } from '../../../../api/organizations/organization-plan-request.model';
import { IOrganizationPlan } from '../../../../api/organizations/organization-plan.model';
import { StorageStatsRequestModel } from '../../../../api/plans/storage-stats-request.model';
import { IStorageStats } from '../../../../api/plans/storage-stats.model';
import { IUser } from '../../../../api/users/user.model';
import * as _ from 'underscore';

@Injectable()
export class OrganizationService {

    public organization: IOrganization = null;
    public loadingOrganization = true;

    public get organizationId(): string { return this.organization != null ? this.organization.id : null; }
    public organizationSubject: BehaviorSubject<IOrganization>;

    private statisticsOrganizationId: string = null;
    private rolesOrganizationId: string = null;

    constructor(private api: DashboardApiService,
                private dashboardSidebarService: DashboardSidebarService) {
        this.organizationSubject = new BehaviorSubject<IOrganization>(null);
    }

    public refresh(): Observable<IOrganization> {
        if (!this.organization || !this.organization.id) {
            return of<IOrganization>(null);
        }
        this.loadingOrganization = true;
        return this.loadOrganization(this.organization.id);
    }

    public loadOrganization(organizationId: string): Observable<IOrganization> {
        this.loadingOrganization = true;
        let organizationRequest = this.api.organizations.getOrganization(organizationId);
        organizationRequest    
            .subscribe(
                organization => { 
                    this.organization = organization;
                    this.organizationSubject.next(organization);
                    this.dashboardSidebarService.setOrganizationName(organization.name);
                },
                error => console.error('load organization:', error),
                () => {
                    this.loadingOrganization = false;
                }
            );
        return organizationRequest;
    }

    public updateOrganizationStorageStats(organizationId: string, statsRequest: StorageStatsRequestModel): Observable<IStorageStats> {
        let updateStats = this.api.organizations.updateOrganizationStorageStats(organizationId, statsRequest);
        updateStats
            .subscribe(
                stats => {
                    if (this.organization && this.organization.id === organizationId) {
                        this.organization.plan.storageStats = stats;
                    }
                },
                error => console.error('update stats:', error)
            );
        return updateStats;
    }

    public patchOrganizationPlan(organizationId: string, plan: OrganizationPlanRequest): Observable<IOrganizationPlan> {
        let result = this.api.organizations.patchOrganizationPlan(this.organization.id, plan);
        result
            .subscribe(
                op => {
                    if (this.organization && this.organization.id === organizationId) {
                        this.organization.plan = op;
                        this.organizationSubject.next(this.organization);
                    }
                }
            );
        return result;
    }

    public addOrganizationAdministrator(organizationId: string, adminId: string): Observable<IUser> {
        let result = this.api.organizations.addOrganizationAdministrator(organizationId, adminId);
        result
            .subscribe(
                u => {
                    if (this.organization && this.organization.id === organizationId) {
                        this.organization.administrators.push(u);
                        this.organizationSubject.next(this.organization);
                    }
                }
            );
        return result;
    }

    public removeOrganizationAdministrator(organizationId: string, adminId: string): Observable<IUser> {
        let result = this.api.organizations.removeOrganizationAdministrator(organizationId, adminId);
        result
            .subscribe(
                u => {
                    if (this.organization && this.organization.id === organizationId) {
                        let idx = _.findIndex(this.organization.administrators, a => a.id === adminId);
                        this.organization.administrators.splice(idx, 1);
                        this.organizationSubject.next(this.organization);
                    }
                }
            );
        return result;
    }
}
