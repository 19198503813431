import { Component, Input, ViewChild } from '@angular/core';
import { IOrganization } from '../../../../../../../api/organizations/organization.model';
import { IStoragePlan } from '../../../../../../../api/plans/storage-plan.model';
import { OrganizationPlanRequest } from '../../../../../../../api/organizations/organization-plan-request.model';
import { StoragePlanRequest } from '../../../../../../../api/plans/storage-plan-request.model';
import { deepCopy } from '../../../../../../../core/deep-copy.function';
import { PlanService } from '../../../../../../../core/plan.service';
import { ISubscriptionPlan } from '../../../../../../../api/plans/subscription-plan.model';
import { TranslateService } from '@ngx-translate/core';
import { DatepickerDirective } from '../../../../../../../components/datepicker/datepicker.directive';
import { OrganizationService } from '../../../organization.service';
import { StoragePlanComponent } from '../../../../../../../components/storage-plan/storage-plan.component';
import * as Flatpickr from 'flatpickr';
import * as _ from 'underscore';
import * as moment from 'moment';
import { finalize } from 'rxjs/operators';

@Component({
    templateUrl: './organization-storage-plan.component.html',
    styleUrls: ['./organization-storage-plan.component.scss'],
    selector: 'organization-storage-plan'
})
export class OrganizationStoragePlanComponent {

    @Input() public isEditing: boolean;
    @ViewChild('storagePlan', { static: true }) public storagePlanComponent: StoragePlanComponent;

    public isSaving = false;
    public get organization(): IOrganization { return this.organizationService ? this.organizationService.organization : null; }

    constructor (private organizationService: OrganizationService) {

    }

    public toggleEditing() {
        if (!this.storagePlanComponent.isEditing) {
            this.storagePlanComponent.beginEditing();
        }
        else {
            this.save();
        }
    }

    public cancelEditing() {
        this.storagePlanComponent.cancelEditing();
    }
 
    private save() {
        let result = this.storagePlanComponent.getPlanToSave();

        let self = this;
        if (result.isValid && result.hasChanges) {
            this.isSaving = true;
            let request = new OrganizationPlanRequest();
            request.storagePlan = result.planRequest;
            this.organizationService.patchOrganizationPlan(this.organization.id, request)
                .pipe(
                    finalize(() => { this.isSaving = false; })
                )
                .subscribe(
                    updatedPlan => {
                        self.storagePlanComponent.completeEditing();
                    },
                    error => {
                        console.error('error', error);
                    }
                );
        }
        else if (result.isValid && !result.hasChanges) {
            this.storagePlanComponent.cancelEditing();
        }
    }
}
