import { NgModule } from '@angular/core';
import { ApiHttp } from './api-http';
import { ApiHttpFactory } from './api-http.factory';
import { UIRouterModule } from '@uirouter/angular';
import { ApiModule } from '../api/api.module';
import { AuthenticationService } from './authentication.service';
import { EnvironmentService } from './environment.service';
import { LocalStorageService } from './local-storage.service';
import { UserService } from './user.service';
import { ApiStatusService } from './api-status.service';
import { PlanService } from './plan.service';
import { DashboardSidebarService } from './dashboard-sidebar.service';
import { DialogService } from './dialog.service';

import { PipesModule } from './pipes/pipes.module';
import { GenericDialogModule } from '../components/generic-dialog/generic-dialog.module';
import { OperationTemplateService } from './operation-template.service';
import { NewTabService } from './new-tab.service';
import { HttpClient } from '@angular/common/http';
import { ApiTokenInterceptor } from './api-token-interceptor';
import { HTTP_INTERCEPTORS } from '@angular/common/http';
import { CountriesService } from './countries.service';
import { QualitySetsService } from './quality-sets.service';
import { TranscodeProfileService } from './transcode-profile.service';
import { RefreshTokenInterceptor } from './refresh-token-interceptor';

@NgModule({
  declarations: [
  ],
  imports: [
    ApiModule,
    UIRouterModule,
    PipesModule,
    GenericDialogModule
  ],
  providers: [
      { provide: HTTP_INTERCEPTORS, useClass: ApiTokenInterceptor, multi: true },
      { provide: HTTP_INTERCEPTORS, useClass: RefreshTokenInterceptor, multi: true },
      AuthenticationService,
      {
        provide: ApiHttp,
        useFactory: ApiHttpFactory,
        deps: [ HttpClient, EnvironmentService ]
      },
      EnvironmentService,
      LocalStorageService,
      UserService,
      ApiStatusService,
      PlanService,
      DashboardSidebarService,
      DialogService,
      OperationTemplateService,
      NewTabService,
      CountriesService,
      QualitySetsService,
      TranscodeProfileService
  ],
  bootstrap: [],
  exports: [
    PipesModule
  ]
})
export class CoreModule { }
