
import {finalize} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter } from '@angular/core';
import { IStorageStats } from '../../api/plans/storage-stats.model';
import { DashboardApiService } from '../../api/dashboard-api.service';
import { StorageStatsRequestModel } from '../../api/plans/storage-stats-request.model';
import { Observable } from 'rxjs';

@Component({
    templateUrl: './storage-stats.component.html',
    selector: 'storage-stats'
})
export class StorageStatsComponent {

    @Input() public  updateStats: (request: StorageStatsRequestModel) => Observable<IStorageStats>;
    @Input() public editPermissionsCheck: () => boolean;
    @Input() public storageStats: IStorageStats;
    public editUploadAvailable: number;
    public isEditing: boolean;
    public isSaving: boolean;

    public get permissionsCheck(): () => boolean { return this.editPermissionsCheck != null && this.editPermissionsCheck !== undefined ? this.editPermissionsCheck : null; }

    constructor (private api: DashboardApiService) {

    }

    public toggleEditing() {
        if (!this.isEditing) {
            this.editUploadAvailable = this.storageStats.uploadAvailable;
            this.isEditing = true;
        }
        else {
            this.save();
        }
    }

    public cancelEditing() {
        this.isEditing = false;
    }

    private save() {
        let changes = false;
        let statsRequest = new StorageStatsRequestModel();
        
        if (this.editUploadAvailable !== this.storageStats.uploadAvailable) {
            statsRequest.uploadAvailable = this.editUploadAvailable;
            changes = true;
        }

        if (!changes) {
            this.isEditing = false;
            return;
        }

        this.isSaving = true;
        this.updateStats(statsRequest).pipe(
            finalize(() => this.isSaving = false))
            .subscribe(
                (stats) => {
                    this.updateStats(stats);
                    this.isEditing = false;
                },
                err => {
                    console.error(err);
                }
            );
    }
}
