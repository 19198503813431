
import {of as observableOf,  Observable } from 'rxjs';
import { Component, Input } from '@angular/core';
import { IExpirePlanWarningProcedure } from '../../../api/operations/procedure-model';
import { IProcedureComponent } from '../procedure-component-interface';
import { ExpirePlanWarningProcedureRequest } from '../../../api/operations/procedure-request.model';
import { take } from 'rxjs/operators';

@Component({
    templateUrl: './expire-plan-warning-procedure.component.html',
    styleUrls: ['./expire-plan-warning-procedure.component.scss'],
    selector: 'expire-plan-warning-procedure'
})
export class ExpirePlanWarningProcedureComponent implements IProcedureComponent {

    @Input() public procedure: IExpirePlanWarningProcedure;
    @Input() public editProcedure: ExpirePlanWarningProcedureRequest;
    @Input() public isEditing: boolean;
    @Input() public isSaving: boolean;

    public hasChanges(): boolean {
        return (this.procedure || null) == null || (this.procedure.showWarning !== this.editProcedure.showWarning);
    }

    public validate(): Observable<boolean> {
        return observableOf<boolean>(true).pipe(take(1));
    }
    
}
