import { Component, Input, EventEmitter, Output, SimpleChange, SimpleChanges, OnChanges, OnInit } from '@angular/core';
import * as _ from 'underscore';
import { IMediaOption } from '../../api/common/media-option.model';
import { deepCopy } from 'app/core/deep-copy.function';
import { MatDialog, MatDialogRef } from '@angular/material';
import { EditFormatComponent, IEditFormatOptions } from './edit-format/edit-format.component';
import { IMediaOptionFormat } from 'app/api/common/media-option-format.model';
import { DialogService, DialogYesButton, DialogNoButton, DialogCaption } from 'app/core/dialog.service';
import { generateString } from 'app/core/generate-string.function';
import { DashboardApiService } from 'app/api/dashboard-api.service';
import { MediaOptionService } from './media-option.service';

class Resolution {
    public get displayName(): string { return `${this.width}x${this.height}`; }

    constructor(public width: number, public height: number) {

    }
}

class EditOption {
    public get resolution(): Resolution { return this._resolution; }
    public set resolution(newResolution: Resolution) { this.value.width = newResolution.width; this.value.height = newResolution.height; this._resolution = newResolution; }
    public get canAddFormat(): boolean { return this.missingFormats.length > 0; }
    public missingFormats: string[] = [];
    private _resolution: Resolution;
    private _availableFormats = ['mp4'];

    constructor(public value: IMediaOption) {
        this._resolution = new Resolution(this.value.width, this.value.height);
        this.calculateMissingFormats();
    }

    public calculateMissingFormats() {
        this.missingFormats = this.getMissingFormats();
    }

    private getMissingFormats(): string[] {
        return _.filter(this._availableFormats, af => !_.any(this.value.formats, ef => ef.type === af));
    }
}

@Component({
    templateUrl: './media-option.component.html',
    styleUrls: ['./media-option.component.scss'],
    host: {
        // 'class': 'wide-row mat-paginator'
    },
    selector: 'media-option'
})
export class MediaOptionComponent implements OnInit, OnChanges { 

    @Input() public isSaving: boolean;
    @Input() public isEditing: boolean;
    @Input() public options: IMediaOption[];
    public defaultOptions: IMediaOption[];
    public sortedOptions: IMediaOption[];
    public editOptions: EditOption[];
    public get availableResolutions(): Resolution[] { return this._availableResolutions; }
    public get optionsToSave(): IMediaOption[] { return this.buildNewOptions(); }
    private _availableResolutions: Resolution[];

    constructor(
        private matDialog: MatDialog,
        private dialogService: DialogService,
        private mediaOptionService: MediaOptionService) {
        this._availableResolutions = this.getAvailableResolutions();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.options && changes.options.previousValue !== changes.options.currentValue) {
            this.updateOptions(changes.options.currentValue);
        }

        if (changes.isEditing && changes.isEditing.previousValue !== changes.isEditing.currentValue) {
            this.startEditing(changes.isEditing.currentValue);
        }
    }

    public ngOnInit() {
        this.mediaOptionService.defaultOptions
            .subscribe(opts => this.defaultOptions = opts);
    }

    public compareResolution(a: Resolution, b: Resolution): boolean {
        return a.width === b.width && a.height === b.height;
    }

    public addFormat(option: EditOption) {
        this.showFormatDialog(option, null)
            .afterClosed().subscribe((result: IMediaOptionFormat) => {
                if (result == null) {
                    return;
                }
                option.value.formats.push(result);
                option.calculateMissingFormats();
             });
    }

    public editFormat(option: EditOption, format: IMediaOptionFormat) {
        this.showFormatDialog(option, format)
            .afterClosed().subscribe((result: IMediaOptionFormat) => {
                if (result == null) {
                    return;
                }
                let idx = option.value.formats.findIndex(f => f === format);
                option.value.formats.splice(idx, 1, result);
                option.calculateMissingFormats();
            });
    }

    public deleteFormat(option: EditOption, format: IMediaOptionFormat) {
        this.dialogService.showDialog(
            new DialogCaption(`Are you sure you want to delete the ${format.type} format?`),
            new DialogYesButton(() => {
                let idx = _.findIndex(option.value.formats, f => f === format);
                if (idx !== -1) {
                    option.value.formats.splice(idx, 1);
                    option.calculateMissingFormats();
                }
            }),
            new DialogNoButton()
        );
    }
    
    public addOption() {
        let availableResolutions = this.getAvailableResolutions();
        let availableSizes = _.filter(availableResolutions, r => !_.any(this.editOptions, o => o.resolution.width === r.width && o.resolution.height === r.height));
        if (availableSizes.length === 0) {
            availableSizes = availableResolutions;
        }

        let option = <IMediaOption>{
            id: generateString(8),
            width: availableSizes[0].width,
            height: availableSizes[0].height,
            formats: []
        };

        this.editOptions.push(new EditOption(option));
    }

    public deleteOption(option: EditOption) {
        this.dialogService.showDialog(
            new DialogCaption(`Are you sure you want to delete the ${option.resolution.displayName} option?`),
            new DialogYesButton(() => {
                let idx = _.findIndex(this.editOptions, eo => eo === option);
                if (idx !== -1) {
                    this.editOptions.splice(idx, 1);
                }
            }),
            new DialogNoButton()
        );
    }

    public hasChanges(): boolean {
        let original = JSON.stringify(this.options);
        let current = JSON.stringify(this.buildNewOptions());
        return original !== current;
    }

    public setDefaultOptions() {
        let sortedDefaults = this.sortOptions(this.defaultOptions || []);
        this.editOptions = [];
        for (let option of sortedDefaults) {
            this.editOptions.push(new EditOption(deepCopy(option)));
        }
    }

    private buildNewOptions(): IMediaOption[] {
        let options = _.map(this.editOptions, o => {
            o.value.width = o.resolution.width;
            o.value.height = o.resolution.height;
            return o.value;
        });
        return options;
    }

    private showFormatDialog(option: EditOption, format?: IMediaOptionFormat): MatDialogRef<EditFormatComponent> {
        return this.matDialog.open(EditFormatComponent, {
            width: '500px',
            data: <IEditFormatOptions>{
                isNew: (format == null),
                availableFormats: option.missingFormats || [],
                format: format
            }
        });
    }

    private startEditing(isEditing: boolean) {
        if (!isEditing) {
            return;
        }

        this.editOptions = [];
        for (let option of this.sortedOptions) {
            this.editOptions.push(new EditOption(deepCopy(option)));
        }
    }

    private updateOptions(options: IMediaOption[]) {
        if (options == null) {
            this.sortedOptions = [];
            return;
        }

        this.sortedOptions = this.sortOptions(this.options);
    }

    private sortOptions(options: IMediaOption[]): IMediaOption[] {
        let sorted = options.slice();
        sorted.sort((a, b) => {

            let aRes = a.width * a.height;
            let bRes = a.height * b.height;
            if (aRes < bRes) {
                return -1;
            } else if (aRes > bRes) {
                return 1;
            }
            return 0;

        });

        for (let opt of sorted) {
            opt.formats = _.sortBy(opt.formats, o => o.type);
        }

        return sorted;
    }

    private getAvailableResolutions(): Resolution[] {
        return _.sortBy([
            new Resolution(426, 240),
            new Resolution(640, 360),
            new Resolution(854, 480),
            new Resolution(960, 540),
            new Resolution(1280, 720),
            new Resolution(1920, 1080),
            new Resolution(2560, 1440),
            new Resolution(3840, 2160)
        ], r => r.width * r.height);
    }
}
