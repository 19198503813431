
import {of as observableOf,  Observable } from 'rxjs';
import { Component, Input, ViewChildren, QueryList } from '@angular/core';
import { IProcedure } from '../../api/operations/procedure-model';
import { ProcedureRequestBase } from '../../api/operations/procedure-request.model';
import { IProcedureComponent } from './procedure-component-interface';
import { take } from 'rxjs/operators';

@Component({
    templateUrl: './procedures-container.component.html',
    selector: 'procedures-container'
})
export class ProceduresContainerComponent implements IProcedureComponent {
    
    @Input() public procedure: IProcedure;
    @Input() public editProcedure: ProcedureRequestBase;
    @Input() public isEditing: boolean;
    @Input() public isSaving: boolean;

    public get hasProcedure(): boolean { return (this.isEditing ? this.editProcedure : this.procedure) != null; }
    public get procedureTypeName(): string { return (this.isEditing ? this.editProcedure.type : this.procedure.type); }

    @ViewChildren('procedureEditor') private children: QueryList<IProcedureComponent>;
    private get procedureComponent(): IProcedureComponent { return this.children.length > 0 ? this.children.first : null; }
    
    public hasChanges(): boolean {
        return (this.procedureComponent != null ? this.procedureComponent.hasChanges() : false);
    }

    public validate(): Observable<boolean> {
        return this.procedureComponent != null
                    ? this.procedureComponent.validate()
                    : observableOf<boolean>(false).pipe(take(1));
    }
    
}
