
import {of as observableOf,  Subscription, Observable } from 'rxjs';

import {finalize,  take } from 'rxjs/operators';
import { Component, OnInit, ViewChild, OnDestroy, ElementRef } from '@angular/core';
import { TasksService } from './tasks.service';
import { PaginatorComponent } from '../../../../components/paginator/paginator.component';
import * as _ from 'underscore';
import * as moment from 'moment';
import { ITask } from '../../../../api/tasks/task.model';
import { StateService, TransitionService, Transition, splitOnDelim } from '@uirouter/angular';
import { RouterStates } from '../../../../core/router-states.constant';
import { MatSort, MatDialog, DialogPosition } from '@angular/material';
import { FilterListComponent, FilterListOption } from '../../../../components/filter-list/filter-list.component';

@Component({
    templateUrl: './list-tasks.component.html',
    styleUrls: ['./list-tasks.component.scss']
})
export class ListTasksComponent implements OnInit, OnDestroy {

    public get loadingTasks(): boolean { return this.loadingStates && this.taskService.loadingData; }
    public get dataSource() { return this.taskService.dataSource; }
    public displayedColumns = ['name', 'created', 'started', 'type', 'status', 'runs'];
    
    private defaultSortColumn = 'created';
    private defaultSortDirection = 'desc';
    @ViewChild(MatSort, { static: true }) private sort: MatSort;
    @ViewChild(PaginatorComponent, { static: true }) private paginator: PaginatorComponent;
    private startDate: string = null;
    private subscriptions: Subscription[] = [];
    private stopWatchingTransition: Function = null;
    private possibleStates: string[];
    private selectedStates: string[] = [];
    private loadingStates = true;
    private lastSortActive = this.defaultSortColumn;

    constructor(
        private taskService: TasksService,
        private stateService: StateService,
        private transitionService: TransitionService,
        private matDialog: MatDialog) {

    }

    public ngOnInit() {
        this.paginator.pageIndex = 0;
        this.paginator.pageSize = 10;

        this.processParams(this.stateService.params, true);
        this.doRefresh(this.startDate, this.paginator.pageIndex, this.paginator.pageSize);

        this.subscriptions.push(this.paginator.onPageChange
            .subscribe(pc => {
                this.doRefresh(this.startDate, this.paginator.pageIndex, this.paginator.pageSize);
            }));

        this.stopWatchingTransition = this.transitionService.onSuccess({ to: this.stateService.current.name }, (trans: Transition) => {
            if (this.processParams(this.stateService.params, false)) {
                this.doRefresh(this.startDate, this.paginator.pageIndex, this.paginator.pageSize);
            }
        });

        this.subscriptions.push(this.sort.sortChange
            .subscribe(sc => {
                if (this.sort.active !== this.lastSortActive) {
                    this.lastSortActive = this.sort.active;
                    this.sort.direction = 'desc';
                }
                else if (!this.sort.direction) {
                    this.sort.direction = 'asc';
                }
                this.paginator.pageIndex = 0;
                this.refresh();
            })); 
    }

    public ngOnDestroy() {
        for (let sub of this.subscriptions) {
            if (sub != null) {
                sub.unsubscribe();
            }
        }
        if (this.stopWatchingTransition != null) {
            this.stopWatchingTransition();
        }
    }

    public viewTask(task: ITask) {
        if (task != null) {
            this.stateService.go(RouterStates.dashboard_tasks_view, { taskId: task.id });
        }
    }

    public createTask() {
        this.stateService.go(RouterStates.dashboard_tasks_new);
    }

    public refresh() {
        this.doRefresh(this.startDate, this.paginator.pageIndex, this.paginator.pageSize);
    }

    public showStatusFilter(event: MouseEvent) {
        if (event.target == null) {
            return;
        }
        let buttonRect = (<HTMLElement>event.target).getBoundingClientRect();

        let options = _.map(this.possibleStates, s => {
            let selected = this.selectedStates.length === 0 || _.any(this.selectedStates, st => st === s);
            return new FilterListOption('TASKS_SHARED.STATUS.' + s.toUpperCase(), true, s, selected);            
        });

        // console.log(event);
        let width = 300;
        this.matDialog.open(FilterListComponent, {
            position: <DialogPosition>{
                top: (buttonRect.bottom + 10) + 'px',
                left: (buttonRect.right - width + 6) + 'px'
            },
            width: width + 'px',
            // position: event.srcElement.
            backdropClass: 'invisible-backdrop',
            data: {
                options: options
            }
        }).afterClosed().subscribe(
            () => {
                let oldSelected = _.sortBy(this.selectedStates.slice(), s => s);
                if (_.all(options, s => s.selected)) {
                    this.selectedStates = [];
                }
                else {
                    this.selectedStates = _.chain(options.slice()).filter(o => o.selected === true).map(o => o.value).value();
                }
                
                let currentSelected = _.sortBy(this.selectedStates, s => s);
                let selectedChanged = currentSelected.length !== oldSelected.length;
                if (!selectedChanged) {
                    for (let i = 0; i < oldSelected.length; ++i) {
                        if (oldSelected[i] !== currentSelected[i]) {
                            selectedChanged = true;
                            break;
                        }
                    }
                }

                if (selectedChanged) {
                    this.paginator.pageIndex = 0;
                    this.refresh();
                }

            });
    }

    private getPossibleStates(): Observable<string[]> {
        return observableOf<string[]>(['new', 'processing', 'complete', 'error', 'skipped', 'cancelled', 'failed']).pipe(take(1));
    }

    private processParams(params: any, firstLoad: boolean): boolean {
        let changes = false;
        
        if (params.page != null) {
            let tmpPage = Number(params.page);
            if (Number.isInteger(tmpPage) && tmpPage >= 0 && tmpPage !== this.paginator.pageIndex) {
                changes = true;
                this.paginator.pageIndex = tmpPage;
            }
        }

        if (params.size != null) {
            let tmpSize = Number(params.size);
            if (Number.isInteger(tmpSize) && tmpSize > 0 && tmpSize !== this.paginator.pageSize) {
                changes = true;
                this.paginator.pageSize = tmpSize;
            }
        }

        if (params.fromDate != null) {
            let tmpDate = moment(decodeURIComponent(params.fromDate));
            if (this.startDate == null || tmpDate.toISOString() !== moment(this.startDate).toISOString()) {
                changes = true;
                this.startDate = tmpDate.toISOString();
            }
        }

        if (params.type != null && params.type.length > 0) {
            if (typeof params.type === 'string') {
                this.selectedStates = params.type.split(',');
            }
            else if (Array.isArray(params.type)) {
                this.selectedStates = params.type;
            }
        }

        let newSortBy = this.sort.active;
        if (params.sortBy != null) {
            newSortBy = params.sortBy;
        }
        else {
            newSortBy = this.defaultSortColumn;
        }
        
        let resort = false;
        if (newSortBy !== this.sort.active) {
            if (!firstLoad) {
                this.startDate = null;
                this.paginator.pageIndex = 0;
            }
            this.sort.active = newSortBy;
            this.lastSortActive = newSortBy;
            changes = true;
            resort = true;
        }

        let newDirection = this.sort.direction;
        if (params.direction != null && (params.direction === 'asc' || params.direction === 'desc')) {
            newDirection = params.direction;
        }
        else {
            newDirection = <any>this.defaultSortDirection;
        }

        if (newDirection !== this.sort.direction) {
            if (!firstLoad) {
                this.startDate = null;
                this.paginator.pageIndex = 0;
            }
            this.sort.direction = newDirection;
            changes = true;
            resort = true;
        }

        if (resort && this.sort.sortables.size > 0) {
            let sortable = this.sort.sortables.get(this.sort.active);
            this.sort.sort(sortable);
            this.sort.direction = newDirection;
        }
        return changes;
    }

    private getStartDate(items: ITask[]): string {
        let sortBy = this.sort.active || this.defaultSortColumn;
        let sortDirection = (this.sort.direction || this.defaultSortDirection)
        let sortAsc = sortDirection === 'asc' ? true : false;

        let chain: any = _.chain(items);
        if (sortBy === 'created') {
            chain = chain.map(i => i.created);
        }
        else if (sortBy === 'started') {
            chain = chain.map(i => i.lastExecuted);
        }

        if (sortAsc) {
            chain = chain.min(r => moment(r).unix());
        }
        else {
            chain = chain.max(r => moment(r).unix());
        }
        
        return chain.value();
    }

    private doRefresh(startDate: string, page: number, pageSize: number) {
        if ((startDate == null || startDate === undefined) && page !== 0) {
            this.paginator.pageIndex = 0;
        }

        if (page === 0) {
            startDate = null;
        }

        let sortBy = this.sort.active || this.defaultSortColumn;
        let sortDirection = (this.sort.direction || this.defaultSortDirection);
        let sortAsc = sortDirection === 'asc' ? true : false;
        let states = this.selectedStates.slice();

        if (this.loadingStates || this.possibleStates.length === 0) {
            this.loadingStates = true;
            this.getPossibleStates().pipe(
                finalize(() => this.loadingStates = false))
                .subscribe(loadedStates => {
                    this.possibleStates = loadedStates;
                });
        }

        this.taskService.reset(startDate, page, pageSize, sortBy, sortAsc, states).pipe(take(1))
            .subscribe(s => {
                if (page === 0) {
                    if (s.items != null && s.items.length > 0) {
                        this.startDate = this.getStartDate(s.items);
                    }   
                    else {
                        this.startDate = null;
                    }
                }

                this.stateService.transitionTo(this.stateService.current.name,
                    { // if new parameters are added here, add them as dynamic in the routing
                        fromDate: this.startDate,
                        page: page, 
                        size: pageSize,
                        sortBy: sortBy,
                        direction: sortDirection,
                        type: states
                    });
                this.paginator.itemCount = s.total;
            });
    }

}
