// Feature module with common modules AND components

import { NgModule } from '@angular/core';
import { CoreModulesModule } from './core-modules.module';
import { ComponentsModule } from './components/components.module';

const modules = [
  CoreModulesModule,
  ComponentsModule
];

@NgModule({
  imports: modules,
  exports: modules
})
export class CoreComponentsModule { }
