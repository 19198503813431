
export const GENERATE_STRING_DEFAULT_CHARS = 'abcdefghijklmnopqrstuvwxyz1234567890';
export const GENERATE_STRING_UPPER_LOWER_NUMBER_CHARS = 'abcdefghijklmnopqrstuvwxyzABCDEFGHIJKLMNOPQRSTUVWXYZ1234567890';

export function generateString(size: number);
export function generateString(size: number, chars?: string);

export function generateString(size: number, chars?: string) {
    if (chars == null || chars.length < 1) {
        chars = GENERATE_STRING_DEFAULT_CHARS;
    }
    let result = [];
    for (let i = 0; i < size; ++i) {
        let randomIdx = Math.floor(Math.random() * chars.length);
        result.push(chars[randomIdx]);
    }
    return result.join('');
}
