import { Component } from '@angular/core';
import { DashboardApiService } from '../../../api/dashboard-api.service';
import { StateService, TransitionService, Transition } from '@uirouter/angular';
import { RouterStates } from '../../../core/router-states.constant';
import { take } from 'rxjs/operators';
import { MatDialog, MatDialogRef } from '@angular/material';
import { EmailContentEditorComponent, EmailContentEditorInputData } from './email-content-editor/email-content-editor.component';
import { IEmailContent } from 'app/api/email/email-content.model';
import { Observable, of } from 'rxjs';

@Component({
    templateUrl: './email-templates.component.html',
    styleUrls: ['./email-templates.component.scss']
})
export class EmailTemplatesComponent  {

    constructor(
        private api: DashboardApiService,
        private stateService: StateService,
        private transitionService: TransitionService,
        private mdDialog: MatDialog) {
        
    }

    public showIt() {
        this.showTemplateModal()
            .afterClosed()
            .subscribe(v => {
                // alert(JSON.stringify(v));
            });
    }

    private showTemplateModal(): MatDialogRef<EmailContentEditorComponent>  {
        return this.mdDialog.open(EmailContentEditorComponent, { 
            width: '900px',
            disableClose: true, // handled in component
            data: <EmailContentEditorInputData>{
                content: {
                    subject: 'test',
                    body: '<h1>Test</h1><p>Moo</p>',
                    language: 'en'
                },
                save: (t) => {
                    return of<IEmailContent>(<IEmailContent>{
                        subject: t.subject,
                        body: t.body,
                        language: t.language
                    }); 
                }
            }
        });
    }
}
