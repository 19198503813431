import { NgModule } from '@angular/core';
import { CoreModulesModule } from '../../core-modules.module';
import { ProcedureSummaryComponent } from './procedure-summary.component';

@NgModule({
  declarations: [
      ProcedureSummaryComponent
  ],
  imports: [
      CoreModulesModule
  ],
  exports: [
    ProcedureSummaryComponent
  ],
  providers: [
  ],
  bootstrap: [
    ProcedureSummaryComponent
  ]
})
export class ProcedureSummaryModule { }
