import { NgModule } from '@angular/core';
import { BasicInfoComponent } from './basic-info.component';
import { CoreModulesModule } from '../../../../../core-modules.module';

@NgModule({
  declarations: [
    BasicInfoComponent
  ],
  imports: [
    CoreModulesModule
  ],
  exports: [
    BasicInfoComponent
  ],
  providers: [
  ],
  bootstrap: [
    BasicInfoComponent
  ]
})
export class BasicInfoModule { }
