import { ApiHttp } from 'app/core/api-http';
import { IMediaOption } from '../common/media-option.model';
import { ICollection } from '../common/collection.model';
import { Observable } from 'rxjs';
import { IMediaQualitySet } from './media-quality-set.model';
import { IMediaTranscodeProfile } from './media-transcode-profile.model';

export class MediaResource {

    constructor(private http: ApiHttp) {

    }

    public getDefaultOptions(): Observable<ICollection<IMediaOption>> {
        let url = 'v1/media/defaults/options';
        return this.http.getTyped<ICollection<IMediaOption>>(url);
    }

    public getQualitySets(): Observable<ICollection<IMediaQualitySet>> {
        let url = 'v1/media/qualitySets';
        return this.http.getTyped<ICollection<IMediaQualitySet>>(url);
    }

    public getTranscodeProfiles(): Observable<ICollection<IMediaTranscodeProfile>> {
        let url = 'v1/media/transcodeprofiles';
        return this.http.getTyped<ICollection<IMediaTranscodeProfile>>(url);
    }
}
