import { Component, OnDestroy } from '@angular/core';
import { ITeam } from '../../../../../api/teams/team.model';
import { ITeamStatistics } from '../../../../../api/teams/team-statistics.model';
import { TeamService } from '../../team.service';
import { Subscription } from 'rxjs';
import * as _ from 'underscore';

@Component({
    templateUrl: './team-stats.component.html',
    selector: 'team-stats'
})
export class TeamStatsComponent implements OnDestroy {

    public get team(): ITeam { return this.teamService ? this.teamService.team : null; }
    public get teamStatistics(): ITeamStatistics { return this.teamService ? this.teamService.teamStatistics : null; }
    public get loadingStatistics(): boolean { return this.teamService ? this.teamService.loadingStatistics : true; }

    public roleOrder = ['manager', 'editor', 'member', 'readonly'];
    public roleLabelKeys: { [roleType: string]: string } = {
         'manager': 'SHARED.ROLES.MANAGERS',
         'editor': 'SHARED.ROLES.EDITORS',
         'member': 'SHARED.ROLES.MEMBERS',
         'readonly': 'SHARED.ROLES.READ_ONLY_USERS'
    };
    public roles: DisplayRole[] = [];
    public memberCount = 0;

    private teamSubscription: Subscription;

    constructor(private teamService: TeamService) {
        this.subscribeTeamChange();
    }

    public ngOnDestroy() {
        if (this.teamSubscription) {
            this.teamSubscription.unsubscribe();
            this.teamSubscription = null;
        }
    }

    private subscribeTeamChange() {
        this.teamSubscription = this.teamService.teamSubject
            .subscribe(team => {
                if (!team) {
                    this.roles = [];
                    return;
                }
                this.teamService.loadStatisticsIfEmpty(team.id);

                this.roles = [];
                if (team.roles && team.roles.items) {
                    for (let role of team.roles.items) {
                        if (role.members) {
                            let order = _.indexOf(this.roleOrder, role.name);
                            if (order === -1) {
                                continue;
                            }
                            this.roles.push({
                                roleType: role.name,
                                labelKey: this.roleLabelKeys[role.name] || role.name,
                                memberCount: role.members.total,
                                sortOrder: order
                            });
                        }
                    }
                    this.roles = _.sortBy(this.roles, r => r.sortOrder);
                    this.recalculateMemberCount();
                }
            });
    }

    private recalculateMemberCount() {
        this.memberCount = _.chain(this.team.roles.items)
        .filter(r => r.isDefault && r.name !== 'all')
        .reduce<number>((m, i) => {
            return m + i.members.total;
        }, 0)
        .value();
    }
}

class DisplayRole {
    public roleType: string;
    public labelKey: string;
    public memberCount: number;
    public sortOrder: number;
}
