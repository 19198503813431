import { NgModule } from '@angular/core';
import { CoreModulesModule } from '../../../core-modules.module';

import { DashboardSidebarComponent } from './sidebar/dashboard-sidebar.component';
import { CoreComponentsModule } from '../../../core-components.module';

@NgModule({
  declarations: [
      DashboardSidebarComponent
  ],
  imports: [
      CoreModulesModule,
      CoreComponentsModule
  ],
  exports: [
      DashboardSidebarComponent
  ],
  providers: [
  ],
  bootstrap: [
      DashboardSidebarComponent
  ]
})
export class DashboardComponentsModule { }
