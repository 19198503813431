import { Component, OnInit, ViewChild, OnDestroy } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { DashboardSidebarService } from '../../../../core/dashboard-sidebar.service';
import { DashboardApiService } from '../../../../api/dashboard-api.service';
import { Observable ,  Subscription } from 'rxjs';
import { IOperationTemplateBase } from '../../../../api/operations/operation-template.model';
import { RouterStates } from '../../../../core/router-states.constant';
import { OperationTemplateRequestBase } from '../../../../api/operations/operation-template-request.model';
import { OperationTemplateTypes } from '../../../../api/operations/operation-template-types.enum';



import { ViewTemplateComponent } from './view-template/view-template.component';
import { getEnumValues } from '../../../../core/enum-values.function';
import * as _ from 'underscore';
import { EditTemplateShared } from './edit-template-shared.service';

@Component({
    templateUrl: './edit-template.component.html'
})
export class EditTemplateComponent implements OnInit, OnDestroy {
    public shared: EditTemplateShared = new EditTemplateShared();
    public operation: IOperationTemplateBase = null;
    public editOperation: OperationTemplateRequestBase;
    public newType: OperationTemplateTypes;

    public operationIdentifier: string;
    public forceVersion?: number; 

    @ViewChild(ViewTemplateComponent, { static: false }) private view: ViewTemplateComponent;
    private loadingOperation = true;
    private opSubscription: Subscription;
    

    constructor(private stateService: StateService,
                private dashboardSidebarService: DashboardSidebarService,
                private api: DashboardApiService) {
                    
        let params = this.stateService.params;

        if (this.stateService.current.name === RouterStates.dashboard_operation_template_new) {
            this.shared.isNew = true;
            let type = this.stateService.params.type.toLowerCase();
            let availableTypes = getEnumValues(OperationTemplateTypes);
            let typeName = _.find(availableTypes, t => t.toLowerCase() === type);
            this.newType = <OperationTemplateTypes>typeName;
        }
        else {
            let templateName = (params.templateName || null);
            if (templateName != null) {
                this.dashboardSidebarService.setOperationTemplateName(templateName);
            }

            this.operationIdentifier = params.operationIdentifier;
            let version = params.v || null;
            if (version != null) {
                this.forceVersion = version;
            }
        }
    }

    public ngOnInit() {
        this.opSubscription = this.shared.operationChanged
            .subscribe(o => {
                this.dashboardSidebarService.setOperationTemplateName(o.name);
            });

        if (!this.shared.isNew) {
            this.refresh();
        }
    }

    public ngOnDestroy() {
        if (this.opSubscription != null) {
            this.opSubscription.unsubscribe();
        }
    }

    public toggleEditing() {
        this.view.toggleEditing();
    }
    
    public cancelEditing() {
        if (this.shared.isEditing) {
            this.view.cancelEditing();
        }
    }

    public restoreVersion() {
        this.view.restoreVersion();
    }

    private refresh() {
        let observable: Observable<IOperationTemplateBase> = null;
        if (this.forceVersion != null) {
            observable = this.api.operationTemplates
                .getOperationTemplateByVersion(this.operationIdentifier, this.forceVersion);
        }
        else {
            observable = this.api.operationTemplates
                .getOperationTemplate(this.operationIdentifier);
        }
        
        this.loadingOperation = true;
        const loadingKey = 'OPERATION';
        this.shared.addLoadingKey(loadingKey);
        observable .subscribe(operation => {
                this.operation = operation;
                // this.dashboardSidebarService.setOperationTemplateName(operation.name);
                this.shared.operationChanged.emit(this.operation);
            },
            err => {
                console.error('Couldn\'t load operation template:', err);
                if (err.status === 404) {
                    this.stateService.go(RouterStates.dashboard_operation_templates);
                }
            }, 
            () => this.shared.removeLoadingKey(loadingKey));
    }
}
