import { Pipe, PipeTransform, SecurityContext } from '@angular/core';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';

@Pipe({ name: 'spsanitize', pure: true })
export class SplyzaSanitize implements PipeTransform {

    constructor(private sanitizer: DomSanitizer) {

    }

    public transform(value: string, fixWhiteSpace: boolean): SafeHtml {
        if (fixWhiteSpace) {
            value = value.replace(/\t/g, '&#8287;&#8287;&#8287;&#8287;&#8287;');
            value = value.replace(/[ ]/g, '&nbsp;');
        }
        return this.sanitizer.sanitize(SecurityContext.HTML, value);
    }

}
