
import {of as observableOf,  Observable ,  BehaviorSubject } from 'rxjs';

import {share,  take } from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { ITeam } from '../../../api/teams/team.model';
import { DashboardApiService } from '../../../api/dashboard-api.service';
import { TeamPlanRequest } from '../../../api/teams/team-plan-request.model';
import { ITeamPlan } from '../../../api/teams/team-plan.model';
import { IGroup } from '../../../api/groups/group.model';
import { ICollection } from '../../../api/common/collection.model';
import { ITeamStatistics } from '../../../api/teams/team-statistics.model';
import { DashboardSidebarService } from '../../../core/dashboard-sidebar.service';
import * as md5 from 'md5';
import { TeamRequest } from '../../../api/teams/team-request.model';
import { StorageStatsRequestModel } from '../../../api/plans/storage-stats-request.model';
import { IStorageStats } from '../../../api/plans/storage-stats.model';
import { IMigrationStatus } from 'app/api/teams/migration-status.model';
import { IGraduation } from 'app/api/teams/graduation.model';
import { GraduationStatus } from 'app/api/teams/graduation-status.enum';
import { ITeamMembershipTermiantion } from 'app/api/teams/team-membership-termination.model';
import { ITeamGraduationCollection } from 'app/api/teams/team-graduation-collection.model';

@Injectable()
export class TeamService {

    public team: ITeam = null;
    public teamStatistics: ITeamStatistics = null;
    public teamRoles: ICollection<IGroup> = null;
    public loadingTeam = true;
    public loadingStatistics = true;
    public loadingRoles = true;

    public get teamId(): string { return this.team != null ? this.team.id : null; }
    public teamSubject: BehaviorSubject<ITeam>;

    private statisticsTeamId: string = null;
    private rolesTeamId: string = null;

    constructor(private api: DashboardApiService,
                private dashboardSidebarService: DashboardSidebarService) {
        this.teamSubject = new BehaviorSubject<ITeam>(null);
    }

    public refresh(): Observable<ITeam> {
        if (!this.team || !this.team.id) {
            return observableOf<ITeam>(null).pipe(take(1));
        }
        this.loadingTeam = true;
        this.loadingStatistics = true;
        this.teamStatistics = null;
        this.statisticsTeamId = null;
        this.teamRoles = null;
        this.rolesTeamId = null;
        return this.loadTeam(this.team.id);
    }

    public loadTeam(teamId: string): Observable<ITeam> {
        this.loadingTeam = true;
        let teamRequest = this.api.teams.getTeam(teamId);
        teamRequest    
            .subscribe(
                team => { 
                    this.team = team;
                    this.teamSubject.next(team);
                    this.dashboardSidebarService.setTeamName(team.name);
                },
                error => console.error('load team:', error),
                () => {
                    this.loadingTeam = false;
                }
            );
        return teamRequest;
    }

    public loadStatistics(teamId: string): Observable<ITeamStatistics> {
        this.loadingStatistics = true;
        let result = this.api.teams.getTeamStatistics(teamId);
        result
            .subscribe(statistics => {
                    if (this.team && this.team.id === teamId) {
                        this.teamStatistics = statistics;
                        this.statisticsTeamId = teamId;
                    }
                },
                error => console.error('load team:', error),
                () => {
                    this.loadingStatistics = false;
                });
        return result;
    }

    public loadRoles(teamId: string): Observable<ICollection<IGroup>> {
        this.loadingRoles = true;
        let result = this.api.teams.getTeamRoles(teamId);
        result
            .subscribe(roles => {
                if (this.team && this.team.id === teamId) {
                    this.teamRoles = roles;
                    this.rolesTeamId = teamId;
                }
            },
        error => console.error('load roles:', error),
        () => {
            this.loadingRoles = false;
        });
        return result;
    }

    public loadStatisticsIfEmpty(teamId: string): Observable<ITeamStatistics> {
        if (!this.teamStatistics || this.statisticsTeamId !== teamId) {
            return this.loadStatistics(teamId);
        }
        else {
            return observableOf(this.teamStatistics).pipe(take(1));
        }
    }

    public loadRolesIfEmpty(teamId: string): Observable<ICollection<IGroup>> {
        if (!this.teamRoles || this.rolesTeamId !== teamId) {
            return this.loadRoles(teamId);
        }
        else {
            return observableOf(this.teamRoles).pipe(take(1));
        }
    }

    public updateTeamStorageStats(teamId: string, statsRequest: StorageStatsRequestModel): Observable<IStorageStats> {
        let updateStats = this.api.teams.updateTeamStorageStats(teamId, statsRequest);
        updateStats
            .subscribe(
                stats => {
                    if (this.team && this.team.id === teamId) {
                        this.team.plan.storageStats = stats;
                    }
                },
                error => console.error('update stats:', error)
            );
        return updateStats;
    }

    public patchTeam(teamId: string, teamRequest: TeamRequest): Observable<ITeam> {
        let patchTeam = this.api.teams.patchTeam(teamId, teamRequest);
        patchTeam
            .subscribe(
                team => { 
                    this.team = team;
                    this.teamSubject.next(team);
                    this.dashboardSidebarService.setTeamName(team.name);
                },
                error => console.error('patch team:', error)
            );
        return patchTeam;
    }

    public patchTeamPlan(teamId: string, plan: TeamPlanRequest): Observable<ITeamPlan> {
        let result = this.api.teams.patchPlan(teamId, plan);
        result
            .subscribe(r => {
                if (this.team && this.team.id === teamId) {
                    this.team.plan = r;
                }
            });
        return result;
    }

    public setNewAdministrator(teamId: string, roleId: string, userId: string): Observable<ICollection<IGroup>> {
        return new Observable<ICollection<IGroup>>(subscriber => {
            this.api.teams.setNewAdministrator(teamId, roleId, {
                user: {
                    id: userId
                }
            })
            .subscribe(changeResult => {
                if (teamId !== this.team.id) {
                    subscriber.unsubscribe();
                    return;
                }

                this.loadRoles(teamId)
                    .subscribe(roles => {
                        if (teamId !== this.team.id) {
                            subscriber.unsubscribe();
                            return;
                        }

                        subscriber.next(roles);
                    },
                err => subscriber.error(err),
                () => subscriber.complete());
            },
            err => subscriber.error(err));

        }).pipe(share());
    }

    public getTeamGraduations(teamId: string, skip?: number, pageSize?: number): Observable<ITeamGraduationCollection> {
        return this.api.teams.getTeamGraduations(teamId, skip, pageSize);
    }

    public getTeamMembershipTerminations(teamId: string, skip?: number, pageSize?: number): Observable<ICollection<ITeamMembershipTermiantion>> {
        return this.api.teams.getTeamMembershipTerminations(teamId, skip, pageSize);
    }

    public updateGraduation(graduationId: string, newStatus: GraduationStatus): Observable<IGraduation> {
        return this.api.teams.updateGraduation(graduationId, newStatus);
    }

    public getGraduation(graduationId: string): Observable<IGraduation> {
        return this.api.teams.getGraduationById(graduationId);
    }

    public deleteTeam(teamId: string, reason: string): Observable<any> {
        let checksum = md5(teamId);
        return this.api.teams.deleteTeam(teamId, checksum, reason).pipe(share());
    }

    public migrateToCrm(teamId: string): Observable<IMigrationStatus> {
        return this.api.teams.beginTeamCrmMigration(teamId);
    }

    public getMigrationStatus(teamId: string, status: IMigrationStatus): Observable<IMigrationStatus> {
        return this.api.teams.getTeamCrmMigrationStatus(teamId, status.migrationTaskId);
    }
}
