import { NgModule } from '@angular/core';
import { CoreModulesModule } from '../../../core-modules.module';
import { AuthOkComponent } from './authok.component';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';

let authOkState = { name: RouterStates.authok, url: '/authok?state&code', component: AuthOkComponent };

@NgModule({
  declarations: [
      AuthOkComponent
  ],
  imports: [
    CoreModulesModule,
    UIRouterModule.forChild({
      states: [authOkState]
    })
  ],
  providers: [
  ],
  bootstrap: []
})
export class AuthOkModule { }
