import { Component } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { DashboardSidebarService } from '../../../core/dashboard-sidebar.service';

@Component({
    templateUrl: './tasks.component.html'
})
export class TasksComponent {

    constructor(private stateService: StateService,
                private dashboardSidebarService: DashboardSidebarService) {

    }

}
