import { NgModule } from '@angular/core';
import { SelectTemplateComponent } from './select-template.component';
import { CoreComponentsModule } from '../../../../core-components.module';

@NgModule({
  declarations: [
    SelectTemplateComponent
  ],
  imports: [
    CoreComponentsModule
  ],
  exports: [
    SelectTemplateComponent
  ],
  providers: [
  ],
  bootstrap: [
    SelectTemplateComponent
  ]
})
export class SelectTemplateModule { }
