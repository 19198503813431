import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { TeamSetupComponent } from './team-setup.component';
import { TeamSetupsModule } from './team-setups/team-setups.module';
import { ViewSetupModule } from './view-setup/view-setup.module';

let teamSetupState = { name: RouterStates.dashboard_teamSetup, component: TeamSetupComponent };

@NgModule({
  declarations: [
    TeamSetupComponent
  ],
  imports: [
    CoreComponentsModule,
    TeamSetupsModule,
    ViewSetupModule,
    UIRouterModule.forChild({
      states: [teamSetupState]
    })
  ],
  exports: [
    
  ],
  providers: [
    // TeamService
  ],
  bootstrap: []
})
export class TeamSetupModule { }

