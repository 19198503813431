
export const environment = {
  mode: 'sandbox',
  forceSsl: true,
  apiBase: 'https://adminapi.teams.sandbox.splyza.com/',
  apiBaseStaging: 'https://staging.adminapi.teams.sandbox.splyza.com',
  frontendUrl: 'https://teams.sandbox.splyza.com/',
  susAuthority: 'https://id.sandbox.splyza.com',
  susClientId: 'splyza-dashboard-web'
};
