import { Component, OnInit } from '@angular/core';
import { RouterStates } from '../../core/router-states.constant';
import { StateService } from '@uirouter/angular';
import { AuthenticationService } from 'app/core/authentication.service';

@Component({
    templateUrl: './dashboard-main.component.html'
})
export class DashboardMainComponent implements OnInit {

    constructor(private authService: AuthenticationService, private stateService: StateService) {

    }

    // public onLogoutClick() {
    //     return;
    // }

    public ngOnInit() {
        this.authService
            .isAuthenticated()
            .subscribe(a => {
                if (a) {
                    return;
                }
                this.stateService.go(RouterStates.welcome);
            });
    }
}
