import { NgModule } from '@angular/core';
import { TeamStatsComponent } from './team-stats.component';
import { CoreComponentsModule } from '../../../../../core-components.module';

@NgModule({
  declarations: [
      TeamStatsComponent
  ],
  imports: [
    CoreComponentsModule
  ],
  providers: [
  ],
  exports: [
    TeamStatsComponent
  ],
  bootstrap: [
      TeamStatsComponent
  ]
})
export class TeamStatsModule {}

