import { NgModule } from '@angular/core';
import { SubheaderComponent } from './subheader.component';
import { CoreModulesModule } from '../../core-modules.module';


@NgModule({
  declarations: [
      SubheaderComponent
  ],
  imports: [
      CoreModulesModule
  ],
  exports: [
      SubheaderComponent
  ],
  providers: [
  ],
  bootstrap: [
      SubheaderComponent
  ]
})
export class SubheaderModule { }
