import { Component, OnInit } from '@angular/core';
import { StateService, TransitionService, Transition } from '@uirouter/angular';
import { take } from 'rxjs/operators';
import { Observable, BehaviorSubject } from 'rxjs';
import { DashboardApiService } from 'app/api/dashboard-api.service';
import { IEmailTemplate } from 'app/api/email/email-template.model';
import { TemplateListService } from './template-list.service';
import { RouterStates } from 'app/core/router-states.constant';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton } from 'app/core/dialog.service';
import * as _ from 'underscore';

@Component({
    templateUrl: './template-list.component.html',
    styleUrls: ['./template-list.component.scss']
})
export class TemplateListComponent implements OnInit {

    public get loadingData(): boolean { return this.templateListService.loadingData; }
    public templatesDataSource: BehaviorSubject<IEmailTemplate[]>;
    public displayedTemplateColumns = ['name', 'updated', 'languages', 'buttons'];
    public isSaving = false;

    constructor(
        private api: DashboardApiService,
        private stateService: StateService,
        private transitionService: TransitionService,
        private templateListService: TemplateListService,
        private dialogService: DialogService) {
        
        this.templatesDataSource = new BehaviorSubject<IEmailTemplate[]>([]);
    }
    
    public ngOnInit(): void {
        this.templateListService.refresh()
            .pipe(take(1))
            .subscribe(v => {
                this.templatesDataSource.next(v.items);
            });
    }

    public viewTemplate(template: IEmailTemplate) {
        this.stateService.go(RouterStates.dashboard_emailTemplates_view, { templateId: template.id, templateName: template.name });
    }

    public createTemplate() {
        this.stateService.go(RouterStates.dashboard_emailTemplates_new);
    }

    public deleteTemplate(template: IEmailTemplate) {
        this.dialogService.showDialog(
            new DialogCaption('EMAIL_TEMPLATE_LIST.TEMPLATE_CONFIRM_DELETE', true, { name: template.name }),
            new DialogYesButton(() => {
                this.deleteTemplateImpl(template);
            }),
            new DialogNoButton()
        );
    }

    private deleteTemplateImpl(template: IEmailTemplate) {
        this.isSaving = true;
        this.api.emailTemplates.deleteTemplateById(template.id)
            .subscribe(r => {
                this.templatesDataSource.next(_.filter(this.templatesDataSource.value, t => t.id !== template.id));
            },
            err => { /**/ },
            () => {
                this.isSaving = false;
             });
    }
}
