import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule, Ng2StateDeclaration } from '@uirouter/angular';
import { OperationTemplatesComponent } from './operation-templates.component';
import { CdkTableModule } from '@angular/cdk/table';

let operationTemplatesState: Ng2StateDeclaration = {
   name: RouterStates.dashboard_operation_templates,
   url: '/operation-templates',
   component: OperationTemplatesComponent
  };

@NgModule({
  declarations: [
    OperationTemplatesComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [operationTemplatesState]
    }),
    CdkTableModule
  ],
  exports: [
    
  ],
  providers: [
  ],
  bootstrap: []
})
export class OperationTemplatesModule { }

