import { Component, ElementRef, EventEmitter, Input, Output, ViewChild } from '@angular/core';

@Component({
    templateUrl: './file-uploader.component.html',
    // styleUrls: ['./file-uploader.component.scss'],
    selector: 'file-uploader'
})
export class FileUploaderComponent {

    @ViewChild('uploader', { read: ElementRef, static: true }) uploaderElement: ElementRef;
    @ViewChild('myButtonText', { read: ElementRef, static: true }) public myButtonText: ElementRef;
    @Input() public accept: string;
    @Input() public multiple = false;
    @Input() public files: File[];
    @Input() public styles: any;
    @Output() public filesChange = new EventEmitter<File[]>();
    @Output() public onSelect = new EventEmitter();

    public get hasContent() { return this.myButtonText != null && this.myButtonText.nativeElement != null && this.myButtonText.nativeElement.childNodes.length > 0; }

    constructor() {
    }


    public uploadFile() {
        this.uploaderElement.nativeElement.click();
    }

    public onFileSelected(event: Event) {

        let files = <File[]>((<any>event.target).files);
        if (files == null || files == undefined) {
            files = [];
        }
        //this.files = files;
        this.filesChange.emit(files);
        this.onSelect.emit();

    }
}