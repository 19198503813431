import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output }from '@angular/core';
import { ITask } from '../../../../../api/tasks/task.model';
import { MatTableDataSource } from '@angular/material';
import { ITaskRun } from '../../../../../api/tasks/task-run.model';
import * as _ from 'underscore';
import * as moment from 'moment';

@Component({
    templateUrl: './task-runs.component.html',
    styleUrls: ['./task-runs.component.scss'],
    selector: 'task-runs'
})
export class TaskRunsComponent implements OnChanges {

    public dataSource: MatTableDataSource<ITaskRun> = new MatTableDataSource<ITaskRun>();
    public displayedColumns = ['id', 'start', 'end'];
    @Input() public task: ITask;
    @Output('viewRun') public doViewRun = new EventEmitter<ITaskRun>();

    constructor() {
        return;
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.task != null  && changes.task.currentValue !== changes.task.previousValue ) {
            if (this.task.runs != null && this.task.runs.items != null) {
                this.dataSource.data = _.sortBy(this.task.runs.items, i => -moment(i.start).unix());
            }
            else {
                this.dataSource.data = [];
            }
        }
    }

    public viewRun(taskRun: ITaskRun) {
        if (this.doViewRun != null) {
            this.doViewRun.emit(taskRun);
        }
    }
}
