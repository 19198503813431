import { NgModule } from '@angular/core';
import { CoreModulesModule } from '../../core-modules.module';

import { HeaderComponent } from './header.component';
import { MatSlideToggleModule } from '@angular/material';
import { SpRoleModule } from '../sp-role/sp-role.module';

@NgModule({
  declarations: [
      HeaderComponent
  ],
  imports: [
      CoreModulesModule,
      MatSlideToggleModule,
      SpRoleModule
  ],
  exports: [
      HeaderComponent
  ],
  providers: [
  ],
  bootstrap: [
      HeaderComponent
  ]
})
export class HeaderModule { }
