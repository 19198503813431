import { Injectable, EventEmitter } from '@angular/core';
import { Observable } from 'rxjs';
import { DashboardApiService } from '../api/dashboard-api.service';
import { IAuthUser } from '../api/auth/auth-user.model';
import * as _ from 'underscore';
import { map } from 'rxjs/operators';

@Injectable()
export class UserService {

    public user: IAuthUser = null;
    public userChanged = new EventEmitter<IAuthUser>();

    constructor (private api: DashboardApiService) {
        this.updateUserData()
            .subscribe();
    }

    public updateUserData(): Observable<IAuthUser> {
        let self = this;
        return this.api.auth.getSelf()
            .pipe(
                map(u => {
                    this.user = u;
                    this.userChanged.emit(u);
                    return u;
                })
            );
    }

    public hasRole(role: string): boolean {
        if (this.user == null || this.user.roles == null) {
            return false;
        }
        return _.findIndex(this.user.roles, r => r === role || r.toLowerCase() === role.toLowerCase()) !== -1;
    }
}
