import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { TeamsComponent } from './teams.component';
import { CdkTableModule } from '@angular/cdk/table';
import { TeamsService } from './teams.service';

let teamsState = {
    name: RouterStates.dashboard_teams,
    url: '/teams?q&page&size&sort&direction',
    component: TeamsComponent,
    params: {
      q: {
        type: 'string',
        dynamic: true
      },
      page: {
        type: 'int',
        dynamic: true
      },
      size: {
        type: 'int',
        dynamic: true
      },
      sort: {
        type: 'string',
        dynamic: true
      },
      direction: {
        type: 'string',
        dynamic: true
      }
    }
  };

@NgModule({
  declarations: [
    TeamsComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [teamsState]
    }),
    CdkTableModule
  ],
  providers: [
    TeamsService
  ],
  bootstrap: [
  ]
})
export class TeamsModule {}

