import { ApiHttp } from '../../core/api-http';
import { Observable } from 'rxjs';
import { ICollection } from '../common/collection.model';
import { SubscriptionPlanRequest } from './subscription-plan-request.model';
import { ISubscriptionPlan } from './subscription-plan.model';

export class PlansResource {

    constructor(private http: ApiHttp) {

    }

    public getPlans(): Observable<ICollection<ISubscriptionPlan>> {
        return this.http.getTyped<ICollection<ISubscriptionPlan>>('/v1/plans');
    }

    public createPlan(plan: SubscriptionPlanRequest): Observable<ISubscriptionPlan> {
        return this.http.postTyped<ISubscriptionPlan>('/v1/plans', JSON.stringify(plan));
    }

    public updatePlan(planId: string, plan: SubscriptionPlanRequest): Observable<ISubscriptionPlan> {
        let url = '/v1/plans/:planId';
        url = url.replace(':planId', planId);
        return this.http.patchTyped<ISubscriptionPlan>(url, JSON.stringify(plan));
    }

    public setPlan(planId: string, plan: SubscriptionPlanRequest): Observable<ISubscriptionPlan> {
        let url = '/v1/plans/:planId';
        url = url.replace(':planId', planId);
        return this.http.putTyped<ISubscriptionPlan>(url, JSON.stringify(plan));
    }

    public deletePlan(planId: string): Observable<any> {
        let url = '/v1/plans/:planId';
        url = url.replace(':planId', planId);
        return this.http.deleteTyped<any>(url);
    }
}
