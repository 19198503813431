import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { OrganizationsComponent } from './organizations.component';
import { OrganizationModule } from './organization/organization.module';
import { ListOrganizationsModule } from './list-organizations/list-organizations.module';

let organizationsState = { 
  name: RouterStates.dashboard_organizations,
  component: OrganizationsComponent 
};

@NgModule({
  declarations: [
    OrganizationsComponent
  ],
  imports: [
    CoreComponentsModule,
    ListOrganizationsModule,
    OrganizationModule,
    UIRouterModule.forChild({
      states: [organizationsState]
    })
  ],
  exports: [
    
  ],
  providers: [
      
  ],
  bootstrap: []
})
export class OrganizationsModule { }
