import { Component, Input }from '@angular/core';
import { ITask } from '../../../../../api/tasks/task.model';
import { ITaskParameter } from '../../../../../api/tasks/task-parameter.model';
import { MatDialog } from '@angular/material';
import { TaskParameterType } from '../../../../../api/tasks/task-parameter-type.enum';
import { JsonViewerComponent } from '../../../../../components/json-viewer/json-viewer.component';

@Component({
    templateUrl: './task-parameters.component.html',
    styleUrls: ['./task-parameters.component.scss'],
    selector: 'task-parameters'
})
export class TaskParametersComponent {

    @Input() public parameters: ITaskParameter[];

    constructor(private matDialog: MatDialog) {

    }

    public viewComplexParameter(parameter: ITaskParameter) {
        if (parameter == null || parameter.type !== TaskParameterType.Complex) {
            return;
        }

        this.matDialog.open(JsonViewerComponent, {
            width: '800px',
            height: '600px',
            data: { 
                json: JSON.stringify(parameter.value),
                captionKey: 'VIEW_TASK.PARAMETERS_VIEW_CAPTION',
                captionParams: {
                    name: parameter.name
                }
            }
        });
    }

}
