
import {finalize} from 'rxjs/operators';
import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChildren, ElementRef, ViewChild } from '@angular/core';
import { ITeam } from '../../../../../../api/teams/team.model';
import { ITeamPlan } from '../../../../../../api/teams/team-plan.model';
import { TeamPlanRequest } from '../../../../../../api/teams/team-plan-request.model';
import { deepCopy } from '../../../../../../core/deep-copy.function';
import { PlanService } from '../../../../../../core/plan.service';
import { ISubscriptionPlan } from '../../../../../../api/plans/subscription-plan.model';
import { TeamService } from '../../../team.service';
import { TranslateService } from '@ngx-translate/core';
import * as _ from 'underscore';
import { Subscription, Observable } from 'rxjs';
import { IPlanFeature } from '../../../../../../api/plans/plan-feature.model';
import { PlanFeatureRequest } from '../../../../../../api/plans/plan-feature.request.model';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton } from '../../../../../../core/dialog.service';
import { MatCheckboxChange } from '@angular/material';
import { MediaOptionComponent } from 'app/components/media-option/media-option.component';
import { QualitySetsService } from 'app/core/quality-sets.service';
import { IMediaQualitySet } from 'app/api/media/media-quality-set.model';
import { UserService } from 'app/core/user.service';
import { getEnumValuesTyped } from 'app/core/enum-values.function';
import { Codecs } from 'app/api/plans/codecs.enum';
import { OptionChecked } from 'app/core/value-checked.class';
import { SelectOption } from 'app/core/select-option.class';
import { TranscodeProfileService } from 'app/core/transcode-profile.service';


class EditFeature {
    public key: string;
    public feature: IPlanFeature;
    public enabled: boolean;
}

@Component({
    templateUrl: './basic-team-plan.component.html',
    styleUrls: ['./basic-team-plan.component.scss'],
    selector: 'basic-team-plan'
})
export class BasicTeamPlanComponent implements OnInit, OnDestroy {

    @Input() public isEditing: boolean;
    @Output() public isEditingChange = new EventEmitter();    
    @ViewChildren('optionInput') public optionInputChildren: ElementRef[];
    @ViewChild(MediaOptionComponent, {static: false}) public mediaOptionComponent !: MediaOptionComponent;

    public teamPlan: ITeamPlan;
    public isSaving = false;
    public fromSubscriptionPlan: ISubscriptionPlan = null;
    public get plans(): ISubscriptionPlan[] { return (this.planService.plans && this.planService.plans.items) ? this.planService.plans.items : []; }
    public get team(): ITeam { return this.teamService != null ? this.teamService.team : null; }
    public planFeatureNames: {[id: string]: string } = {};
    public get qualitySetsReady(): boolean { return !this.qualitySetsService.loadingSets && this.qualitySetsService.qualitySets == null; }
    public get qualitySet(): IMediaQualitySet { return this.team != null && this.team.plan != null && this.qualitySetsService.qualitySets != null && _.find(this.qualitySetsService.qualitySets, s => s.id === this.team.plan.mediaSetId); }
    public get teamObservable(): Observable<ITeam> { return this.teamService.teamSubject; }
    public codecOptions: OptionChecked<Codecs>[];
    public profileOptions: SelectOption<string>[];
    public get isUserLimited(): boolean { return this.checkIsUserLimited(); }

    private historicPlanFeatureKeys = [];
    private planFeatureKeys = ['hd-download', 'motion'];
    private availableFeatureKeys: string[];
    private editFeatures: EditFeature[];
    private subscriptions: Subscription[] = [];

    constructor (
        private planService: PlanService,
        private teamService: TeamService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private qualitySetsService: QualitySetsService,
        private userService: UserService,
        private profileService: TranscodeProfileService) {
    }

    public checkIsUserLimited(): boolean {
        if (this.team == null) {
            return true;
        }

        if (this.userService.hasRole('Administrator')) {
            return false;
        }

        return this.team.isCrmManaged;
    }

    public ngOnInit(): void {
        this.translatePlanFeatures();        
        this.subscriptions.push(this.teamService.teamSubject.subscribe(team => this.loadedTeam(team)));
        this.subscriptions.push(this.qualitySetsService.loadSetsIfEmpty().subscribe());
        if (this.team) {
            this.loadedTeam(this.team);
        }

    }

    public ngOnDestroy(): void {
        for (let subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    public toggleEditing() {
        if (!this.isEditing) {
            this.teamPlan = deepCopy(this.team.plan);
            this.editFeatures = _.map(this.availableFeatureKeys, key => {
                let feature = this.team.plan.features.find(tp => tp.key === key);
                return {
                    key: key,
                    feature: feature || this.createNewFeature(key),
                    enabled: feature != null && feature !== undefined
                } as EditFeature;      
            });
            this.fromSubscriptionPlan = null;
            this.codecOptions = this.buildCodecOptions(this.team.plan.codecs == null ? [] : this.team.plan.codecs);
            this.profileOptions = this.buildProfileOptions(this.team.plan.transcodeProfile);
            
            this.setEditing(true);
        }
        else {
            this.save();
        }
    }

    public cancelEditing() {
        // TODO: Add confirmation dialog
            this.setEditing(false);
    }

    public onSelectPlan() {
        if (!this.fromSubscriptionPlan) {
            return;
        }
        this.teamPlan.memberLimit = Number(this.fromSubscriptionPlan.memberLimit);
        this.teamPlan.readOnlyMemberLimit = Number(this.fromSubscriptionPlan.readOnlyMemberLimit);
    }

    public getFeatureName(key: string): string {
        let name = this.planFeatureNames[key];
        if (!name || name.length === 0) {
            return key;
        }
        return name;
    }

    public onTrialChange(event: MatCheckboxChange) {
        if (event.checked === true) {            
            this.dialogService.showDialog(
                new DialogCaption('TEAM_VIEW.MANAGEMENT.MARK_TRIAL_CONFIRMATION'),
                new DialogYesButton(null),
                new DialogNoButton(() => this.teamPlan.isTrial = false, true)
            );
        }
    }

    private createNewFeature(key: string): IPlanFeature {
        return { key: key };
    }

    private loadedTeam(team: ITeam) {
        this.availableFeatureKeys = this.getFeatureKeys(team);
        this.team.plan.features = _.sortBy(this.team.plan.features, f => this.availableFeatureKeys.indexOf(f.key));
    }

    private translatePlanFeatures() {
        let self = this;
        for (let key of _.unique(this.planFeatureKeys.concat(this.historicPlanFeatureKeys))) {
            let translateKey = key.split('-').join('_').toUpperCase();
            translateKey = 'SHARED.PLAN_FEATURES.' + translateKey;
            let translationSubscription = this.translateService.get(translateKey)
                .subscribe(
                    t => { self.planFeatureNames[key] = t; },
                    e => { console.error('Could not translate plan feature: ', e); self.planFeatureNames[key] = key; }
                );
            this.subscriptions.push(translationSubscription);
        }
    }

    private buildCodecOptions(selectedCodecs: Codecs[]): OptionChecked<Codecs>[] {

        let result: OptionChecked<Codecs>[] = [];
        for (let codec of getEnumValuesTyped<Codecs>(Codecs)) {
            let idx = selectedCodecs.indexOf(codec);
            let option = new OptionChecked<Codecs>(codec);
            option.checked = idx !== -1;
            option.disabled = codec === Codecs.H264;
            result.push(option);
        }
        return result;

    }

    private buildProfileOptions(currentProfile: string): SelectOption<string>[] {
        
        let availableTranscodeProfiles = _.chain(this.profileService.profiles).filter(p => p.name !== 'default').map(p => p.name).value();
        availableTranscodeProfiles.splice(0, 0, 'default');

        let currentIsUnavailable = false;
        if (currentProfile !== '' && availableTranscodeProfiles.indexOf(currentProfile) === -1) {
            currentIsUnavailable = true;
            availableTranscodeProfiles.splice(0, 0, currentProfile);
        }

        let profileOptions: SelectOption<string>[] = [];
        for (let profile of _.sortBy(availableTranscodeProfiles, p => p)) {
            let option = new SelectOption<string>(profile);
            if (profile === currentProfile && currentIsUnavailable) {
                option.disabled = true;
            }
            profileOptions.push(option);
        }
        return profileOptions;

    }

    private getFeatureKeys(team: ITeam): string [] {
        let teamKeys = [];
        if (team && team.plan && team.plan.features) {
            teamKeys = _.map(team.plan.features, f => f.key);
        }
        let keys = _.unique(this.planFeatureKeys.concat(teamKeys));
        return keys;
    }

    private getFeaturesToSave(): IPlanFeature[] {
        let currentFeatureKeys = _.map(this.team.plan.features, f => f.key);
        let enabledFeatures = _.filter(this.editFeatures, f => f.enabled === true);
        
        if (currentFeatureKeys.length !== enabledFeatures.length
            || _.chain(enabledFeatures).map(f => f.key).zip(currentFeatureKeys).any(z => z[0] !== z[1]).value()) {
            
            return _.map(enabledFeatures, f => f.feature);
        }
        return null;
    }

    private getRequestFeature(feature: IPlanFeature): PlanFeatureRequest {
        return { key: feature.key };
    }

    private save() {
        let hasChanges = false;

        let request = new TeamPlanRequest();
        if (this.team.plan.memberLimit !== this.teamPlan.memberLimit) {
            request.memberLimit = this.teamPlan.memberLimit;
            hasChanges = true;
        }

        if (this.team.plan.readOnlyMemberLimit !== this.teamPlan.readOnlyMemberLimit) {
            request.readOnlyMemberLimit = this.teamPlan.readOnlyMemberLimit;
            hasChanges = true;
        }

        if (this.team.plan.isTrial !== this.teamPlan.isTrial) {
            request.isTrial = this.teamPlan.isTrial;
            hasChanges = true;
        }

        let selectedCodecs = _.chain(this.codecOptions).filter(o => o.checked).sortBy(o => o.option).map(o => o.option).value();
        let currentCodecs = _.sortBy(this.teamPlan.codecs, o => o);
        if (selectedCodecs.length !== currentCodecs.length || _.any(_.zip(selectedCodecs, currentCodecs), o => o[0] !== o[1])) {
            request.codecs = selectedCodecs;
            hasChanges = true;
        }

        if (this.team.plan.transcodeProfile !== this.teamPlan.transcodeProfile) {
            request.transcodeProfile = this.teamPlan.transcodeProfile;
            hasChanges = true;
        }

        let planFeatures = this.getFeaturesToSave();
        if (planFeatures !== null) {
            hasChanges = true;
            request.features = _.map(planFeatures, f => this.getRequestFeature(f));
        }

        if (this.mediaOptionComponent != null && this.mediaOptionComponent.hasChanges()) {
            hasChanges = true;
            request.mediaOptions = this.mediaOptionComponent.optionsToSave;
        }      

        if (hasChanges) {
            this.isSaving = true;
            // todo: move to service
            this.teamService.patchTeamPlan(this.team.id, request).pipe(
                finalize(() => { this.isSaving = false; }))
                .subscribe(
                    updatedPlan => {
                        if (hasChanges) {
                            this.availableFeatureKeys = this.getFeatureKeys(this.team);
                        }
                        this.setEditing(false); 
                    },
                    error => {
                        console.error('error', error);
                    },
                    () => {
                        this.isSaving = false;
                    }
                );
        }
        else {
            this.setEditing(false);
        }
    }

    private setEditing(editing: boolean) {
        this.isEditing = editing;
        this.isEditingChange.emit(this.isEditing);
    }
}
