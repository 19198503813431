
import {of as observableOf,  Observable } from 'rxjs';
import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, Inject, Optional, ViewChildren, QueryList } from '@angular/core';

import { IOperationTemplateBase } from '../../../../../api/operations/operation-template.model';
import { OperationTemplateRequestBase, OperationTemplateRequest, OperationTemplateSetRequest } from '../../../../../api/operations/operation-template-request.model';
import { DashboardApiService } from '../../../../../api/dashboard-api.service';


import { OperationTemplateTypes } from '../../../../../api/operations/operation-template-types.enum';
import { MAT_DIALOG_DATA, MatDialogRef, MatDialog } from '@angular/material';
import { StateService } from '@uirouter/angular';
import { RouterStates } from '../../../../../core/router-states.constant';
import { ITemplateComponent } from '../template-component-interface';
import { PlanPeriod } from '../../../../../api/plans/plan-period.model';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton } from '../../../../../core/dialog.service';
import { EditTemplateShared } from '../edit-template-shared.service';
import { take } from 'rxjs/operators';

@Component({
    templateUrl: './view-template.component.html',
    styleUrls: ['./view-template.component.scss'],
    selector: 'view-template'
})
export class ViewTemplateComponent implements OnChanges {
    
    @Input() public shared: EditTemplateShared;
    @Input() public operation: IOperationTemplateBase;
    @Output() public operationChange = new EventEmitter();
    @Input() public editOperation: OperationTemplateRequestBase;
    @Output() public editOperationChange = new EventEmitter();
    @Input() public newType: OperationTemplateTypes;
    public isModal = false;

    public get operationType(): string { return (this.operation || this.editOperation || {'type': ''}).type || ''; }
    
    @ViewChildren('templateView') private children: QueryList<ITemplateComponent>;
    private get templateComponent(): ITemplateComponent { return this.children.length > 0 ? this.children.first : null; }

    constructor (private api: DashboardApiService,
                private stateService: StateService,
                private mdDialog: MatDialog,
                private dialogService: DialogService,
                @Optional() private mdDialogRef: MatDialogRef<ViewTemplateComponent>,
                @Optional() @Inject(MAT_DIALOG_DATA) data: any) {
        // data will be injected if this component is being used in a "view template" modal capacity.
        if (data && data.operation) {
            this.shared = new EditTemplateShared();
            this.isModal = true;
            this.operation = data.operation;
            this.shared.isEditing = false;
        }
    }
    public ngOnChanges(changes: SimpleChanges) {
        if (changes.shared && changes.shared.currentValue.isNew) {
            setTimeout(() => this.toggleEditing(), 10);
        }
    }

    public toggleEditing() {

        if (!this.shared.isEditing) {
            if (this.shared.isNew) {
                this.editOperation = this.generateNewEditTemplate(this.newType);
            }
            else {
                this.editOperation = OperationTemplateRequestBase.ConvertOperationTemplateToRequest(this.operation);
            }
            this.editOperationChange.emit(this.editOperation);
            this.setEditing(true);
        }
        else {
            this.shared.isSaving = true;
            this.save()
                .subscribe(
                    r => {
                        if (r) {
                            this.setEditing(false);
                            if (this.shared.isNew) {
                                this.shared.isNew = false;
                                this.stateService.go(RouterStates.dashboard_operation_template_view, { operationIdentifier: this.operation.identifier.key });
                            }
                            this.operationChange.emit(this.operation);
                            this.shared.operationChanged.emit(this.operation);
                        }
                    },
                    err => console.error('unable to save template', err),
                    () => this.shared.isSaving = false
                );
        }
        
    }

    public restoreVersion() {
        this.dialogService.showDialog(
            new DialogCaption('EDIT_OPERATION_TEMPLATE.CONFIRM_RESTORE_VERSION'),
            new DialogYesButton(() => this.doRestoreVersion()),
            new DialogNoButton()
        );
    }

    public cancelEditing() {
        if (this.shared.isEditing) {
            this.setEditing(false);
        }
    }

    public openTemplate() {
        
        this.stateService.go(RouterStates.dashboard_operation_template_view, {
            operationIdentifier: this.operation.identifier.key,
            v: this.operation.identifier.version            
         });

        if (this.mdDialogRef != null && this.mdDialogRef !== undefined) {
            this.mdDialogRef.close();
            this.mdDialog.closeAll();
        }

    }

    public closeDialog() {

        if (this.mdDialogRef != null && this.mdDialogRef !== undefined) {
            this.mdDialogRef.close();
        }

    }

    public showTemplateModal(template: IOperationTemplateBase): MatDialogRef<ViewTemplateComponent>  {
        return this.mdDialog.open(ViewTemplateComponent, { 
            width: template.type === OperationTemplateTypes.Template ? '550px' : '900px',
            data: {
                operation: template
            }
        });
    }

    private doRestoreVersion() {
        this.api.operationTemplates 
            .updateOperationTemplate(this.operation.identifier.key,
            OperationTemplateRequestBase.ConvertOperationTemplateToRequest(this.operation),
            true
        ).subscribe(o => {
            this.operation = o;
            this.operationChange.emit(o);
            this.stateService.go(RouterStates.dashboard_operation_template_view, { operationIdentifier: o.identifier.key, v: null });
        },
        err => console.error('Error restoring version: ', err));
    }

    private setEditing(isEditing: boolean) {
        this.shared.isEditing = isEditing;
    }

    private save(): Observable<boolean> {
        if (this.templateComponent == null) {
            return observableOf<boolean>(false).pipe(take(1));
        }
        else {
            return this.templateComponent.save();
        }
    }

    private generateNewEditTemplate(type: OperationTemplateTypes): OperationTemplateRequestBase {
        if (type === OperationTemplateTypes.Template) {
            return new OperationTemplateRequest('', PlanPeriod.Default(), 1, null, null);
        }
        else if (type === OperationTemplateTypes.TemplateSet) {
            return new OperationTemplateSetRequest('', [], []);
        }
        else {
            throw 'Plan type not implemented in generateNewEditTemplate()';
        }
    }
}
