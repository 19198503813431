import { Component, OnInit, Inject } from '@angular/core';
import { OperationTemplateTypes } from '../../../../api/operations/operation-template-types.enum';
import { MatDialogRef, MAT_DIALOG_DATA, MatTableDataSource } from '@angular/material';
import * as _ from 'underscore';
import { IOperationTemplateBase, IOperationTemplate } from '../../../../api/operations/operation-template.model';
import { OperationTemplateService } from '../../../../core/operation-template.service';

@Component({
    templateUrl: './select-template.component.html',
    styleUrls: ['./select-template.component.scss'],
    host: {
        'class': 'flex-component'
    }
})
export class SelectTemplateComponent implements OnInit {

    public get isLoading(): boolean { return this.templateService.loadingData; }
    public dataSource: MatTableDataSource<IOperationTemplate> = new MatTableDataSource<IOperationTemplate>();
    public displayedTemplateColumns: string[] = ['name', 'updated', 'type', 'offset', 'repetition', 'buttons'];

    constructor (private templateService: OperationTemplateService,
                private mdDialogRef: MatDialogRef<SelectTemplateComponent>,
                @Inject(MAT_DIALOG_DATA) private data: any) {
    }

    public ngOnInit(): void {
        this.templateService.loadTemplatesIfEmpty()
            .subscribe(
                () => {
                    let existingIds = (this.data.existingIds || []) as string[];
                    if (existingIds.length > 0) {
                        console.log(this.templateService.templates);
                        this.dataSource.data = _.filter(this.templateService.templates, d => !_.contains(existingIds, d.identifier.key));
                    }
                    else {
                        this.dataSource.data = this.templateService.templates;
                    }
                    console.log('a');
                },
                err => console.error('Unable to load templates: ', err)
            );
    }

    public selectTemplate(row: IOperationTemplateBase) {
        this.mdDialogRef.close({ template:  row });
    }
}
