import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { ViewThreadComponent } from './view-thread.component';
import { MatSnackBarModule } from '@angular/material';
import { UndeleteProgressComponent } from './undelete-progress/undelete-progress.component';
import { ViewTaskModule } from '../../tasks/view-task/view-task.module';
import { TasksModule } from 'app/components/tasks/tasks.module';

let viewThreadState = { name: RouterStates.dashboard_thread_view, url: '/threads/:threadId', params: { title: '' }, component: ViewThreadComponent };

@NgModule({
  declarations: [
    ViewThreadComponent,
    UndeleteProgressComponent
  ],
  imports: [
    CoreComponentsModule,
    MatSnackBarModule,
    TasksModule,
    UIRouterModule.forChild({
      states: [viewThreadState]
    })
  ],
  providers: [
    ViewThreadComponent
  ],
  bootstrap: [
    UndeleteProgressComponent
  ]
})
export class ViewThreadModule {}

