import { NgModule } from '@angular/core';
import { CoreModulesModule } from '../../core-modules.module';
import { LinkableRefComponent } from './linkable-ref.component';

@NgModule({
  declarations: [
    LinkableRefComponent
  ],
  imports: [
      CoreModulesModule
  ],
  exports: [
    LinkableRefComponent
  ],
  providers: [
  ],
  bootstrap: [
    LinkableRefComponent
  ]
})
export class LinkableRefModule { }
