import { Injectable } from '@angular/core';


@Injectable()
export class DashboardSidebarService {
    
    public get teamName(): string { return this._teamName; }
    public get userName(): string { return this._userName; }
    public get organizationName(): string { return this._organizationName; }
    public get threadTitle(): string { return this._threadTitle; }
    public get teamTokenName(): string { return this._teamTokenName; }
    public get operationTemplateName(): string { return this._operationTemplateName; }
    public get emailTemplateName(): string { return this._emailTemplateName; }
    
    private _teamName: string;    
    private _userName: string;
    private _organizationName: string;
    private _threadTitle: string;
    private _teamTokenName: string;
    private _operationTemplateName: string;
    private _emailTemplateName: string;
    
    public setTeamName(teamName: string) {
        
        this._teamName = teamName;
    }

    public setUserName(userName: string) {
        this._userName = userName;
    }

    public setOrganizationName(organizationName: string) {
        this._organizationName = organizationName;
    }

    public setThreadTitle(threadTitle: string) {
        this._threadTitle = threadTitle;
    }

    public setTeamTokenName(teamName: string) {
        this._teamTokenName = teamName;
    }

    public setOperationTemplateName(templateName: string) {
        this._operationTemplateName = templateName;
    }

    public setEmailTemplateName(templateName: string) {
        this._emailTemplateName = templateName;
    }
}
