import { Component, EventEmitter } from '@angular/core';
import * as _ from 'underscore';
import { deepCopy } from '../../../../../../core/deep-copy.function';
import { OrganizationService } from '../../organization.service';
import { IOrganization } from '../../../../../../api/organizations/organization.model';

@Component({
    templateUrl: './organization-basic.component.html',
    styleUrls: ['./organization-basic.component.scss'],
    selector: 'organization-basic'
})
export class OrganizationBasicComponent {

    public get organization(): IOrganization { return this.organizationService.organization; }
    
    constructor(
        private organizationService: OrganizationService) {
        
    }
}
