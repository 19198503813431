import { Component, OnInit } from '@angular/core';
import { DashboardApiService } from '../../../../api/dashboard-api.service';
import { StateService } from '@uirouter/angular';
import { ITeamRequestForm } from '../../../../api/teams/team-request-form.model';
import { ITeam } from '../../../../api/teams/team.model';
import { RouterStates } from '../../../../core/router-states.constant';
import { IUser } from '../../../../api/users/user.model';
import { TranslateService } from '@ngx-translate/core';
import { finalize } from 'rxjs/operators';


@Component({
    templateUrl: './view-request.component.html',
    styleUrls: ['./view-request.component.scss']
})
export class ViewRequestComponent implements OnInit {

    public request: ITeamRequestForm = null;
    public get requestId(): string { return this.request != null ? this.request.id : (this.stateService.params.requestId != null ? this.stateService.params.requestId : ''); }
    public loading = true;  

    public get fee(): string {
        if (this.request == null) {
            return '';
        }

        let total = this.request.price || 0;
        let parts: string[] = [];
        if (this.request.initialFee != null && this.request.initialFee > 0) {
            total += this.request.initialFee;
            parts.push(this.translateService.instant('VIEW_TEAM_REQUEST.PAYMENT_AMOUNT_INITIAL_FEE', { fee: this.formatNumber(this.request.initialFee) }));
        }

        if (this.request.supportFee != null && this.request.supportFee > 0) {
            total += this.request.supportFee;
            parts.push(this.translateService.instant('VIEW_TEAM_REQUEST.PAYMENT_AMOUNT_SUPPORT_FEE', { fee: this.formatNumber(this.request.supportFee) }));
        }

        let compiledParts = '';
        for (let part of parts) {
            compiledParts += this.translateService.instant('VIEW_TEAM_REQUEST.PAYMENT_AMOUNT_ADDITION_FORMAT', { value: part });
        }

        const formatStringKey = parts.length > 0 ? 'VIEW_TEAM_REQUEST.PAYMENT_AMOUNT_FORMAT_EXTRAS' : 'VIEW_TEAM_REQUEST.PAYMENT_AMOUNT_FORMAT';
        return this.translateService.instant(formatStringKey, { 
            price: this.formatNumber(this.request.price),
            extras: compiledParts,
            total: this.formatNumber(total)
        });
    }

    private formatNumber(num: number): string {
        let numberFormat = new Intl.NumberFormat('en-US');
        return numberFormat.format(num);
    }

    constructor (
        private api: DashboardApiService,
        private stateService: StateService,
        private translateService: TranslateService) {

    }

    public ngOnInit() {

        this.api.teams.getTeamRequestForm(this.stateService.params.requestId)
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe(requestForm => {
                this.request = requestForm;
            });

    }

    public viewTeam(team: ITeam) {
        this.stateService.go(RouterStates.dashboard_team_view, { teamId: team.id, teamName: team.name });
    }

    public viewUser(user: IUser) {
        this.stateService.go(RouterStates.dashboard_user_view, { userId: user.id, userName: user.name });
    }
}