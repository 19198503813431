import { NgModule } from '@angular/core';
import { UserBasicComponent } from './user-basic.component';
import { CoreComponentsModule } from '../../../../../core-components.module';

@NgModule({
  declarations: [
      UserBasicComponent
  ],
  imports: [
    CoreComponentsModule
  ],
  providers: [
  ],
  exports: [
    UserBasicComponent
  ],
  bootstrap: [
    UserBasicComponent
  ]
})
export class UserBasicModule {}

