import { NgModule } from '@angular/core';
import { StoragePlanComponent } from './storage-plan.component';
import { CoreModulesModule } from '../../core-modules.module';
import { DatepickerModule } from '../datepicker/datepicker.module';

@NgModule({
  declarations: [
      StoragePlanComponent
  ],
  imports: [
    CoreModulesModule,
    DatepickerModule
  ],
  exports: [
    StoragePlanComponent
  ],
  providers: [
  ],
  bootstrap: [
    StoragePlanComponent
  ]
})
export class StoragePlanModule { }
