import { Component, Input, Output, EventEmitter, AfterContentChecked, ElementRef, ViewChild } from '@angular/core';
import * as _ from 'underscore';
import { IObjectRef } from '../../api/common/object-ref.model';
import { ObjectTypes } from '../../api/common/object-types.enum';
import { StateService } from '@uirouter/angular';
import { RouterStates } from '../../core/router-states.constant';
import { getEnumValues } from '../../core/enum-values.function';
import { MatSelectChange, MatInput, MatSelect } from '@angular/material';

@Component({
    templateUrl: './linkable-ref.component.html',
    styleUrls: ['./linkable-ref.component.scss'],
    host: {
        class: 'linkable-ref'
    },
    selector: 'linkable-ref'
})
export class LinkableRefComponent implements AfterContentChecked {

    public get isEditing(): boolean { return this.isEditingInt == null ? false : this.isEditingInt; }
    public get isClickable(): boolean { return this.isClickableInt == null ? true : this.isClickableInt; }
    public get isDisabled(): boolean { return this.disabled == null ? false : this.disabled; }
    @Input() public refTypes: string[];
    @Input('ref') public ref: IObjectRef;
    public get reference(): IObjectRef { return this.ref == null ? (this.isEditing ? <IObjectRef>{ type: '', id: '' } : null) : this.ref; }
    public set reference(value: IObjectRef) { this.ref = value; }
    public get referenceHasName(): boolean { return this.reference && this.reference.name && this.reference.name.trim().length > 0; }
    @Output('ref') public refChange = new EventEmitter<IObjectRef>();
    @Input('isEditing') public isEditingInt: boolean;
    @Input('isClickable') public isClickableInt: boolean;
    @Input('disabled') public disabled: boolean;
    @Input() public name: string;

    @ViewChild(MatInput, { read: ElementRef, static: false }) public inputElement: ElementRef;
    @ViewChild(MatSelect, { read: ElementRef, static: false }) public selectElement: ElementRef;

    private shouldFocus = false;

    constructor(private stateService: StateService) {
        this.refTypes = getEnumValues(ObjectTypes);
    }

    public ngAfterContentChecked() {
        if (this.shouldFocus && this.isEditing) {
            if (this.inputElement != null) {
                this.inputElement.nativeElement.select();
                this.inputElement.nativeElement.focus();
                this.shouldFocus = false;
            }
        }
    }

    public focus() {
        this.shouldFocus = true;
    }

    public canViewType(type: ObjectTypes): boolean {
        let allowedTypes: ObjectTypes[] = [
            ObjectTypes.Organization,
            ObjectTypes.Team,
            ObjectTypes.User            
        ];
        return _.findIndex(allowedTypes, t => t === type) !== -1;
    }

    public view(type: ObjectTypes, id: string) {
        if (type == null || !this.canViewType(type) 
            || id == null || id.length === 0) {
            return;
        }

        switch (type) {
            case ObjectTypes.Organization:
                this.stateService.go(RouterStates.dashboard_organizations_organization_view, { organizationId: id }, { reload: true });
                break;
            case ObjectTypes.Team:
                this.stateService.go(RouterStates.dashboard_team_view, { teamId: id }, { reload: true });
                break;
            case ObjectTypes.User:
                this.stateService.go(RouterStates.dashboard_user_view, { userId: id }, { reload: true });
                break;
            case ObjectTypes.Thread:
                this.stateService.go(RouterStates.dashboard_thread_view, { threadId: id },  { reload: true });
                break;
        }
    }

    public typeChange(event: MatSelectChange) {
        return;
    }
}
