import { NgModule } from '@angular/core';
import { SpRoleDirective } from './sp-role.directive';
import { CoreModulesModule } from '../../core-modules.module';

@NgModule({
  declarations: [
    SpRoleDirective
  ],
  imports: [
      CoreModulesModule
  ],
  exports: [
    SpRoleDirective
  ],
  providers: [
  ],
  bootstrap: [
  ]
})
export class SpRoleModule { }
