import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import * as _ from 'underscore';
import { PlanPeriod, PlanPeriodTypes } from '../../api/plans/plan-period.model';
import { TranslateService } from '@ngx-translate/core';


import { getEnumValues } from '../../core/enum-values.function';

@Component({
    templateUrl: './period-selector.component.html',
    styleUrls: ['./period-selector.component.scss'],
    host: {
        // 'class': 'wide-row mat-paginator'
    },
    selector: 'period-selector'
})
export class PeriodSelectorComponent implements OnInit {

    @Input() public period: PlanPeriod;
    @Output() public periodChange = new EventEmitter();
    @Input() public editing: boolean;
    @Input() public disabled: boolean;
    public planPeriods: PlanPeriodType[] = [];
    public planPeriodNames: { [id: string]: string } = {};
    public get isEditing(): boolean { return this.editing || false; }
    
    constructor(private translateService: TranslateService) {
        
    }

    public ngOnInit() {
        let types = getEnumValues(PlanPeriodTypes);
        let keys = _.map(types, t => 'SHARED.STORAGE.DISPLAY_PERIOD.' + t.toUpperCase());

        this.translateService.get(keys)
            .subscribe(translations => {
                for (let i = 0; i < types.length; ++i) {
                    let type = types[i];
                    let translation = translations[keys[i]];
                    this.planPeriods.push({
                        key: type,
                        displayName: translation
                    });
                    this.planPeriodNames[type] = translation;
                }
            });
    }

    public editPeriodLength($event: any) {
        if (this.period.periodLength < 0) {
            this.period.periodLength = 0;
        }
        this.periodChange.emit(this.period);
    }

    public editPeriodType($event: any) {
        this.periodChange.emit(this.period);
    }
}

interface PlanPeriodType {
    key: string;
    displayName: string;
}
