
import {of as observableOf,  Observable } from 'rxjs';
import { Component, Inject, OnInit, ViewChild } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelectChange } from '@angular/material';
import { IOperation } from '../../../api/operations/operation.model';
import { IProcedure } from '../../../api/operations/procedure-model';
import { ProcedureTypes, getCreateableProcedureTypes } from '../../../api/operations/procedure-types.enum';
import { PatchOperationRequest } from '../../../api/operations/patch-operation-request.model';
import * as moment from 'moment';
import * as _ from 'underscore';
import { ProcedureRequestBase } from '../../../api/operations/procedure-request.model';
import { OperationStatus } from '../../../api/operations/operation-status.enum';
import { deepCopy } from '../../../core/deep-copy.function';
import * as Flatpickr from 'flatpickr';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton } from '../../../core/dialog.service';
import { IProcedureComponent } from '../../procedures/procedure-component-interface';
import { OperationRequest } from '../../../api/operations/operation-request.model';
import { take } from 'rxjs/operators';
import { ILogItem } from '../../../api/common/log-item.model';

@Component({
    templateUrl: './operation-modal.component.html',
    styleUrls: ['./operation-modal.component.scss']
})
export class OperationModalComponent implements OnInit {

    public get sharedOperation(): any { return this.isEditing ? this.editOperation : this.operation; }
    public get operation(): IOperation { return this.data.operation; }
    public get hasProcedure(): boolean { return this.isEditing ? this.editProcedure != null : (this.data.operation != null && this.data.operation.procedure != null); }
    public get procedure(): IProcedure { return this.operation != null && this.operation.procedure != null ? this.operation.procedure : null; }
    public get isNew(): boolean { return this.data.isNew || false; }
    public get editProcedure(): ProcedureRequestBase { return this.editOperation != null ? this.editOperation.procedure : null; }
    public get canEdit(): boolean { return this.isNew || this.operation.status === OperationStatus.Pending; }
    public logEntries: ILogItem[] = [];
    public isEditing = false;
    public isSaving = false;
    public editOperation: EditOperation;
    public datePickerOptions: Flatpickr.Options = null;
    public procedureTypes: ProcedureTypeModel[] = [];
    public selectedProcedureType: ProcedureTypeModel = undefined;

    @ViewChild('procedureEditor', { static: false }) private procedureEditor: IProcedureComponent;

    constructor(
        private translateService: TranslateService,
        private dialogService: DialogService,
        private mdDialogRef: MatDialogRef<OperationModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: IOperationModalInput) {

        let procTypes = getCreateableProcedureTypes();
        if (data.allowedProcedureTypes == null || data.allowedProcedureTypes.length === 0) {
            data.allowedProcedureTypes = <ProcedureTypes[]>procTypes;
        }

        this.datePickerOptions = {
            minuteIncrement: 5
        };

        for (let type of data.allowedProcedureTypes) {
            this.procedureTypes.push({ key: type, displayName: type });
        }

        if (this.isNew) {
            this.editOperation = new EditOperation();
            this.isEditing = true;
        }
        
        if (this.operation != null && this.operation.logs != null && this.operation.logs.length > 0) {
            this.logEntries = _.sortBy(this.operation.logs, l => moment(l.timestamp).toDate());
        }
    }

    public ngOnInit() {
        this.loadProcedureTypeNames();
    }

    public toggleEditing() {
        if (!this.isEditing) {
            this.editOperation = new EditOperation(this.operation);
            this.isEditing = true;
        }
        else {
            this.save();
        }
    }

    public cancelEditing() {
        if (this.isEditing) {
            this.isEditing = false;
            this.editOperation = null;
        }
    }
    
    public procedureTypeChange(change: MatSelectChange) {
        if (change.value != null) {
            let procedureType = <ProcedureTypes>change.value;
            if (this.editOperation.procedure != null) {
                this.dialogService.showDialog(
                    new DialogCaption('EDIT_OPERATION_TEMPLATE.TEMPLATE.CHANGE_PROCEDURE'),
                    new DialogYesButton(() => this.editOperation.procedure = ProcedureRequestBase.CreateProcedure(procedureType)),
                    new DialogNoButton()
                );
            }
            else {
                this.editOperation.procedure = ProcedureRequestBase.CreateProcedure(procedureType);
            }
        }
    }

    public closeDialog() {
        if (this.isEditing || this.isNew) {
            this.dialogService.showDialog(
                new DialogCaption('SHARED.WITHOUT_SAVING_PROMPT'),
                new DialogYesButton(() => this.mdDialogRef.close()),
                new DialogNoButton()
            );
        }
        else {
            this.mdDialogRef.close();
        }
    }

    private loadProcedureTypeNames() {
        // update the existing procedure types with the updated translations
        let translateKeys = [];
        let mapping: { [id: string]: ProcedureTypeModel } = {};
        for (let proc of this.procedureTypes) {
            let transKey = 'SHARED_OPERATIONS.PROCEDURES.' + proc.key.toUpperCase();
            translateKeys.push(transKey);
            mapping[transKey] = proc;
        }
        this.translateService.get(translateKeys)
            .subscribe(
                translations => {
                    for (let k in translations) {
                        if (translations.hasOwnProperty(k)) {
                            mapping[k].displayName = translations[k];
                        }
                    }
                });
    }

    private save() {
        if (this.isNew) {
            this.saveNew();
            return;
        }

        let changeRequest: PatchOperationRequest = {};
        let hasChanges = false;
        if (this.editOperation.runAt !== this.operation.runAt) {
            hasChanges = true;
            changeRequest.runAt = this.editOperation.runAt;
        }

        if (this.procedureEditor.hasChanges()) {
            hasChanges = true;
            changeRequest.procedure = this.editProcedure;
        }

        if (!hasChanges) {
            console.log('no changes');
            this.cancelEditing();
        }

        this.isSaving = true;
        this.validate()
            .subscribe(
                valid => {
                    this.data.saveOperation(this.operation.id, changeRequest)
                        .subscribe(
                            o => {
                                this.close();
                            },
                            err => console.error(err),
                            () => this.isSaving = false
                        );
                },
                err => console.error(err),
                () => this.isSaving = false);
    }

    private saveNew() {

        this.isSaving = true;
        let operationRequest: OperationRequest = {
            runAt: this.editOperation.runAt,
            procedure: this.editProcedure
        };
        this.validate()
            .subscribe(
                valid => {
                    this.data.createOperation(operationRequest)
                        .subscribe(
                            o => {
                                this.close();
                            },
                            err => console.error(err),
                            () => this.isSaving = false
                        );
                },
                err => console.error(err),
                () => this.isSaving = false);
    }

    private close() {
        this.mdDialogRef.close();
    }

    private validate(): Observable<boolean> {

        if (this.editOperation.procedure == null) {
            return observableOf<boolean>(false).pipe(take(1));
        }

        let runAtTime = moment(this.editOperation.runAt);
        let days = moment().diff(runAtTime, 'days', true);
        
        if (days > 31) {
            return observableOf<boolean>(false).pipe(take(1));
        }

        return this.procedureEditor.validate();
    }
}

interface ProcedureTypeModel {
    key: string;
    displayName: string;
}

class EditOperation extends PatchOperationRequest {
    public created: string;
    public updated: string;
    public status: OperationStatus;

    constructor(operation?: IOperation) {
        super();
        if (operation == null) {
            let now = moment();
            this.created = moment().toISOString();
            this.updated = this.created;
            this.runAt = this.roundTime(moment(), 5, true);
            this.status = OperationStatus.Pending;
        }
        else {
            this.created = operation.created;
            this.updated = operation.updated;
            this.status = operation.status;
            this.runAt = operation.runAt;
            this.procedure = deepCopy(operation.procedure);
        }
    }

    private roundTime(time: string | moment.Moment, minuteIncrement: number, up?: boolean): string {
        let dt = moment(time);
        // Remove seconds and milliseconds
        dt = dt.add(-dt.milliseconds(), 'milliseconds');
        dt = dt.add(-dt.seconds(), 'seconds');
        
        if (dt.minutes() % minuteIncrement !== 0) {
            let increment = up === true ? (minuteIncrement - dt.minutes() % minuteIncrement) : -(dt.minutes() % minuteIncrement);
            dt = dt.add(increment, 'minutes');
        }
        return dt.toISOString();
    }
}

export interface IOperationModalInput {
    allowedProcedureTypes: ProcedureTypes[];
    operation: IOperation;
    isNew: boolean;
    createOperation: (operationParams: OperationRequest) => Observable<IOperation>;
    saveOperation: (operationId: string, operationParams: PatchOperationRequest) => Observable<IOperation>;
}
