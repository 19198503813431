import { PlanPeriod } from '../plans/plan-period.model';

export class OperationTemplateChildSettings {
    public offset: PlanPeriod;

    constructor (offset?: PlanPeriod) {
        if (offset != null) {
            this.offset = offset;
        }
    }
}
