import { Component, OnInit, Input, Output, ViewChild, EventEmitter, OnChanges, SimpleChanges } from '@angular/core';
import { IStoragePlan } from '../../api/plans/storage-plan.model';
import { defaultStoragePlan } from '../../api/plans/default-storage-plan.function';
import { StoragePlanRequest } from '../../api/plans/storage-plan-request.model';

import { PlanService } from '../../core/plan.service';
import { TranslateService } from '@ngx-translate/core';
import { DatepickerDirective } from '../datepicker/datepicker.directive';
import { ISubscriptionPlan } from '../../api/plans/subscription-plan.model';
import { DialogService, DialogCaption, DialogOKButton } from '../../core/dialog.service';
import { deepCopy } from '../../core/deep-copy.function';
import * as Flatpickr from 'flatpickr';
import * as _ from 'underscore';
import * as moment from 'moment';
import { addPlanPeriod } from '../../api/plans/add-plan-period.function';

@Component({
    templateUrl: './storage-plan.component.html',
    styleUrls: ['./storage-plan.component.scss'],
    selector: 'storage-plan'
})
export class StoragePlanComponent implements OnInit, OnChanges {

    @Input() public plan: IStoragePlan;
    @Input() public isEditing: boolean;
    @Input() public hideCanUpload: boolean;
    @Input() public hideLoadPlan: boolean;
    @Input() public forceNewPlan: boolean;
    @Output() public isEditingChange = new EventEmitter();
    @ViewChild('startDatePicker', { static: true }) public startDatePicker: DatepickerDirective;

    public newStoragePlan: IStoragePlan;
    public endDatePickerOptions: Flatpickr.Options = null;
    public planPeriods: PlanPeriodType[] = [];
    public fromSubscriptionPlan: ISubscriptionPlan = null;
    public get plans(): ISubscriptionPlan[] { return (this.planService.plans && this.planService.plans.items) ? this.planService.plans.items : []; }

    public get planPeriodTypeTranslated(): string { return this.getPlanText(); }
    public get isNewPlan(): boolean {
        return this.forceNewPlan || this.newStoragePlan && this.newStoragePlan.startDate !== this.plan.startDate;
    }
    
    private lastPeriodKey: string;
    private lastPeriodText: string;
    private emittingEditing = false;

    constructor (private planService: PlanService, private translateService: TranslateService, private dialogService: DialogService) {
        
    }

    public loadFromSubscriptionPlan(plan: ISubscriptionPlan) {
        this.fromSubscriptionPlan = plan;
        this.onSelectPlan();
    }

    public onSelectPlan() {
        if (!this.fromSubscriptionPlan) {
            return;
        }

        this.newStoragePlan.startDate = this.getTruncatedTimeNow().toISOString();
        this.newStoragePlan.maxSize = this.fromSubscriptionPlan.maxSize;
        this.newStoragePlan.renewals = 0;
        this.newStoragePlan.remainingRenewals = this.fromSubscriptionPlan.renewals;
        this.newStoragePlan.planPeriod = this.fromSubscriptionPlan.storageRenewalFrequency;

        if (this.fromSubscriptionPlan.fixedEndDate) {
            this.newStoragePlan.endDate = this.fromSubscriptionPlan.fixedEndDate;
        }
        else if (this.fromSubscriptionPlan.planExpiry) {
            this.newStoragePlan.endDate = addPlanPeriod(moment(this.newStoragePlan.startDate), this.fromSubscriptionPlan.planExpiry, 1).toISOString();
        }
        else {
            this.newStoragePlan.endDate = null;
        }
        this.updatePlanNextUpdate();
    }
    
    public updatePlanNextUpdate() {
        if (this.isNewPlan) {
            this.newStoragePlan.nextUpdate = addPlanPeriod(moment(this.newStoragePlan.startDate), this.newStoragePlan.planPeriod, 1).toISOString();
        }
    }

    public setNoEndDate() {
        this.newStoragePlan.endDate = null;
    }

    public ngOnInit() {
        let types = ['day', 'week', 'month', 'year'];
        let keys = _.map(types, t => 'SHARED.STORAGE.DISPLAY_PERIOD.' + t.toUpperCase());

        this.translateService.get(keys)
            .subscribe(translations => {
                for (let i = 0; i < types.length; ++i) {
                    let type = types[i];
                    let translation = translations[keys[i]];
                    this.planPeriods.push({
                        key: type,
                        displayName: translation
                    });
                }
            });

        this.endDatePickerOptions = {
            weekNumbers: true
        };
        (<any>this.endDatePickerOptions).getWeek = (d) => this.getEndDateWeek(d);
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.isEditing && changes.isEditing.previousValue !== changes.isEditing.currentValue && changes.isEditing.currentValue === true && !this.emittingEditing) {
            this.beginEditing(true);
        }
        this.emittingEditing = false;
    }  

    public beginEditing(forceBegin?: boolean) {
        if (!this.isEditing || forceBegin) {
            if (this.plan) {
                this.newStoragePlan = deepCopy(this.plan);
            }
            else {
                this.newStoragePlan = defaultStoragePlan();
            }
            this.setEditing(true);
        }
    }
    
    public cancelEditing() {
        if (this.isEditing) {
            this.setEditing(false);
            this.newStoragePlan = null;
        }
    }
        
    public completeEditing() {
        this.cancelEditing();
    }

    public getPlanToSave(): PlanRequestValidation {
        let hasChanges = false;
        let storagePlanRequest = new StoragePlanRequest();
        let oldPlan = this.plan;
        let isNewPlan = this.forceNewPlan || false;

        let self = this;
        if (oldPlan.startDate !== this.newStoragePlan.startDate) {
            // The start date has to be in the future (with some tolerance)    
            if (!this.newStoragePlan.startDate || moment(this.newStoragePlan.startDate).diff(moment(), 'day') < -7) {
                let past = Math.abs(moment(this.newStoragePlan.startDate).diff(moment(), 'day'));
                this.dialogService.showDialog(new DialogCaption('TEAM_VIEW.MANAGEMENT.START_IN_PAST', true, { past: past }), new DialogOKButton(() => {
                    self.startDatePicker.showPicker();
                }));
                return { isValid: false, hasChanges: true, planRequest: null };
            }

            storagePlanRequest.startDate = this.newStoragePlan.startDate;
            hasChanges = true;
            isNewPlan = true;
        }

        if (isNewPlan || oldPlan.maxSize !== this.newStoragePlan.maxSize) {
            hasChanges = true;
            storagePlanRequest.maxSize = this.newStoragePlan.maxSize;
        }

        if (isNewPlan || oldPlan.endDate !== this.newStoragePlan.endDate) {
            hasChanges = true;
            if (!this.newStoragePlan.endDate) {
                storagePlanRequest.endDate = null;
                storagePlanRequest.noEndDate = true;
            }
            else {
                storagePlanRequest.endDate = this.newStoragePlan.endDate;
                storagePlanRequest.noEndDate = null;
            }
        }

        if (isNewPlan || oldPlan.remainingRenewals !== this.newStoragePlan.remainingRenewals) {
            hasChanges = true;
            storagePlanRequest.remainingRenewals = this.newStoragePlan.remainingRenewals;
        }

        if (isNewPlan 
            || (oldPlan.planPeriod && this.newStoragePlan.planPeriod
                && (oldPlan.planPeriod.periodLength !== this.newStoragePlan.planPeriod.periodLength 
                    || oldPlan.planPeriod.periodType !== this.newStoragePlan.planPeriod.periodType))) {
                hasChanges = true;
                storagePlanRequest.planPeriod = {
                    periodLength: this.newStoragePlan.planPeriod.periodLength,
                    periodType: this.newStoragePlan.planPeriod.periodType
                };
        }

        return {
            isValid: true,
            hasChanges: hasChanges,
            planRequest: storagePlanRequest
        };
    }

    private getEndDateWeek(date: string): string {
        let startDate = moment(this.newStoragePlan.startDate);
        let curDate = moment(date);

        let diff = Math.floor(curDate.diff(startDate, 'day') / 7);
        if (diff > 0) {
            return String(diff);
        }
        else {
            return '';
        }
    }

    private setEditing(editing: boolean) {
        if (editing !== this.isEditing) {
            this.emittingEditing = true;
            this.isEditing = editing;
            this.isEditingChange.emit(this.isEditing);
        }
    }
    
    private getTruncatedTimeNow(): moment.Moment {
        let time = Math.floor(new Date().getTime() / 1000);
        let hourSeconds = Math.floor(time % 3600);
        let adjustedHourSeconds = 0;
        time -= hourSeconds;
        if (hourSeconds > 1800) {
            adjustedHourSeconds = 1800;
        }
        time += adjustedHourSeconds;
        time *= 1000;
        return moment(new Date(time));
    }

    private getPlanText(): string {
        let key: string = null;
        if (this.plan && this.plan.planPeriod) {
            key = this.plan.planPeriod.periodType;
        }

        if (key === this.lastPeriodKey) {
            return this.lastPeriodText;
        }

        let typeObject = _.find(this.planPeriods, p => p.key.toLowerCase() === key.toLowerCase());
        if (!typeObject) {
            return null;
        }

        this.lastPeriodKey = key;
        this.lastPeriodText = typeObject.displayName;
        return typeObject.displayName;
    }

}

interface PlanPeriodType {
    key: string;
    displayName: string;
}

export interface PlanRequestValidation {
    isValid: boolean;
    hasChanges: boolean;
    planRequest: StoragePlanRequest;
}
