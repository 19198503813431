import { Component, Input, OnInit, Output } from '@angular/core';
import { EventEmitter } from '@angular/core';
import * as _ from 'underscore';
import { PlanPeriod, PlanPeriodTypes } from '../../api/plans/plan-period.model';
import { TranslateService } from '@ngx-translate/core';


import { getEnumValues } from '../../core/enum-values.function';
import { IProcedure } from '../../api/operations/procedure-model';
import { ProcedureRequestBase } from '../../api/operations/procedure-request.model';
import { ProcedureTypes } from '../../api/operations/procedure-types.enum';

@Component({
    templateUrl: './procedure-summary.component.html',
    // styleUrls: ['./procedure-summary.component.scss'],
    host: {
        // 'class': 'wide-row mat-paginator'
    },
    selector: 'procedure-summary'
})
export class ProcedureSummaryComponent {

    @Input() public procedure: IProcedure | ProcedureRequestBase;
    public ProcTypes: { [id: string]: string };
    // public get procedureTypes(): ProcedureTypes[] { return ProcedureTypes; }
    
    constructor(private translateService: TranslateService) {
        this.ProcTypes = <any>ProcedureTypes;
    }
}
