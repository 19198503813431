import { Component, OnChanges, OnInit, Input, Output, EventEmitter, ViewChild, Inject, Optional, OnDestroy, SimpleChanges } from '@angular/core';
import * as _ from 'underscore';
import { TranslateService } from '@ngx-translate/core';
import { DashboardApiService } from '../../api/dashboard-api.service';
import { MatTableDataSource, MatDialog, MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IUser } from '../../api/users/user.model';
import { PaginatorComponent } from '../paginator/paginator.component';
import { Subscription, Observable } from 'rxjs';
import { deepCopy } from '../../core/deep-copy.function';

@Component({
    templateUrl: './user-selector.component.html',
    styleUrls: ['./user-selector.component.scss'],
    host: {
        // 'class': 'flex-component'
    },
    selector: 'user-selector'
})
export class UserSelectorComponent implements OnInit, OnDestroy, OnChanges {

    @Input() public selectionMode: string;
    @Input() public disabled: boolean;
    @Input() public pageSize: number;
    @Input() public selectConfirmationDialog: (user: IUser) => Observable<boolean>;
    @Input() public excludeUserIds: string[];
    @Output() public userSelected: EventEmitter<IUser>;
    public loadingData = true;
    public dataSource = new MatTableDataSource<IUser>();
    public displayedColumns = ['name', 'email', 'joined', 'lastSeen', 'loginProviders', 'buttons'];
    public get currentSelectionMode(): string {
        if (this.selectionMode === 'radio') {
            return 'radio';
        }
        else {
            return 'button';
        }
    }
    public get usePageSize(): number {
        if (this.pageSize != null && this.pageSize > 0) {
            return this.pageSize;
        }
        return 5;
    }
    public selectedUserId: string = null;
    public searchText = '';

    @ViewChild(PaginatorComponent, { static: true }) private paginator: PaginatorComponent;
    private debouncedRefresh: Function;
    private lastSearchText: string;
    private loadingSubscription: Subscription;
    private loadingTimeout: NodeJS.Timer;

    constructor(
        private api: DashboardApiService,
        private mdDialog: MatDialog,
        @Optional() private mdDialogRef: MatDialogRef<UserSelectorComponent>,
        @Optional() @Inject(MAT_DIALOG_DATA) data: any) {
        let self = this;
        this.debouncedRefresh = _.debounce(() => { self.refreshUsers(); }, 500);
        this.userSelected = new EventEmitter<IUser>();

        if (data != null) {
            if (data.pageSize) {
                this.pageSize = data.pageSize;
            }

            if (data.selectConfirmationDialog) {
                this.selectConfirmationDialog = data.selectConfirmationDialog;
            }

            if (data.excludeUserIds) {
                this.excludeUserIds = data.excludeUserIds;
            }
        }
    }
    
    public ngOnInit() {

        if (this.mdDialogRef != null && this.mdDialogRef.componentInstance !== this) {
            this.mdDialogRef = null;
        }
    

        this.paginator.pageIndex = 0;
        this.paginator.pageSize = this.usePageSize;
        this.paginator.onPageChange
            .subscribe(() => {
            this.refreshUsers();
        });
        this.refreshUsers();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.excludeUserIds && changes.excludeUserIds.currentValue !== changes.excludeUserIds.previousValue) {
            setTimeout(() => this.refreshUsers(), 50);
        }
    }

    public ngOnDestroy() {
        if (this.loadingSubscription != null) {
            this.loadingSubscription.unsubscribe();
        }
    }

    public performSearch() {
        if (this.searchText !== this.lastSearchText) {
            this.paginator.pageIndex = 0;
            this.debouncedRefresh();
        }
    }

    public selectUser(user: IUser | string) {
        if (typeof user === 'string') {
            user = _.find(this.dataSource.data, u => u.id === user);
        }

        let selectedUser = <IUser>user;
        let doResult: (user: IUser) => void;

        if (this.mdDialogRef != null) {
            doResult = (resultUser) => this.mdDialogRef.close({ user: resultUser });
        }
        else {
            doResult = (resultUser) => this.userSelected.emit(resultUser);
        }

        if (this.selectConfirmationDialog != null) {
            this.selectConfirmationDialog(user)
                .subscribe(r => {
                    if (r === true) {
                        doResult(selectedUser);
                    }
                });
        }
        else {
            doResult(selectedUser);
        }
    }

    private refreshUsers() {
        if (this.loadingSubscription != null) {
            this.loadingSubscription.unsubscribe();
            this.loadingSubscription = null;
            if (this.loadingTimeout != null) {
                clearTimeout(this.loadingTimeout);
                this.loadingTimeout = null;
            }
        }

        this.loadingTimeout = setTimeout(() => {
            this.loadingData = true;
            clearTimeout(this.loadingTimeout);
            this.loadingTimeout = null;
        }, 500);

        let skip = this.paginator.pageIndex * this.paginator.pageSize;
        let excludeIds = [];
        if (this.excludeUserIds != null) {
            excludeIds = _.unique(this.excludeUserIds);
        }
        this.loadingSubscription = this.api
            .users.getUsers(this.searchText, skip, this.paginator.pageSize, null, null, excludeIds)
            .subscribe(u => {
                if (!_.any(u.items, i => i.id === this.selectedUserId)) {
                    this.selectedUserId = null;
                    this.userSelected.emit(null);
                }
                this.dataSource.data = u.items;
                this.paginator.itemCount = u.total;
            }, err => {
                console.error('unable to load users', err);
            }, () => {
                this.loadingSubscription = null;
                clearTimeout(this.loadingTimeout);
                this.loadingData = false;
            });
    }

}
