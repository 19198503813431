import { Component } from '@angular/core';
import { AuthenticationService } from '../../../core/authentication.service';

@Component({
    templateUrl: './welcome.component.html'
})
export class WelcomeComponent {

    constructor(private AuthenticationService: AuthenticationService) {

    }

    public onLoginClick() {
        this.AuthenticationService.beginAuth();
    }

}
