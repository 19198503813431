import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../../core-components.module';
import { RouterStates } from '../../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { OrganizationMembersComponent } from './organization-members.component';
import { CdkTableModule } from '@angular/cdk/table';

let organizationMembersState = {
    name: RouterStates.dashboard_organizations_organization_members,
    url: '/organizations/:organizationId/members',
    component: OrganizationMembersComponent
};

@NgModule({
  declarations: [
    OrganizationMembersComponent
  ],
  imports: [
    CoreComponentsModule,
    CdkTableModule,
    UIRouterModule.forChild({
      states: [organizationMembersState]
    })
  ],
  exports: [
    
  ],
  providers: [
    OrganizationMembersComponent
  ],
  bootstrap: []
})
export class OrganizationMembersModule { }
