
export enum ObjectTypes {
    Video = 'Video',
    Thread = 'Thread',
    Comment = 'Comment',
    Room = 'Room',
    Team = 'Team',
    Media = 'Media',
    User = 'User',
    Organization = 'Organization'
}
