import { NgModule } from '@angular/core';
import { SplyzaSecondsPipe } from './splyza-seconds.pipe';
import { SplyzaYesNoPipe } from './splyza-yesno.pipe';
import { MomentPipe } from './moment.pipe';
import { SplyzaRepetitionPipe } from './splyza-repetition.pipe';
import { SplyzaPostCode } from './splyza-postcode';
import { SplyzaSanitize } from './sanitize.pipe';
import { SplyzaTranslatePrefixPipe } from './translate-prefix.pipe';

let pipes = [
    SplyzaSecondsPipe,
    SplyzaYesNoPipe,
    MomentPipe,
    SplyzaRepetitionPipe,
    SplyzaPostCode,
    SplyzaSanitize,
    SplyzaTranslatePrefixPipe
];

@NgModule({
  declarations: pipes,
  imports: [],
  bootstrap: [],
  exports: pipes
})
export class PipesModule { }
 
