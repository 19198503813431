import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { OrganizationService } from '../organization.service';
import { IOrganization } from '../../../../../api/organizations/organization.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { TranscodeProfileService } from 'app/core/transcode-profile.service';

@Component({
    templateUrl: './view-organization.component.html',
    styleUrls: ['./view-organization.component.scss']
})
export class ViewOrganizationComponent implements OnInit {

    public loading = false;
    public get organization(): IOrganization { return this.organizationService.organization; }

    constructor(private organizationService: OrganizationService, private profileService: TranscodeProfileService) {
        
    }

    public ngOnInit() {
        this.loading = true;
        Observable.zip(
            // this.planService.loadPlansIfEmpty(),
            this.profileService.loadProfiles(),
            this.organizationService.organizationSubject.pipe(take(1)),

        )
        .subscribe(
            d => null, 
            e => console.error(e),
            () => {
                this.loading = false;
            }
        );
    }

    public refresh() {
        this.loading = true;
        Observable.zip(
            this.profileService.loadProfiles(),
            this.organizationService.refresh().pipe(take(1))
        )
        .subscribe(
            d => null,
            e => console.error(e),
            () => this.loading = false
        );
    }

}
