import { Component, Inject, SecurityContext } from '@angular/core';
import { MatDialogRef, MAT_DIALOG_DATA, MatSelectChange } from '@angular/material';
import * as moment from 'moment';
import * as _ from 'underscore';
import { DomSanitizer, SafeHtml } from '@angular/platform-browser';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './delete-team-modal.component.html',
    styleUrls: ['./delete-team-modal.component.scss']
})
export class DeleteTeamModalComponent {

    public prompt: SafeHtml;
    public confirmId: string;
    public get teamId(): string { return this.data.teamId; };
    public deleteReason: string;

    constructor(
        public dialogRef: MatDialogRef<DeleteTeamModalComponent>,
        @Inject(MAT_DIALOG_DATA) private data: IDeleteTeamModalOptions,
        private sanitizer: DomSanitizer,
        private translate: TranslateService) {

        let prompt = translate.instant('TEAM_VIEW.DELETE_PROMPT', { name: this.sanitize(data.teamName), id: this.sanitize(data.teamId) });
        this.prompt = this.trustString(prompt);
    }

    public close() {
        this.dialogRef.close({delete: false});
    }

    public confirmDelete() {
        if (this.teamId !== this.confirmId) {
            return;
        }
        this.dialogRef.close({delete: true, teamId: this.teamId, reason: this.deleteReason});
    }

    private sanitize(text: string): SafeHtml {
        return this.sanitizer.sanitize(SecurityContext.HTML, text);
    }

    private trustString(text: string): SafeHtml {
        return this.sanitizer.bypassSecurityTrustHtml(text);
    }
}

export interface IDeleteTeamModalOptions {
    teamName: string;
    teamId: string;
}
