import { Injectable } from '@angular/core';
import { DashboardDataSource } from '../../../../core/dashboard-data-source.model';
import { ITeamSetup } from '../../../../api/teams/team-setup.model';
import { DashboardApiService } from '../../../../api/dashboard-api.service';
import { Subscription ,  Observable } from 'rxjs';
import { ICollection } from '../../../../api/common/collection.model';

@Injectable()
export class TeamSetupsService {
    
    public dataSource = new DashboardDataSource<ITeamSetup>();
    public loadingData = true;

    private timeout: any = null;
    private loadingSubscription: Subscription = null;

    constructor(private api: DashboardApiService) {
        this.loadingData = true;
        this.dataSource.set([]);
    }

    public reset(sortBy: string, direction: string, page: number, pageSize: number): Observable<ICollection<ITeamSetup>> {
        let sortAsc = direction === 'asc';
        let self = this;
        let skip = page * pageSize;

        if (this.loadingSubscription) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            this.loadingSubscription.unsubscribe();
        }

        this.timeout = setTimeout(() => {
            if (self.timeout == null) { return; }
            self.loadingData = true;
            self.timeout = null;
        }, 500);

        return new Observable((subscriber) => {

            // let result = self.api.teams.getTeams(query, skip, pageSize, sortBy, sortAsc);
            let result = self.api.teams.getTeamSetups(skip, pageSize, sortBy, sortAsc);
            self.loadingSubscription = result
                .subscribe(
                    data => {
                        self.timeout = null;
                        self.dataSource.set(data.items);
                        subscriber.next(data);
                    },
                    error => {
                        console.error('Unable to get team setups', error);
                        subscriber.error(error);
                    },
                    () => {
                        clearTimeout(self.timeout);
                        self.loadingData = false;
                        self.loadingSubscription = null;
                        subscriber.complete();
                    }
                );
            // return result;

        });
    }

}
