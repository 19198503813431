import { NgModule } from '@angular/core';
import { CoreModulesModule } from '../../core-modules.module';
import { MediaOptionComponent } from './media-option.component';
import { EditFormatComponent } from './edit-format/edit-format.component';
import { MediaOptionService } from './media-option.service';

@NgModule({
  declarations: [
    MediaOptionComponent,
    EditFormatComponent
  ],
  imports: [
      CoreModulesModule
  ],
  exports: [
    MediaOptionComponent
  ],
  entryComponents: [
    EditFormatComponent
  ],
  providers: [
    MediaOptionService
  ],
  bootstrap: [
    MediaOptionComponent
  ]
})
export class MediaOptionModule { }
