import * as _ from 'underscore';
import { downloadFileFromString } from './blob-downloader';

export class CsvBuilder<T> {
    
    private readonly BOM = '\uFEFF'; // fix for displaying japanese characters correctly when openning csv on excel
    private readonly cellSeparator = ',';
    private readonly newlineCharacter = '\r\n';
    
    constructor(private translatedColumnHeaders: string[], private cellMapFunction: (obj: T) => any[]) {
        
    }
    
    public createCsv(objects: T[], fileName: string) {
        let parts = [];
        parts.push(this.BOM);
        
        let rows = [this.translatedColumnHeaders].concat(_.map(objects, o => this.getRowItems(o)));
        let firstRow = true;
        for (let row of rows) {
            if (!firstRow) {
                parts.push(this.newlineCharacter);
            }
            firstRow = false;

            let firstCell = true;
            for (let cell of row) {
                if (!firstCell) {
                    parts.push(this.cellSeparator);
                }
                firstCell = false;
                parts.push(cell);
            }
        }
        let content = parts.join('');
        this.downloadCsv(content, fileName, 'text/csv;charset=utf-8');
    }

    private getRowItems(obj: T): string[] {
        let result = [];
        let items = this.cellMapFunction(obj);
        for (let item of items) {
            if (typeof item === 'string') {
                result.push('"' + item.split('"').join('""') + '"');
            }
            else if (typeof item === 'boolean') {
                if (item) {
                    result.push('1');
                }
                else {
                    result.push('0');
                }
            }
            else if (typeof item === 'number') {
                result.push(item);
            }
            else {
                result.push('');
            }
        }
        return result;
    }

    private downloadCsv(content, fileName, mimeType) {
        downloadFileFromString(content, fileName, mimeType, true);
    }

}
