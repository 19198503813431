import { ApiHttp } from '../../core/api-http';
import { IValidatedUrl } from './validated-url.model';
import { Observable } from 'rxjs';

export class UrlValidationResource {

    constructor(private http: ApiHttp) {

    }

    public getInfo(encodedUrl: string): Observable<IValidatedUrl> {
        let url = '/v1/urls/:encodedUrl/info';
        url = url.replace(':encodedUrl', encodedUrl);

        return this.http.getTyped<IValidatedUrl>(url);
    }
}
