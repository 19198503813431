
import {forkJoin as observableForkJoin,  Subscription ,  Observable } from 'rxjs';

import {first, finalize,  take } from 'rxjs/operators';
import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TeamService } from '../team.service';
import { ITeam } from '../../../../api/teams/team.model';
import { ICollection } from '../../../../api/common/collection.model';
import { IGroup } from '../../../../api/groups/group.model';
import { IGroupMember } from '../../../../api/groups/group-member.model';
import { DashboardDataSource } from '../../../../core/dashboard-data-source.model';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton } from '../../../../core/dialog.service';
import { IUser } from '../../../../api/users/user.model';
import { RouterStates } from '../../../../core/router-states.constant';
import { StateService } from '@uirouter/angular';
import * as _ from 'underscore';
import { TranslateService } from '@ngx-translate/core';
import { CsvBuilder } from '../../../../core/csv-builder';
import { ITeamMembershipTermiantion } from 'app/api/teams/team-membership-termination.model';
import { PaginatorComponent } from 'app/components/paginator/paginator.component';
import * as moment from 'moment';

@Component({
    templateUrl: './team-members.component.html',
    styleUrls: ['./team-members.component.scss']
})
export class TeamMembersComponent implements OnInit, OnDestroy {

    public loading = false;
    public updatingAdmin = false;
    public tabIndex = 0;
    public get team(): ITeam { return this.teamService.team; }
    public displayedColumns = ['id', 'name', 'email', 'since', 'loginProviders', 'makeadmin'];
    public roles: { [roleType: string]: DashboardDataSource<IGroupMember> } = {};
    public roleOrder = [ 'administrator', 'manager', 'editor', 'member', 'readonly' ];
    public visibleRoles = [];
    public roleLabelKeys: { [roleType: string]: string } = {
        'administrator': 'SHARED.ROLES.ADMINISTRATOR',
        'manager': 'SHARED.ROLES.MANAGERS',
        'editor': 'SHARED.ROLES.EDITORS',
        'member': 'SHARED.ROLES.MEMBERS',
        'readonly': 'SHARED.ROLES.READ_ONLY_USERS'
    };

    public roleLabelKeysSingular: { [roleType: string]: string } = {
        'administrator': 'SHARED.ROLES.ADMINISTRATOR',
        'manager': 'SHARED.ROLES.MANAGER',
        'editor': 'SHARED.ROLES.EDITOR',
        'member': 'SHARED.ROLES.MEMBER',
        'readonly': 'SHARED.ROLES.READ_ONLY_USER'
    };

    public terminations: ITeamMembershipTermiantion[] = [];
    public terminationsDisplayedColumns = ['user', 'role', 'joined', 'terminated', 'terminationCause', 'terminatedBy'];
    @ViewChild(PaginatorComponent, { static: false }) private terminationsPaginator: PaginatorComponent;

    private teamSubscription: Subscription;
    private paginatorSubscription: Subscription;
    private rolesData: IGroup[];

    constructor(
        private teamService: TeamService,
        private dialogService: DialogService,
        private stateService: StateService,
        private translateService: TranslateService) {
    }

    public ngOnInit() {
        this.subscribeTeamChange();        
    }

    public ngOnDestroy() {
        if (this.teamSubscription) {
            this.teamSubscription.unsubscribe();
            this.teamSubscription = null;
        }

        if (this.paginatorSubscription) {
            this.paginatorSubscription.unsubscribe();
            this.paginatorSubscription = null;
        }
    }

    public tabChanged() {

        // this seems hacky so I need to double-check
        if (this.paginatorSubscription == null) { 
             this.paginatorSubscription = this.terminationsPaginator.onPageChange
                .subscribe(() => {
                    this.loadTerminations();
            });
        }

        if (this.tabIndex === 1 && this.terminations.length === 0) {
            this.loadTerminations();
        }
    }

    public makeAdministrator(member: IGroupMember) {
        let adminRole = _.find(this.rolesData, r => r.isDefault && r.name === 'administrator');
        let currentAdmin = adminRole.members.items[0];

        let currentAdminName = currentAdmin != null && currentAdmin !== undefined && currentAdmin.user != null ? currentAdmin.user.name : '...';
        
        this.dialogService.showDialog(
            new DialogCaption('TEAM_MEMBERS.CONFIRM_MAKE_ADMIN', null, {
                oldAdminName: currentAdminName,
                newAdminName: member.user.name
             }),
            new DialogYesButton(() => this.changeAdministrator(adminRole, member)),
            new DialogNoButton()
        );
    }

    public refresh() {
        this.loading = true;
        this.teamService.refresh()
            .subscribe(
                d => null,
                e => console.error(e)
            );
    }

    public viewUser(user: IUser) {
        if (user) {
            this.stateService.go(RouterStates.dashboard_user_view, { userId: user.id, userName: user.name });
        }
    }

    public downloadCSV() {
        if (this.tabIndex === 0) {
            this.downloadCurrentMembersCSV();
        } else if (this.tabIndex === 1) {
            this.downloadFormerMembersCSV();
        }
    }

    private downloadCurrentMembersCSV() {
      let headerKeys = [
        'TEAM_MEMBERS.USER_ID', 'SHARED.USERNAME', 'SHARED.ROLES.ROLE_CAPTION', 'TEAM_MEMBERS.JOINED', 
      ];
      headerKeys = _.map(headerKeys, k =>  this.translateService.instant(k));

      let users = [];

      let observables = [];
      for (let role of this.visibleRoles) {
        observables.push(this.roles[role].connect().pipe(first()));
      }

      observableForkJoin(observables)
        .pipe(take(1))
        .subscribe(roles => {
            for (let idx = 0; idx < roles.length; ++idx) {
                let roleName = this.translateService.instant(this.roleLabelKeys[this.visibleRoles[idx]]);
                for (let member of (<any>roles)[idx]) {
                    users.push([member.user.id, member.user.name, roleName, member.since]);
                }
            }
            
            let helper = new CsvBuilder<any>(headerKeys, u => {
                return u;
            });
            helper.createCsv(users, 'team-' + this.team.id + '-users.csv');
        });
    }

    private downloadFormerMembersCSV() {
      let headerKeys = [
        'TEAM_MEMBERS.USER_ID', 'SHARED.USERNAME', 'SHARED.ROLES.ROLE_CAPTION', 'TEAM_MEMBERS.JOINED', 'TEAM_MEMBERS.TERMINATED', 'TEAM_MEMBERS.TERMINATION_CAUSE'
      ];
      headerKeys = _.map(headerKeys, k =>  this.translateService.instant(k));

      let users = [];
      for (let idx = 0; idx < this.terminations.length; ++idx) {
        let termination = this.terminations[idx];
        let roleName = this.translateService.instant(this.roleLabelKeysSingular[termination.role]);
        let formattedJoinedDate = moment(termination.joinedDate).format('L');
        let formattedTerminationDate = moment(termination.terminationDate).format('L');
        let terminationCause = this.translateService.instant('TEAM_MEMBERS.TERMINATION_CAUSE_' + termination.terminationCause.toUpperCase());
        users.push([termination.user.id, termination.user.name, roleName, formattedJoinedDate, formattedTerminationDate, terminationCause]);
      }

      let helper = new CsvBuilder<any>(headerKeys, u => {
        return u;
      });
      helper.createCsv(users, 'team-' + this.team.id + '-terminated-users.csv');
    }

    private loadTerminations() {
        this.teamService.getTeamMembershipTerminations(this.team.id, this.terminationsPaginator.pageIndex * this.terminationsPaginator.pageSize, this.terminationsPaginator.pageSize)
            .subscribe(terminations => {
                this.terminations = terminations.items;
                this.terminationsPaginator.itemCount = terminations.total;
                this.terminationsPaginator.pageSize = this.terminationsPaginator.pageSize;
            });
    }

    private changeAdministrator(adminRole: IGroup, newAdministrator: IGroupMember) {
        this.updatingAdmin = true;
        this.teamService.setNewAdministrator(this.team.id, adminRole.id, newAdministrator.user.id).pipe(
            finalize(() => {
                this.updatingAdmin = false;
            }))
            .subscribe(roles => {
                this.updateRoles(roles);
            },
            error => {
                console.error('update admin', error);
                alert(error);
            });
    }

    private subscribeTeamChange() {
        this.teamSubscription = this.teamService.teamSubject
            .subscribe(team => {
                if (!team) {
                    this.loading = true;
                    return;
                }
                this.loadRoles(team);
            });
    }

    private loadRoles(team: ITeam) {
        this.teamService.loadRolesIfEmpty(team.id).pipe(
            finalize(() => {
                this.loading = false;
            }))
            .subscribe(roles => {
                this.updateRoles(roles);
            });
    }

    private updateRoles(roles: ICollection<IGroup>) {
        this.rolesData = roles.items;
        this.visibleRoles = [];
        for (let role of roles.items) {
            this.visibleRoles.push(role.name);
            if (!this.roles[role.name]) {
                this.roles[role.name] = new DashboardDataSource<IGroupMember>();
            }
            this.roles[role.name].set(role.members.items);
        }
        this.visibleRoles = _.sortBy(this.visibleRoles, r => this.roleOrder.indexOf(r));
    }

}
