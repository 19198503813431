import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { ViewTeamModule } from './view-team/view-team.module';
import { TeamMembersModule } from './team-members/team-members.module';
import { TeamComponent } from './team.component';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { TeamService } from './team.service';
import { TeamGraduationsModule } from './team-graduations/team-graduations.module';

let teamState = { name: RouterStates.dashboard_team, params: { teamName: '' }, component: TeamComponent };

@NgModule({
  declarations: [
    TeamComponent
  ],
  imports: [
    CoreComponentsModule,
    ViewTeamModule,
    TeamMembersModule,
    TeamGraduationsModule,
    UIRouterModule.forChild({
      states: [teamState]
    })
  ],
  exports: [
    
  ],
  providers: [
    TeamService
  ],
  bootstrap: []
})
export class TeamModule { }

