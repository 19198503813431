import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';

import { EventEmitter } from '@angular/core';


import * as _ from 'underscore';

export class PaginatorConfiguration {
    public itemsPerPageKey: string;
    public itemsOfCountKey: string;
    public itemsOfCountNoneKey: string;
}

@Component({
    templateUrl: './paginator.component.html',
    styleUrls: ['./paginator.component.scss'],
    host: {
        'class': 'mat-paginator'
    },
    selector: 'paginator'

})
export class PaginatorComponent implements OnInit, OnChanges {

    @Input() public paginatorConfiguration: PaginatorConfiguration;
    @Input() public pageSizeOptions: number[];
    @Input() public pageSize = 25;
    public pageIndex: number;
    public onPageChange: EventEmitter<any>;
    public itemsOfCountKey = 'SHARED.PAGINATOR.ITEMS_OF_COUNT';
    public itemsOfCountNoneKey = 'SHARED.PAGINATOR.ITEMS_OF_COUNT_NONE';
    public itemsPerPageKey = 'SHARED.PAGINATOR.ITEMS_PER_PAGE';
    
    // a hack because it's easier than working out how to get this
    // stupid mat-select component to display correctly
    public get pageSizeClass(): string {
        let tmp = this.pageSize;
        let size = 0;
        while (tmp > 0) {
            tmp = Math.floor(tmp / 10);
            ++size;
        }
        if (size === 0) { size = 1; }
        return 'mat-paginator-margin-' + size;
    }

    public get itemCount() { return this.length || 0; }
    public set itemCount(value: number) {
        this.length = value || 0;
        let totalPages = Math.ceil(this.length / this.pageSize);
        let pageIndex = Math.min(this.pageIndex, totalPages);
        this.calculateRange();
        if (pageIndex < this.pageIndex) {
            this.pageIndex = 0;
            this.onPageChange.emit();
        }
    }

    private length: number;
    private positionTranslationParams: any;
    
    constructor() {
        this.pageIndex = 0;
        this.length = 0;
        this.onPageChange = new EventEmitter<any>();
    }

    public ngOnInit() {
        this.updateTranslationKeys(this.paginatorConfiguration);

        if (!this.pageSizeOptions || this.pageSizeOptions.length === 0) {
            this.pageSizeOptions = [5, 10, 25, 50, 100];
        }

        if (_.findIndex(this.pageSizeOptions, o => o === this.pageSize) === -1) {
            this.pageSize = this.pageSizeOptions[0];
        }
        
        this.calculateRange();
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.paginatorConfiguration && changes.paginatorConfiguration.currentValue) {
            let configuration = <PaginatorConfiguration>changes.paginatorConfiguration.currentValue;
            this.updateTranslationKeys(configuration);
        }
    }

    public hasNextPage(): boolean {
        return (this.pageIndex + 1) * this.pageSize < this.length;
    }

    public hasPreviousPage(): boolean {
        return this.pageIndex !== 0;
    }

    public onSelectPageSize() {
        this.pageIndex = 0;
        this.onPageChange.emit();
    }

    public nextPage() {
        let totalPages = Math.ceil(this.length / this.pageSize);
        this.pageIndex = Math.min(this.pageIndex + 1, totalPages);
        this.calculateRange();
        this.onPageChange.emit();
    }

    public previousPage() {
        this.pageIndex = Math.max(this.pageIndex - 1, 0);
        this.calculateRange();
        this.onPageChange.emit();
    }

    private calculateRange() {
        let startIndex = 0;
        let endIndex = 0;
        if (this.length !== 0 && this.pageSize !== 0) {
            let length = Math.max(this.length, 0);
            startIndex = this.pageIndex * this.pageSize;
            // If the start index exceeds the list length, do not try and fix the end index to the end.
            endIndex = startIndex < length 
                                ? Math.min(startIndex + this.pageSize, length) 
                                : startIndex + this.pageSize;
        }
        this.positionTranslationParams = {
            startItem: startIndex + 1,
            endItem: endIndex,
            count: this.length
        };
    }

    private updateTranslationKeys(configuration: PaginatorConfiguration) {
        configuration = configuration || this.paginatorConfiguration;
        if (!configuration) {
            this.itemsOfCountKey = 'SHARED.PAGINATOR.ITEMS_OF_COUNT';
            this.itemsOfCountNoneKey = 'SHARED.PAGINATOR.ITEMS_OF_COUNT_NONE';
            this.itemsPerPageKey = 'SHARED.PAGINATOR.ITEMS_PER_PAGE';
        }
        else {
            this.itemsOfCountKey = configuration.itemsOfCountKey || 'SHARED.PAGINATOR.ITEMS_OF_COUNT';
            this.itemsOfCountNoneKey = configuration.itemsOfCountNoneKey || 'SHARED.PAGINATOR.ITEMS_OF_COUNT_NONE';
            this.itemsPerPageKey = configuration.itemsPerPageKey || 'SHARED.PAGINATOR.ITEMS_PER_PAGE';
        }
    }
}
