import { Component, OnDestroy } from '@angular/core';
import { UserService } from '../../user.service';

import { IUser } from '../../../../../api/users/user.model';
import { IUserTeam } from '../../../../../api/users/user-team.model';
import { ITeam } from '../../../../../api/teams/team.model';

import { Subscription } from 'rxjs';
import { RouterStates } from '../../../../../core/router-states.constant';
import { StateService } from '@uirouter/angular';
import * as _ from 'underscore';

@Component({
    templateUrl: './user-teams.component.html',
    styleUrls: ['./user-teams.component.scss'],
    selector: 'user-teams'
})
export class UserTeamsComponent implements OnDestroy {

    public get user(): IUser { return this.userService ? this.userService.user : null; }
    public userSubscription: Subscription;
    public visibleRoles = [];
    public roleLabelKeys: { [roleType: string]: string } = {
        'administrator': 'SHARED.ROLES.ADMINISTRATOR',
        'manager': 'SHARED.ROLES.MANAGERS',
        'editor': 'SHARED.ROLES.EDITOR',
        'member': 'SHARED.ROLES.MEMBER',
        'readonly': 'SHARED.ROLES.READ_ONLY'
    };

    public teamsByRole: { [roleType: string]: IUserTeam[] } = {};

    private roleOrder = ['administrator', 'manager', 'editor', 'member', 'readonly'];

    constructor(private userService: UserService, private stateService: StateService) {
        this.subscribeUserChange();
    }

    public ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
            this.userSubscription = null;
        }
    }

    public viewTeam(team: ITeam) {
        if (team) {
            this.stateService.go(RouterStates.dashboard_team_view, { teamId: team.id, teamName: team.name });
        }
    }

    private subscribeUserChange() {
        this.userSubscription = this.userService.userSubject
            .subscribe(
                user => {
                    this.loadUserTeams();
                }
            );
    }

    private loadUserTeams() {
        this.userService.loadTeams(this.user.id)
            .subscribe(teams => {

                let teamsByRole: { [roleType: string]: IUserTeam[] } = {};
                for (let team of teams.items) {
                    let role = team.role;
                    if (this.roleOrder.indexOf(role) === -1) {
                        continue;
                    }

                    if (!teamsByRole[role]) {
                        teamsByRole[role] = [];
                    }
                    teamsByRole[role].push(team);
                }
                this.teamsByRole = teamsByRole;
                this.visibleRoles = _.chain(Object.keys(teamsByRole))
                                    .filter(k => teamsByRole.hasOwnProperty(k))
                                    .sortBy(k => this.roleOrder.indexOf(k))
                                    .value();
            });
    }
}
