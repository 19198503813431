import { Component, ContentChild, Input, TemplateRef, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { IGraduation } from 'app/api/teams/graduation.model';
import { ITeam } from 'app/api/teams/team.model';
import { IUser } from 'app/api/users/user.model';
import { RouterStates } from 'app/core/router-states.constant';
import * as _ from 'underscore';

@Component({
    templateUrl: './graduations-list.component.html',
    styleUrls: ['./graduations-list.component.scss'],
    selector: 'graduations-list'
})
export class GraduationsListComponent {

    @ContentChild('extra', { static: false }) extraTemplate;
    @Input('graduations') public graduations: IGraduation[];
    @Input('displayTeam') public displayTeam = false;
    
    public get displayedColumns(): string[] { 
        let columnsToDisplay = ['user', 'status', 'startDate', 'endDate', 'processDate', 'class'];
        if (this.displayTeam) {
            columnsToDisplay = ['team'].concat(columnsToDisplay);
        }

        if (this.extraTemplate) {
            columnsToDisplay.push('extra');
        }

        return columnsToDisplay;
    }

    constructor(private stateService: StateService) {
    }

    public viewUser(user: IUser) {
        if (user) {
            this.stateService.go(RouterStates.dashboard_user_view, { userId: user.id, userName: user.name });
        }
    }


    public viewTeam(team: ITeam) {
        if (team) {
            this.stateService.go(RouterStates.dashboard_team_view, { teamId: team.id, teamName: team.name });
        }
    }

}
