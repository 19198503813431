import { NgModule } from '@angular/core';
import { ViewTemplateComponent } from './view-template.component';
import { CoreModulesModule } from '../../../../../core-modules.module';
import { TemplateSetModule } from '../template-set/template-set.module';
import { TemplateNormalModule } from '../template-normal/template-normal.module';


@NgModule({
  declarations: [
    ViewTemplateComponent
  ],
  imports: [
    CoreModulesModule,
    TemplateSetModule,
    TemplateNormalModule
  ],
  exports: [
    ViewTemplateComponent
  ],
  providers: [
  ],
  bootstrap: [
    ViewTemplateComponent
  ]
})
export class ViewTemplateModule { }
