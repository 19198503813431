import { Injectable } from '@angular/core';
import { DashboardApiService } from 'app/api/dashboard-api.service';
import { IMediaOption } from 'app/api/common/media-option.model';
import { Observable } from 'rxjs';
import { map } from 'rxjs/operators';


@Injectable()
export class MediaOptionService {

    public defaultOptions: Observable<IMediaOption[]>;

    constructor(private api: DashboardApiService) {

        this.defaultOptions = this.api.media.getDefaultOptions()
            .pipe(map(m => m.items))
            .shareReplay();

    }

}

