
import {of as observableOf,  Observable } from 'rxjs';
import { Component, Input } from '@angular/core';
import { IExpirePlanProcedure } from '../../../api/operations/procedure-model';
import { IProcedureComponent } from '../procedure-component-interface';
import { ExpirePlanProcedureRequest } from '../../../api/operations/procedure-request.model';
import { take } from 'rxjs/operators';

@Component({
    templateUrl: './expire-plan-procedure.component.html',
    styleUrls: ['./expire-plan-procedure.component.scss'],
    selector: 'expire-plan-procedure'
})
export class ExpirePlanProcedureComponent implements IProcedureComponent {

    @Input() public procedure: IExpirePlanProcedure;
    @Input() public editProcedure: ExpirePlanProcedureRequest;
    @Input() public isEditing: boolean;
    @Input() public isSaving: boolean;

    public hasChanges(): boolean {
        return false;
    }

    public validate(): Observable<boolean> {
        return observableOf<boolean>(true).pipe(take(1));
    }
    
}
