import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { OrganizationService } from './organization.service';
import { DashboardSidebarService } from '../../../../core/dashboard-sidebar.service';

@Component({
    templateUrl: './organization.component.html' // ,
    // styleUrls: ['./organization.component.scss']
})
export class OrganizationComponent implements OnInit {

    constructor(
        private stateService: StateService,
        private organizationService: OrganizationService,
        private dashboardSidebarService: DashboardSidebarService) {
        
        setTimeout(() => this.dashboardSidebarService.setOrganizationName(this.stateService.params.organizationName), 100);
    }

    public ngOnInit() {
        this.organizationService.loadOrganization(this.stateService.params.organizationId);
    }


}
