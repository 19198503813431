import { NgModule } from '@angular/core';
import { RouterStates } from 'app/core/router-states.constant';
import { ClusterScriptsComponent } from './cluster-scripts.component';
import { CoreComponentsModule } from 'app/core-components.module';
import { UIRouterModule } from '@uirouter/angular';
import { ScriptViewComponent } from './script-view/script-view.component';

let tasksState = { name: RouterStates.dashboard_clusterScripts, url: '/scripts', component: ClusterScriptsComponent };

@NgModule({
  declarations: [
    ClusterScriptsComponent,
    ScriptViewComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [tasksState]
    })
  ],
  exports: [
    
  ],
  providers: [
        
  ],
  bootstrap: [
    ScriptViewComponent
  ]
})
export class ClusterScriptsModule { }

