import { Injectable } from '@angular/core';
import { Observable ,  Subscription } from 'rxjs';
import { DashboardDataSource } from '../../../core/dashboard-data-source.model';
import { IUser } from '../../../api/users/user.model';
import { ICollection } from '../../../api/common/collection.model';
import { DashboardApiService } from '../../../api/dashboard-api.service';

@Injectable()
export class UsersService {

    public dataSource = new DashboardDataSource<IUser>();
    public loadingData = true;

    private timeout: any = null;
    private loadingSubscription: Subscription = null;

    constructor(private api: DashboardApiService) {
        this.loadingData = true;
        this.dataSource.set([]);
    }

    public clearData() {
        if (this.loadingSubscription) {
            this.loadingSubscription.unsubscribe();
            this.loadingSubscription = null;
        }
        this.loadingData = false;
        this.dataSource.set([]);
    }

    public getUserEmails(): Observable<string> {
        return this.api.users.getUserEmailAddressesRequest();
    }

    public reset(query: string, page: number, pageSize: number, sortBy: string, sortAsc: string): Observable<ICollection<IUser>> {
        let self = this;
        let skip = page * pageSize;
        let bSortAsc = (sortAsc === 'asc');

        if (this.loadingSubscription) {
            if (this.timeout) {
                clearTimeout(this.timeout);
                this.timeout = null;
            }
            this.loadingSubscription.unsubscribe();
        }

        if (!query) {
            query = '';
        }

        this.timeout = setTimeout(() => {
            if (self.timeout == null) { return; }
            self.loadingData = true;
            self.timeout = null;
        }, 500);

        return new Observable((subscriber) => {

            let result = self.api.users.getUsers(query.trim(), skip, pageSize, sortBy, bSortAsc);
            self.loadingSubscription = result
                .subscribe(
                    data => {
                        self.timeout = null;
                        self.dataSource.set(data.items);
                        subscriber.next(data);
                    },
                    error => {
                        console.error('Unable to get users', error);
                        subscriber.error(error);
                    },
                    () => {
                        clearTimeout(self.timeout);
                        self.loadingData = false;
                        self.loadingSubscription = null;
                        subscriber.complete();
                    }
                );
                
        });
    }

    private emptyCollection<T>(): ICollection<T> {
        return {
            total: 0,
            items: []
        };
    }
}
