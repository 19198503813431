import * as moment from 'moment';
import { PlanPeriod, PlanPeriodTypes } from './plan-period.model';

export function addPlanPeriod(date: moment.Moment, period: PlanPeriod, amount: number): moment.Moment {
    date = date.clone();
    if (period.periodType === PlanPeriodTypes.DAY ) {
        return date.add(period.periodLength * amount, 'day');
    }
    else if (period.periodType === PlanPeriodTypes.WEEK) {
        return date.add(7 * period.periodLength * amount, 'day');
    }
    else if (period.periodType === PlanPeriodTypes.MONTH) {
        return date.add(period.periodLength * amount, 'month');
    }
    else if (period.periodType === PlanPeriodTypes.YEAR) {
        return date.add(period.periodLength * amount, 'year');
    }
    else {
        return null;
    }
}
