import { ViewEncapsulation } from '@angular/core';
import { Component, OnDestroy, OnInit, SecurityContext, ViewChild } from "@angular/core";
import { MatTableDataSource } from "@angular/material";
import { DomSanitizer, SafeHtml } from "@angular/platform-browser";
import { TranslateService } from "@ngx-translate/core";
import { StateService, Transition, TransitionService } from "@uirouter/angular";
import { DashboardApiService } from "app/api/dashboard-api.service";
import { IThread } from "app/api/threads/thread.model";
import { PaginatorComponent } from "app/components/paginator/paginator.component";
import { DialogCaption, DialogOKButton, DialogService } from 'app/core/dialog.service';
import { RouterStates } from "app/core/router-states.constant";
import { observeApiError } from "app/core/rxjs/observe-api-error.operator";
import { Subscription } from "rxjs";
import { finalize } from "rxjs/operators";
import * as _ from 'underscore';

@Component({
    templateUrl: './search-threads.component.html',
    styleUrls: ['./search-threads.component.scss'],
    encapsulation: ViewEncapsulation.None
})
export class SearchThreadsComponent implements OnInit, OnDestroy {

    public searchText = '';
    public loadingItems = false;
    public threadsDataSource: MatTableDataSource<IThread> = new MatTableDataSource<IThread>();
    public displayedColumns: string[] = ['id', 'updated', 'team', 'room', 'author', 'stats'];
    public sanitizedTipsHtml: SafeHtml;
    public selectedType = 'Active';
    private lastSearchText = '';
    private debouncedRefresh: () => void;
    private paginatorSubscription: Subscription;
    private tipsSubscription: Subscription;
    private stopWatchingTransition: Function;
    
    @ViewChild(PaginatorComponent, { static: true }) private paginator: PaginatorComponent;

    constructor(
        private api: DashboardApiService,
        private stateService: StateService,
        private transitionService: TransitionService,
        private translate: TranslateService,
        private dialogService: DialogService,
        sanitizer: DomSanitizer) {
        let self = this;
        this.debouncedRefresh = _.debounce(() => { self.refreshView(); }, 500);
        this.tipsSubscription = translate.get('SEARCH_THREADS.TIPS')
            .subscribe(translation => {
                this.sanitizedTipsHtml = sanitizer.bypassSecurityTrustHtml(translation);
            });
    }

    public performSearch() : void {
        if (this.searchText != this.lastSearchText) {
            this.paginator.pageIndex = 0;
            this.debouncedRefresh();
        }
    }

    public ngOnInit(): void {
        this.paginatorSubscription = this.paginator.onPageChange
            .subscribe(() => {
                this.refreshView();
            });

        this.stopWatchingTransition = this.transitionService.onSuccess({ to: this.stateService.current.name }, (trans: Transition) => {
            this.updateFromState();
        });

        this.paginator.pageIndex = 0;
        this.paginator.pageSize = 10;
        this.updateFromState(true);
    }

    public ngOnDestroy(): void {
        if (this.paginatorSubscription) {
            this.paginatorSubscription.unsubscribe();
        }

        if (this.tipsSubscription) {
            this.tipsSubscription.unsubscribe();
        }

        if (this.stopWatchingTransition) {
            this.stopWatchingTransition();
        }
    }

    public changeViewType(event: Event) {
        this.refreshView();
    }

    public viewThread(thread: IThread): void {
        this.stateService.go(RouterStates.dashboard_thread_view, { threadId: thread.id, title: thread.title });
    }

    private updateFromState(forceUpdate?: boolean): void { 
        let searchText = this.stateService.params.q;
        let page = this.stateService.params.page;
        let size = this.stateService.params.size;
        let type = this.stateService.params.type;
        let changes = false;

        if (searchText && searchText !== this.searchText) {
            this.searchText = decodeURIComponent(searchText);
            changes = true;
        }
        
        if (size && size !== this.paginator.pageSize) {
            this.paginator.pageSize = size;
            changes = true;
        }
  
        if (page && page !== this.paginator.pageIndex) {
            this.paginator.pageIndex = page;
            changes = true;
        }

        if (type && type !== this.selectedType) {
            this.selectedType = type;
            changes = true;
        }

        if (changes || forceUpdate) {
            this.refreshView();
        }
    }

    private refreshView(): void {
        if (this.loadingItems) {
            return;
        }

        this.lastSearchText = this.searchText;
        this.loadingItems = true;
        const skip = this.paginator.pageIndex * this.paginator.pageSize;
        const pageSize = this.paginator.pageSize;
        const selType = this.selectedType;
        console.log('search type', selType);
        this.api.threads.searchThreads(this.searchText, skip, pageSize, selType)
            .pipe(finalize(() => {
                this.loadingItems = false;
            }))
            .pipe(observeApiError(err => {
                this.dialogService.showDialog(new DialogCaption(err.messageWithCode, false), new DialogOKButton());
            }))
            .subscribe(threads => {
                this.threadsDataSource.data = threads.items;
                this.paginator.itemCount = threads.total;

                this.stateService.transitionTo(this.stateService.current.name,
                    { // if new parameters are added here, add them as dynamic in the routing
                      q: this.searchText, 
                      page: this.paginator.pageIndex, 
                      size: this.paginator.pageSize,
                      type: selType
                    });
            });
    }

}