
import {forkJoin as observableForkJoin,  Observable } from 'rxjs';

import {share} from 'rxjs/operators';
import { Injectable } from '@angular/core';
import { MatDialog } from '@angular/material';
import { TranslateService } from '@ngx-translate/core';
import { deepCopy } from './deep-copy.function';
import { GenericDialogComponent, GenericDialogCaption, GenericDialogButton } from '../components/generic-dialog/generic-dialog.component';
import * as _ from 'underscore';
import { v4 } from 'uuid';

class DialogElement {
    public text: string;
    public textIsKey = true;
    public translateParams: any = {};

    constructor(text: string, translate?: boolean, translateParams?: any) {
        this.text = text;
        if (translate == null) { translate = true; }
        this.textIsKey = translate;
        this.translateParams = translateParams || {};
    }
}

export class DialogCaption extends DialogElement {

    constructor(text: string, translate?: boolean, translateParams?: any) {
        super(text, translate, translateParams);
    }

}

export class DialogButton extends DialogElement {
    public clickHandler?: Function;
    public isDefault?: boolean;
    
    constructor(text: string, translate?: boolean, translateParams?: any, clickHandler?: Function, isDefault?: boolean) {
        super(text, translate, translateParams);
        this.clickHandler = clickHandler;
        this.isDefault = isDefault;
    }
}

export class DialogYesButton extends DialogButton {
    constructor(clickHandler?: Function, isDefault?: boolean) {
        super('SHARED.DIALOG.YES', true, null, clickHandler, isDefault);
    }
}

export class DialogNoButton extends DialogButton {
    constructor(clickHandler?: Function, isDefault?: boolean) {
        super('SHARED.DIALOG.NO', true, null, clickHandler, isDefault);
    }
}

export class DialogOKButton extends DialogButton {
    constructor(clickHandler?: Function, isDefault?: boolean) {
        super('SHARED.DIALOG.OK', true, null, clickHandler, isDefault);
    }
}

export class DialogCancelButton extends DialogButton {
    constructor(clickHandler?: Function, isDefault?: boolean) {
        super('SHARED.DIALOG.CANCEL', true, null, clickHandler, isDefault);
    }
}

@Injectable()
export class DialogService {

    constructor (private mdDialog: MatDialog, private translateService: TranslateService) {

    }

    public showDialog(caption: DialogCaption, ...buttons: DialogButton[]) {       
        let newCaption = deepCopy(caption);
        let newButtons = deepCopy(buttons || []);
        if (newButtons.length === 0) {
            newButtons.push(new DialogOKButton());
        }

        let elementsToTranslate: DialogElement[] = _.filter([newCaption].concat(newButtons), e => e.textIsKey);
        
        let observables: Observable<string>[] = [];
        for (let toTranslate of elementsToTranslate) {
            let observable = this.translateService.get(toTranslate.text, toTranslate.translateParams).pipe(share());
            observables.push(observable);
            observable.subscribe(translation => {
                toTranslate.text = translation;
            });
        }

        observableForkJoin(observables)
            .subscribe(complete => {

                let genericCaption = new GenericDialogCaption(newCaption.text);
                let genericButtons = _.map(newButtons, button => {
                    return new GenericDialogButton(button.text, v4(), button);
                });

                let dialogRef = this.mdDialog.open(GenericDialogComponent, {
                    data: {
                        caption: genericCaption,
                        buttons: genericButtons
                    }
                });
                dialogRef.afterClosed()
                    .subscribe(resultId => {
                        let button = _.find(genericButtons, b => b.id === resultId);
                        if (!button) {
                            button = _.find(genericButtons, b => b.data && b.data.isDefault);
                        }
                        if (button && button.data && button.data.clickHandler && typeof button.data.clickHandler === 'function') {
                            button.data.clickHandler();
                        }
                    });

            });
    }

}
