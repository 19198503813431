import { Component, OnInit, OnDestroy, ViewChild } from '@angular/core';
import { Subscription } from 'rxjs';
import { DashboardApiService } from '../../../../api/dashboard-api.service';
import { MatSelectChange, MatTableDataSource } from '@angular/material';
import { StateService, Transition, TransitionService, StateParams } from '@uirouter/angular';
import { PaginatorComponent } from '../../../../components/paginator/paginator.component';
import { RouterStates } from '../../../../core/router-states.constant';
import { TeamRequestRenewalType } from 'app/api/teams/team-request-renewal-type.enum';
import { ITeamRequestRenewal } from 'app/api/teams/team-request-renewal.model';
import * as _ from 'underscore';
import { finalize } from 'rxjs/operators';

@Component({
    templateUrl: './list-requests.component.html',
    styleUrls: ['./list-requests.component.scss']
})
export class ListRequestsComponent implements OnInit, OnDestroy {

    public tabIndex = 0;
    public loadingRequests = true;
    public dataSource = new MatTableDataSource<ITeamRequestRenewal>();
    public displayedColumns = [];
    public displayingMultiple = false;

    public selectedType = 'Requests';
    public searchText = '';
    
    private lastSearchText = '';
    private debouncedRefresh: Function;
    private subscriptions: Subscription[] = [];
    private stopWatchingTransition: Function = null;

    @ViewChild('requestsPaginator', { static: true }) private requestsPaginator: PaginatorComponent;

    constructor (
        private api: DashboardApiService,
        private stateService: StateService, 
        private transitionService: TransitionService) {

    }

    public getColumnsToDisplay(): string[] {

        let columns: string[] = [];
        let isAll = this.selectedType === 'All';
        let isRequests = this.selectedType === 'Requests';
        let isRenewals = this.selectedType === 'Renewals';

        if (isAll) {
            columns.push('type');
        }

        columns.push('submitted', 'teamName');

        if (isAll || isRequests) {
            columns.push('trialPlan');
            columns.push('teamCreated');
        }

        columns.push('userName');

        if (isAll || isRequests) {
            columns.push('contactName');
        }

        columns.push('contactEmail');

        if (isAll || isRequests) {
            columns.push('teamLimit');
        }

        return columns;

    }

    public ngOnInit() {

        this.requestsPaginator.pageSize = 10;
        // this.renewalsPaginator.pageSize = 10;
        this.processParams(this.stateService.params, true);
        this.displayedColumns = this.getColumnsToDisplay();
        this.doRefresh();
            
        this.stopWatchingTransition = this.transitionService.onSuccess({ to: this.stateService.current.name }, (trans: Transition) => {
            if (this.processParams(this.stateService.params, false)) {
                this.doRefresh();
            }
        });

        this.subscriptions.push(this.requestsPaginator.onPageChange
            .subscribe(pc => {
                this.doRefresh();
            })
        );
        
        let self = this;
        this.debouncedRefresh = _.debounce(() => { self.doRefresh(); }, 500);
    }

    public ngOnDestroy() {
        for (let sub of this.subscriptions) {
            if (sub != null) {
                sub.unsubscribe();
            }
        }
        if (this.stopWatchingTransition != null) {
            this.stopWatchingTransition();
        }
    }

    public changeViewType(event: MatSelectChange) {

        this.displayedColumns = this.getColumnsToDisplay();
        this.doRefresh();
    }

    public refresh() {
        this.doRefresh();
    }
    
    public viewRequest(request: ITeamRequestRenewal) {
        switch (request.type) {
            case TeamRequestRenewalType.Request:
                this.stateService.go(RouterStates.dashboard_teamRequests_request_view, { requestId: request.resource.id });
                break;
            case TeamRequestRenewalType.Renewal:
                this.stateService.go(RouterStates.dashboard_teamRequests_renewal_view, { renewalId: request.resource.id });
                break;
        }
    }

    public performSearch() {
        if (this.searchText !== this.lastSearchText) {
            this.debouncedRefresh();
        }
    }

    private processParams(params: StateParams, firstLoad?: boolean): boolean {
        if (firstLoad == null) { firstLoad = false; }

        let changes = false;

        if (params.type != null && params.type !== this.selectedType) {
            this.selectedType = params.type;
            changes = true;
        }

        let paginator = this.requestsPaginator;

        if (params.page != null && Number.isInteger(params.page)) {
            let pageNumber = Number(params.page);
            if (pageNumber !== paginator.pageIndex) {
                paginator.pageIndex = pageNumber;
                changes = true;
            }
        }

        if (params.size != null && Number.isInteger(params.size)) {
            let pageSize = Number(params.size);
            if (pageSize !== paginator.pageSize) {
                paginator.pageSize = pageSize;
                changes = true;
            }
        }

        return changes;
    }

    private doRefresh() {
        let page = this.requestsPaginator.pageIndex;
        let size = this.requestsPaginator.pageSize;
        let types: TeamRequestRenewalType[] = [];

        switch (this.selectedType) {
            case 'All':
                types.push(TeamRequestRenewalType.Request);
                types.push(TeamRequestRenewalType.Renewal);
                break;
            case 'Requests':
                types.push(TeamRequestRenewalType.Request);
                break;
            case 'Renewals':
                types.push(TeamRequestRenewalType.Renewal);
                break;
        }

        this.displayingMultiple = types.length > 1;
        this.lastSearchText = this.searchText;

        let skip = page * size;
        this.loadingRequests = true;
        this.api.teams.searchTeamRequestRenewalForms(this.searchText, skip, size, types)
            .pipe(
                finalize(() => this.loadingRequests = false)
            )
            .subscribe(items => {
                if (skip >= items.total) {
                    this.requestsPaginator.pageIndex = 0;
                    this.doRefresh();
                    return;
                }
                this.requestsPaginator.itemCount = items.total;
                this.dataSource.data = items.items;
                this.displayedColumns = this.getColumnsToDisplay();
                this.transition(page, size, this.selectedType);
            });

    }

    private transition(page: number, size: number, type: string) {
        this.stateService.transitionTo(this.stateService.current.name,
            { // if new parameters are added here, add them as dynamic in the routing
                type: type,
                page: page,
                size: size
            });
    }
}