import { Component, OnInit, EventEmitter } from '@angular/core';
import { DashboardApiService } from 'app/api/dashboard-api.service';
import { finalize } from 'rxjs/operators';
import * as _ from 'underscore';
import { ICluster } from 'app/api/cluster-scripts/cluster.model';
import { MatTabChangeEvent, MatSelectChange } from '@angular/material';
import { IScriptRun } from 'app/api/cluster-scripts/script-run.model';
import { IScript } from 'app/api/cluster-scripts/script.model';
import { ScriptRunRequest } from 'app/api/cluster-scripts/script-run.request.model';

@Component({
    templateUrl: './cluster-scripts.component.html',
    styleUrls: ['./cluster-scripts.component.scss']
})
export class ClusterScriptsComponent implements OnInit {

    public tabIndex = 0;
    public loading = false;
    public launching = false;
    public clusters: ICluster[] = [];
    public clusterScripts: {[id: string]: IScript[]} = {};
    public scriptOptions: {[id: string]: string} = {};
    public selectedCluster: ICluster;
    public selectedScript: IScript;
    public newRuns = new EventEmitter<IScriptRun>();

    private selectedClusterScript: {[id: string]: string} = {};
    private lastScriptKey: string = null;

    constructor(private api: DashboardApiService) {
        
    }

    public ngOnInit() {
        this.loading = true;
        this.api.clusterScripts.getAllScripts()
            .subscribe(clusterScripts => {
                this.loading = false;

                for (let group of clusterScripts.items) {
                    let cluster = group.cluster;
                    this.clusters.push(cluster);
                    this.clusterScripts[cluster.hash] = group.items;
                    this.selectedClusterScript[cluster.hash] = group.items.length > 0 ? group.items[0].name : null;
                }

                if (this.clusters.length > 0 && this.clusterScripts[this.clusters[0].hash].length > 0) {
                    this.selectedScriptChange(this.clusters[0], null);
                }
            });
    }

    public tabChanged(event: MatTabChangeEvent) {
        this.selectedScriptChange(this.clusters[this.tabIndex], null);
    }

    public selectedScriptChange(cluster: ICluster, event?: MatSelectChange) {
        this.selectedCluster = cluster;
        let selectedScript = this.getSelectedScript(cluster);
        this.selectedCluster = cluster;
        this.selectedScript = selectedScript;
        let key = this.getScriptKey(cluster, selectedScript);

        if (this.lastScriptKey !== key) {
            this.lastScriptKey = key;

            // generate options for this script
            this.scriptOptions = {};
            for (let option of selectedScript.options) {
                if (option.defaultValue != null) {
                    this.scriptOptions[option.name] = option.defaultValue;
                }
                else if (option.isRequired && !option.isFreeText && option.values != null && option.values.length > 0) {
                    this.scriptOptions[option.name] = option.values[0].value;
                }
            }
        }
    }

    public getSelectedScript(cluster: ICluster): IScript {
        let selectedScriptName = this.selectedClusterScript[cluster.hash];
        let selectedScript = _.find(this.clusterScripts[cluster.hash], s => s.name === selectedScriptName);
        return selectedScript;
     }

     public launchScript(cluster: ICluster, script: IScript, options: {[id: string]: string}) {
         let request: ScriptRunRequest = {
            options
         };

         let key = this.getScriptKey(cluster, script);
         this.launching = true;
         this.api.clusterScripts.runScript(cluster.hash, script.name, request)
            .pipe(finalize(() => this.launching = false))
            .subscribe(run => {
                this.newRuns.emit(run);
            });
     }

     private getScriptKey(cluster: ICluster, script: IScript) {
         return cluster.hash + '@' + script.name;
     }

}
