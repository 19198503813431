import { Component, Input, Output, EventEmitter, OnInit, OnDestroy, ViewChild, ViewChildren, ElementRef } from '@angular/core';
import * as _ from 'underscore';
import { Subscription } from 'rxjs';

import { IOrganizationPlan } from '../../../../../../../api/organizations/organization-plan.model';
import { ISubscriptionPlan } from '../../../../../../../api/plans/subscription-plan.model';
import { IOrganization } from '../../../../../../../api/organizations/organization.model';
import { OrganizationService } from '../../../organization.service';
import { TranslateService } from '@ngx-translate/core';
import { deepCopy } from '../../../../../../../core/deep-copy.function';
import { OrganizationPlanRequest } from '../../../../../../../api/organizations/organization-plan-request.model';
import { DialogService } from '../../../../../../../core/dialog.service';
import { MediaOptionComponent } from 'app/components/media-option/media-option.component';
import { finalize } from 'rxjs/operators';
import { Codecs } from 'app/api/plans/codecs.enum';
import { OptionChecked } from 'app/core/value-checked.class';
import { getEnumValuesTyped } from 'app/core/enum-values.function';
import { DashboardApiService } from 'app/api/dashboard-api.service';
import { TranscodeProfileService } from 'app/core/transcode-profile.service';
import { SelectOption } from 'app/core/select-option.class';

@Component({
    templateUrl: './basic-organization-plan.component.html',
    styleUrls: ['./basic-organization-plan.component.scss'],
    selector: 'basic-organization-plan'
})
export class BasicOrganizationPlanComponent implements OnInit, OnDestroy {

    @Input() public isEditing: boolean;
    @Output() public isEditingChange = new EventEmitter();
    @ViewChildren('optionInput') public optionInputChildren: ElementRef[];
    @ViewChild(MediaOptionComponent, {static: false}) public mediaOptionComponent !: MediaOptionComponent;
    public codecOptions: OptionChecked<Codecs>[];
    public profileOptions: SelectOption<string>[];

    public organizationPlan: IOrganizationPlan;
    public isSaving = false;
    public fromSubscriptionPlan: ISubscriptionPlan = null;
    public get organization(): IOrganization { return this.organizationService ? this.organizationService.organization : null; }
    public planFeatureNames: {[id: string]: string } = {};
    private subscriptions: Subscription[] = [];

    constructor (
        private organizationService: OrganizationService,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private profileService: TranscodeProfileService) {
    }
    
    public ngOnInit(): void {
        this.subscriptions.push(this.organizationService.organizationSubject.subscribe(organization => this.loadedOrganization(organization)));
        if (this.organization) {
            this.loadedOrganization(this.organization);
        }
    }

    public ngOnDestroy(): void {
        for (let subscription of this.subscriptions) {
            subscription.unsubscribe();
        }
    }

    public toggleEditing() {
        if (!this.isEditing) {
            this.organizationPlan = deepCopy(this.organization.plan);
            this.codecOptions = this.buildCodecOptions(this.organization.plan.codecs == null ? [] : this.organization.plan.codecs);
            this.profileOptions = this.buildProfileOptions(this.organization.plan.transcodeProfile);
            this.setEditing(true);
        }
        else {
            this.save();
        }
    }

    public cancelEditing() {
            this.setEditing(false);
    }

    private loadedOrganization(organization: IOrganization) {
        //
    }

    private save() {
        let hasChanges = false;

        let plan: OrganizationPlanRequest = {};

        if (this.organizationPlan.autoAnalysis !== this.organization.plan.autoAnalysis) {
            hasChanges = true;
            plan.autoAnalysis = this.organizationPlan.autoAnalysis;
        }

        if (this.mediaOptionComponent.hasChanges()) {
            hasChanges = true;
            plan.mediaOptions = this.mediaOptionComponent.optionsToSave;
        }

        let selectedCodecs = _.chain(this.codecOptions).filter(o => o.checked).sortBy(o => o.option).map(o => o.option).value();
        let currentCodecs = _.sortBy(this.organizationPlan.codecs, o => o);
        if (selectedCodecs.length !== currentCodecs.length || _.any(_.zip(selectedCodecs, currentCodecs), o => o[0] !== o[1])) {
            plan.codecs = selectedCodecs;
            hasChanges = true;
        }

        if (this.organization.plan.transcodeProfile !== this.organizationPlan.transcodeProfile) {
            plan.transcodeProfile = this.organizationPlan.transcodeProfile;
            hasChanges = true;
        }

        if (!hasChanges) {
            this.setEditing(false);
            return;
        }

        this.isSaving = true;
        this.organizationService.patchOrganizationPlan(this.organization.id, plan)
            .pipe(finalize(() => this.isSaving = false))
            .subscribe(
                p => {
                    //
                },
                err => console.error('unable to save organization plan: ', err),
                () => this.setEditing(false)
            );
    }

    private buildProfileOptions(currentProfile: string): SelectOption<string>[] {
        
        let availableTranscodeProfiles = _.chain(this.profileService.profiles).filter(p => p.name !== 'default').map(p => p.name).value();
        availableTranscodeProfiles.splice(0, 0, 'default');

        let currentIsUnavailable = false;
        if (currentProfile !== '' && availableTranscodeProfiles.indexOf(currentProfile) === -1) {
            currentIsUnavailable = true;
            availableTranscodeProfiles.splice(0, 0, currentProfile);
        }

        let profileOptions: SelectOption<string>[] = [];
        for (let profile of _.sortBy(availableTranscodeProfiles, p => p)) {
            let option = new SelectOption<string>(profile);
            if (profile === currentProfile && currentIsUnavailable) {
                option.disabled = true;
            }
            profileOptions.push(option);
        }
        return profileOptions;

    }

    private buildCodecOptions(selectedCodecs: Codecs[]): OptionChecked<Codecs>[] {

        let result: OptionChecked<Codecs>[] = [];
        for (let codec of getEnumValuesTyped<Codecs>(Codecs)) {
            let idx = selectedCodecs.indexOf(codec);
            let option = new OptionChecked<Codecs>(codec);
            option.checked = idx !== -1;
            option.disabled = codec === Codecs.H264;
            result.push(option);
        }
        return result;

    }

    private setEditing(editing: boolean) {
        this.isEditing = editing;
        this.isEditingChange.emit(this.isEditing);
    }

}
