import { ObjectTypes } from './object-types.enum';
import { getEnumValues } from '../../core/enum-values.function';
import * as _ from 'underscore';

export interface IObjectRef {
    type: ObjectTypes | string;
    name?: string | undefined;
    id: string;
}

export class ObjectRef implements IObjectRef {

    public type: ObjectTypes | string;
    public name?: string | undefined;
    public id: string;

    public static isValid(obj: IObjectRef): boolean {
        let refTypes = getEnumValues(ObjectTypes);
        let idRegex = /^[A-F0-9]{24}$/ig;
        return _.findIndex(refTypes, t => t === obj.type) !== -1 && idRegex.test(obj.id);
    }

    constructor (type?: ObjectTypes, id?: string) {
        this.type = type == null ? ObjectTypes.Team : type;
        this.id = id == null ? '' : id;
    }    

}
