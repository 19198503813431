import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { SelectLanguageComponent } from './select-language.component';

@NgModule({
  declarations: [
    SelectLanguageComponent
  ],
  imports: [
    CoreComponentsModule, 
  ],
  exports: [
  ],
  entryComponents: [
      SelectLanguageComponent
  ],
  bootstrap: [
    
  ]
})
export class SelectLanguageModule { }
