
export class OperationKey {
    public key: string;
    public version?: number;

    constructor(key?: string, version?: number) {
        if (key != null && key !== undefined) {
            this.key = key;
        } 
        if (version != null && version !== undefined) {
            this.version = version;
        }
    }
}
