import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { ViewTeamComponent } from './view-team.component';
import { TeamBasicModule } from './team-basic/team-basic.module';
import { TeamStatsModule } from './team-stats/team-stats.module';
import { TeamManagementModule } from './team-management/team-management.module';

let viewTeamState = { name: RouterStates.dashboard_team_view, url: '/teams/:teamId', component: ViewTeamComponent };

@NgModule({
  declarations: [
    ViewTeamComponent
  ],
  imports: [
    CoreComponentsModule,
    TeamManagementModule,
    TeamBasicModule,
    TeamStatsModule,
    UIRouterModule.forChild({
      states: [viewTeamState]
    })
  ],
  providers: [
    ViewTeamComponent
  ],
  bootstrap: [
  ]
})
export class ViewTeamModule {}

