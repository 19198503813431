import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { OrganizationComponent } from './organization.component';
import { OrganizationService } from './organization.service';
import { ViewOrganizationModule } from './view-organization/view-organization.module';
import { OrganizationMembersModule } from './organization-members/organization-members.module';
import { OrganizationImportConfigModule } from './organization-import-config/organization-import-config.module';

let organizationState = {
    name: RouterStates.dashboard_organizations_organization,
    // url: '/organizations/:organizationId', 
    params: {
        organizationName: ''
    },
    component: OrganizationComponent
};

@NgModule({
  declarations: [
    OrganizationComponent
  ],
  imports: [
    CoreComponentsModule,
    ViewOrganizationModule,
    OrganizationMembersModule,
    OrganizationImportConfigModule,
    UIRouterModule.forChild({
      states: [organizationState]
    })
  ],
  exports: [
    
  ],
  providers: [
    OrganizationComponent,
    OrganizationService
  ],
  bootstrap: []
})
export class OrganizationModule { }
