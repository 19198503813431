import { Component } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { RouterStates } from 'app/core/router-states.constant';
import { EmailTemplateRequest } from 'app/api/email/email-template-request.model';
import { IEmailTemplate } from 'app/api/email/email-template.model';
import { DashboardApiService } from 'app/api/dashboard-api.service';
import { HttpErrorResponse } from '@angular/common/http';
import { DialogService, DialogCaption, DialogOKButton, DialogYesButton, DialogNoButton } from 'app/core/dialog.service';
import { DashboardSidebarService } from 'app/core/dashboard-sidebar.service';
import { IEmailContent } from 'app/api/email/email-content.model';
import { EmailContentRequest } from 'app/api/email/email-content-request.model';
import { BehaviorSubject, Observable, of } from 'rxjs';
import { MatDialog } from '@angular/material';
import { SelectLanguageComponent, SelectLanguageInputData } from '../select-language/select-language.component';
import * as _ from 'underscore';
import { EmailContentEditorComponent, EmailContentEditorInputData } from '../email-content-editor/email-content-editor.component';
import { share, take } from 'rxjs/operators';
import { TranslateService } from '@ngx-translate/core';

@Component({
    templateUrl: './edit-email-template.component.html',
    styleUrls: ['./edit-email-template.component.scss']
})
export class EditEmailTemplateComponent {

    public emailTemplateRequest: EmailTemplateRequest = null;
    public emailTemplate: IEmailTemplate = null;
    public get templateName(): string { return this.emailTemplate != null ? this.emailTemplate.name : this.stateService.params.templateName; }
    public isLoading = true;
    public isEditing = false;
    public isSaving = false;
    public isNew = false;
    public get content(): (IEmailContent | EmailContentRequest)[] { return this.isNew ? this.emailTemplateRequest.content : this.emailTemplate.content; }
    public displayedContentColumns = ['language', 'created', 'updated', 'buttons'];
    public contentDataSource: BehaviorSubject<(IEmailContent | EmailContentRequest)[]>;
    public missingLanguages: string[] = [];
    
    private readonly allowedLanguages = ['ja', 'en'];
    private templateId: string; 

    constructor (
        private stateService: StateService,
        private api: DashboardApiService,
        private dialogService: DialogService,
        private dashboardSidebarService: DashboardSidebarService,
        private mdDialog: MatDialog,
        private translateService: TranslateService) {
        
        this.contentDataSource = new BehaviorSubject<(IEmailContent | EmailContentRequest)[]>([]);
        if (this.stateService.current.name === RouterStates.dashboard_emailTemplates_new) {
            
            this.isNew = true;
            this.isEditing = true;
            this.emailTemplateRequest = {
                name: '',
                content: []
            };
            this.updateContent();
            this.isLoading = false;

        } else {

            this.isNew = false;
            let params = this.stateService.params;
            this.templateId = params.templateId;
            let templateName = (params.templateName || null);
            if (templateName != null) {
                this.dashboardSidebarService.setEmailTemplateName(templateName);
            }
            this.loadTemplate();
        }

    }

    public toggleEditing() {
        if (this.isSaving) { return; }

        if (this.isNew) {
            this.createTemplate();
        }
        else if (!this.isEditing) {
            this.emailTemplateRequest = {
                name: this.emailTemplate.name
            };
            this.isEditing = true;
        } else {
            this.save();
        }
    }

    public cancelEditing() {
        this.isEditing = false;
    }

    public createContent() {
        if (this.missingLanguages.length === 0) {
            return;
        }

        this.mdDialog.open(SelectLanguageComponent, {
            width: '200px',
            maxHeight: '90vh',
            data: <SelectLanguageInputData>{
                displayLanguages: this.missingLanguages
            }   
        }).afterClosed()
        .subscribe(l => {
            if (l != null && l !== '') {
                this.showContentCreateDialog(l);
            }
        });
    }

    public viewContent(content: IEmailContent) {
        
        this.mdDialog.open(EmailContentEditorComponent, {
            width: '90vw',
            data: <EmailContentEditorInputData>{
                content: content,
                isNew: this.isNew,
                save: (c) => this.updateExistingContent(content.language, c),
                preview: (c) => this.api.emailTemplates.getPreview((this.isNew ? '000000000000000000000000' : this.templateId), content.language, c)
            }
        }).afterClosed()
        .subscribe(c => {
            if (c != null) {
                let contentIdx = _.findIndex(this.content, l => l.language === content.language);
                if (contentIdx === -1) {
                    this.content.push(c);
                } else {
                    this.content[contentIdx] = c;
                }
                this.updateContent();
            }
        });

    }

    public deleteContent(content: IEmailContent) {
        
        let self = this;
        function removeContent() {
            let contentIdx = _.findIndex(self.content, l => l.language === content.language);
            if (contentIdx !== -1) {
                self.content.splice(contentIdx, 1);            
            }
            self.updateContent();
        }

        let language = this.translateService.instant(('SHARED.LANGUAGES.' + content.language).toUpperCase());
        this.dialogService.showDialog(
            new DialogCaption('EMAIL_TEMPLATE_VIEW.CONTENT_CONFIRM_DELETE', true, { language: language }),
            new DialogYesButton(() => {

                if (this.isNew) {
                    removeContent();
                } else {
                    this.api.emailTemplates.deleteTemplateLanguageContent(this.templateId, content.language)
                        .subscribe(
                            c => {
                                removeContent();
                            }                    
                        );
                }

            }),
            new DialogNoButton()
        );

    }

    public undeleteTemplate() {

        if (!this.emailTemplate.isDeleted || this.isNew) {
            return;
        }
        this.isSaving = true;
        this.api.emailTemplates
            .updateTemplateById(this.templateId, {
                isDeleted: false
            })
            .subscribe(t => {
                this.emailTemplate = t;
                this.dashboardSidebarService.setEmailTemplateName(t.name);
                this.updateContent();
            },
            err => {
                return;
            }, () => {
                this.isSaving = false;
            });

    }

    private showContentCreateDialog(language: string) {
        
        this.mdDialog.open(EmailContentEditorComponent, {
            width: '90vw',
            data: <EmailContentEditorInputData>{
                content: {
                    language: language,
                    subject: '',
                    body: ''
                },
                isNew: true,
                save: (c) => this.saveNewContent(c),
                preview: (c) => this.api.emailTemplates.getPreview((this.isNew ? '000000000000000000000000' : this.templateId), language, c)
            }
        }).afterClosed()
        .subscribe(c => {
            if (c != null) {
                this.content.push(c);
                this.updateContent();
            }
        });

    }

    private updateExistingContent(existingLanguage: string, templateContent: EmailContentRequest): Observable<IEmailContent> {
        if (this.isNew) {
            return of<IEmailContent>(<IEmailContent>templateContent).pipe(take(1));
        } else {
            return this.api.emailTemplates.updateTemplateLanguageContent(this.templateId, existingLanguage, templateContent);
        }
    }

    private saveNewContent(templateContent: EmailContentRequest): Observable<IEmailContent> {
        if (this.isNew) {
            return of<IEmailContent>(<IEmailContent>templateContent).pipe(take(1));
        } else {
            return this.api.emailTemplates.addTemplateLanguageContent(this.templateId, templateContent).pipe(share());
        }
    }

    private save() {
        if (this.isSaving) { return; }

        this.isSaving = true;
        let hasChanges = false;
        let changeObject: EmailTemplateRequest = {};

        if (this.emailTemplate.name !== this.emailTemplateRequest.name) {
            changeObject.name = this.emailTemplateRequest.name;
            hasChanges = true;
        }

        if (!hasChanges) {
            this.isSaving = false;
            this.isEditing = false;
            return;
        }

        this.api.emailTemplates.updateTemplateById(this.templateId, changeObject)
            .subscribe(t => {
                this.emailTemplate = t;
                this.dashboardSidebarService.setEmailTemplateName(t.name);
                this.updateContent();
            },
            err => { /* */ },
            () => {
                this.isSaving = false;
                this.isEditing = false;
            });
    }

    private createTemplate() {
        this.isSaving = true;
        this.api.emailTemplates.createTemplate(this.emailTemplateRequest)
            .subscribe(t => {
                this.stateService.go(RouterStates.dashboard_emailTemplates_view, { templateId: t.id });
            },
            err => { /* */ },
            () => {
                this.isSaving = false;
            });
    }

    private loadTemplate() {
        this.api.emailTemplates.getTemplateById(this.templateId)
            .subscribe(t => {
                this.emailTemplate = t;
                this.dashboardSidebarService.setEmailTemplateName(t.name);
                this.updateContent();
                this.isLoading = false;
            },
            err => {
                if (err instanceof HttpErrorResponse) {
                    this.handleLoadError(<HttpErrorResponse>err);
                }
            });
    }

    private handleLoadError(response: HttpErrorResponse) {
        console.error('Unable to load template', this.templateId, response);
        let message = response.status === 404 ? 'SHARED.NOT_FOUND_ERROR' : 'SHARED.GENERIC_ERROR';
        this.dialogService.showDialog(
            new DialogCaption(message),
            new DialogOKButton(() => this.stateService.go(RouterStates.dashboard_emailTemplates_list))
        );
    }

    private updateContent() {
        this.contentDataSource.next(this.content);
        this.missingLanguages = _.filter(this.allowedLanguages, l => !_.any(this.content, cl => cl.language === l));
    }
}
