import { StoragePlanRequest } from '../plans/storage-plan-request.model';
import { PlanFeatureRequest } from '../plans/plan-feature.request.model';
import { IMediaOption } from '../common/media-option.model';
import { Codecs } from '../plans/codecs.enum';

export class TeamPlanRequest {
    public memberLimit?: number;
    public readOnlyMemberLimit?: number;
    public storagePlan?: StoragePlanRequest;
    public features?: PlanFeatureRequest[];
    public mediaOptions?: IMediaOption[];
    public isTrial?: boolean;
    public codecs?: Codecs[];
    public transcodeProfile?: string;
}
