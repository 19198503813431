import { NgModule } from '@angular/core';
import { CoreModulesModule } from '../../core-modules.module';
import { NavLinkComponent } from './nav-link.component';

@NgModule({
  declarations: [
    NavLinkComponent
  ],
  imports: [
      CoreModulesModule
  ],
  exports: [
    NavLinkComponent
  ],
  providers: [
  ],
  bootstrap: [
    NavLinkComponent
  ]
})
export class NavLinkModule { }
