import { IMediaOption } from '../common/media-option.model';
import { StoragePlanRequest } from '../plans/storage-plan-request.model';
import { Codecs } from '../plans/codecs.enum';

export class OrganizationPlanRequest {
    public autoAnalysis?: boolean;
    public mediaOptions?: IMediaOption[];
    public storagePlan?: StoragePlanRequest;
    public codecs?: Codecs[];
    public transcodeProfile?: string;
}
