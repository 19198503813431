
import {finalize} from 'rxjs/operators';
import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { RouterStates } from '../../../../core/router-states.constant';
import { DashboardApiService } from '../../../../api/dashboard-api.service';
import { ITeamSetup } from '../../../../api/teams/team-setup.model';

import { PlanService } from '../../../../core/plan.service';
import { ISubscriptionPlan } from '../../../../api/plans/subscription-plan.model';

import * as moment from 'moment';
import { deepCopy } from '../../../../core/deep-copy.function';
import { defaultSubscriptionPlan } from '../../../../api/plans/default-subscription-plan.function';
import { TeamSetupRequest } from '../../../../api/teams/team-setup-request.model';
import { DialogService, DialogOKButton, DialogCaption, DialogYesButton, DialogNoButton } from '../../../../core/dialog.service';
import { SubscriptionPlanComponent } from '../../../../components/subscription-plan/subscription-plan.component';

import { DashboardSidebarService } from '../../../../core/dashboard-sidebar.service';
import { MatChipInputEvent } from '@angular/material';
import * as _ from 'underscore';
import { ENTER, COMMA } from '@angular/cdk/keycodes';

@Component({
    templateUrl: './view-setup.component.html',
    styleUrls: ['./view-setup.component.scss']
})
export class ViewSetupComponent implements OnInit {

    public loading = true;
    public isNew = false;
    public isEditing = false;
    public isSaving = false;
    public teamSetup: ITeamSetup = null;
    public editSetup: ITeamSetup = null;
    public links: {[id: string]: string} = {};
    public get teamName(): string { return (this.teamSetup && this.teamSetup.teamName) ? this.teamSetup.teamName : this.stateTeamName; }    
    public get plans(): ISubscriptionPlan[] { return (this.planService.plans && this.planService.plans.items) ? this.planService.plans.items : []; }
    public fromSubscriptionPlan: ISubscriptionPlan = null;
    public tagModel: string;
    public separatorKeysCodes = [ENTER, COMMA];

    @ViewChild(SubscriptionPlanComponent, { static: false }) private subscriptionPlan: SubscriptionPlanComponent;
    private stateTeamName = '';

    constructor(
        private stateService: StateService,
        private api: DashboardApiService,
        private planService: PlanService,
        private dialogService: DialogService,
        private dashboardSidebarService: DashboardSidebarService) {
        this.planService.loadPlansIfEmpty().pipe(
            finalize(() => { this.loading = false; }))
            .subscribe(plans => {
                this.setupDisplay('plans');
            },
            error => console.error(error));
    }

    public ngOnInit() {
        this.setupDisplay('init');
    }

    public toggleEditing() {
        if (!this.isEditing) {
            this.beginEditing();
        }
        else {
            this.saveToken(this.isNew);
        }
    }
    
    public addTag(event: MatChipInputEvent) {
        let input = event.input;
        let value = event.value;
    
        value = (value || '').trim();
        let valueLower = value.toLowerCase();

        if (valueLower.length === 0 || valueLower.length > 30) {
            return;
        }
        
        if (_.any(this.editSetup.tags, t => t.toLowerCase().trim() === valueLower)) {
            if (input) {
                this.tagModel = '';
            }
            return;
        }

        this.editSetup.tags.push(value);
        if (input) {
            this.tagModel = '';
        }
    }

    public removeTag(tag: string) {
        if (this.isSaving) { return; }
        let value = (tag || '').trim();
        let position = _.indexOf(this.editSetup.tags, value);
        if (position !== -1) {
            this.editSetup.tags.splice(position, 1);
        }
    }
    
    public onSelectPlan() {
        if (this.fromSubscriptionPlan) {
            if (this.editSetup) {
                this.editSetup.plan = deepCopy(this.fromSubscriptionPlan);
            }
        }
    }

    public deleteSetup() {
        if (this.teamSetup && this.teamSetup.id) {
            let caption = new DialogCaption('TEAM_SETUP_VIEW.CONFIRM_TOKEN_DELETE', true, { name: this.teamSetup.teamName });
            let yesButton = new DialogYesButton(() => {
                
                this.isSaving = true;
                this.api.teams.deleteTeamSetup(this.teamSetup.id).pipe(
                    finalize(() => { this.isSaving = false; }))
                    .subscribe(
                        () => {
                            this.stateService.go(RouterStates.dashboard_teamSetup_setups);                            
                        },
                        error => console.error(error)
                    );

            });
            this.dialogService.showDialog(caption, yesButton, new DialogNoButton());
        }
    }

    public viewUser() {
        if (this.teamSetup && this.teamSetup.usedBy && this.teamSetup.usedBy.id) {
            this.stateService.go(RouterStates.dashboard_user_view, { userId: this.teamSetup.usedBy.id, userName: this.teamSetup.usedBy.name });
        }
    }

    public viewTeam() {
        if (this.teamSetup.team && this.teamSetup.team.id) {
            this.stateService.go(RouterStates.dashboard_team_view, { teamId: this.teamSetup.team.id, teamName: this.teamSetup.team.name });
        }
    }

    public hasAnySignupLinks(): boolean {
        return this.hasLink('classi_signup') || this.hasLink('general_signup');
    }

    public hasLink(linkName: string): boolean {
        return this.links.hasOwnProperty(linkName);
    }

    public selectAllText($event: MouseEvent) {
        let element: any = $event.target || $event.srcElement || $event.currentTarget;
        element.select();
    }

    private saveToken(isNewToken: boolean) {

        let tokenRequest = new TeamSetupRequest();

        if (!this.editSetup.teamName || this.editSetup.teamName.length < 1 || this.editSetup.teamName.length > 100) {
            this.dialogService.showDialog(new DialogCaption('TEAM_SETUP_VIEW.TEAM_NAME_LENGTH_INVALID'), new DialogOKButton());
            return;
        }

        tokenRequest.teamName = this.editSetup.teamName;
        tokenRequest.plan = {
          autoAnalysis: this.editSetup.plan.autoAnalysis,
          displayName: null,
          maxSize: this.editSetup.plan.maxSize,
          memberLimit: this.editSetup.plan.memberLimit,
          readOnlyMemberLimit: this.editSetup.plan.readOnlyMemberLimit,
          renewals: this.editSetup.plan.renewals,
          storageRenewalFrequency: this.editSetup.plan.storageRenewalFrequency,
          units: this.editSetup.plan.units,
          updatePlanExpiry: true
        };
        tokenRequest.tags = this.editSetup.tags;
        tokenRequest.reseller = this.editSetup.reseller;
        tokenRequest.plan.displayName = null;

        if (this.subscriptionPlan.editEndType === 'period') {
            tokenRequest.plan.planExpiry = this.editSetup.plan.planExpiry;
        }
        else if (this.subscriptionPlan.editEndType === 'fixed') {
            tokenRequest.plan.fixedEndDate = this.editSetup.plan.fixedEndDate;
        }
        
        this.isSaving = true;
        if (isNewToken) {
            this.api.teams.createTeamSetup(tokenRequest).pipe(
                finalize(() => { this.isSaving = false; }))
                .subscribe(
                    ts => {
                        this.stateService.go(RouterStates.dashboard_teamSetup_setup_view, { setupId: ts.id });
                    },
                    error => console.error(error));
        }
        else {
            this.api.teams.updateTeamSetup(this.teamSetup.id, tokenRequest).pipe(
                finalize(() => { this.isSaving = false; }))
                .subscribe(
                    ts => {
                        this.teamSetup = ts;
                        for (let link of ts.links || []) {
                            this.links[link.rel] = link.href;
                        }
                        this.isSaving = false;
                        this.isEditing = false;
                        this.editSetup = null;
                    },
                    error => console.error(error));
        }
    }

    private beginEditing() {
        console.log('copy');
        this.editSetup = deepCopy(this.teamSetup);
        this.tagModel = '';
        this.isEditing = true;
    }

    private cancelEditing() {
        this.isEditing = false;
        this.editSetup = null;
    }

    private setupDisplay(src: string) {
        let route = this.stateService.current.name;
        let params = <any>this.stateService.params;

        if (route === RouterStates.dashboard_teamSetup_setup_view && src === 'init') {
            this.links = {};
            this.stateTeamName = params.teamName || '';
            this.isNew = false;
            this.api.teams.getTeamSetup(this.stateService.params.setupId)
                .subscribe(
                    ts => {
                        this.teamSetup = ts;
                        this.stateTeamName = '';
                        this.loading = false;
                        this.dashboardSidebarService.setTeamTokenName(ts.teamName);

                        for (let link of ts.links || []) {
                            this.links[link.rel] = link.href;
                        }
                        console.log(this.links);
                    }
                );
        }
        else if (route === RouterStates.dashboard_teamSetup_setup_new && src === 'plans') {
            this.links = {};
            let defaultPlan = this.planService.plans && this.planService.plans.items ? this.planService.plans.items.find(p => p.key === 'default') : null;
            let startDate = moment();

            this.teamSetup = {
                id: '',
                verificationCode: '',
                created: startDate.toISOString(),
                teamName: '',
                plan: null,
                isUsed: false,
                used: null,
                usedBy: null,
                team: null,
                tags: [],
                reseller: 'SPLYZA'
            };

            if (defaultPlan) {                
                this.teamSetup.plan = deepCopy(defaultPlan);
            }
            else {
                this.teamSetup.plan = defaultSubscriptionPlan();
            }

            this.loading = false;
            this.isNew = true;
            this.beginEditing();
        }
    }
}
