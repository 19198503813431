import { Component, OnDestroy } from '@angular/core';
import { DashboardApiService } from '../../../api/dashboard-api.service';
import { StateService, TransitionService } from '@uirouter/angular';
import { finalize } from 'rxjs/operators';
import { RouterStates } from 'app/core/router-states.constant';
import { IConnectivityApiResult, IConnectivitySpeed, IConnectivitySpeedTest, IConnectivityTest } from 'app/api/diagnostics/connectivity-test.model';
import { observeApiError } from 'app/core/rxjs/observe-api-error.operator';
import { ConnectivityResultType } from 'app/api/diagnostics/connectivity-result-type.enum';
import * as _ from 'underscore';
import { MatTableDataSource } from '@angular/material';
import { GetStatusCodeDetailsByCode } from 'app/core/get-status-code-details-by-code.function';

@Component({
    templateUrl: './connectivity-test.component.html',
    styleUrls: ['./connectivity-test.component.scss']
})
export class ConnectivityTestComponent implements OnDestroy {

    private stopWatchingTransition: Function;
    public loading = false;
    public resultId: string = '';
    public error: string | undefined;
    public result: IConnectivityTest;
    public apiResultsSource: MatTableDataSource<IConnectivityApiResult> = new MatTableDataSource<IConnectivityApiResult>();
    public displayedColumns = ['success', 'caption', 'url', 'detail'];

    // lineChart
    public lineChartData: Array<any> = [];
    public lineChartOptions: any = {
        responsive: true,
        maintainAspectRatio: true,
        scales: {
            yAxes: [{ 
                type: 'linear',
                ticks: {
                    beginAtZero: true,
                    min: 0,
                }
            }],
            xAxes: [{
                type: 'linear'
            }]
        }
    };

    public lineChartColors: Array<any> = [
        { // orange
            backgroundColor: 'rgba(255,125,0,0.2)',
            borderColor: 'rgba(255,125,0,1)',
            pointBackgroundColor: 'rgba(255,125,0,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(255,125,0,1)'
        },
        { // greeny blue
            backgroundColor: 'rgba(21,97,109,0.2)',
            borderColor: 'rgba(21,97,109,1)',
            pointBackgroundColor: 'rgba(21,97,109,1)',
            pointBorderColor: '#fff',
            pointHoverBackgroundColor: '#fff',
            pointHoverBorderColor: 'rgba(21,97,109,0.8)'
        }
    ];
    
    constructor(
        private api: DashboardApiService,
        private stateService: StateService,
        private transitionService: TransitionService) {
        
        this.stopWatchingTransition = this.transitionService.onSuccess(
            { from: this.stateService.current.name, to: this.stateService.current.name }, 
            (_) => {
                this.updateFromTransition(true);
        });

        this.updateFromTransition(false);
    }

    private updateFromTransition(fromStateChange: boolean) {
        const resultId = this.stateService.params.resultId || '';
        if (this.resultId != resultId) {
            this.resultId = resultId;
            this.loadResult();
        }
    }

    private processByteData(speed: IConnectivitySpeed, divisor: number): GraphDataPoint[] {
        let result: GraphDataPoint[] = [];
        let time = 0;

        const consolidateByTimeMs = 1000; 
        let remainingBytes = 0;
        let remainingTime = 0;

        for (let entry of speed.bytesByTime) {
            remainingBytes += entry.bytesLoaded;
            remainingTime += entry.timeDeltaMilliseconds;

            let bytesPerMs = remainingBytes / remainingTime;
            while (remainingTime >= consolidateByTimeMs) {
                const bytes = bytesPerMs * consolidateByTimeMs;
                remainingTime -= consolidateByTimeMs;
                remainingBytes -= bytes;
                const bitsPerSecond = bytesPerMs * 8 * 1000; // convert the per-millisecond byte rate into a per-second bitrate
                const unitBitsPerSecond = bitsPerSecond / divisor; // make it Kbps, Mbps, Gbps, etc.
                result.push(new GraphDataPoint(Math.ceil(time / 1000), unitBitsPerSecond));
                time += consolidateByTimeMs;
            }
        }

        if (remainingBytes > 0) {
            const bitsPerSecond = (remainingBytes / remainingTime) * 8 * 1000;  // convert the per-millisecond byte rate into a per-second bitrate
            const unitBitsPerSecond = bitsPerSecond / divisor; // make it Kbps, Mbps, Gbps, etc.
            result.push(new GraphDataPoint(Math.ceil(time / 1000), unitBitsPerSecond));
        }

        return result;
    }

    private GetCommonDivisor(a: string, b: string): number {
        let aValue = 0;
        let bValue = 0;

        switch (a.toLowerCase()) {
            case 'bps':
                aValue = 0;
                break;
            case 'kbps':
                aValue = 1;
                break;
            case 'mbps':
                aValue = 2;
                break;
            case 'gbps':
                aValue = 3;
                break;
        }

        switch (b.toLowerCase()) {
            case 'bps':
                bValue = 0;
                break;
            case 'kbps':
                bValue = 1;
                break;
            case 'mbps':
                bValue = 2;
                break;
            case 'gbps':
                bValue = 3;
                break;
        }

        let max = Math.max(aValue, bValue);
        return Math.pow(1000, max);
    }

    private prepareSpeedTestGraphs(speedtest: IConnectivitySpeedTest) {

        let downloadUnits = speedtest.download ? speedtest.download.units : 'bps';
        let uploadUnits = speedtest.upload ? speedtest.upload.units : 'bps';
        let divisor = this.GetCommonDivisor(downloadUnits, uploadUnits);

        this.lineChartData = [];
        if (speedtest.download != null && speedtest.download.success) {
            let chartData = this.processByteData(speedtest.download, divisor);
            let points = [];
            
            for (let dataPoint of chartData)
            {
                const time = Math.round(dataPoint.seconds * 10) / 10;
                points.push({ x: time, y: dataPoint.bitsPerSecond });
            }
            
            this.lineChartData.push({ data: points, label: 'Download' });
        }
        
        if (speedtest.upload != null && speedtest.upload.success) {
            let points = [];
            let chartData = this.processByteData(speedtest.upload, divisor);
            for (let dataPoint of chartData)
            {
                const time = Math.round(dataPoint.seconds * 10) / 10;
                points.push({ x: time, y: dataPoint.bitsPerSecond });
            }
            this.lineChartData.push({ data: points, label: 'Upload' });
        }
    }

    public ngOnDestroy() {
        this.stopWatchingTransition();
    }

    public get resultType(): ConnectivityResultType {
        return this.result ? this.result.resultType : ConnectivityResultType.Decoded;
    }

    public get apiTestTotal(): number {
        return this.result.apiTests != null ? this.result.apiTests.length : 0;
    }

    public get apiTestSuccess(): number {
        return this.result.apiTests != null ? _.filter(this.result.apiTests, t => t.success).length : 0;
    }

    public getCodeDescription(apiResult: IConnectivityApiResult): string {
        return GetStatusCodeDetailsByCode(apiResult.statusCode).phrase;
    }

    public loadResult() {
        this.loading = true;
        this.resultId = this.resultId.trim();
        this.stateService.go(RouterStates.dashboard_connectivityTest, { resultId: this.resultId }, { reload: false });

        if (this.resultId.length === 0) {
            this.loading = false;
            return;
        }

        this.api.diagnostics.getConnectivityTestResult(this.resultId)
            .pipe(finalize(() => {
                this.loading = false
            }))
            .pipe(observeApiError(observedError => {
                if (!observedError.isApiError && observedError.isHttpError && observedError.httpError.status === 404)  {
                    this.error = 'Not found';
                } else {
                    this.error = observedError.message;
                }
            }))
            .subscribe(result => {
                this.result = result;
                this.error = undefined;

                if (result.resultType === ConnectivityResultType.Saved && result.speedTest != null) {
                    this.prepareSpeedTestGraphs(result.speedTest);
                }

                this.apiResultsSource.data = result.apiTests;
            }, err => {
                console.error(err);
            });
    }
}

class GraphDataPoint {
    constructor(public seconds: number, public bitsPerSecond: number) {

    }
}