import { NgModule } from '@angular/core';
import { FileUploaderComponent } from './file-uploader.component';
import { CoreModulesModule } from '../../core-modules.module';

@NgModule({
  imports: [
      CoreModulesModule
  ],
  declarations: [
    FileUploaderComponent
  ],
  exports: [
    FileUploaderComponent
  ],
  bootstrap: [
    FileUploaderComponent
  ]
})
export class FileUploaderModule { }

