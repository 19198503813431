export class OptionChecked<TOption> {
    public option: TOption;
    public displayName: string;
    public checked: boolean;
    public disabled: boolean;

    constructor(option: TOption, displayName?: string) {
        this.option = option;
        this.displayName = displayName == null ? option.toString() : displayName;
    }
}
