import * as _ from 'underscore';

export function deepCopy<T>(obj: T): T {
    let objMap = new WeakMap();
    return copyObj(obj, objMap);
}

function copyObj(src: any, objMap: WeakMap<object, any>): any {
    
    if (src == null) {
        return null;
    }
    else if (src === undefined) {
        return undefined;
    }

    if (objMap.has(src)) {
        return objMap.get(src);
    }
    if (Array.isArray(src)) {
        return _.map(<any[]>src, srcObj => copyObj(srcObj, objMap));
    }
    if (typeof src === 'function') {
        return src;
    }
    else if (typeof src === 'string') {
        return (' ' + src).slice(1);
    }

    let result: { [id: string]: any } = {};
    objMap.set(src, result); // add object to the map here to prevent problems with circular references (unlikely we'll have any, but who knows...)
    for (let k in src) {
        if (src.hasOwnProperty(k)) {

            let value = src[k];
            if (typeof value === 'object' || Array.isArray(value)) {
                if (value == null || value === undefined) {
                    result[k] = null;
                }
                else if (objMap.has(value)) {
                    result[k] = objMap.get(value);
                }
                else {
                    result[k] = copyObj(value, objMap);
                }
            }
            else {
                result[k] = value;
            }
        }
    }
    return result;
}
