import { ProcedureTypes }from './procedure-types.enum';
import { IProcedure }from './procedure-model';
import { deepCopy } from '../../core/deep-copy.function';
import { IMediaOption } from '../common/media-option.model';

export abstract class ProcedureRequestBase {
    public type: ProcedureTypes;

    public static ConvertProcedureToRequest(procedure: IProcedure): ProcedureRequestBase {
        return deepCopy(<ProcedureRequestBase><any>procedure);
    }

    public static CreateProcedure(procedureType: ProcedureTypes): ProcedureRequestBase {
        if (procedureType === ProcedureTypes.MemberLimit) {
            return new MemberLimitProcedureRequest(0);
        }
        else if (procedureType === ProcedureTypes.SupporterLimit) {
            return new SupporterLimitProcedureRequest(0);
        }
        else if (procedureType === ProcedureTypes.StorageAssignment) {
            return new StorageAssignmentProcedureRequest('seconds', 0);
        }
        else if (procedureType === ProcedureTypes.UpdateStorageStats) {
            return new UpdateStorageStatsProcedureRequest('seconds', 0, true);
        }
        else if (procedureType === ProcedureTypes.ExpirePlanWarning) {
            return new ExpirePlanWarningProcedureRequest(false);
        }
        else if (procedureType === ProcedureTypes.ExpirePlan) {
            return new ExpirePlanProcedureRequest();
        }
        else if (procedureType === ProcedureTypes.SendEmail) {
            return new SendEmailProcedureRequest(null, []);
        }
        else if (procedureType === ProcedureTypes.MediaOptions) {
            return new MediaOptionsProcedureRequest([]);
        }
        else {
            throw 'This procedure type is not implemented in CreateProcedure()';
        }
    }

    constructor (type: ProcedureTypes) {
        this.type = type;
    }
}

export class StorageAssignmentProcedureRequest extends ProcedureRequestBase {
    public units: string;
    public available: number;

    constructor(units?: string, available?: number) {
        super(ProcedureTypes.StorageAssignment);
        if (units != null && units !== undefined) {
            this.units = units;
        }
        if (available != null && available !== undefined) {
            this.available = available;
        }
    }
}

export class UpdateStorageStatsProcedureRequest extends ProcedureRequestBase {
    public units: string;
    public available: number;
    public isDeltaAmount: boolean;
    public allowDecreaseStorage?: boolean;


    constructor(units?: string, available?: number, isDeltaAmount?: boolean, allowDecreaseStorage?: boolean) {
        super(ProcedureTypes.UpdateStorageStats);
        if (units != null && units !== undefined) {
            this.units = units;
        }

        if (available != null && available !== undefined) {
            this.available = available;
        }

        if (isDeltaAmount != null && isDeltaAmount !== undefined) {
            this.isDeltaAmount = isDeltaAmount;
        }

        if (this.isDeltaAmount === true && allowDecreaseStorage != null && allowDecreaseStorage !== undefined) {
            this.allowDecreaseStorage = allowDecreaseStorage;
        }
    }
}

export class MemberLimitProcedureRequest extends ProcedureRequestBase {
    public limit: number;

    constructor(limit?: number) {
        super(ProcedureTypes.MemberLimit);
        if (limit != null && limit !== undefined) {
            this.limit = limit;
        }
    }
}

export class SupporterLimitProcedureRequest extends ProcedureRequestBase {
    public limit: number;

    constructor(limit?: number) {
        super(ProcedureTypes.SupporterLimit);
        if (limit != null && limit !== undefined) {
            this.limit = limit;
        }
    }
}

export class ExpirePlanWarningProcedureRequest extends ProcedureRequestBase {
    public showWarning: boolean;

    constructor (showWarning?: boolean) {
        super(ProcedureTypes.ExpirePlanWarning);
        if (showWarning != null) {
            this.showWarning = showWarning;
        }
    }
}

export class ExpirePlanProcedureRequest extends ProcedureRequestBase {
    constructor() {
        super(ProcedureTypes.ExpirePlan);
    }
}

export class SendEmailProcedureRequest extends ProcedureRequestBase {
    public templateId: string;
    public emailTargets: string[];

    constructor(templateId?: string, emailTargets?: string[]) {
        super(ProcedureTypes.SendEmail);
        if (templateId != null) {
            this.templateId = templateId;
        }
        if (emailTargets != null) {
            this.emailTargets = emailTargets;
        }
    }
}

export class MediaOptionsProcedureRequest extends ProcedureRequestBase {
    public mediaOptions: IMediaOption[];

    constructor(mediaOptions: IMediaOption[]) {
        super(ProcedureTypes.MediaOptions);
        if (mediaOptions != null) {
            this.mediaOptions = mediaOptions;
        }
    }
}
