import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule, StateDeclaration, Ng2StateDeclaration } from '@uirouter/angular';
import { CreateTaskComponent } from './create-task.component';
import { PrettyJsonModule } from 'angular2-prettyjson';
import { CdkTableModule } from '@angular/cdk/table';
import { TemplateSelectorComponent } from './template-selector/template-selector.component'; 
import { CreateTaskEditorComponent } from './create-task-editor/create-task-editor.component';

let createTaskState: Ng2StateDeclaration = { 
    name: RouterStates.dashboard_tasks_new, 
    url: '/tasks/new?srcId',
    params: {
        srcId: null,
        srcTask: null
    },
    component: CreateTaskComponent
};

@NgModule({
    declarations: [
        CreateTaskComponent,
        TemplateSelectorComponent,
        CreateTaskEditorComponent
    ],
    imports: [
        CoreComponentsModule,
        PrettyJsonModule,
        CdkTableModule,
        UIRouterModule.forChild({
          states: [createTaskState]
        })
    ],
    providers: [
        CreateTaskComponent
    ]
})
export class CreateTaskModule { }
