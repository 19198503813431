import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';

import { HomeComponent } from './home.component';

let homeState = { name: RouterStates.dashboard_home, url: '/home', component: HomeComponent };

@NgModule({
  declarations: [
    HomeComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [homeState]
    })
  ],
  providers: [
  ],
  bootstrap: []
})
export class HomeModule {}

