import { ApiHttp } from '../../core/api-http';
import { Observable } from 'rxjs';
import { ITask } from './task.model';
import { ICollection } from '../common/collection.model';
import { ITaskRun } from './task-run.model';
import { ITaskTemplate } from './task-template.model';
import { TaskRequest } from './task-request.model';

export class TasksResource {

    constructor(private http: ApiHttp) {

    }

    public createTask(request: TaskRequest): Observable<ITask> {
        let url = '/v1/tasks';
        return this.http.postTyped<ITask>(url, JSON.stringify(request));
    }

    public getTaskTemplates(): Observable<ICollection<ITaskTemplate>> {
        let url = '/v1/tasks/templates';
        return this.http.getTyped<ICollection<ITaskTemplate>>(url);
    }

    public getTasks(fromDate?: string, skip?: number, pageSize?: number, sortBy?: string, sortAsc?: boolean, selectedStates?: string[]): Observable<ICollection<ITask>> {
        let url = '/v1/tasks';

        let params: any = {};
        
        if (pageSize != null && pageSize !== undefined) {
            params.pageSize = pageSize;
        }

        if (skip != null && skip !== undefined) {
            params.skip = skip;
        }

        if (fromDate != null && fromDate !== undefined) {
            params.fromDate = fromDate; 
        }

        if (sortBy != null && sortBy.length > 0) {
            params.sortBy = sortBy;

            if (sortAsc != null) {
                params.sortAsc = sortAsc;
            }
        }
        
        if (selectedStates != null && selectedStates.length > 0) {
            params.states = selectedStates;
        }

        return this.http.getTyped<ICollection<ITask>>(url, { params: params });
    }
    
    public getTaskById(taskId: string): Observable<ITask> {
        let url = '/v1/tasks/:taskId';
        url = url.replace(':taskId', taskId);
        return this.http.getTyped<ITask>(url);
    }

    public getTaskRunById(taskId: string, runId: string): Observable<ITaskRun> {
        let url = '/v1/tasks/:taskId/runs/:runId';
        url = url.replace(':taskId', taskId);
        url = url.replace(':runId', runId);
        return this.http.getTyped<ITaskRun>(url);
    }

    public requeueTaskById(taskId: string): Observable<ITask> {
        let url = '/v1/tasks/:taskId/runs';
        url = url.replace(':taskId', taskId);
        return this.http.postTyped<ITask>(url, '{}');
    }

}
