import { NgModule } from '@angular/core';
import { OrganizationPlanComponent } from './organization-plan.component';
import { BasicOrganizationPlanComponent } from './basic-organization-plan/basic-organization-plan.component';
import { OrganizationStoragePlanComponent } from './organization-storage-plan/organization-storage-plan.component';
import { CoreComponentsModule } from '../../../../../../core-components.module';

@NgModule({
  declarations: [
    OrganizationPlanComponent,
    BasicOrganizationPlanComponent,
    OrganizationStoragePlanComponent
  ],
  imports: [
    CoreComponentsModule
  ],
  providers: [
  ],
  exports: [
    OrganizationPlanComponent
  ],
  bootstrap: [
    OrganizationPlanComponent,
    BasicOrganizationPlanComponent,
    OrganizationStoragePlanComponent
  ]
})
export class OrganizationPlanModule {}

