import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { StorageAssignmentProcedureComponent } from './storage-assignment-procedure.component';

@NgModule({
  declarations: [
    StorageAssignmentProcedureComponent
  ],
  imports: [
    CoreComponentsModule
  ],
  exports: [
    StorageAssignmentProcedureComponent
  ],
  providers: [
  ],
  bootstrap: [
    StorageAssignmentProcedureComponent
  ]
})
export class StorageAssignmentProcedureModule { }

