import { NgModule } from '@angular/core';
import { BasicTeamPlanComponent } from './basic-team-plan/basic-team-plan.component';
import { TeamStoragePlanComponent } from './team-storage-plan/team-storage-plan.component';
import { CoreComponentsModule } from '../../../../../core-components.module';
import { TeamOperationsModule } from './team-operations/team-operations.module';
import { TeamManagementComponent } from './team-management.component';
import { ManagementDataComponent } from './management-data/management-data.component';

@NgModule({
  declarations: [
    TeamManagementComponent,
    BasicTeamPlanComponent,
    TeamStoragePlanComponent,
    ManagementDataComponent
  ],
  imports: [
    CoreComponentsModule,
    TeamOperationsModule
  ],
  providers: [
  ],
  exports: [
    TeamManagementComponent
  ],
  bootstrap: [
    TeamManagementComponent,
    BasicTeamPlanComponent,
    TeamStoragePlanComponent,
    ManagementDataComponent
  ]
})
export class TeamManagementModule {}

