import { ApiHttp } from '../../core/api-http';
import { Observable } from 'rxjs';
import { IAuthUser } from './auth-user.model';

export class AuthResource {

    constructor(private http: ApiHttp) {

    }

    public getSelf(): Observable<IAuthUser> {
        return this.http.getTyped<IAuthUser>('/v1/auth/self');
    }

}
