import { Component, Input, ElementRef, HostListener, OnChanges } from '@angular/core';
import * as _ from 'underscore';
import { NavState } from './nav-state';
import { StateService, StateDeclaration } from '@uirouter/angular';

@Component({
    templateUrl: './nav-link.component.html',
    styleUrls: ['./nav-link.component.scss'],
    selector: 'navlink'
    
})
export class NavLinkComponent implements OnChanges {
    
    @Input() public targetState: NavState;
    @Input() public disableNav: boolean;
    public get targetRouteName(): string { return this.targetState != null && !this.disableNav ? <string>this.targetState.to : null; }
    public get targetParams(): any { return this.targetState != null && !this.disableNav ? this.targetState.params : {}; }

    constructor(
        private stateService: StateService) {
        
    }

    public ngOnChanges() {
        if (this.targetState != null) {
            if (this.stateService.current.name === this.targetRouteName) {
                this.disableNav = true;
            }
            else {
                this.disableNav = false;
            }
        }
    }

    @HostListener('click', ['$event'])
    public navClick(event: MouseEvent) {
        this.nav(this.targetState);
    }

    public nav(state: NavState) {
        if (state == null) { 
            return;
        }
        this.stateService.go(state.to, state.params, state.options);
    }

}
