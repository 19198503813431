
export function downloadFileFromString(content, fileName, mimeType, includeBOM = true) {
    let BOM = '\uFEFF'; // fix for displaying japanese characters correctly when openning csv on excel
    if (includeBOM && !content.startsWith(BOM)) {
        content = BOM + content;
    }
    let a = document.createElement('a');
    mimeType = mimeType || 'application/octet-stream';

    if ((<any>navigator).msSaveBlob) { // IE10
        (<any>navigator).msSaveBlob(new Blob([content], {
            type: mimeType
        }), fileName);
    } else if (URL && 'download' in a) { // html5 A[download]
        let blob = new Blob([content], {
            type: mimeType
        });
        a.href = URL.createObjectURL(blob);
        a.setAttribute('download', fileName);
        document.body.appendChild(a);
        a.click();
        document.body.removeChild(a);
    } else {
        location.href = 'data:application/octet-stream,' + encodeURIComponent(content); // only this mime type is supported
    }
}
