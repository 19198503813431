import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { SendEmailProcedureComponent } from './send-email-procedure.component';
import { SendEmailProcedureService } from './send-email-procedure.service';

@NgModule({
  declarations: [
    SendEmailProcedureComponent
  ],
  imports: [
    CoreComponentsModule
  ],
  exports: [
    SendEmailProcedureComponent
  ],
  providers: [
    SendEmailProcedureService
  ],
  bootstrap: [
    SendEmailProcedureComponent
  ]
})
export class SendEmailProcedureModule { }

