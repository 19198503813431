import { Component } from '@angular/core';
import { AuthenticationService } from '../../core/authentication.service';
import { ApiStatusService } from '../../core/api-status.service';
import { UserService } from '../../core/user.service';
import { IStatus } from '../../api/status/status.model';
import { EnvironmentService } from 'app/core/environment.service';
import { Observable } from 'rxjs';

@Component({
    templateUrl: './header.component.html',
    selector: 'header'
})
export class HeaderComponent {

    public get hasProductionStatus(): boolean { return this.apiStatusService.Production != null; }
    public get productionStatus(): IStatus { return this.apiStatusService.Production; }
    public get hasStagingStatus(): boolean { return this.apiStatusService.Staging != null; }
    public get stagingStatus(): IStatus { return this.apiStatusService.Staging; }
    public get isLoggedIn(): Observable<boolean> { return this.authService.isAuthenticated(); }
    public get useStaging(): boolean { return this.environmentService.useStaging; }
    public set useStaging(value: boolean) { this.environmentService.useStaging = value; }

    constructor(
        private authenticationService: AuthenticationService, 
        private apiStatusService: ApiStatusService, 
        private authService: AuthenticationService,
        private environmentService: EnvironmentService) {

    }

    public onLogoutClick() {
        this.authenticationService.logout();
    }
}
