
export class SelectOption<TOptionType> {
    public value: TOptionType;
    public displayName: string;
    public disabled: boolean;

    constructor(value: TOptionType, displayName?: string) {
        this.value = value;
        this.displayName = displayName == null ? value.toString() : displayName;
    }
}
