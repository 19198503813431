import { HttpErrorResponse } from "@angular/common/http";
import { IApiError } from "app/api/error/api-error.model";
import { getApiErrors } from "app/api/error/get-api-errors.function";
import { Observable, throwError } from "rxjs";
import { catchError } from "rxjs/operators";

export function observeApiError<T>(
        error: (error: IObservedError) => void | undefined
    ) : (source$: Observable<T>) => Observable<T> {

    return (source$: Observable<T>) => {
        return source$.pipe(catchError(err => {
            let errorCollection: IApiError[] = null;
            if (err instanceof HttpErrorResponse && (errorCollection = getApiErrors(err)) != null) {
                let observedError = new ObservedError(err, errorCollection, err);
                error(observedError);
            } else {
                let observedError = new ObservedError(err, [], undefined);
                error(observedError);
            }
            return throwError(err);
        }));
    }
}

export interface IObservedError {
    message: string;
    messageWithCode: string;
    firstApiError: IApiError | undefined;
    exception: Error,
    httpError?: HttpErrorResponse;
    apiErrors: IApiError[];
    isApiError: boolean;
    isHttpError: boolean;
}

class ObservedError implements IObservedError {
    
    constructor(
        public exception: Error,
        public apiErrors: IApiError[],
        public httpError?: HttpErrorResponse) {

    }

    private getExceptionMessage(): string {
        if (this.httpError && this.httpError.message && this.httpError.message.length > 0) {
            return this.httpError.message;
        } else {
            return this.exception.message;
        }
    }

    private getExceptionCode(): string {
        if (this.httpError) {
            return this.httpError.status.toString();
        } else {
            return this.exception.name;
        }        
    }

    public get isApiError(): boolean {
        return this.apiErrors.length > 0;
    }

    public get isHttpError(): boolean {
        return this.httpError != null && this.httpError !== undefined;
    }

    public get firstApiError(): IApiError | undefined {
        return this.apiErrors.length > 0 ? this.apiErrors[0] : undefined;
    }

    public get message(): string {
        return this.firstApiError && this.firstApiError.message && this.firstApiError.message.length > 0
            ? this.firstApiError.message
            : this.getExceptionMessage();
    }

    public get messageWithCode(): string {
        let code = this.firstApiError && this.firstApiError.code && this.firstApiError.code.length > 0
            ? this.firstApiError.code
            : this.getExceptionCode();
        
        let message = this.message;
        return message && message.length > 0
            ? code + ': ' + message
            : code;
    }

}