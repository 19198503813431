
export function toDictionary<T>(enumerable: T[], keySelector: (item: T) => string): { [id: string]: T };
export function toDictionary<T, TVal>(enumerable: T[], keySelector: (item: T) => string, valueSelector?: (item: T) => TVal): {[id: string]: TVal};

export function toDictionary<T, TVal>(enumerable: T[], keySelector: (item: T) => string, valueSelector?: (item: T) => T | TVal): {[id: string]: TVal} {
    let result: {[id: string]: any } = [];
    if (valueSelector == null) {
        valueSelector = t => t;
    }
    for (let val of enumerable) {
        let key = keySelector(val);
        let value: any = val;
        if (valueSelector != null) {
            value = valueSelector(val);
        }
        result[key] = value;
    }
    return result;
}

export interface IKeyValue<TKey, TValue> {
    key: TKey;
    value: TValue;
}

export function fromDictionary<TValue, TProjectedValue>(dictionary: {[id: string]: TValue}, valueSelector: (value: TValue) => TProjectedValue): IKeyValue<string, TProjectedValue>[] {
    if (valueSelector == null) {
        valueSelector = (t: TValue) => <TProjectedValue><any>t;
    }

    let result: IKeyValue<string, TProjectedValue>[] = [];
    for (let key in dictionary) {
        if (dictionary.hasOwnProperty(key)) {
            let value = valueSelector(dictionary[key]);
            result.push({ key: key, value: value });
        }
    }
    return result;
}
