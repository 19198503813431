import { Component, OnInit, ViewChild } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { DashboardSidebarService } from '../../../../core/dashboard-sidebar.service';
// import { OperationTemplatesService } from './operation-templates.service';
import { OperationTemplateTypes } from '../../../../api/operations/operation-template-types.enum';
import * as _ from 'underscore';
import { PlanPeriod, PlanPeriodTypes } from '../../../../api/plans/plan-period.model';
import { MatTabChangeEvent, MatTabGroup } from '@angular/material';
import { IOperationTemplateBase, IOperationTemplateSet } from '../../../../api/operations/operation-template.model';
import { RouterStates } from '../../../../core/router-states.constant';
import { DialogService, DialogYesButton, DialogNoButton, DialogCaption } from '../../../../core/dialog.service';
import { LocalStorageService } from '../../../../core/local-storage.service';
import { OperationTemplateService } from '../../../../core/operation-template.service';

const TEMPLATE_TAB_STORAGE_KEY = 'operation-templates-last-tab';

@Component({
    templateUrl: './operation-templates.component.html',
    styleUrls: ['./operation-templates.component.scss']
})
export class OperationTemplatesComponent implements OnInit {

    public index = 0;
    public viewType: OperationTemplateTypes = OperationTemplateTypes.TemplateSet;
    public testPeriod: PlanPeriod = {  periodType: PlanPeriodTypes.MONTH, periodLength: 1 };
    // public get dataSources() { return this.templateService.dataSources; }
    public get templatesDataSource() { return this.templateService.templatesDataSource; }
    public get templateSetsDataSource() { return this.templateService.templateSetsDataSource; }
    public get loadingData(): boolean { return this.templateService.loadingData; }
    public displayedTemplateSetColumns: string[] = ['name', 'updated', 'templates', 'buttons'];
    public displayedTemplateColumns: string[] = ['name', 'updated', 'type', 'repetition', 'summary', 'buttons'];
    @ViewChild(MatTabGroup, { static: true }) public tabGroup: MatTabGroup;

    private debouncedRefresh: Function;
   
    constructor(private localStorageService: LocalStorageService,
                private stateService: StateService,
                private dashboardSidebarService: DashboardSidebarService,
                // private templateService: OperationTemplatesService,
                private templateService: OperationTemplateService,
                private dialogService: DialogService) {
                
        let self = this;
        this.debouncedRefresh = _.debounce(() => { self.refreshView(); }, 500);

        if (this.localStorageService.isStorageAvailable) {
            let tab = this.localStorageService.getItem(TEMPLATE_TAB_STORAGE_KEY);
            if (tab != null) {
                this.index = Math.min(1, Math.max(0, Number(tab)));
                this.viewType = this.index === 0 ? OperationTemplateTypes.TemplateSet : OperationTemplateTypes.Template;
            }
        }
    }

    public ngOnInit() {
        this.templateService.loadTemplatesIfEmpty();
    }

    public refresh() {
        this.debouncedRefresh();
    }

    public tabChanged(tabChangeEvent: MatTabChangeEvent) {
        if (tabChangeEvent.index === 0) {
            this.viewType = OperationTemplateTypes.TemplateSet;
            this.refresh();
        }
        else if (tabChangeEvent.index === 1) {
            this.viewType = OperationTemplateTypes.Template;
            this.refresh();
        }
        if (this.localStorageService.isStorageAvailable) {
            this.localStorageService.setItem(TEMPLATE_TAB_STORAGE_KEY, String(tabChangeEvent.index));
        }
    }

    public viewTemplate(template: IOperationTemplateBase) {
        this.stateService.go(RouterStates.dashboard_operation_template_view, { operationIdentifier: template.identifier.key, templateName: template.name });
    }

    public createTemplate() {
        this.stateService.go(RouterStates.dashboard_operation_template_new, { type: this.viewType });
    }

    public deleteTemplate(template: IOperationTemplateBase) {
        let canDelete = 
            (template.type === OperationTemplateTypes.Template)
            || (template.type === OperationTemplateTypes.TemplateSet && (<IOperationTemplateSet>template).requiredProcedures.length === 0);
            
        if (!canDelete) {
            console.error('Delete is not permitted for a template in this state.');
            return;
        }

        this.dialogService.showDialog(
            new DialogCaption('OPERATION_TEMPLATES.CONFIRM_DELETE', true, { name: template.name }),
            new DialogYesButton(() => {
                
                this.templateService.deleteTemplate(template.identifier.key)
                    .subscribe(
                        () => null,
                        err => this.dialogService.showDialog(new DialogCaption('SHARED.GENERIC_ERROR'))
                    );

            }),
            new DialogNoButton()
        );
    }

    private refreshView() {
        this.templateService.refresh();
    }
}
