import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { ViewSetupComponent } from './view-setup.component';

let newSetupState = { name: RouterStates.dashboard_teamSetup_setup_new, url: '/team-setups/new', component: ViewSetupComponent };
let viewSetupState = { name: RouterStates.dashboard_teamSetup_setup_view, url: '/team-setups/:setupId', params: { teamName: '' }, component: ViewSetupComponent };

@NgModule({
    declarations: [
        ViewSetupComponent
    ],
    imports: [
        CoreComponentsModule,
        UIRouterModule.forChild({
          states: [newSetupState, viewSetupState]
        })
    ],
    providers: [
        ViewSetupComponent
    ]
})
export class ViewSetupModule { }
