import { Injectable } from '@angular/core';

enum StorageType {
    LocalStorage = 'localStorage',
    SessionStorage = 'sessionStorage'
}

@Injectable()
export class LocalStorageService {

    private _window: Window = window;

    public getItem(itemKey: string): string {
        return this.getItemImpl(StorageType.LocalStorage, itemKey);
    }

    public setItem(itemKey: string, value: string) {
        return this.setItemImpl(StorageType.LocalStorage, itemKey, value);
    }

    public removeItem(itemKey: string) {
        this.removeItemImpl(StorageType.LocalStorage, itemKey);
    }

    public getSessionItem(itemKey: string): string {
        return this.getItemImpl(StorageType.SessionStorage, itemKey);
    }

    public setSessionItem(itemKey: string, value: string) {
        return this.setItemImpl(StorageType.SessionStorage, itemKey, value);
    }

    public removeSessionItem(itemKey: string) {
        this.removeItemImpl(StorageType.SessionStorage, itemKey);
    }

    public isStorageAvailable(type: StorageType) {
        try {
            let storage: any = this._window[type];
            let x = '__storage_test__';
            storage.setItem(x, x);
            storage.removeItem(x);
            return true;
        }  catch (e) {
            console.warn(e);
            return false;
        }
    }

    private getItemImpl(type: StorageType, itemKey: string) {
        if (!this.isStorageAvailable(type)) {
            return;
        }
        return this._window[type].getItem(itemKey);
    }

    private setItemImpl(type: StorageType, itemKey: string, value: string) {
        if (!this.isStorageAvailable(type)) {
            return;
        }
        return this._window[type].setItem(itemKey, value);
    }

    private removeItemImpl(type: StorageType, itemKey: string) {
        if (!this.isStorageAvailable(type)) {
            return;
        }
        return this._window[type].removeItem(itemKey);
    }
}
