
export class PlanPeriod {
    public periodType: PlanPeriodTypes;
    public periodLength: number;

    public static Default(): PlanPeriod {
        return new PlanPeriod(PlanPeriodTypes.MONTH, 1);
    }

    constructor (periodType?: PlanPeriodTypes, periodLength?: number) {
        if (periodType != null) {
            this.periodType = periodType;
        }

        if (periodLength != null) {
            this.periodLength = periodLength;
        }
    }
}

export enum PlanPeriodTypes {
    DAY = 'day',
    WEEK = 'week',
    MONTH = 'month',
    YEAR = 'year'
}

