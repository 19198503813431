import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { ListOrganizationsComponent } from './list-organizations.component';
import { CdkTableModule } from '@angular/cdk/table';
import { OrganizationsService } from './organizations.service';
import { CreateOrganizationDialogModule } from './create-organization-dialog/create-organization-dialog.module';

let listOrganizationsState = {
    name: RouterStates.dashboard_organizations_listOrganizations,
    url: '/organizations?q', 
    component: ListOrganizationsComponent,
    params: {
        q: {
            type: 'string',
            dynamic: true
        }
    }
};

@NgModule({
  declarations: [
    ListOrganizationsComponent
  ],
  imports: [
    CoreComponentsModule,
    CdkTableModule,
    CreateOrganizationDialogModule,
    UIRouterModule.forChild({
      states: [listOrganizationsState]
    })
  ],
  exports: [
    
  ],
  providers: [
      OrganizationsService
  ],
  bootstrap: []
})
export class ListOrganizationsModule { }
