import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { GraduationsComponent } from './graduations.component';



let graduationsState = { name: RouterStates.dashboard_graduations, url: '/graduations', component: GraduationsComponent };

@NgModule({
  declarations: [
    GraduationsComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [graduationsState]
    })
  ],
  providers: [
  ],
  bootstrap: []
})
export class GraduationsModule {}

