import { Component, Input }from '@angular/core';
import { ITask } from '../../../../../api/tasks/task.model';

@Component({
    templateUrl: './task-basic.component.html',
    styleUrls: ['./task-basic.component.scss'],
    selector: 'task-basic'
})
export class TaskBasicComponent {

    @Input() public task: ITask;

}
