import {Observable, BehaviorSubject} from 'rxjs';
import {DataSource} from '@angular/cdk/table';

export class DashboardDataSource<T> extends DataSource<T> {
    private subject: BehaviorSubject<T[]> = new BehaviorSubject<T[]>([]);

    constructor() {
        super();
    }

    /** Connect function called by the table to retrieve one stream containing the data to render. */
    public connect(): Observable<T[]> {
        return this.subject; 
    }

    public disconnect() { return; }

    public set(values: T[]) {
        this.subject.next(values);
    }
}
