import { NgModule } from '@angular/core';
import { TeamOperationsComponent } from './team-operations.component';
import { CoreComponentsModule } from '../../../../../../core-components.module';
import { OperationsListModule } from '../../../../../../components/operations/operations-list/operations-list.module';

@NgModule({
  declarations: [
    TeamOperationsComponent
  ],
  imports: [
    CoreComponentsModule,
    OperationsListModule
  ],
  providers: [
  ],
  exports: [
    TeamOperationsComponent
  ],
  bootstrap: [
    TeamOperationsComponent
  ]
})
export class TeamOperationsModule {}

