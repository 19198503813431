import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { CdkTableModule } from '@angular/cdk/table';
import { ViewRequestComponent } from './view-request.component';

let moduleState = { 
  name: RouterStates.dashboard_teamRequests_request_view,
  url: '/teamrequests/request/{requestId}',
  component: ViewRequestComponent
};

@NgModule({
  declarations: [
    ViewRequestComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [moduleState]
    }),
    CdkTableModule
  ],
  exports: [
    
  ],
  providers: [
      
  ],
  bootstrap: []
})
export class ViewRequestModule { }

