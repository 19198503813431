import { Component, OnDestroy } from '@angular/core';
import { IUser } from '../../../../../api/users/user.model';
import { DashboardApiService } from '../../../../../api/dashboard-api.service';
import { UserService } from '../../user.service';
import { Subscription } from 'rxjs';
import { ObjectRef } from '../../../../../api/common/object-ref.model';
import { ObjectTypes } from '../../../../../api/common/object-types.enum';
import { IUserPermissions } from '../../../../../api/users/user-permissions.model';
import { DialogService, DialogCaption } from '../../../../../core/dialog.service';
import { finalize } from 'rxjs/operators';

@Component({
    templateUrl: './user-permissions.component.html',
    selector: 'user-permissions',
    styleUrls: ['./user-permissions.component.scss']
})
export class UserPermissionsComponent implements OnDestroy {

    public get user(): IUser { return this.userService ? this.userService.user : null; }
    public userSubscription: Subscription;
    public ref: ObjectRef;
    public refTypes = [ObjectTypes.Team, ObjectTypes.Room, ObjectTypes.Thread, ObjectTypes.Comment, ObjectTypes.Organization, ObjectTypes.Media];
    public result: IUserPermissions;
    public loading = false;

    constructor(
        private userService: UserService,
        private apiSerivce: DashboardApiService,
        private dialogService: DialogService) {
            this.subscribeUserChange();
            this.ref = new ObjectRef();
    }

    public ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
            this.userSubscription = null;
        }
    }

    public checkPermission() {
        
        this.result = null;
        let regex = /[0-9a-f]{24}/
        if (!regex.test(this.ref.id)) {
            this.dialogService.showDialog(new DialogCaption('USER_VIEW.INVALID_OBJECT_ID'));
            return;
        }
        this.loading = true;
        
        this.apiSerivce.users.getUserPermissions(this.user.id, this.ref)
            .pipe(
                finalize(() => this.loading = false)
            )
            .subscribe(result => {
                this.result = result;
            },
            err => {
                this.dialogService.showDialog(new DialogCaption(err));
            });
    }

    private subscribeUserChange() {
        this.userSubscription = this.userService.userSubject
            .subscribe(
                user => {
                    this.ref = new ObjectRef();
                    this.result = null;
                }
            );
    }
    
}