import { Injectable, Injector, NgZone } from "@angular/core";
import { environment } from 'environments/environment';
import * as CryptoJS from "crypto-js";
import { HttpClient, HttpErrorResponse, HttpHeaders, HttpParams, HttpUrlEncodingCodec } from "@angular/common/http";
import { Observable, combineLatest, of, throwError, timer } from "rxjs";
import { generateUuid } from "./uuid";
import { hexToBase64Url } from "./hex-to-base64";
import { BroadcastMessage } from "app/broadcast-message.model";
import { catchError, map, switchMap } from "rxjs/operators";
import { AuthToken } from "./auth-token.model";
import { TranslateService } from "@ngx-translate/core";
import { UserService } from "./user.service";
import { StateService, UIRouter } from "@uirouter/angular";
import { RouterStates } from "./router-states.constant";

const authorizeUrl: string = `${environment.susAuthority}/connect/authorize`;
const tokenUrl: string = `${environment.susAuthority}/connect/token`;
const logoutMessage = 'LOGOUT';

@Injectable({
    providedIn: 'root'
})
export class AuthenticationService {

    private writeSerivce = new BroadcastChannel(`auth-service-${environment.mode}`);

    constructor (
        private http: HttpClient,
        private injector: Injector,
        private translate: TranslateService, 
        private stateService: StateService,
        ngZone: NgZone) {
        this.writeSerivce.onmessage = (event) => {
            const message: BroadcastMessage | undefined = event.data;
            if (!message) { return; }
            if (message.key === logoutMessage) {
                ngZone.run(() => this.stateService.go(RouterStates.welcome));
            }
        }
    }

    public beginAuth(): void {
        const state = generateUuid();
        const codeVerifier = (generateUuid() + generateUuid() + generateUuid()).replace('-', '');
        const codeVerifierHash = CryptoJS.SHA256(codeVerifier).toString();
        const codeChallenge = hexToBase64Url(codeVerifierHash);

        window.sessionStorage.setItem(this.makeStateKey(state), JSON.stringify(new StateObject(environment.susClientId, codeVerifier)));
             
        let params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
            .set('client_id', environment.susClientId)
            .set('response_type', 'code')
            .set('scope', 'openid profile offline_access teams-admin-api')
            .set('redirect_uri', window.location.origin + '/#/authok')
            .set('state', state)
            .set('code_challenge', codeChallenge)
            .set('code_challenge_method', 'S256')
            .set('hl', this.translate.getDefaultLang());

            const queryString = params.toString();
            const url = `${authorizeUrl}?${queryString}`;
            window.location.href = url;
    }

    public validateAuth(code: string, state: string): Observable<boolean> {
        const key = this.makeStateKey(state);
        const storedValue = window.sessionStorage.getItem(key);
        window.sessionStorage.removeItem(key);
        
        if (!storedValue) {
            return of(false);
        }

        const stateObj: StateObject = JSON.parse(storedValue);
        if (!stateObj || !stateObj.clientId || !stateObj.codeVerifier) {
            return of(false);
        }

        return this.exchangeCode(stateObj.clientId, code, stateObj.codeVerifier)
            .pipe(
                catchError(err => {
                    return of(undefined);
                }),
                map(result => {
                    if (!result) {
                        return false;
                    }
                    
                    // Save the session in local storage
                    const sessionKey = this.makeSessionKey(environment.mode);
                    window.localStorage.setItem(sessionKey, JSON.stringify(result));
                    return true;
                }),
                // We should preload the user from the admin API before "completing" login
                // Since we've already saved the tokens, if something goes wrong and the user
                // refreshes, they should still be logged in.
                switchMap(isSuccess => {
                    const selfService = this.injector.get(UserService);
                    return isSuccess 
                        ? combineLatest([of(isSuccess), selfService.updateUserData()])
                        : combineLatest([of(isSuccess), of(undefined)]);
                }),
                map(([isSuccess, user]) => {
                    const loadedUser = !!user;
                    return isSuccess && loadedUser;
                })
            );
    }

    public isAuthenticated(): Observable<boolean> {
        return this.getAccessToken()
            .pipe(
                catchError(_ => of(false)),
                map(v => !!v)
            );
    }

    public getAccessToken(): Observable<string> {
        const errMsg = 'User session credentials not found.';
        const sessionKey = this.makeSessionKey(environment.mode);
        const currentToken = window.localStorage.getItem(sessionKey);
        if (!currentToken) {
            return throwError(() => errMsg);
        }
        const tokenData = <AuthToken>JSON.parse(currentToken);
        if (!tokenData) {
            return throwError(() => errMsg);
        }

        return of(tokenData.access_token);
    }

    public refreshToken(): Observable<string | undefined> {
        const noCredsErrMsg = 'User session credentials not found.';
        const tokenRefreshErrMsg = 'Token refresh failed.';
        const sessionKey = this.makeSessionKey(environment.mode);
        const currentToken = window.localStorage.getItem(sessionKey);
        if (!currentToken) {
            console.error(noCredsErrMsg);
            return of(undefined);
        }

        const currentTokenData = <AuthToken>JSON.parse(currentToken);
        if (!currentTokenData) {
            console.error(noCredsErrMsg);
            return of(undefined);
        }

        return this.performTokenRefresh(environment.susClientId, currentTokenData.refresh_token)
            .pipe(
                catchError(err => {
                    if (err instanceof HttpErrorResponse && err.status >= 400 && err.status < 500) {
                        return timer(500)
                            .pipe(switchMap(_ => {
                                const newToken = window.localStorage.getItem(sessionKey);
                                if (!newToken) {
                                    window.localStorage.removeItem(sessionKey);
                                    return throwError(() => err);
                                }

                                const newTokenData = <AuthToken>JSON.parse(newToken);
                                if (!newTokenData) {
                                    window.localStorage.removeItem(sessionKey);
                                    return throwError(() => err);
                                }

                                if (newTokenData.access_token !== currentTokenData.access_token) {
                                    return of(newTokenData);
                                }
                                
                                this.writeSerivce.postMessage(<BroadcastMessage>{ key: logoutMessage });
                                return throwError(() => err);
                            }));
                    }

                    window.localStorage.removeItem(sessionKey);
                    return throwError(() => err);
                }),
                switchMap(tokenData => {
                    if (!tokenData) {
                        return throwError(() => tokenRefreshErrMsg);
                    }
                    
                    window.localStorage.setItem(sessionKey, JSON.stringify(tokenData));
                    return of(tokenData.access_token);
                }),
                catchError(err => { console.error(err); this.logout(); return of(undefined) })
            );
    }

    public logout(): void {
        const sessionKey = this.makeSessionKey(environment.mode);
        window.localStorage.removeItem(sessionKey);
        this.writeSerivce.postMessage(<BroadcastMessage>{ key: logoutMessage });
        this.stateService.go(RouterStates.welcome);
    }

    private exchangeCode(clientId: string, code: string, verifier: string): Observable<AuthToken | null> {
        const currentUrl = window.location.href;
        const queryStartIdx = currentUrl.indexOf('?');
        const redirectUri = queryStartIdx >= 0 ? currentUrl.substring(0, queryStartIdx) : currentUrl;
        
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded');

        const params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
            .set('grant_type', 'authorization_code')
            .set('client_id', clientId)
            .set('redirect_uri', redirectUri)
            .set('code', code)
            .set('code_verifier', verifier);

        const requestBody = params.toString();

        return this.http.post<AuthToken>(tokenUrl, requestBody, { 
            headers,
            observe: 'response'
        }).pipe(map(r => r.body));

    }

    private performTokenRefresh(clientId: string, refreshToken: string): Observable<AuthToken | null> {
        const headers = new HttpHeaders()
            .set('Content-Type', 'application/x-www-form-urlencoded');

        const params = new HttpParams({ encoder: new HttpUrlEncodingCodec() })
            .set('grant_type', 'refresh_token')
            .set('client_id', clientId)
            .set('refresh_token', refreshToken);

        const requestBody = params.toString();

        return this.http.post<AuthToken>(tokenUrl, requestBody, { 
            headers,
            observe: 'response'
        }).pipe(map(r => r.body));
    }

    makeStateKey(state: string): string {
        return `auth_${state}`;
    }

    makeSessionKey(environment: string): string {
        return `session_${environment}`;
    }
}



class StateObject {
    constructor (public clientId: string, public codeVerifier: string) { }
}