import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { TasksComponent } from './tasks.component';
import { ViewTaskModule } from './view-task/view-task.module';
import { ListTasksModule } from './list-tasks/list-tasks.module';
import { CreateTaskModule } from './create-task/create-task.module';

let tasksState = { name: RouterStates.dashboard_tasks, component: TasksComponent };

@NgModule({
  declarations: [
    TasksComponent
  ],
  imports: [
    CoreComponentsModule,
    ListTasksModule,
    ViewTaskModule,
    CreateTaskModule,
    UIRouterModule.forChild({
      states: [tasksState]
    })
  ],
  exports: [
    
  ],
  providers: [
    
  ],
  bootstrap: []
})
export class TasksModule { }

