import { Pipe, PipeTransform, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';
import { PlanPeriod } from '../../api/plans/plan-period.model';

@Pipe({ name: 'sprepetition', pure: false })
export class SplyzaRepetitionPipe implements PipeTransform, OnDestroy {

    private subscription: Subscription;

    constructor(private _ref: ChangeDetectorRef, private translateService: TranslateService) {

        this.subscription = this.translateService.onTranslationChange
            .subscribe((t) => {
                this._ref.markForCheck();
            });

    }

    public transform(repetitionPeriod: PlanPeriod, repetitionCount?: number): any {

        if (repetitionCount == null) {
            repetitionCount = 1;
        }
        if (repetitionCount === 1) {
            return this.translateService.instant('OPERATION_TEMPLATES.REPETITION_ONCE');
        }
         
        let singlePeriod = this.translateService.instant('SHARED.STORAGE.PERIOD.' + repetitionPeriod.periodType.toUpperCase());
        let pluralPeriod = this.translateService.instant('SHARED.STORAGE.DISPLAY_PERIOD.' + repetitionPeriod.periodType.toUpperCase());

        let period = repetitionPeriod.periodLength === 1 
            ? singlePeriod
            : pluralPeriod;

        let totalLength = repetitionPeriod.periodLength * repetitionCount;
        let totalPeriod = totalLength === 1
            ? singlePeriod
            : pluralPeriod;

        return this.translateService.instant('OPERATION_TEMPLATES.REPETITION_PERIOD',
            {
                frequency: this.translateService.instant('OPERATION_TEMPLATES.REPETITION_TIME', { length: repetitionPeriod.periodLength, type: period }),
                period: this.translateService.instant('OPERATION_TEMPLATES.REPETITION_TIME', { length: totalLength, type: totalPeriod })
            });
    }

    public ngOnDestroy() {

        if (this.subscription) {
            this.subscription.unsubscribe();
        }

    }
}
