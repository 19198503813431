import { Component, Inject, OnInit } from '@angular/core';
import { SelectPlaceholderService } from './select-placeholder.service';
import { MatDialogRef, MAT_DIALOG_DATA } from '@angular/material';
import { IEmailPlaceholder } from 'app/api/email/email-placeholder.model';
import { BehaviorSubject } from 'rxjs';
import * as _ from 'underscore';
import { IEmailPlaceholderModifier } from 'app/api/email/email-placeholder-filter.model';

export class SelectPlaceholderInputData {

}

@Component({
    templateUrl: './select-placeholder.component.html',
    styleUrls: ['./select-placeholder.component.scss']
})
export class SelectPlaceholderComponent implements OnInit {
    
    public placeholders = new BehaviorSubject<IEmailPlaceholder[]>([]);
    public modifiers = new BehaviorSubject<IEmailPlaceholderModifier[]>([]);
    public get isLoading(): boolean { return this.placeholderService.isLoading; }
    public displayedColumns = ['placeholderIcon', 'placeholder', 'sample', 'description', 'buttons'];
    private path: IEmailPlaceholder[] = [];
    private topLevel: IEmailPlaceholder[] = null;

    constructor(
        private placeholderService: SelectPlaceholderService,
        private mdDialogRef: MatDialogRef<SelectPlaceholderComponent>,
        @Inject(MAT_DIALOG_DATA) private inputData: SelectPlaceholderInputData) {

    }

    public ngOnInit(): void {
        
        this.placeholderService.loadPlaceholders()
            .subscribe(
                p => {
                    this.placeholdersReloaded(p.placeholders.items);
                    this.modifiers.next(_.sortBy(p.modifiers.items, i => i.usageSample));
                },
                err => { /**/ });

    }

    public close() {
        this.mdDialogRef.close(null);
    }

    public selectPlaceholder(placeholder: IEmailPlaceholder) {
        this.mdDialogRef.close('{{' + placeholder.placeholder + '}}'); 
    }

    public viewArray(placeholder: IEmailPlaceholder) {
        this.path.push(placeholder);
        this.placeholders.next(placeholder.itemPlaceholders);
    }

    public navigatePath(level: number) {
        if (level === -1) {
            this.path = [];
            this.placeholders.next(this.topLevel);
        }
        else if (level < this.path.length - 1) {
            this.path = this.path.slice(0, level + 1);
            this.placeholders.next(this.path[level].itemPlaceholders);
        }
    }

    private placeholdersReloaded(placeholders: IEmailPlaceholder[]) {
        this.topLevel = _.sortBy(placeholders, i => i.placeholder);

        let currentLevel = this.topLevel;
        for (let i = 0; i < this.path.length; ++i) {
            let currentItem = this.path[i];
            let pathItem = _.find(currentLevel, c => c.placeholder === currentItem.placeholder);

            if (pathItem == null) {
                this.path = this.path.slice(0, i + 1);
            } else {
                this.path[i] = pathItem;
                currentLevel = pathItem.itemPlaceholders;
            }
        }
        this.placeholders.next(currentLevel);
    }
}
