import { NgModule } from '@angular/core';
import { OperationModalModule } from './operation-modal/operation-modal.module';
import { OperationCreationModalModule } from './operation-creation-modal/operation-creation-modal.module';

let modules: any[] = [
    OperationModalModule,
    OperationCreationModalModule
];

@NgModule({
  imports: modules,
  exports: modules
})
export class OperationsSharedModule { }

