import { NgModule } from '@angular/core';
import { ScrollableComponent } from './scrollable.component';
import { CoreModulesModule } from '../../core-modules.module';


@NgModule({
  declarations: [
      ScrollableComponent
  ],
  imports: [
      CoreModulesModule
  ],
  exports: [
      ScrollableComponent
  ],
  providers: [
  ],
  bootstrap: [
      ScrollableComponent
  ]
})
export class ScrollableModule { }
