import { Component } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { DashboardSidebarService } from '../../../core/dashboard-sidebar.service';

@Component({
    templateUrl: './operation-template.component.html'
})
export class OperationTemplateComponent {

    constructor(private stateService: StateService,
                private dashboardSidebarService: DashboardSidebarService) {

    }
}
