import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { PlansComponent } from './plans.component';
import { CdkTableModule } from '@angular/cdk/table';
import { ViewPlanModule } from './view-plan/view-plan.module';

let plansState = { name: RouterStates.dashboard_plans, url: '/plans', component: PlansComponent };

@NgModule({
  declarations: [
    PlansComponent
  ],
  imports: [
    CoreComponentsModule,
    ViewPlanModule,
    UIRouterModule.forChild({
      states: [plansState]
    }),
    CdkTableModule
  ],
  providers: [
    PlansComponent
  ],
  bootstrap: []
})
export class PlansModule {}

