import { NgModule } from '@angular/core';
import { DatepickerDirective } from './datepicker.directive';
import { CoreModulesModule } from '../../core-modules.module';

@NgModule({
  declarations: [
      DatepickerDirective
  ],
  imports: [
      CoreModulesModule
  ],
  exports: [
      DatepickerDirective
  ],
  providers: [
  ],
  bootstrap: [
  ]
})
export class DatepickerModule { }
