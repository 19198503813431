
import {finalize} from 'rxjs/operators';
import { Component, OnDestroy } from '@angular/core';
import { IUser } from '../../../../../api/users/user.model';
import { UserService } from '../../user.service';
import { PlanService } from '../../../../../core/plan.service';
import { ISubscriptionPlan } from '../../../../../api/plans/subscription-plan.model';
import { IUserTeamPlan } from '../../../../../api/users/user-team-plan.model';
import { UserTeamPlanRequest } from '../../../../../api/users/user-team-plan-request.model';
import { Subscription } from 'rxjs';
import { deepCopy } from '../../../../../core/deep-copy.function';
import * as _ from 'underscore';
import { OperationTemplateService } from '../../../../../core/operation-template.service';
import { IOperationTemplateSet } from '../../../../../api/operations/operation-template.model';
import { OperationTemplateTypes } from '../../../../../api/operations/operation-template-types.enum';
import { MatSelectChange } from '@angular/material';

@Component({
    templateUrl: './user-basic.component.html',
    selector: 'user-basic',
    styleUrls: ['./user-basic.component.scss']
})
export class UserBasicComponent implements OnDestroy {

    public get user(): IUser { return this.userService ? this.userService.user : null; }
    // public get plans(): ISubscriptionPlan[] { return (this.planService.plans && this.planService.plans.items) ? this.planService.plans.items : []; }
    public get templates(): IOperationTemplateSet[] { return this.templateService.templateSets; }
    public isEditing = false;
    public isSaving = false;
    public userSubscription: Subscription;
    public currentTemplate: IOperationTemplateSet;
    public userTeamPlan: IUserTeamPlan = null;
    private dummyTemplate: IOperationTemplateSet = { 
        type: OperationTemplateTypes.TemplateSet,
        name: null,
        identifier: { key: '', version: 0 },
        isArchived: false,
        requiredProcedures: null,
        templates: null,
        childSettings: null 
    };

    constructor(
        private userService: UserService,
        private templateService: OperationTemplateService) {
        this.subscribeUserChange();
    }

    public ngOnDestroy() {
        if (this.userSubscription) {
            this.userSubscription.unsubscribe();
            this.userSubscription = null;
        }
    }

    public toggleEditing() {
        if (!this.isEditing) {
            this.isEditing = true;
            this.userTeamPlan = deepCopy(this.user.teamPlan);
            if (this.userTeamPlan.defaultTeamPlan !== '' && !this.userTeamPlan.defaultTeamPlan.match(/[a-z0-9]{24}/i)) {
                this.userTeamPlan.defaultTeamPlan = '';
            }
        }
        else {
            this.save();
        }
    }

    public cancelEditing() {
        this.isEditing = false;
    }

    private save() {
        let hasChanges = false;
        let request = new UserTeamPlanRequest();

        if (this.userTeamPlan.defaultTeamPlan !== this.user.teamPlan.defaultTeamPlan) {
            hasChanges = true;
            request.defaultTeamPlan = this.userTeamPlan.defaultTeamPlan;
        }

        if (this.userTeamPlan.teamsLimit !== this.user.teamPlan.teamsLimit) {
            hasChanges = true;
            request.teamsLimit = this.userTeamPlan.teamsLimit;
        }

        if (!hasChanges) {
            this.isEditing = false;
            return;
        }
        
        this.isSaving = true;
        this.userService.patchUserTeamPlan(this.user.id, request).pipe(
            finalize(() => this.isSaving = false))
            .subscribe(
                r => {
                    this.updateUserPlan();
                    this.isEditing = false;
                }
            );
    }

    private subscribeUserChange() {
        this.userSubscription = this.userService.userSubject
            .subscribe(
                user => {
                    if (user) {
                        this.updateUserPlan();
                    }
                }
            );
    }

    private updateUserPlan() {
        let template = _.find(this.templates, t => t != null && t.identifier.key === this.user.teamPlan.defaultTeamPlan);
        if (template == null) {
            template = this.dummyTemplate;
            this.user.teamPlan.defaultTeamPlan = '';
        }
        this.currentTemplate = template;
    }
}
