import { IApiError } from './api-error.model';
import { Observable } from 'rxjs';
import { take } from 'rxjs/operators';
import { IApiErrorCollection } from './api-error-collection.model';
import { HttpResponse, HttpErrorResponse } from '@angular/common/http';

export function getApiErrors(response: HttpErrorResponse): IApiError[] {
    if (response.status >= 400 && response.status <= 500) {
        try {
            let parsedResult = <IApiErrorCollection><any>(response.error);
            if (parsedResult != null && parsedResult.errors != null) {
                return parsedResult.errors;
            }

            // If the response type is "string" we seem to get the JSON response as string
            // not sure why! The server returns JSON with the correct Content-Type.
            // Must be an Angular thing.
            if (typeof parsedResult == 'string') {
                let result = <IApiErrorCollection>JSON.parse(parsedResult);
                if (result != null && result.errors != null) {
                    return result.errors;
                }
            }
        } catch (pe) {
            console.error('could not parse error', pe);
        }
        return null;
    }
}
