import { Component, OnDestroy, OnInit, ViewChild } from '@angular/core';
import { TeamService } from '../team.service';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton } from '../../../../core/dialog.service';
import { StateService } from '@uirouter/angular';
import { Subscription } from 'rxjs';
import { ITeam } from 'app/api/teams/team.model';
import { IGraduation } from 'app/api/teams/graduation.model';
import { finalize } from 'rxjs/operators';
import { PaginatorComponent } from 'app/components/paginator/paginator.component';
import { GraduationStatus } from 'app/api/teams/graduation-status.enum';
import { HttpErrorResponse } from '@angular/common/http';
import { IApiError } from 'app/api/error/api-error.model';
import { getApiErrors } from 'app/api/error/get-api-errors.function';
import * as _ from 'underscore';

@Component({
    templateUrl: './team-graduations.component.html',
    styleUrls: ['./team-graduations.component.scss']
})
export class TeamGraduationsComponent implements OnInit, OnDestroy  {

    private teamSubscription: Subscription;
    private paginatorSubscription: Subscription;

    public loading = false;
    public updating = false;
    public graduations: IGraduation[] = [];
    public currentMemberIds: { [id: string]: boolean } = {};
    public get team(): ITeam { return this.teamService.team; }
    @ViewChild(PaginatorComponent, { static: true }) private paginator: PaginatorComponent;
    
    constructor(
        private teamService: TeamService,
        private dialogService: DialogService,
        private stateService: StateService) {
    }

    public ngOnInit() {
        this.paginatorSubscription = this.paginator.onPageChange
            .subscribe(() => {
                this.loadPage();
        });
        this.subscribeTeamChange();
    }

    public ngOnDestroy() {
        if (this.teamSubscription) {
            this.teamSubscription.unsubscribe();
            this.teamSubscription = null;
        }

        if (this.paginatorSubscription) {
            this.paginatorSubscription.unsubscribe();
            this.paginatorSubscription = null;
        }
    }

    public revertGraduation(graduation: IGraduation) {
        this.dialogService.showDialog(
            new DialogCaption('TEAM_GRADUATIONS.CONFIRM_REVERT', true, { userName: graduation.user.name, userId: graduation.user.id }),
            new DialogYesButton(() => {

                this.updating = true;
                this.teamService.updateGraduation(graduation.id, GraduationStatus.Reverting)
                    .subscribe(g => {
                        this.replaceGraduation(graduation, g);
                        this.updating = false;
                    },
                    err => {
                        let errorCollection: IApiError[] = null;
                        if (err instanceof HttpErrorResponse && (errorCollection = getApiErrors(err)) != null && errorCollection.length > 0) {
                            let errorObject = errorCollection[0];
                            if (errorObject.code === 'INVALID_STARTING_STATE') {
                                this.dialogService.showDialog(new DialogCaption('TEAM_GRADUATIONS.INVALID_STATE_REVERT'));
                            } else if (errorObject.code === 'USER_CURRENT_MEMBER') {
                                this.dialogService.showDialog(new DialogCaption('TEAM_GRADUATIONS.ALREADY_MEMBER'));
                            }
                            this.refreshUser(graduation);
                        }
                    });

            }),
            new DialogNoButton()
        );
    }

    public cancelGraduation(graduation: IGraduation) {
        this.dialogService.showDialog(
            new DialogCaption('TEAM_GRADUATIONS.CONFIRM_CANCEL', true, { userName: graduation.user.name, userId: graduation.user.id }),
            new DialogYesButton(() => {

                this.updating = true;
                this.teamService.updateGraduation(graduation.id, GraduationStatus.Cancelled)
                    .subscribe(g => {
                        this.replaceGraduation(graduation, g);
                        this.updating = false;
                    },
                    err => {
                        let errorCollection: IApiError[] = null;
                        if (err instanceof HttpErrorResponse && (errorCollection = getApiErrors(err)) != null && errorCollection.length > 0) {
                            let errorObject = errorCollection[0];
                            if (errorObject.code === 'INVALID_STARTING_STATE') {
                                this.dialogService.showDialog(new DialogCaption('TEAM_GRADUATIONS.INVALID_STATE_CANCEL'));
                            }
                            this.refreshUser(graduation);
                        }
                    });

            }),
            new DialogNoButton()
        );
    }

    private refreshUser(existingGraduation: IGraduation) {

        this.teamService.getGraduation(existingGraduation.id)
            .pipe(finalize(() => this.updating = false))
            .subscribe(g => {
                this.replaceGraduation(existingGraduation, g);
                this.updating = false;
            });
            
    }

    private replaceGraduation(existingGraduation: IGraduation, newGraduation: IGraduation) {
        let idx = this.graduations.indexOf(existingGraduation);
        if (idx >= 0) {
            this.graduations.splice(idx, 1, newGraduation);
            this.graduations = _.toArray(this.graduations);
        }
    }

    public refresh() {
        if (!this.loading) {
            this.loadPage();
        }
    }

    private subscribeTeamChange() {
        this.teamSubscription = this.teamService.teamSubject
            .subscribe(team => {
                if (!team) {
                    this.loading = true;
                    return;
                } else {
                    this.paginator.pageIndex = 0;
                    this.loadPage();
                }
            });
    }

    private loadPage() {
        this.loading = true;
        this.teamService.getTeamGraduations(this.team.id, this.paginator.pageIndex * this.paginator.pageSize, this.paginator.pageSize)
            .pipe(finalize(() => this.loading = false))
            .subscribe(g => {
                this.graduations = g.items;

                let currentMembers: { [id: string]: boolean } = {};
                for (let id of g.currentMemberIds) {
                    currentMembers[id] = true;
                }
                this.currentMemberIds = currentMembers;

                this.paginator.itemCount = g.total;
                this.paginator.pageSize = this.paginator.pageSize;
            });
    }

}