import { Component, OnInit, OnDestroy } from '@angular/core';
import { DashboardApiService } from '../../../../api/dashboard-api.service';
import { OrganizationsService } from './organizations.service';
import { StateService, TransitionService, Transition, or } from '@uirouter/angular';
import * as _ from 'underscore';
import { MatDialog, MatDialogRef } from '@angular/material';
import { CreateOrganizationDialogComponent } from './create-organization-dialog/create-organization-dialog.component';
import { IOrganization } from '../../../../api/organizations/organization.model';
import { RouterStates } from '../../../../core/router-states.constant';

@Component({
    templateUrl: './list-organizations.component.html',
    styleUrls: ['./list-organizations.component.scss']
})
export class ListOrganizationsComponent implements OnInit, OnDestroy {

    public get loadingOrganizations(): boolean { return this.organizationsService.loadingData; }
    public get dataSource() { return this.organizationsService.dataSource; }
    public displayedColumns = ['organization', 'created'];
    public searchText: string;

    private debouncedRefresh: Function;
    private stopWatchingTransition: Function;
    private lastSearchText: string;

    constructor (
        private api: DashboardApiService,
        private organizationsService: OrganizationsService,
        private stateService: StateService,
        private transitionService: TransitionService,
        private mdDialog: MatDialog) {
        
        let self = this;
        this.debouncedRefresh = _.debounce(() => { self.refresh(); }, 500);
    }

    public ngOnInit() {
        this.initialize();
    }

    public ngOnDestroy() {
      if (this.stopWatchingTransition) {
        this.stopWatchingTransition();
      }
    }

    public performSearch() {
      if (this.searchText !== this.lastSearchText) {
        this.debouncedRefresh();
      }
    }

    public createOrganization() {
        this.showCreateOrganizationDialog()
            .afterClosed()
            .subscribe(result => {
                if (result != null) {
                    this.viewOrganization(result.organization);
                }
            });
    }

    public viewOrganization(organization: IOrganization) {
        this.stateService.go(RouterStates.dashboard_organizations_organization_view, { organizationId: organization.id, organizationName: organization.name });
    }

    private showCreateOrganizationDialog(): MatDialogRef<CreateOrganizationDialogComponent>  {
        return this.mdDialog.open(CreateOrganizationDialogComponent, { 
            width: '1000px',
            height: '600px'
        });
    }

    private initialize() {
      this.updateFromState();
      this.stopWatchingTransition = this.transitionService.onSuccess({ to: this.stateService.current.name }, (trans: Transition) => {
          this.updateFromState();
      });
      	
      this.refresh();
    }

    private updateFromState() {
      let searchText = this.stateService.params.q;
      let changes = false;

      if (searchText && searchText !== this.searchText) {
          this.searchText = decodeURIComponent(searchText);
          changes = true;
      }
      
      if (changes) {
          this.refresh();
      }
    }

    private refresh() {
        let searchText = null;
        if ((this.searchText || '').trim().length > 0) {
            searchText = this.searchText;
        }

        this.organizationsService
            .reset(searchText)
            .subscribe(o => {
                this.stateService.transitionTo(this.stateService.current.name,
                { // if new parameters are added here, add them as dynamic in the routing
                      q: this.searchText
                });
            });
    }

}
