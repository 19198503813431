export function toModifiedBase64(str: string): string {
    return replaceAll(replaceAll(replaceAll(window.btoa(str), '+', '-'), '/', '_'), '=', '');
}

export function fromModifiedBase64(str: string): string {
    str = replaceAll(replaceAll(str, '-', '+'), '_', '/');
    let diff = 4 - (str.length % 4);
    if (diff < 4) {
        str = str + '='.repeat(diff);
    }
    return window.atob(str);
}

export function replaceAll(str: string, search: string, replacement: string): string {
    return str.split(search).join(replacement);
}
