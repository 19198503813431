import { Component, OnDestroy, OnInit } from '@angular/core';
import { Observable, Subscription } from 'rxjs';
import { TeamService } from '../../../team.service';
import { ITeam } from '../../../../../../api/teams/team.model';
import { DashboardApiService } from '../../../../../../api/dashboard-api.service';
import { IOperation } from '../../../../../../api/operations/operation.model';

import { PatchOperationRequest } from '../../../../../../api/operations/patch-operation-request.model';
import { OperationsRevisionRequest } from '../../../../../../api/operations/operations-revision-request.model';
import { IOperationsRevision } from '../../../../../../api/operations/operations-revision.model';
import { UserService } from 'app/core/user.service';
import { finalize } from 'rxjs/operators';

@Component({
    templateUrl: './team-operations.component.html',
    styleUrls: ['./team-operations.component.scss'],
    selector: 'team-operations'
})
export class TeamOperationsComponent implements OnInit, OnDestroy {
    
    public get team(): ITeam { return this.teamService ? this.teamService.team : null; }
    public subscription = new Subscription();
    public operations: IOperation[];

    private lastTeamId: string = null;
    private loadingOperationsSubscription: Subscription = null;

    constructor (
        private teamService: TeamService,
        private api: DashboardApiService,
        private userService: UserService) {

    }

    public getEditPermissionCheck(): (() => boolean) {
        return () => {
          
            if (this.team == null) {
                return false;
            }

            if (!this.team.isCrmManaged) {
                return this.userService.hasRole('Accounts');
            } else {
                return this.userService.hasRole('Administrator');
            }

        };
    }

    public ngOnInit() {
        let teamSubscription = this.teamService.teamSubject
            .subscribe(t => {
                if (t.id !== this.lastTeamId) {
                    this.lastTeamId = t.id;
                    this.loadOperations();
                }
            });

        this.subscription.add(teamSubscription);
    }
    
    public ngOnDestroy() {
        this.subscription.unsubscribe();
    }

    public getOperationFunc(): (operationId: string) => Observable<IOperation> {
        return (operationId: string) => 
                    this.api.teamOperations.getOperation(this.team.id, operationId);
    }

    public getReviseOperationsFunc(): (revisionParams: OperationsRevisionRequest) => Observable<IOperationsRevision> {
        return (revisionParams: OperationsRevisionRequest) => 
                    this.api.teamOperations.reviseOperations(this.team.id, revisionParams);
    }

    public getSaveOperationFunc(): (operationId: string, operationParams: PatchOperationRequest) => Observable<IOperation> {
        return (operationId: string, operationParams: PatchOperationRequest) => 
                    this.api.teamOperations.patchOperation(this.team.id, operationId, operationParams);
    }

    public getDeleteOperationFunc(): (operationId: string) => Observable<Response> {
        return (operationId: string) =>
                    this.api.teamOperations.deleteOperation(this.team.id, operationId);
    }

    private loadOperations() {
        if (this.loadingOperationsSubscription != null) {
            this.loadingOperationsSubscription.unsubscribe();
        }
        let teamId = this.team.id;
        this.loadingOperationsSubscription = this.api.teamOperations.getOperations(this.team.id)
            .pipe(
                finalize(() => this.loadingOperationsSubscription = null)
            )
            .subscribe(o => {
                if (teamId !== this.team.id) {
                    return;
                }
                this.operations = o.items;
            });
    }

}
