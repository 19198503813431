import { Component, NgZone, ViewChild, OnInit } from '@angular/core';
import { IOrganization } from '../../../../../../api/organizations/organization.model';
import { OrganizationService } from '../../organization.service';
import { MatTab } from '@angular/material';
import { StorageStatsRequestModel } from '../../../../../../api/plans/storage-stats-request.model';
import { IStorageStats } from '../../../../../../api/plans/storage-stats.model';
import { Observable } from 'rxjs';

@Component({
    templateUrl: './organization-plan.component.html',
    selector: 'organization-plan'
})
export class OrganizationPlanComponent implements OnInit {

    public get organization(): IOrganization { return this.organizationService ? this.organizationService.organization : null; }
    // public editing : Editing;
    private _isEditing = false;

    constructor(private organizationService: OrganizationService) {
	
    }
    
    public updateStorageStats(): (request: StorageStatsRequestModel) => Observable<IStorageStats> {
        let self = this;
        return (request) => self.organizationService.updateOrganizationStorageStats(self.organization.id, request);
    }

    public ngOnInit() {
        return;
    }
}
