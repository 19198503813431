import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../../core-components.module';
import { AngularEditorModule } from '@kolkov/angular-editor';
import { FormsModule } from '@angular/forms';
import { SelectPlaceholderComponent } from './select-placeholder.component';
import { SelectPlaceholderService } from './select-placeholder.service';

@NgModule({
  declarations: [
    SelectPlaceholderComponent
  ],
  imports: [
    CoreComponentsModule, 
    FormsModule,
    AngularEditorModule
  ],
  exports: [
  ],
  providers: [    
    SelectPlaceholderService
  ],
  entryComponents: [
    SelectPlaceholderComponent
  ],
  bootstrap: [
  ]
})
export class SelectPlaceholderModule { }
