
import {finalize} from 'rxjs/operators';
import { Component, Input, ViewChild } from '@angular/core';
import { ITeam } from '../../../../../../api/teams/team.model';

import { TeamPlanRequest } from '../../../../../../api/teams/team-plan-request.model';







import { TeamService } from '../../../team.service';
import { StoragePlanComponent } from '../../../../../../components/storage-plan/storage-plan.component';




@Component({
    templateUrl: './team-storage-plan.component.html',
    styleUrls: ['./team-storage-plan.component.scss'],
    selector: 'team-storage-plan'
})
export class TeamStoragePlanComponent {

    @Input() public isEditing: boolean;
    @ViewChild('storagePlan', { static: true }) public storagePlanComponent: StoragePlanComponent;

    public isSaving = false;
    public get team(): ITeam { return this.teamService ? this.teamService.team : null; }

    constructor (private teamService: TeamService) {

    }

    public toggleEditing() {
        if (!this.storagePlanComponent.isEditing) {
            this.storagePlanComponent.beginEditing();
        }
        else {
            this.save();
        }
    }

    public cancelEditing() {
        this.storagePlanComponent.cancelEditing();
    }
 
    private save() {
        let result = this.storagePlanComponent.getPlanToSave();

        let self = this;
        if (result.isValid && result.hasChanges) {
            this.isSaving = true;
            let request = new TeamPlanRequest();
            request.storagePlan = result.planRequest;
            this.teamService.patchTeamPlan(this.team.id, request).pipe(
                finalize(() => { this.isSaving = false; }))
                .subscribe(
                    updatedPlan => {
                        self.storagePlanComponent.completeEditing();
                    },
                    error => {
                        console.error('error', error);
                    }
                );
        }
        else if (result.isValid && !result.hasChanges) {
            this.storagePlanComponent.cancelEditing();
        }
    }
}
