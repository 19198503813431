import { Component, OnDestroy } from '@angular/core';
import { DashboardApiService } from '../../../api/dashboard-api.service';
import { StateService, TransitionService, Transition } from '@uirouter/angular';
import { RouterStates } from '../../../core/router-states.constant';
import { toModifiedBase64 } from '../../../core/modified-base64.function';
import { take } from 'rxjs/operators';
import { IValidatedUrl } from '../../../api/url-validation/validated-url.model';
import { ITeam } from '../../../api/teams/team.model';

@Component({
    templateUrl: './url-validator.component.html',
    styleUrls: ['./url-validator.component.scss']
})
export class UrlValidatorComponent implements OnDestroy {

    public inputUrl: string;
    public loading = true;
    public validatedUrl: IValidatedUrl;
    public showError = false;

    private stopWatchingTransition: Function = null;

    constructor(
        private api: DashboardApiService,
        private stateService: StateService,
        private transitionService: TransitionService) {
        
        this.updateFromState();
        this.stopWatchingTransition = this.transitionService.onSuccess({ from: this.stateService.current.name, to: this.stateService.current.name }, (trans: Transition) => {
            this.updateFromState(true);
        });

        if (this.inputUrl.trim().length === 0) {
            this.loading = false;
        }
        else {
            this.doValidate();
        }
    }

    public updateFromState(fromStateChange?: boolean) {
        if (this.stateService.params.url !== this.inputUrl) {
            this.inputUrl = decodeURIComponent(this.stateService.params.url || '');
            if (fromStateChange && (this.inputUrl || '').trim().length !== 0) {
                this.doValidate();
            }
        }
    }

    public ngOnDestroy() {
        if (this.stopWatchingTransition != null) {
            this.stopWatchingTransition();
        }
    }

    public validate(fromStateChange?: boolean) {
        
        let encodedUri = encodeURIComponent(this.inputUrl);
        if (this.stateService.params.url !== encodedUri) {
            this.stateService.transitionTo(this.stateService.current.name, { url: this.inputUrl });
        }

        if ((this.inputUrl || '').trim().length === 0) {
            return;
        }

        if (this.loading) {
            return;
        }

        this.doValidate();
    }

    public viewTeam(team: ITeam) {
        this.stateService.go(RouterStates.dashboard_team_view, { teamId: team.id, teamName: team.name }, { reload: true });
    }

    public selectUrl($event: MouseEvent) {
        let range = document.createRange();
        range.selectNode(<any>$event.srcElement);
        window.getSelection().removeAllRanges();
        window.getSelection().addRange(range);
    }

    private doValidate() {
        this.loading = true;
        this.showError = false;
        let encodedUri = toModifiedBase64(this.inputUrl.trim());
        this.api.urlValidation.getInfo(encodedUri)
            .subscribe(
                val => {
                    console.log(val);
                    this.validatedUrl = val;
                    this.loading = false;
                },
                err => {
                    console.error('unable to load url', err);
                    this.showError = true;
                    this.validatedUrl = null;
                    this.loading = false;
                }
            );
    }
}
