import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { RouterStates } from '../../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { TeamSetupsService } from './team-setups.service';
import { TeamSetupsComponent } from './team-setups.component';
import { CdkTableModule } from '@angular/cdk/table';

let teamSetupsState = { name: RouterStates.dashboard_teamSetup_setups, url: '/team-setups', component: TeamSetupsComponent };

@NgModule({
  declarations: [
    TeamSetupsComponent
  ],
  imports: [
    CoreComponentsModule,
    // ViewTeamModule,
    // TeamMembersModule,
    UIRouterModule.forChild({
      states: [teamSetupsState]
    }),
    CdkTableModule
  ],
  exports: [
    
  ],
  providers: [
    TeamSetupsService
  ],
  bootstrap: []
})
export class TeamSetupsModule { }

