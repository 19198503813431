import { Component, OnInit } from '@angular/core';
import { RouterStates } from '../../../../core/router-states.constant';
import { UserService } from '../../../../core/user.service';
import { DashboardSidebarService } from '../../../../core/dashboard-sidebar.service';
import { StateService,  StateDeclaration, TransitionService, Transition } from '@uirouter/angular';
import { Button } from 'selenium-webdriver';

@Component({
    templateUrl: './dashboard-sidebar.component.html',
    host: {
        'class': 'flex-component'
    },
    selector: 'dashboard-sidebar'
})
export class DashboardSidebarComponent implements OnInit {

    public readonly BUTTONS = {
        HOME: 'home',
        USERS: 'users',
        USER: 'user',
        GRADUATIONS: 'graduations',
        TEAMS: 'teams',
        TEAM: 'team',
        TEAM_MEMBERS: 'teamMembers',
        TEAM_GRADUATIONS: 'teamGraduations',
        TEAM_SETUPS: 'teamSetups',
        TEAM_SETUPS_NEW: 'teamSetupsNew',
        TEAM_SETUPS_VIEW: 'teamSetupsView',
        TEAM_THREADS: 'teamThreads',
        THREADS: 'threads',
        THREAD_VIEW: 'threadsView',
        PLANS: 'plans',
        OPERATION_TEMPLATES: 'operationTemplates',
        OPERATION_TEMPLATES_NEW: 'operationTemplatesNew',
        OPERATION_TEMPLATES_VIEW: 'operationTemplatesView',
        URL_VALIDATOR: 'urlValidator',
        ORGANIZATIONS: 'organizations',
        ORGANIZATION_VIEW: 'organizationView',
        ORGANIZATION_MEMBERS: 'organizationMembers',
        ORGANIZATION_IMPORT_CONFIG: 'organizationImportConfig',
        TASKS: 'tasks',
        TEAM_REQUESTS: 'teamRequests',
        TEAM_REQUEST_VIEW_REQUEST: 'teamRequests.viewRequest',
        TEAM_REQUEST_VIEW_RENEWAL: 'teamRequests.viewRenewal',
        EMAIL_TEMPLATES_LIST: 'emailTemplates.list',
        EMAIL_TEMPLATES_NEW: 'emailTemplates.new',
        EMAIL_TEMPLATES_VIEW: 'emailTemplates.edit',
        CLUSTER_SCRIPTS: 'clusterScripts',
        CONNECTIVITY_TESTER: 'connectivityTester'
    };
    public selected: string;
    public showTeamRoutes = false;
    public showUserRoutes = false;
    public showSetupsRoutes = false;
    public showOrganizationRoutes = false;
    public showOperationTemplatesRoutes = false;
    public showEmailTemplateRoutes = false;
    public showThreadRoutes = false;

    constructor(
        private userService: UserService,
        private stateService: StateService,
        private transitionService: TransitionService,
        public dashboardSidebarService: DashboardSidebarService) {
    }

    public hasRole(role: string): boolean {
        return this.userService.hasRole(role);
    }

    public navigate(destination: string) {
        // let a: TransitionOptions = {};

        if (destination === this.selected) { return; }

        if (destination === this.BUTTONS.HOME) {
            this.stateService.go(RouterStates.dashboard_home);
        }
        else if (destination === this.BUTTONS.USERS) {
            this.stateService.go(RouterStates.dashboard_users, null, { reload: true });
        }
        else if (destination === this.BUTTONS.USER) {
            this.stateService.go(RouterStates.dashboard_user, { userId: this.stateService.params.userId });
        }
        else if (destination === this.BUTTONS.GRADUATIONS) {
            this.stateService.go(RouterStates.dashboard_graduations);
        }
        else if (destination === this.BUTTONS.TEAMS) {
            this.stateService.go(RouterStates.dashboard_teams, null, { reload: true });
        }
        else if (destination === this.BUTTONS.THREADS) {
            this.stateService.go(RouterStates.dashboard_threads_search, null, { reload: true });
        }
        else if (destination === this.BUTTONS.THREAD_VIEW) {
            this.stateService.go(RouterStates.dashboard_thread_view, { threadId: this.stateService.params.teamId });
        }
        else if (destination === this.BUTTONS.PLANS) {
            this.stateService.go(RouterStates.dashboard_plans);
        }
        else if (destination === this.BUTTONS.TEAM) {
            this.stateService.go(RouterStates.dashboard_team_view, { teamId: this.stateService.params.teamId });
        }
        else if (destination === this.BUTTONS.TEAM_GRADUATIONS) {
            this.stateService.go(RouterStates.dashboard_team_graduations, { teamId: this.stateService.params.teamId });
        }
        else if (destination === this.BUTTONS.TEAM_MEMBERS) {
            this.stateService.go(RouterStates.dashboard_team_members, { teamId: this.stateService.params.teamId });
        }
        else if (destination === this.BUTTONS.TEAM_THREADS) {
            destination = this.BUTTONS.THREADS;
            this.stateService.go(RouterStates.dashboard_threads_search, { q: 'team:' + this.stateService.params.teamId }, { reload: true });
        }
        else if (destination === this.BUTTONS.TEAM_SETUPS) {
            this.stateService.go(RouterStates.dashboard_teamSetup_setups, { setupId: this.stateService.params.setupId });
        }
        else if (destination === this.BUTTONS.URL_VALIDATOR) {
            this.stateService.go(RouterStates.dashboard_urlValidator, { url: null }, { reload: true });
        }
        else if (destination === this.BUTTONS.OPERATION_TEMPLATES) {
            this.stateService.go(RouterStates.dashboard_operation_templates, null, { reload: true });
        }
        else if (destination === this.BUTTONS.ORGANIZATIONS) {
            this.stateService.go(RouterStates.dashboard_organizations_listOrganizations, { organizationId: null }, { reload: true });
        }
        else if (destination === this.BUTTONS.ORGANIZATION_VIEW) {
            this.stateService.go(RouterStates.dashboard_organizations_organization_view, { organizationId: this.stateService.params.organizationId });
        }
        else if (destination === this.BUTTONS.ORGANIZATION_MEMBERS) {
            this.stateService.go(RouterStates.dashboard_organizations_organization_members, { organizationId: this.stateService.params.organizationId });
        }
        else if (destination === this.BUTTONS.ORGANIZATION_IMPORT_CONFIG) {
            this.stateService.go(RouterStates.dashboard_organizations_organization_importConfig, { organizationId: this.stateService.params.organizationId });
        }
        else if (destination === this.BUTTONS.TASKS) {
            this.stateService.go(RouterStates.dashboard_tasks_list, { fromId: null, pageSize: null });
        }
        else if (destination === this.BUTTONS.TEAM_REQUESTS) {
            this.stateService.go(RouterStates.dashboard_teamRequests_list, null, { reload: true });
        }
        else if (destination === this.BUTTONS.EMAIL_TEMPLATES_LIST) {
            this.stateService.go(RouterStates.dashboard_emailTemplates_list, null, { reload: true });
        }
        else if (destination === this.BUTTONS.CLUSTER_SCRIPTS) {
            this.stateService.go(RouterStates.dashboard_clusterScripts, null, { reload: true });
        }
        else if (destination === this.BUTTONS.CONNECTIVITY_TESTER) {
            this.stateService.go(RouterStates.dashboard_connectivityTest, { resultId: this.stateService.params.resultId }, { reload: true });
        }
    }

    public ngOnInit() {

        this.updateCurrentState(this.stateService.current);
        let self = this;
        this.transitionService.onSuccess({ to: 'dashboard.**' }, (trans: Transition) => {
            self.updateCurrentState(trans.to());
        });

    }

    private updateCurrentState(state: StateDeclaration) {
        let routeName = state.name;
        if (routeName === RouterStates.dashboard_home) {
            this.selected = this.BUTTONS.HOME;
        }
        else if (routeName === RouterStates.dashboard_users) {
            this.selected = this.BUTTONS.USERS;
        }
        else if (routeName === RouterStates.dashboard_user_view) {
            this.selected = this.BUTTONS.USER;
        }
        else if (routeName === RouterStates.dashboard_graduations) {
            this.selected = this.BUTTONS.GRADUATIONS;
        }
        else if (routeName === RouterStates.dashboard_teams) {
            this.selected = this.BUTTONS.TEAMS;
        }
        else if (routeName === RouterStates.dashboard_threads_search) {
            this.selected = this.BUTTONS.THREADS;
        }
        else if (routeName === RouterStates.dashboard_thread_view) {
            this.selected = this.BUTTONS.THREAD_VIEW
        }
        else if (routeName === RouterStates.dashboard_plans) {
            this.selected = this.BUTTONS.PLANS;
        }
        else if (routeName === RouterStates.dashboard_team_view) {
            this.selected = this.BUTTONS.TEAM;
        }
        else if (routeName === RouterStates.dashboard_team_graduations) {
            this.selected = this.BUTTONS.TEAM_GRADUATIONS;
        }
        else if (routeName === RouterStates.dashboard_team_members) {
            this.selected = this.BUTTONS.TEAM_MEMBERS;
        }
        else if (routeName === RouterStates.dashboard_teamSetup_setups) {
            this.selected = this.BUTTONS.TEAM_SETUPS;
        }
        else if (routeName === RouterStates.dashboard_teamSetup_setup_new) {
            this.selected = this.BUTTONS.TEAM_SETUPS_NEW;
        }
        else if (routeName === RouterStates.dashboard_teamSetup_setup_view) {
            this.selected = this.BUTTONS.TEAM_SETUPS_VIEW;            
        }
        else if (routeName === RouterStates.dashboard_urlValidator) {
            this.selected = this.BUTTONS.URL_VALIDATOR;
        }
        else if (routeName === RouterStates.dashboard_operation_templates) {
            this.selected = this.BUTTONS.OPERATION_TEMPLATES;
        }
        else if (routeName === RouterStates.dashboard_organizations_listOrganizations) {
            this.selected = this.BUTTONS.ORGANIZATIONS;
        }
        else if (routeName === RouterStates.dashboard_operation_template_new) {
            this.selected = this.BUTTONS.OPERATION_TEMPLATES_NEW;
        }
        else if (routeName === RouterStates.dashboard_organizations_organization_view) {
            this.selected = this.BUTTONS.ORGANIZATION_VIEW;
        }
        else if (routeName === RouterStates.dashboard_operation_template_view) {
            this.selected = this.BUTTONS.OPERATION_TEMPLATES_VIEW;
        }
        else if (routeName === RouterStates.dashboard_organizations_organization_members) {
            this.selected = this.BUTTONS.ORGANIZATION_MEMBERS;
        }
        else if (routeName === RouterStates.dashboard_organizations_organization_importConfig) {
            this.selected = this.BUTTONS.ORGANIZATION_IMPORT_CONFIG;
        }
        else if (routeName === RouterStates.dashboard_tasks_list) {
            this.selected = this.BUTTONS.TASKS;
        }
        else if (routeName === RouterStates.dashboard_teamRequests_list) {
            this.selected = this.BUTTONS.TEAM_REQUESTS;
        }
        else if (routeName === RouterStates.dashboard_teamRequests_request_view) {
            this.selected = this.BUTTONS.TEAM_REQUEST_VIEW_REQUEST;
        }
        else if (routeName === RouterStates.dashboard_teamRequests_renewal_view) {
            this.selected = this.BUTTONS.TEAM_REQUEST_VIEW_RENEWAL;
        }
        else if (routeName === RouterStates.dashboard_emailTemplates_list) {
            this.selected = this.BUTTONS.EMAIL_TEMPLATES_LIST;
        }
        else if (routeName === RouterStates.dashboard_clusterScripts) {
            this.selected = this.BUTTONS.CLUSTER_SCRIPTS;
        }
        else if (routeName === RouterStates.dashboard_emailTemplates_new) {
            this.selected = this.BUTTONS.EMAIL_TEMPLATES_NEW;
        }
        else if (routeName === RouterStates.dashboard_emailTemplates_view) {
            this.selected = this.BUTTONS.EMAIL_TEMPLATES_VIEW;
        }
        else if (routeName === RouterStates.dashboard_connectivityTest) {
            this.selected = this.BUTTONS.CONNECTIVITY_TESTER;
        }
        else {
            this.selected = null;
        }

        this.showTeamRoutes = routeName.startsWith(RouterStates.dashboard_team + '.');
        this.showOrganizationRoutes = routeName.startsWith(RouterStates.dashboard_organizations_organization + '.');
        this.showUserRoutes = routeName.startsWith(RouterStates.dashboard_user + '.');
        this.showSetupsRoutes = routeName.startsWith(RouterStates.dashboard_teamSetup + '.');
        this.showOperationTemplatesRoutes = routeName.startsWith(RouterStates.dashboard_operation_template + '.');
        this.showEmailTemplateRoutes = routeName.startsWith(RouterStates.dashboard_emailTemplates + '.');
        this.showThreadRoutes = routeName.startsWith(RouterStates.dashboard_threads + '.') && routeName !== RouterStates.dashboard_threads_search;
    }
}
