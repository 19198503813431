import { NgModule } from '@angular/core';
import { DashboardApiService } from './dashboard-api.service';

@NgModule({
  declarations: [],
  imports: [],
  providers: [
    DashboardApiService
  ],
  bootstrap: []
})
export class ApiModule { }
