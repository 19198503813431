// Feature module with common modules

import { NgModule } from '@angular/core';
import { FormsModule } from '@angular/forms';
import { CommonModule } from '@angular/common';
import { ApiModule } from './api/api.module';
import { CoreModule } from './core/core.module';
import { TranslateModule } from '@ngx-translate/core';
import { UIRouterModule } from '@uirouter/angular';

import { BrowserAnimationsModule } from '@angular/platform-browser/animations';
import { MatSortModule, MatToolbarModule, MatSelectModule, MatDialogModule, MatTableModule, MatTabsModule,
        MatButtonModule, MatCardModule, MatInputModule, MatIconModule, MatFormFieldModule, MatRadioModule,
        MatProgressSpinnerModule, MatChipsModule, MatCheckboxModule, MatExpansionModule } from '@angular/material';
import { FlexLayoutModule } from '@angular/flex-layout';

const modules = [
    FormsModule,
    CommonModule,
    ApiModule,
    CoreModule,
    TranslateModule,
    UIRouterModule,

    BrowserAnimationsModule,
    MatSortModule,
    MatToolbarModule,
    MatSelectModule,
    MatDialogModule,
    MatTableModule,
    MatTabsModule,
    MatButtonModule,
    MatCardModule,
    MatInputModule,
    MatIconModule,
    MatFormFieldModule,
    MatRadioModule,
    MatProgressSpinnerModule,
    MatChipsModule,
    MatCheckboxModule,
    MatExpansionModule,
    FlexLayoutModule
];

@NgModule({
  imports: modules,
  exports: modules
})
export class CoreModulesModule { }
