import 'hammerjs';
import { enableProdMode } from '@angular/core';
import { platformBrowserDynamic } from '@angular/platform-browser-dynamic';


import { AppModule } from 'app/app.module';
import { environment } from 'environments/environment';

if (environment.mode === 'release') {
  enableProdMode();
}

// if (environment.mode === 'dev') {
  platformBrowserDynamic().bootstrapModule(AppModule);
// }
// else {
//  platformBrowser().bootstrapModule(AppModule);
// }
