import { UIRouter } from '@uirouter/angular';
import { Injector } from '@angular/core';
import { RouterStates } from './core/router-states.constant';


export function uiRouterConfigFn(router: UIRouter, injector: Injector) {

    router.urlService.rules.otherwise({ state: RouterStates.dashboard_home });
    router.urlService.listen();

    // hacky fix for `router.urlService.config.strictMode(false);` not working
    router.transitionService.onBefore({}, (f) => {
        let url = router.urlService.url();
        let query = null;
        let queryIdx = url.indexOf('?');
        if (queryIdx !== -1) {
            query = url.substr(queryIdx);
            url = url.substr(0, queryIdx);
        }
        if (url.endsWith('/') && Object.keys(f.params).length === 0) {
            let newUrl = url.substr(0, url.length - 1) + (query != null ? query : '');
            router.urlService.url(newUrl, true, f.targetState());
        }
    }, {});
}
