import { Component, Inject } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import { IMediaOptionFormat, IMediaOptionFormatMp4 } from 'app/api/common/media-option-format.model';
import * as _ from 'underscore';

export interface IEditFormatOptions {
    isNew: boolean;
    availableFormats?: string[];
    format?: IMediaOptionFormat;
}

@Component({
    templateUrl: './edit-format.component.html',
    styleUrls: ['./edit-format.component.scss']
})
export class EditFormatComponent {
    
    public mode: ('create' | 'edit');
    public isNew: boolean;
    public format: IMediaOptionFormat;
    public availableFormats: string[];
    public get type(): string { return this.format != null ? this.format.type : ''; }
    public selectedType = null;
    public mp4Presets = [null, 'ultrafast', 'superfast', 'veryfast', 'faster', 'fast', 'medium', 'slow', 'slower', 'veryslow', 'placebo'];

    constructor(
        private mdDialogRef: MatDialogRef<EditFormatComponent>,
        @Inject(MAT_DIALOG_DATA) private data: IEditFormatOptions) {
    
        this.isNew = data.isNew || false;
        if (data.isNew) {
            this.mode = 'create';
            this.availableFormats = data.availableFormats;
            this.selectedType = this.availableFormats[0];
        } else {
            this.mode = 'edit';
            this.format = data.format;
        }
    }

    public close() {
        this.mdDialogRef.close(null);
    }

    public createFormat(type: string) {
        if (this.format != null && this.format.type === type) {
            this.mode = 'edit';
            return;
        }

        switch (type) {
            case 'mp4':
                this.format = <IMediaOptionFormatMp4>{
                    type,
                    crf: null,
                    preset: null,
                    retranscode: false,
                    retranscodeCrf: null,
                    retranscodePreset: null,
                    isH265: false
                };
                break;
            default:
                console.error(`Format ${type} not recognised.`);
                return;
        }

        this.mode = 'edit';
    }

    public mp4RetranscodeChange() {
        if (this.format.type !== 'mp4') {
            return;
        }

        if (!this.format.retranscode) {
            let mp4Format = <IMediaOptionFormatMp4>this.format;
            mp4Format.retranscodeCrf = null;
            mp4Format.retranscodePreset = null;
        }
    }
    
    public save() {

        if (!this.isValid()) {
            alert('not valid');
            return;
        }

        this.mdDialogRef.close(this.format);

    }

    public isValid(): boolean {
        if (this.format.type === 'mp4') {
            let mp4Format = <IMediaOptionFormatMp4>this.format;
            return (mp4Format.crf == null || (mp4Format.crf >= 0 && mp4Format.crf <= 51))
                && (mp4Format.preset == null || _.contains(this.mp4Presets, mp4Format.preset))
                && (mp4Format.retranscode === false || (mp4Format.retranscodeCrf != null && (mp4Format.retranscodeCrf >= 0 && mp4Format.retranscodeCrf <= 51)))
                && (mp4Format.retranscode === false || (mp4Format.retranscodePreset != null && _.contains(this.mp4Presets, mp4Format.retranscodePreset)));
        } else {
            console.error(`Format ${this.format.type} not recognised.`);
            return false;
        }
    }
}
