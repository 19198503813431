import { NgModule } from '@angular/core';
import { OrganizationBasicComponent } from './organization-basic.component';
import { CoreComponentsModule } from '../../../../../../core-components.module';

@NgModule({
  declarations: [
      OrganizationBasicComponent
  ],
  imports: [
    CoreComponentsModule
  ],
  providers: [
  ],
  exports: [
    OrganizationBasicComponent
  ],
  bootstrap: [
      OrganizationBasicComponent
  ]
})
export class OrganizationBasicModule {}
