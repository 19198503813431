
import {of as observableOf,  Observable ,  Subscription } from 'rxjs';

import {map,  take } from 'rxjs/operators';
import { Component, Input, OnChanges, SimpleChanges, Output, EventEmitter, OnDestroy } from '@angular/core';
import * as _ from 'underscore';
import { IOperationTemplateSet, IOperationTemplateBase, IOperationTemplate } from '../../../../../api/operations/operation-template.model';
import { OperationTemplateSetRequest } from '../../../../../api/operations/operation-template-request.model';
import { OperationKey } from '../../../../../api/operations/operation-key.model';
import { DashboardApiService } from '../../../../../api/dashboard-api.service';
import { deepCopy } from '../../../../../core/deep-copy.function';
import { MatTableDataSource, MatDialogRef, MatDialog } from '@angular/material';
import { SelectTemplateComponent } from '../../select-template/select-template.component';
import { OperationTemplateTypes } from '../../../../../api/operations/operation-template-types.enum';
import { TranslateService } from '@ngx-translate/core';
import { DialogService, DialogCaption } from '../../../../../core/dialog.service';
import { ITemplateComponent } from '../template-component-interface';
import { ProcedureTypes } from '../../../../../api/operations/procedure-types.enum';
import { EditTemplateShared } from '../edit-template-shared.service';
import { OperationTemplateChildSettings } from '../../../../../api/operations/operation-template-child-settings.model';
import { PlanPeriod, PlanPeriodTypes } from '../../../../../api/plans/plan-period.model';
import { OperationTemplateService } from '../../../../../core/operation-template.service';

@Component({
    templateUrl: './template-set.component.html',
    styleUrls: ['./template-set.component.scss'],
    selector: 'template-set'
})
export class TemplateSetComponent implements OnChanges, OnDestroy, ITemplateComponent {

    @Input() public shared: EditTemplateShared;
    @Input() public operation: IOperationTemplateSet;
    @Output() public operationChange = new EventEmitter();
    @Input() public editOperation: OperationTemplateSetRequest; 
    @Output() public showTemplateModal: EventEmitter<IOperationTemplateBase> = new EventEmitter<IOperationTemplateBase>();
    public get canEditName(): boolean {
        return (this.operation != null && this.operation.requiredProcedures.length === 0) || (this.operation == null && this.editOperation != null);
    }
    public get isSaving(): boolean { return this.shared != null ? this.shared.isSaving : false; }
    public templates: TemplateItem[] = null;
    public dataSource: MatTableDataSource<TemplateItem> = new MatTableDataSource<TemplateItem>();
    public displayedTemplateColumns: string[] = ['name', 'updated', 'type', 'offset', 'repetition', 'summary', 'buttons'];
    public get canShowTemplate(): boolean { return this.showTemplateModal.observers.length > 0; }
    public get hasArchivedTemplates(): boolean { return _.any((this.templates || []), t => t.isArchived); }
    public shownWarning = false;
    private isEditingSubscription: Subscription = null;

    constructor (
        private api: DashboardApiService,
        private mdDialog: MatDialog,
        private translateService: TranslateService,
        private dialogService: DialogService,
        private templateService: OperationTemplateService) {

    }
    
    public ngOnChanges(changes: SimpleChanges) {
        if (changes.operation && changes.operation.currentValue != null) {
            let operation = (<IOperationTemplateSet>changes.operation.currentValue);
            this.loadExtraData(operation);
        }

        if (changes.shared && changes.shared.currentValue.isNew) {
            this.templates = [];
            this.updateDataSource();
        }

        if (changes.shared) {
            if (this.isEditingSubscription != null) {
                this.isEditingSubscription.unsubscribe();
                this.isEditingSubscription = null;
            }
            if (changes.shared.currentValue != null) {
                this.isEditingSubscription = (<EditTemplateShared>changes.shared.currentValue)
                                                    .isEditingChange.subscribe(isEditing => this.handleEditingChange(isEditing));
            }
        }
    }

    public ngOnDestroy() {
        if (this.isEditingSubscription != null) {
            this.isEditingSubscription.unsubscribe();
            this.isEditingSubscription = null;
        }
    }

    public save(): Observable<boolean> {
        let keysToSave = this.getKeysToSave();
        let settingsToSave = this.getChildSettingsToSave(keysToSave);

        if (!this.hasChanges(keysToSave, settingsToSave)) {
            console.log('no changes');
            return observableOf<boolean>(true).pipe(take(1));
        }

        // Get all procedures with no offset
        let procedureTypes = 
            _.chain(keysToSave)
            .map(k => _.find(this.templates, t => t.template.identifier.key === k.key) || null)
            .filter(t => t != null 
                    && t.template.type === OperationTemplateTypes.Template 
                    && settingsToSave[t.edited.identifier.key].offset == null)
            .map(t => (<IOperationTemplate>t.template).procedure.type)
            .value();

        let observable = new Observable<boolean>((subscriber) => {
            this.validate(procedureTypes, settingsToSave)
                .subscribe(valid => {

                    if (!valid) {
                        subscriber.next(false);
                        subscriber.complete();
                        return;
                    }

                    this.editOperation.templates = keysToSave;
                    this.editOperation.childSettings = settingsToSave;
                    let saveObservable: Observable<IOperationTemplateBase> = null;
                    
                    if (this.shared.isNew) {
                        saveObservable = this.api.operationTemplates.createOperationTemplate(this.editOperation);
                    }
                    else {
                        saveObservable = this.api.operationTemplates.updateOperationTemplate(this.operation.identifier.key, this.editOperation);       
                    }

                    saveObservable
                        .subscribe(
                            o => {
                                this.operation = <IOperationTemplateSet>o;
                                this.operationChange.emit(this.operation);
                                if (!this.shared.isNew) {
                                    this.loadExtraData(this.operation);
                                }
                                this.shownWarning = false;
                                this.templateService.mergeTemplate(o);
                                subscriber.next(true);
                            },
                            err => subscriber.error(err),
                            () => {
                                subscriber.complete();
                            }
                        );

                },
                err => { subscriber.error(err); subscriber.complete(); });                    
            });

        return observable;
    }

    public syncTemplates() {
        let keys = _.map(this.templates, t => new OperationKey(t.template.identifier.key));
        this.loadTemplates(keys)
            .subscribe(updatedTemplates => {
                let anyArchived = false;
                for (let updatedTemplate of updatedTemplates) {
                    anyArchived = anyArchived || updatedTemplate.isArchived;
                    let template = _.find(this.templates, t => t.template.identifier.key === updatedTemplate.identifier.key);
                    if ((template || null) != null) {
                        template.edited = updatedTemplate;
                    }
                }
                this.updateDataSource();
                
                if (anyArchived) {
                    this.dialogService.showDialog(
                        new DialogCaption('EDIT_OPERATION_TEMPLATE.TEMPLATE_SET.SYNC_ARCHIVED_OPERATIONS')
                    );
                }
            });
    }

    public viewTemplate(templateItem: TemplateItem) {
        this.showTemplateModal.emit(<IOperationTemplateBase>templateItem.template);
    }
    
    public addTemplate() {
        this.showSelectTemplateDialog()
            .afterClosed()
            .subscribe(o => {
                let template: IOperationTemplateBase = (o || {}).template || null;
                if (template == null) { return; }

                let existingItem = _.find(this.templates, t => t.template.identifier.key === template.identifier.key) || null;
                if (existingItem == null) {
                    let editSettings = this.createEditSettings();
                    if (template.type === OperationTemplateTypes.Template) {
                        let normalOperation = <IOperationTemplate>template;
                        if (normalOperation.offset != null) {
                            editSettings.offset = deepCopy(normalOperation.offset);
                        }
                    }
                    this.templates.push(new TemplateItem(null, null, template, editSettings));
                }
                else if (existingItem.isUserDeleted) {
                    existingItem.isUserDeleted = false;
                }
                this.updateDataSource();
            });
    }

    public deleteTemplate(template: TemplateItem) {
        if (template.initial != null) {
            template.isUserDeleted = true;
        }
        else {
            let idx = _.findIndex(this.templates, t => t.template.identifier.key === template.template.identifier.key);
            if (idx !== -1) {
                this.templates.splice(idx, 1);
            }
        }
        this.updateDataSource();
    }

    public undoDeleteTemplate(template: TemplateItem) {
        if (template.initial != null) {
            template.isUserDeleted = false;
        }
    }

    private handleEditingChange(isEditing: boolean) {
        if (this.templates != null) {
            if (isEditing) {
                _.each(this.templates, t => { t.edited = deepCopy(t.initial); t.editedSettings = this.createEditSettings(t.initialSettings); });
                
                if (_.any(this.templates, t => t.isArchived) && !this.shownWarning) {
                    this.shownWarning = true;
                    setTimeout(() =>
                        this.dialogService.showDialog(
                            new DialogCaption('EDIT_OPERATION_TEMPLATE.TEMPLATE_SET.ARCHIVED_OPERATIONS')
                        ),
                        250);
                }
            }
            else if (isEditing === false) {
                this.templates = _.chain(this.templates).filter(t => t.initial != null).each(t => { t.edited = null; t.editedSettings = null; }).value();
            }
            this.updateDataSource();
        }
    }

    private validate(procedureTypes: ProcedureTypes[], settingsCollection: {[id: string]: OperationTemplateChildSettings}): Observable<boolean> {
        if (this.editOperation.name.length < 3) {
            this.dialogService.showDialog(
                new DialogCaption('EDIT_OPERATION_TEMPLATE.VALIDATION_FAIL')
            );
            return observableOf<boolean>(false).pipe(take(1));
        }

        if (this.editOperation.requiredProcedures.length > 0) {
            let missingProcedureTypes = 
            _.chain(this.operation.requiredProcedures)
            .filter(p => !_.any(procedureTypes, t => t === p))
            .map(p => this.translateService.instant('SHARED_OPERATIONS.PROCEDURES.' + p.toUpperCase()))
            .value();

            if (missingProcedureTypes.length > 0) {
                let joinedProcedures = missingProcedureTypes.join(', ');
                this.dialogService
                    .showDialog(
                        new DialogCaption('EDIT_OPERATION_TEMPLATE.TEMPLATE_SET.MISSING_PROCEDURES_SAVE', true, { procedures: joinedProcedures })
                    );
                return observableOf<boolean>(false).pipe(take(1));
            }
        }

        for (let sKey in settingsCollection) {
            if (settingsCollection.hasOwnProperty(sKey)) {
                let settings = settingsCollection[sKey];
                if (settings.offset != null && settings.offset.periodLength < 1) {
                    this.dialogService.showDialog(
                        new DialogCaption('EDIT_OPERATION_TEMPLATE.VALIDATION_FAIL')
                    );
                    return observableOf<boolean>(false).pipe(take(1));
                }
            }
        }

        return observableOf<boolean>(true).pipe(take(1));
    }

    private hasChanges(keysToSave: OperationKey[], settingsCollection: {[id: string]: OperationTemplateChildSettings}) {
        let hasChanges = false;
        if (this.shared.isNew) {
            return true;
        }
        if (this.operation.name !== this.editOperation.name) {
            hasChanges = true;
        }

        let existingKeys = this.operation.templates;

        hasChanges = hasChanges || (existingKeys.length !== keysToSave.length);
        if (!hasChanges) {
            for (let existing of existingKeys) {
                // any keys removed?
                if (!_.any(keysToSave, k => k.key === existing.key && k.version === existing.version)) {
                    hasChanges = true;
                    break;
                }
            }
            if (!hasChanges) {
                for (let newKey of keysToSave) {
                    if (!_.any(existingKeys, k => k.key === newKey.key && k.version === newKey.version)) {
                        hasChanges = true;
                        break;
                    }
                }
            }
        }

        for (let sKey in settingsCollection) {
            if (settingsCollection.hasOwnProperty(sKey)) {
                let editedSettings = settingsCollection[sKey];
                let initialSettings = _.chain(this.templates).filter(t => t.edited.identifier.key === sKey).map(t => t.initialSettings).first().value();
                if (initialSettings == null) {
                    return true;
                }
                if (this.periodHasChanges(initialSettings.offset, editedSettings.offset, true)) {
                    return true;
                }
            }
        }

        return hasChanges;
    }

    private getKeysToSave(): OperationKey[] {
        return _.chain(this.templates)
            .filter(t => !t.isUserDeleted && !t.isArchived)
            .map(t => new OperationKey(t.template.identifier.key, t.template.identifier.version))
            .value();
    }

    private getChildSettingsToSave(keys: OperationKey[]): {[id: string]: OperationTemplateChildSettings} {
        let matchingTemplates = _.filter(this.templates, t => _.any(keys, k => k.key === t.edited.identifier.key));
        let result: {[id: string]: OperationTemplateChildSettings} = {};
        for (let template of matchingTemplates) {
            let settings = deepCopy(template.editedSettings || this.createEditSettings());
            if (settings.offset != null && settings.offset.periodLength === 0) {
                settings.offset = null;
            }
            result[template.edited.identifier.key] = settings;
        } 
        return result;
    }

    private showSelectTemplateDialog(): MatDialogRef<SelectTemplateComponent> {
        let keys = _.chain(this.templates).filter(t => !t.isUserDeleted).map(t => t.template.identifier.key).value();
        return this.mdDialog.open(SelectTemplateComponent, {
            width: '1000px',
            height: '500px',
            data: {
                existingIds: keys 
            }            
        });
    }
    
    private loadExtraData(operation: IOperationTemplateSet) {
        if (operation == null) {
            console.error('called loadExtraDat() without an operation.');
            return;
        }
        let templateKeys = _.map(operation.templates, o => o);
        const loadingKey = 'CHILD_TEMPLATES';
        this.shared.addLoadingKey(loadingKey);
        this.loadTemplates(templateKeys)
            .subscribe(t => {
                this.templates = _.map(t, s => {
                    let childSettings = operation.childSettings[s.identifier.key] || new OperationTemplateChildSettings();
                    let editChildSettings = this.shared.isEditing ? this.createEditSettings(childSettings) : null;
                    let editTemplate = this.shared.isEditing ? deepCopy(s) : null;
                    return new TemplateItem(s, childSettings, editTemplate, editChildSettings);
                });
                this.updateDataSource();
            },
            err => console.error('Unable to load templates', err),
            () => this.shared.removeLoadingKey(loadingKey));
    }

    private loadTemplates(templateKeys: OperationKey[]): Observable<IOperationTemplateBase[]> {
        let observables = [];
        if (templateKeys.length === 0) {
            return observableOf<IOperationTemplateBase[]>([]).pipe(take(1));
        }
        let keys = _.map(templateKeys, k => k.version != null ? (k.key + '|' + k.version) : k.key);
        return this.api.operationTemplates
                .getOperationTemplates(null, keys, true).pipe(
                map((v, i) => _.filter(v.items, t => t.type === OperationTemplateTypes.Template)));
    }

    private updateDataSource() {
        this.dataSource.data = this.templates;
    }

    private createEditSettings(initial?: OperationTemplateChildSettings): OperationTemplateChildSettings {
        let copy: OperationTemplateChildSettings = null;
        if (initial != null) {
            copy = deepCopy(initial);
        }
        else {
            copy = new OperationTemplateChildSettings();
        }

        if (copy.offset == null) {
            copy.offset = new PlanPeriod(PlanPeriodTypes.MONTH, 0);
        }
        return copy;
    }

    private periodHasChanges(a: PlanPeriod, b: PlanPeriod, zeroLengthIsNull?: boolean) {
        a = a || null;
        b = b || null;
        
        if (zeroLengthIsNull === true) {
            if (a != null && a.periodLength === 0) {
                a = null;
            }

            if (b != null && b.periodLength === 0) {
                b = null;
            }
        }

        if ((a != null) !== (b != null)) {
            return true;
        }

        return a && b && (a.periodType !== b.periodType || a.periodLength !== b.periodLength);
    }
}

class TemplateItem {
    public initial: IOperationTemplateBase = null;
    public initialSettings: OperationTemplateChildSettings;
    public edited: IOperationTemplateBase = null;
    public editedSettings: OperationTemplateChildSettings;
    public get template() { return (this.edited != null ? this.edited : this.initial); }
    public get settings() { return (this.editedSettings != null ? this.editedSettings : this.initialSettings); }
    public get isArchived() { return (this.template || {isArchived: false}).isArchived; }
    public isUserDeleted = false;

    constructor(
        initial: IOperationTemplateBase, 
        initialSettings: OperationTemplateChildSettings,
        edited: IOperationTemplateBase,
        editedSettings: OperationTemplateChildSettings) {
        this.initial = initial || null;
        this.initialSettings = initialSettings || null;
        this.edited = edited || null;
        this.editedSettings = editedSettings || null;
    }
}
