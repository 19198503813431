import { Pipe, PipeTransform } from '@angular/core';
import * as moment from 'moment';

@Pipe({ name: 'moment', pure: false })
export class MomentPipe implements PipeTransform {

    public transform(value: string, format?: string, utc?: boolean): any {
        if (!value) { return value; }

        let momentValue = moment(value);

        if (utc) {
            momentValue = momentValue.utc(false);
        }

        return momentValue.format(format || 'L LTS');
    }
}
