import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { UrlValidatorComponent } from './url-validator.component';

let validatorState = { 
    name: RouterStates.dashboard_urlValidator, 
    url: '/urlvalidator/:url?', 
    component: UrlValidatorComponent,
    params: {
        url: {
            type: 'string',
            dynamic: true
        }
    }
};

@NgModule({
  declarations: [
    UrlValidatorComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [validatorState]
    })
  ],
  exports: [
    
  ],
  bootstrap: []
})
export class UrlValidatorModule { }
