import { Component } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { EnvironmentService } from './core/environment.service';
import * as moment from 'moment';

@Component({
  selector: 'app-root',
  templateUrl: './app.component.html'
})
export class AppComponent {

  constructor(private translate: TranslateService, private environmentService: EnvironmentService) {
    
    if (this.environmentService.forceSsl) {
      if (!(window.location.href || '').toLowerCase().startsWith('https://')) {
        window.location.href = window.location.href.replace('http://', 'https://');
      }
    }

    this.setupTranslations();
    this.setupMoment();
    
  }

  private setupTranslations() {
    // this language will be used as a fallback when a translation isn't found in the current language
    this.translate.setDefaultLang('en');

    // try to work out which language the browser wants
    let browserLanguage = this.translate.getBrowserLang();
    let browserCultureLanguage = this.translate.getBrowserCultureLang();
    let validLanguages = ['en', 'ja'];
    if (validLanguages.indexOf(browserLanguage) === -1) {
      browserLanguage = 'en';
      browserCultureLanguage = 'en-us';
    }

    // the lang to use, if the lang isn't available, it will use the current loader to get them
    this.translate.use(browserLanguage);
    moment.locale(browserCultureLanguage);
  }

  private setupMoment() {
    return;
  }
}
