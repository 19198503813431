import { HttpErrorResponse } from '@angular/common/http';
import { Component, OnInit } from '@angular/core';
import { StateService } from '@uirouter/angular';
import { DashboardApiService } from 'app/api/dashboard-api.service';
import { IApiErrorCollection } from 'app/api/error/api-error-collection.model';
import { IApiError } from 'app/api/error/api-error.model';
import { getApiErrors } from 'app/api/error/get-api-errors.function';
import { GraduationStatus } from 'app/api/teams/graduation-status.enum';
import { IGraduation } from 'app/api/teams/graduation.model';
import { ITeam } from 'app/api/teams/team.model';
import { IUser } from 'app/api/users/user.model';
import { finalize } from 'rxjs/operators';

@Component({
    templateUrl: './graduations.component.html'
})
export class GraduationsComponent implements OnInit {

    public files: File[];
    public isUploading = false;
    public isUploadFailed = false;
    public uploadErrors: string[] = [];
    public tabIndex = 0;
    public graduations: IGraduation[] = [];

    constructor(
        private stateService: StateService,
        private api: DashboardApiService) {

    }
    
    ngOnInit(): void {
        
        // let parts: BlobPart[] = ['hello'];
        // let file = new File(parts, 'test.csv');
        // this.api.teams.importGraduationsCsv(file)
        //     .subscribe(_ => {});

    }

    public filesSelected() {
        this.isUploadFailed = false;
        this.uploadErrors = [];
        this.tabIndex = 0;
    }

    public cancelFile() {
        this.files = [];
    }

    public uploadFile() {
        this.isUploading = true;
        this.api.teams.importGraduationsCsv(this.files[0])
            .pipe(finalize(() => this.isUploading = false))
            .subscribe(
                result => {
                    this.graduations = result.items;
                    this.tabIndex = 0;
                    this.files = [];
                },
                err => {
                    let errorCollection: IApiError[] = null;
                    if (err instanceof HttpErrorResponse && (errorCollection = getApiErrors(err)) != null && errorCollection.length > 0) {
                        let errorObject = errorCollection[0];
                        if (errorObject.code === 'VALIDATION_FAILURE') {
                            let expression = new RegExp("(\r\n|\r|\n)");
                            let parts = errorObject.message.split(expression);
                            this.uploadErrors = parts;
                        } else {
                            this.uploadErrors = [errorObject.code + ' ' + errorObject.message];
                        }
                    }
                    this.tabIndex = 1;
                    this.isUploadFailed = true; 
                }
            );
    }
}
