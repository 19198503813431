import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../core-components.module';
import { RouterStates } from '../../../core/router-states.constant';
import { UIRouterModule } from '@uirouter/angular';
import { ThreadsComponent } from './threads.component';
import { CdkTableModule } from '@angular/cdk/table';
import { SearchThreadsModule } from './search-threads/search-threads.module';
import { ViewThreadModule } from './view-thread/view-thread.module';

let threadsState = { name: RouterStates.dashboard_threads, component: ThreadsComponent };

@NgModule({
  declarations: [
    ThreadsComponent
  ],
  imports: [
    CoreComponentsModule,
    UIRouterModule.forChild({
      states: [threadsState]
    }),
    CdkTableModule,
    SearchThreadsModule,
    ViewThreadModule
  ],
  providers: [
    
  ],
  bootstrap: [
  ]
})
export class ThreadsModule {}

