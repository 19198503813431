import { OperationTemplateTypes }from './operation-template-types.enum';
import { PlanPeriod }from '../plans/plan-period.model';
import { ProcedureRequestBase }from './procedure-request.model';
import { OperationKey } from './operation-key.model';
import { IOperationTemplateSet, IOperationTemplate, IOperationTemplateBase } from './operation-template.model';
import { deepCopy } from '../../core/deep-copy.function';
import { OperationTemplateChildSettings } from './operation-template-child-settings.model';


export abstract class OperationTemplateRequestBase {
    public type: OperationTemplateTypes;
    public name: string;

    public static ConvertOperationTemplateToRequest(template: IOperationTemplateBase): OperationTemplateRequestBase {
        if (template.type === OperationTemplateTypes.Template) {
            let standardTemplate = <IOperationTemplate>template;
            return new OperationTemplateRequest(
                deepCopy(standardTemplate.name),
                deepCopy(standardTemplate.repetitionPeriod),
                standardTemplate.repetitionCount,
                deepCopy(standardTemplate.offset),
                ProcedureRequestBase.ConvertProcedureToRequest(standardTemplate.procedure)
            );
        }
        else if (template.type === OperationTemplateTypes.TemplateSet) {
            let setTemplate = <IOperationTemplateSet>template;
            return new OperationTemplateSetRequest(
                deepCopy(setTemplate.name),
                deepCopy(setTemplate.requiredProcedures),
                deepCopy(setTemplate.templates),
                deepCopy(setTemplate.childSettings)
            );
        }
        else {
            throw 'Template type not implemeneted in ConvertOperationTemplateToRequest';
        }
    }

    constructor(name?: string) {
        if (name != null && name !== undefined) {
            this.name = name;
        }
    }
}

export class OperationTemplateRequest extends OperationTemplateRequestBase {
    public repetitionPeriod: PlanPeriod;
    public repetitionCount: number;
    public offset?: PlanPeriod;
    public procedure: ProcedureRequestBase;

    constructor(name?: string, repetitionPeriod?: PlanPeriod, repetitionCount?: number, offset?: PlanPeriod, procedure?: ProcedureRequestBase) {
        super(name);
        this.type = OperationTemplateTypes.Template;
        
        if (repetitionPeriod != null && repetitionPeriod !== undefined) {
            this.repetitionPeriod = repetitionPeriod;
        }
        if (repetitionCount != null && repetitionCount !== undefined) {
            this.repetitionCount = repetitionCount;
        }
        if (offset != null && offset !== undefined) {
            this.offset = offset;
        }
        if (procedure != null && procedure !== undefined) {
            this.procedure = procedure;
        }
    }
}

export class OperationTemplateSetRequest extends OperationTemplateRequestBase {
    public requiredProcedures: string[];
    public templates: OperationKey[];
    public childSettings: {[id: string]: OperationTemplateChildSettings};

    constructor(
        name?: string, 
        requiredProcedures?: string[], 
        templates?: OperationKey[], 
        childSettings?: {[id: string]: OperationTemplateChildSettings}) {
        super(name);
        this.type = OperationTemplateTypes.TemplateSet;
        this.requiredProcedures = requiredProcedures || [];
        this.templates = templates || [];
        this.childSettings = childSettings || {};        
    }
}
