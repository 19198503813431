import { Component, Input, Inject, Optional } from '@angular/core';
import { MAT_DIALOG_DATA, MatDialogRef } from '@angular/material';
import * as _ from 'underscore';

@Component({
    templateUrl: './filter-list.component.html',
    styleUrls: ['./filter-list.component.scss']
})
export class FilterListComponent {

    public options: FilterListOption[];
    public get allSelected(): boolean {
        if (this.options == null) { return false; }
        return _.all(this.options, o => o.selected);
     }
     public set allSelected(value: boolean) { 
        for (let item of this.options) {
            item.selected = value;
        }
     }

    constructor(
        private mdDialogRef: MatDialogRef<FilterListComponent>,
        @Inject(MAT_DIALOG_DATA) private dialogData: any) {
            if (dialogData.options != null) {
                this.options = dialogData.options;
            }
    }

    public closeDialog() {
        if (this.mdDialogRef != null) {
            this.mdDialogRef.close(null);
        }
    }

    public cancel() {
        // this.dialogService.showDialog(
        //     new DialogCaption('SHARED.WITHOUT_SAVING_PROMPT'),
        //     new DialogYesButton(() => this.mdDialogRef.close()),
        //     new DialogNoButton()
        // );
    }

    public save() {
        // this.mdDialogRef.close({ json: this.minimizeJson(this.jsonData) });
    }
}

export class FilterListOption {

    constructor (public displayName: string, public translateKey?: boolean, public value?: any, public selected?: boolean) {
        if (this.translateKey == null) {
            this.translateKey = true;
        }
        if (this.value == null) {
            this.value = this.displayName;
        }
        if (this.selected == null) {
            this.selected = false;
        }
    }
}