import { Injectable } from '@angular/core';
import { DashboardApiService } from 'app/api/dashboard-api.service';
import { Observable } from 'rxjs';
import { IEmailPlaceholder } from 'app/api/email/email-placeholder.model';
import { map, shareReplay } from 'rxjs/operators';
import * as _ from 'underscore';
import { IEmailPlaceholderOptions } from 'app/api/email/email-placeholder-options.model';

@Injectable()
export class SelectPlaceholderService {

    public isLoading = false;
    private placeholders: Observable<IEmailPlaceholderOptions> = null;

    constructor(private api: DashboardApiService) {

    }

    public loadPlaceholders(): Observable<IEmailPlaceholderOptions> {

        if (this.placeholders != null) {
            return this.placeholders;
        }

        this.placeholders = this.api.emailTemplates.getPlaceholders()
            .pipe(shareReplay());

        return this.placeholders;
    }

}
