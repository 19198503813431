import { Pipe, PipeTransform, ChangeDetectorRef, OnDestroy } from '@angular/core';
import { TranslateService } from '@ngx-translate/core';
import { Subscription } from 'rxjs';

@Pipe({ name: 'spseconds', pure: false })
export class SplyzaSecondsPipe implements PipeTransform, OnDestroy {

    private subscription: Subscription;

    constructor(private _ref: ChangeDetectorRef, private translateService: TranslateService) {

        this.subscription = this.translateService.onTranslationChange
            .subscribe((t) => {
                this._ref.markForCheck();
            });

    }

    public transform(value: number, showSign?: boolean, includeBasic?: boolean): any {
        if (!value) { 
            value = 0;            
         }

        if (includeBasic == null) {
            includeBasic = true;
        }

        let units = this.translateService.instant('SHARED.STORAGE.UNITS.SECONDS');
        let tmp = Number(value);
        let seconds = tmp % 60; tmp = Math.floor(tmp / 60);
        let minutes = tmp % 60; tmp = Math.floor(tmp / 60);
        let hours = tmp;

        let formattedTime = this.padDigit(hours) + ':' + this.padDigit(minutes) + ':' + this.padDigit(seconds);

        if (showSign === true && value > 0) {
            formattedTime = '+' + formattedTime;
        }
        else if (value < 0) {
            formattedTime = '-' + formattedTime;
        }
        
        let key = includeBasic ? 'SHARED.STORAGE.FORMATTED_TIME_SECONDS_BASIC' : 'SHARED.STORAGE.FORMATTED_TIME_SECONDS';
        return this.translateService.instant(key, {
            formattedTime: formattedTime,
            value: value,
            units: units
        });
    }

    public ngOnDestroy() {

        if (this.subscription) {
            this.subscription.unsubscribe();
        }

    }

    private padDigit(value: number) {
        if (value > 9) { return value; }
        let tmp = '00' + String(value);
        return tmp.substr(tmp.length - 2, 2);
    }
}
