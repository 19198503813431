import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output }from '@angular/core';
import { ITask } from '../../../../../api/tasks/task.model';
import { MatTableDataSource } from '@angular/material';
import * as _ from 'underscore';
import * as moment from 'moment';

@Component({
    templateUrl: './task-related.component.html',
    styleUrls: ['./task-related.component.scss'],
    selector: 'task-related'
})
export class TaskRelatedComponent implements OnChanges {
    
    @Input() public task: ITask;
    @Output('viewTask') public doViewTask = new EventEmitter<any>();

    public dataSource: MatTableDataSource<ITask> = new MatTableDataSource<ITask>();
    public displayedColumns = ['name', 'created', 'status'];

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.task && changes.task.currentValue !== changes.task.previousValue) {
            if (changes.task.currentValue == null) {
                this.dataSource.data = [];
            }
            else {
                let related = _.sortBy(this.task.relatedTasks, t => moment(t.created).toDate());
                this.dataSource.data = related;
            }
        }
    }

    public viewTask(event: MouseEvent, task: ITask) {
        if (event.which !== 1 && event.which !== 2) {
            return;
        }

        let openInNewWindow = (event.which === 1 && event.ctrlKey === true) || event.which === 2;
        if (this.doViewTask != null) {
            this.doViewTask.emit({ task: task, newWindow: openInNewWindow });
        }
    }
}
