import { Injectable } from '@angular/core';
import { environment } from 'environments/environment';
import { LocalStorageService } from './local-storage.service';

@Injectable()
export class EnvironmentService {

    private _environment: any;
    private _apiBase: string = null;
    private _apiBaseStaging: string = null;
    private _siteUrl: string = null;
    private _frontendUrl: string = null;
    private _useStaging = false;

    constructor(
        private localStorageService: LocalStorageService) {
        let currentUrl = window.location.href;
        let hostEnd = currentUrl.indexOf('/', currentUrl.indexOf('//') + 2);
        currentUrl = currentUrl.substr(0, hostEnd);

        this._apiBase = this.removeTrailingSlash(environment.apiBase);
        this._apiBaseStaging = this.removeTrailingSlash(environment.apiBaseStaging);
        this._siteUrl = currentUrl;
        this._frontendUrl = this.removeTrailingSlash(environment.frontendUrl);
        this._environment = environment;

        if (localStorageService.getSessionItem('useStaging') === 'true') {
            this._useStaging = true;
        }
    }

    public get isProduction(): boolean { return this._environment.mode === 'release'; }
    public get isSandbox(): boolean { return this._environment.mode === 'sandbox'; }
    public get isDevelopment(): boolean { return this._environment.mode === 'dev'; }
    public get forceSsl(): boolean { return this._environment.forceSsl || false; }
    public get apiBase(): string { return this._apiBase; }
    public get apiBaseStaging(): string { return this._apiBaseStaging; }
    public get siteUrl(): string { return this._siteUrl; }
    public get frontendUrl(): string { return this._frontendUrl; }
    public get useStaging(): boolean { return this._useStaging; }
    public set useStaging(value: boolean) { this._useStaging = value; this.localStorageService.setSessionItem('useStaging', String(value)); }

    private removeTrailingSlash(url: string): string {
        if (!url) {
            return url;
        }

        if (url.endsWith('/')) {
            return url.substring(0, url.length - 1);
        }
        else {
            return url;
        }
    }

    public makeFrontendUrl(path: string): string {
        if (path.length > 0 && path.substring(0, 1) !== '/') {
            return `${this._frontendUrl}/${path}`;
        } else {
            return `${this._frontendUrl}${path}`;
        }
    }
}
