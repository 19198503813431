import { NgModule } from '@angular/core';
import { CoreModulesModule } from '../../core-modules.module';
import { PaginatorComponent } from './paginator.component';

@NgModule({
  declarations: [
      PaginatorComponent
  ],
  imports: [
      CoreModulesModule
  ],
  exports: [
      PaginatorComponent
  ],
  providers: [
  ],
  bootstrap: [
      PaginatorComponent
  ]
})
export class PaginatorModule { }
