import { NgModule } from '@angular/core';
import { CoreComponentsModule } from '../../../../core-components.module';
import { ViewPlanComponent } from './view-plan.component';
import { ComponentsModule } from '../../../../components/components.module';

@NgModule({
    imports: [
        CoreComponentsModule,
        ComponentsModule
    ],
    declarations: [
        ViewPlanComponent
    ],
    exports: [
        ViewPlanComponent
    ],
    bootstrap: [
        ViewPlanComponent
    ]
})
export class ViewPlanModule { }
