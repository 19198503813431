
import {of as observableOf,  Observable } from 'rxjs';
import { Component, Input, OnInit, OnChanges, SimpleChanges } from '@angular/core';
import { ISendEmailProcedure } from '../../../api/operations/procedure-model';
import { IProcedureComponent } from '../procedure-component-interface';
import { SupporterLimitProcedureRequest, SendEmailProcedureRequest } from '../../../api/operations/procedure-request.model';
import { take } from 'rxjs/operators';
import { SendEmailProcedureService } from './send-email-procedure.service';
import { IEmailTemplate } from 'app/api/email/email-template.model';
import { StateService } from '@uirouter/angular';
import { RouterStates } from 'app/core/router-states.constant';
import * as _ from 'underscore';

class EmailTargetOption {
    constructor(public targetName: string, public selected?: boolean) {
        if (selected == null) {
            selected = false;
        }
    }
}

@Component({
    templateUrl: './send-email-procedure.component.html',
    styleUrls: ['./send-email-procedure.component.scss'],
    selector: 'send-email-procedure'
})
export class SendEmailProcedureComponent implements IProcedureComponent, OnInit, OnChanges {

    @Input() public procedure: ISendEmailProcedure;
    @Input() public editProcedure: SendEmailProcedureRequest;
    @Input() public isEditing: boolean;
    @Input() public isSaving: boolean;
    public templates: IEmailTemplate[] = [];
    public get isLoading(): boolean { return this.procedureService.isLoading; }
    public targetOptions: EmailTargetOption[];

    constructor(
        private procedureService: SendEmailProcedureService, 
        private stateService: StateService) {

    }

    public hasChanges(): boolean {
        return (this.procedure || null) == null || (this.procedure.templateId !== this.editProcedure.templateId || this.targetsChanged());
    }

    public ngOnInit(): void {
        let templateToLoad = this.procedure != null ? this.procedure.templateId : null;
        this.procedureService.loadTemplates(templateToLoad)
            .subscribe(templates => {
                this.templates = templates;
            },
            err => {
                /* todo: handle */
            });

        this.targetOptions = [];
        let keys = ['administrator', 'contact'];
        for (let key of keys) {
            this.targetOptions.push(new EmailTargetOption(key));
        }
    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.editProcedure.currentValue !== changes.editProcedure.previousValue && changes.editProcedure.currentValue != null) {
            this.targetOptions = [];
            let keys = ['administrator', 'contact'];
            for (let key of keys) {
                this.targetOptions.push(new EmailTargetOption(key, _.findIndex(changes.editProcedure.currentValue.emailTargets, v => v === key) !== -1));
            }
        }
    }

    public targetSelected(option: EmailTargetOption) {
        if (option.selected) {
            this.editProcedure.emailTargets.push(option.targetName);
        } else {
            let idx = this.editProcedure.emailTargets.findIndex(v => v === option.targetName);
            if (idx !== -1) {
                this.editProcedure.emailTargets.splice(idx, 1);
            }
        }
    }

    public validate(): Observable<boolean> {
        return observableOf<boolean>(this.editProcedure.templateId != null && this.editProcedure.templateId !== '' && this.editProcedure.templateId !== '000000000000000000000000' && this.editProcedure.emailTargets.length > 0).pipe(take(1));
    }

    public viewTemplate(template: IEmailTemplate) {
        this.stateService.go(RouterStates.dashboard_emailTemplates_view, { templateId: template.id, templateName: template.name });
    }
    
    private targetsChanged(): boolean {
        let originalTargets = _.chain(this.procedure.emailTargets).map(t => t.toLowerCase()).sortBy(t => t).reduce<string>((v, r) => (r || '') + ';' + v, '').value();
        let newTargets = _.chain(this.editProcedure.emailTargets).map(t => t.toLowerCase()).sortBy(t => t).reduce<string>((v, r) => (r || '') + ';' + v, '').value();
        return originalTargets !== newTargets;
    }
}
