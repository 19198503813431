
export function toBase64(str: string): string {
    let unescape = (<any>window).unescape;
    return window.btoa(unescape(encodeURIComponent(str)));
}

export function toModifiedBase64(str: string): string {
    return replaceAll(replaceAll(replaceAll(toBase64(str), '+', '-'), '/', '_'), '=', '');
}

export function fromBase64(base64Str: string): string {
    try {
        let escape = (<any>window).escape;
        return decodeURIComponent(escape(window.atob(base64Str)));
    } catch (e) {
        console.error('unable to decode base64 string: ', e);
        return null;
    }
}

export function fromModifiedBase64(str: string): string {
    if (str == null) {
        return null;
    }
    str = replaceAll(replaceAll(str, '-', '+'), '_', '/');
    let diff = 4 - (str.length % 4);
    if (diff < 4) {
        str = str + '='.repeat(diff);
    }
    return fromBase64(str);
}

export function replaceAll(str: string, search: string, replacement: string): string {
    return str.split(search).join(replacement);
}
