import { Component, Input, OnChanges, SimpleChanges, EventEmitter, Output }from '@angular/core';
import { ITaskTemplate } from '../../../../../api/tasks/task-template.model';
import * as _ from 'underscore';
import { MatTableDataSource, MatTable } from '@angular/material';
import { DialogService, DialogCaption, DialogYesButton, DialogNoButton } from '../../../../../core/dialog.service';

@Component({
    templateUrl: './template-selector.component.html',
    styleUrls: ['./template-selector.component.scss'],
    selector: 'template-selector'
})
export class TemplateSelectorComponent implements OnChanges {

    @Input() public templates: { [id: string]: ITaskTemplate[] };
    public templateDataSources: { [id: string]: MatTableDataSource<ITaskTemplate> } = {};
    public templateTypes: string[] = [];
    public displayedColumns = ['name', 'description', 'parameters', 'buttons'];
    @Output() public templateSelected = new EventEmitter<ITaskTemplate>();
    private selectingTemplate = false;

    constructor(private dialogService: DialogService) {

    }

    public ngOnChanges(changes: SimpleChanges) {
        if (changes.templates != null && changes.templates.currentValue !== changes.templates.previousValue) {
            if (changes.templates.currentValue != null) {
                this.handleTemplatesChanged(changes.templates.currentValue);
            }
            else {
                this.templateTypes = [];
            }
        }
    }

    public selectTemplate(template: ITaskTemplate) {
        if (this.selectingTemplate) {
            return;
        }
        this.selectingTemplate = true;

        this.dialogService.showDialog(
            new DialogCaption('CREATE_TASK.TEMPLATE_SELECTOR.CONFIRM_SELECTION', true, { name: template.systemTypeName }),
            new DialogYesButton(() => { this.templateSelected.emit(template); this.selectingTemplate = false }),
            new DialogNoButton(() => this.selectingTemplate = false)
        );
    }

    private getOwnKeys(object: any): string[] {
        return _.chain(Object.keys(object)).filter(k => object.hasOwnProperty(k)).value();
    }

    private handleTemplatesChanged(templates:  { [id: string]: ITaskTemplate[] }) {
        this.templateTypes = _.sortBy(this.getOwnKeys(templates), k => k.toLowerCase());
        let existingSources = this.getOwnKeys(this.templateDataSources);

        let keysToRemove = _.filter(existingSources, k => !_.any(this.templateTypes, t => t === k));
        let keysToAdd = _.filter(this.templateTypes, k => !_.any(existingSources, s => s === k));

        for (let key of keysToRemove) {
            delete this.templateDataSources[key];
        }

        for (let key of keysToAdd) {
            this.templateDataSources[key] = new MatTableDataSource<ITaskTemplate>();
        }

        for (let key of this.templateTypes) {
            this.templateDataSources[key].data = _.sortBy(this.templates[key], t => t.systemTypeName.toLowerCase());
        }
    }
}
